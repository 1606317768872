import {
  ContentScreen,
  Input,
  PositionedContainer
} from '../../../../components'

import { InputList } from '../../../../domains'
import responsiveStyles from './InputScreen.styled'

/**
 * It returns a ContentScreen component with a bunch of props.
 * @returns Input Screen
 */
const InputScreen = () => {
  return (
    <ContentScreen
      key="InputScreen"
      chapter="Input"
      domainName="Elem."
      pageNumber="30"
      asideTopContent={
        <PositionedContainer left="10px" top="20px">
          <InputList />
        </PositionedContainer>
      }
      content={
        <PositionedContainer {...responsiveStyles}>
          <Input
            width="300px"
            mega
            type="primary"
            defaultValue="user@domain.com"
            state="active"
          />
        </PositionedContainer>
      }
    />
  )
}

export default InputScreen
