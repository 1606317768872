import './AppIconIosScreen.vars.css'

const responsiveStyles = {
  // here use vars that you can overwrite it on different media size
  width: 'var(--ios-logo-width)',
  height: 'var(--ios-logo-height)',

  /* positioned container  */
  top: 'var(--ios-logo-top)',
  right: 'var(--ios-logo-right)'
}

export default responsiveStyles
