import { useMemo } from 'react'
import { useDocument, useLoading } from 'hooks'
import {
  useGetApplicationPlatform,
  useGetApplicationRole
} from 'domains/Application/hooks'

const useGetApplicationInitialValues = (id) => {
  // [DATA_FETCH]
  const [application, applicationLoading] = useDocument({
    ref: id ? `applications/${id}` : null
  })
  const [platformId, platformLoading] = useGetApplicationPlatform(application)
  const [roleId, roleLoading] = useGetApplicationRole(application)

  // [COMPUTED_PROPERTIES]
  const initialValues = useMemo(
    () => ({
      application,
      platformId,
      roleId
    }),
    [application, platformId, roleId]
  )
  const loadings = useMemo(
    () =>
      id
        ? [!application, applicationLoading, platformLoading, roleLoading]
        : [],
    [id, application, applicationLoading, platformLoading, roleLoading]
  )

  // [ADDITIONAL_HOOKS]
  const loading = useLoading(loadings)

  return [initialValues, loading]
}

export default useGetApplicationInitialValues
