import styled, { css } from 'styled-components'

const headerColor = {
  secondary: css`
    color: var(--title-color-secondary);
  `,
  success: css`
    color: var(--title-color-success);
  `,
  warning: css`
    color: var(--title-color-warning);
  `,
  danger: css`
    color: var(--title-color-danger);
  `,
  white: css`
    color: var(--title-color-white);
  `,
  accent: css`
    color: var(--title-color-accent);
  `
}
const headerConfig = (level) => {
  const type = level === 'subtitle' ? `--subtitle` : `--title-${level}`
  return css`
    font-size: var(${type}-font-size);
    line-height: var(${type}-line-height);
    margin-top: var(${type}-margin-top);
    margin-bottom: var(${type}-margin-bottom);
    letter-spacing: var(${type}-letter-spacing);
    font-family: var(${type}-font-family);
  `
}

const TitleStyled = styled.h1`
  margin: 0;
  color: ${({ color }) =>
    color ? `var(--title-color-${color})` : `var(--title-color-primary)`};
  text-align: ${({ textAlign }) => textAlign};
  font-weight: ${({ fontWeight }) =>
    fontWeight ? `var(--fw-${fontWeight})` : `var(--fw-bold)`};
  position: ${({ position }) => position};
  text-overflow: ${({ textOverflow }) => textOverflow};
  overflow: ${({ overflow }) => overflow};
  ${({ type }) => headerColor[type]}
  ${({ level }) => headerConfig(level)};
`
export default TitleStyled
