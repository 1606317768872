import './ExteriorScreen.vars.css'

import {
  Decoration,
  ExteriorScreenContainer,
  ExteriorScreenInfo
} from './components'
import { Footer, Header } from '../../../components'

import ExteriorScreenStyled from './ExteriorScreen.styled'
import { IMAGE_DECORATIONS } from '../../../__constants__'
import PropTypes from 'prop-types'

/**
 *  Used for first and last screen -- greeting and thanks.
 *  @param {string} subtitle - subtitle for information.
 *  @param {string} title - main title page.
 *  @param {string} decoration - closing or opening, choose background to display.
 *  EXTERIOR_DECORATION_PROPS - opening or closing - background for components.
 *  @returns exterior screen.
 */

const ExteriorScreen = (props) => {
  const { subtitle, title, decoration } = props
  const { BG, THX } = IMAGE_DECORATIONS
  // Checks the screen type and inserts the necessary decorations
  const imageSrc = decoration === 'opening' ? BG : THX

  return (
    <ExteriorScreenStyled className="senseteq">
      <Decoration decoration={decoration} imageSrc={imageSrc} />
      <Header breadcrumbs />
      <ExteriorScreenContainer>
        <ExteriorScreenInfo subtitle={subtitle} title={title} />
      </ExteriorScreenContainer>
      <Footer />
    </ExteriorScreenStyled>
  )
}

ExteriorScreen.propTypes = {
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string.isRequired,
  decoration: PropTypes.string.isRequired
}

export default ExteriorScreen
