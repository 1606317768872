import { Alphabet, MegaText, TypoIntro } from 'brandbook/domains'

import { ContentScreen } from 'brandbook/components'
import { PositionedContainerStyled } from '../__constants__'
import { useBrandbookConfig } from 'brandbook/contexts/BrandbookConfig'
import { useBrandbookInitialValues } from 'domains/Brandbook/hooks'
import { useParams } from 'react-router-dom'

const TypographySecondaryScreen = () => {
  // [ADDITIONAL_HOOKS]
  const { brandbookId } = useParams()
  const { brandbookConfigData } = useBrandbookConfig()
  const [brandbook] = useBrandbookInitialValues(brandbookId)

  // [COMPUTED PROPERTIES]
  const secondaryFont =
    brandbookConfigData?.ffSecondary || brandbook?.brandbookConfig?.ffSecondary
  const decription =
    'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Praesent facilisis nibh ut placerat fermentum. Etiam ut hendrerit elit. Suspendisse ultricies, augue in gravida egestas, enim quam ornare odio, id ultrices sapien purus a erat. Etiam et tincidunt dui. Morbi vel posuere nisi, ut suscipit lectus. Vivamus at condimentum arcu. Mauris at malesuada quam. '
  return (
    <ContentScreen
      positionOuter
      pageNumber="09"
      chapter="Secondary"
      domainName="Typo."
      key="TypographySecondaryScreen"
      asideTopContent={
        <TypoIntro
          title={secondaryFont?.name || 'Lorem'}
          subtitle="Semibold / Medium / Regular"
          description={decription}
        />
      }
      content={
        <>
          <PositionedContainerStyled>
            <Alphabet ff="secondary" />
          </PositionedContainerStyled>
          <MegaText responsive />
        </>
      }
    />
  )
}

export default TypographySecondaryScreen
