const ICONS_LIST = [
  'Home',
  'Search',
  'Settings',
  'Trash',
  'TV',
  'Language',
  'Comments',
  'User',
  'Mail',
  'Bell',
  'Image',
  'Scanner',
  'Mobile',
  'Target',
  'Notification',
  'Help'
]

export default ICONS_LIST
