import { Col, Container, Row, Title } from '@qonsoll/react-design'

import PropTypes from 'prop-types'
import { Select } from 'antd'
import { useTranslations } from 'contexts/Translation'
import { useMemo } from 'react'
import { useGetColors } from 'domains/Color/hooks'
import _ from 'lodash'
import { getId } from 'services/firestore'

const { Option } = Select

const ColorListWithCreate = (props) => {
  const { value, onChange } = props

  // [ADDITIONAL_HOOKS]
  const { t } = useTranslations()
  const [allColors = []] = useGetColors({ orderBy: 'hex' })

  // [COMPUTED_PROPS]
  const possibleOptions = useMemo(
    () =>
      value ? _.uniqBy([...allColors, ...(value || [])], 'hex') : allColors,
    [allColors, value]
  )
  const computedValue = useMemo(() => value?.map((item) => item?.hex), [value])

  // [HANDLERS]
  const handleChange = (value) => {
    const checkIfArrayContainsItem = (item) => {
      return allColors?.some((color) => color.hex === item)
    }

    onChange(
      value.map((item) => {
        return !checkIfArrayContainsItem(item)
          ? { hex: item, _id: item._id || getId('colors') }
          : Array.from(possibleOptions).find((color) => color?.['hex'] === item)
      })
    )
  }

  return (
    <Container mb={4}>
      <Title level={4} mb={2}>
        {t('Colors')}
      </Title>
      <Row>
        <Col px="0px">
          <Select
            onChange={handleChange}
            mode="tags"
            placeholder={t('Enter colors')}
            value={computedValue}>
            {Array.from(possibleOptions).map((color, index) => (
              <Option key={index} value={color?.hex}>
                {color?.hex}
              </Option>
            ))}
          </Select>
        </Col>
      </Row>
    </Container>
  )
}

ColorListWithCreate.propTypes = {
  value: PropTypes.arrayOf(PropTypes.object),
  onChange: PropTypes.func
}

export default ColorListWithCreate
