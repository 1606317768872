import { Checkbox } from 'antd'
import PropTypes from 'prop-types'
import { useGetOffers } from 'domains/Offer/hooks'
import { useSubfilterActions } from 'hooks'
import { StyledDetails } from '../MockupFilter.styles'
import { useTranslations } from 'contexts/Translation'
import { Text } from '@qonsoll/react-design'

const OfferFilter = ({ filterData, setFilterData }) => {
  // [ADDITIONAL_HOOKS]
  const [offers] = useGetOffers()
  const { t } = useTranslations()

  // [COMPUTED_PROPERTIES]
  const summaryStyles = { marginBottom: '8px' }

  const { onToggleDetails, onChange, checkIsEnabled } = useSubfilterActions({
    filterData,
    setFilterData,
    fieldName: 'offer',
    operand: '=='
  })

  return (
    <StyledDetails
      open={localStorage.getItem(`offer-details-visibility`) === 'opened'}>
      <summary style={summaryStyles} onClick={() => onToggleDetails('offer')}>
        {t('Offer')}
      </summary>
      {offers?.map((offer, index) => (
        <div key={`status-${index}`}>
          <Checkbox
            key={`status-${index}`}
            checked={checkIsEnabled(offer)}
            onChange={() => onChange(offer)}>
            <Text wordBreak="break-all">
              {offer?.paymentTerms || t('Unnamed filter')}
            </Text>
          </Checkbox>
        </div>
      ))}
    </StyledDetails>
  )
}

OfferFilter.propTypes = {
  filterData: PropTypes.object,
  setFilterData: PropTypes.func
}

export default OfferFilter
