import { ContentScreen, PositionedContainer } from '../../../../components'

import { AppIcon } from '../../../../domains'
import responsiveStyles from './AppIconGuideScreen.styled'
import { useBrandbookConfig } from 'brandbook/contexts/BrandbookConfig'

/**
 * It returns a ContentScreen component with a PositionedContainer component that contains an AppIcon
 * component
 * @returns A ContentScreen component with the following props:
 *   - key: "AppIconGuideScreen"
 *   - chapter: "Guide"
 *   - domainName: "App icon."
 *   - pageNumber: "25"
 *   - content: A PositionedContainer component with the following props:
 */
const AppIconGuideScreen = () => {
  //  [ADDITIONAL_HOOKS]
  const { brandbookConfigData } = useBrandbookConfig()
  return (
    <ContentScreen
      key="AppIconGuideScreen"
      chapter="Guide"
      domainName="App icon."
      pageNumber="25"
      content={
        <PositionedContainer {...responsiveStyles}>
          <AppIcon appIcon={brandbookConfigData?.appIcon?.appIconGuide} />
        </PositionedContainer>
      }
    />
  )
}

export default AppIconGuideScreen
