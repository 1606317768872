const CUSTOMER_STATUS = {
  REQUESTED_CALCULATOR_RESULT: 'Requested calculator result',
  BOOKED_FIRST_MEETING: 'Booked first meeting',
  FIRST_MEETING_FINISHED: 'First meeting finished',
  OFFER_SENT: 'Offer sent',
  BOOKED_SECOND_MEETING: 'Booked second meeting',
  SECOND_MEETING_FINISHED: 'Second meeting finished'
}

const CUSTOMER_STATUS_OPTIONS = Object.keys(CUSTOMER_STATUS).map((key) => ({
  label: CUSTOMER_STATUS[key],
  value: key
}))

export default CUSTOMER_STATUS
export { CUSTOMER_STATUS_OPTIONS, CUSTOMER_STATUS }
