import { useMemo } from 'react'
import { useDocument, useLoading } from 'hooks'
import {} from 'domains/Representative/hooks'

const useGetRepresentativeInitialValues = (id) => {
  // [DATA_FETCH]
  const [representative, representativeLoading] = useDocument({
    ref: id ? `representatives/${id}` : null
  })

  // [COMPUTED_PROPERTIES]
  const initialValues = useMemo(
    () => ({
      representative
    }),
    [representative]
  )
  const loadings = useMemo(
    () => (id ? [!representative, representativeLoading] : []),
    [id, representative, representativeLoading]
  )

  // [ADDITIONAL_HOOKS]
  const loading = useLoading(loadings)

  return [initialValues, loading]
}

export default useGetRepresentativeInitialValues
