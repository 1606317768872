import './styles/index.css'

import { GreetingScreen, NavigationDots, Screen } from 'offer/components'

import { IMAGE_DECORATIONS } from 'offer/__constants__'
import PropTypes from 'prop-types'
import { Spinner } from 'components'
import { useScreenConfig } from 'offer/hooks'
import { useTranslations } from 'contexts/Translation'

const Offer = (props) => {
  const { offerId } = props
  const { HELLO, THX } = IMAGE_DECORATIONS

  // [ADDITIONAL_HOOKS]
  const { t } = useTranslations()
  const [screenConfig, loading] = useScreenConfig(offerId)

  return !loading ? (
    <div className="offer-module">
      {/* Navigation dots between Screens */}
      <NavigationDots screensConfig={screenConfig} />
      {/* First Welcome screen */}
      <GreetingScreen
        title={`${t('Hello, we are Senseteq')}!`}
        subtitle={t('Offer presentation')}
        decorationSrc={HELLO}
        id="hello"
      />
      {screenConfig?.map(({ title, idScreen, component, screenData }, index) =>
        component ? (
          <Screen
            key={title + index}
            title={t(title)}
            breadcrumbs={[t('Offer'), title]}
            id={idScreen}
            {...screenData}
            idScreen={idScreen}>
            {component}
          </Screen>
        ) : null
      )}
      {/* Last Congratulation screen */}
      <GreetingScreen
        title={`${t('Thank you for watching')}!`}
        decorationSrc={THX}
        id="thankYou"
      />
    </div>
  ) : (
    <Spinner />
  )
}

Offer.propTypes = {
  initialData: PropTypes.object,
  offerId: PropTypes.string.isRequired
}

export default Offer
