import './Divider.vars.css'

import PropTypes from 'prop-types'
import { setHeight } from './helpers'
import styled from 'styled-components'

const Divider = styled.div`
  ${({ size = 'md' }) => setHeight(size)}
  background-color: ${({ color }) =>
    color ? `var(--divider-${color}-bg)` : 'var(--divider-dark-bg)'};
  width: ${({ semibold }) =>
    semibold ? 'var(--divider-semibold-width)' : 'var(--divider-width)'};
`

Divider.propTypes = {
  color: PropTypes.oneOf(['accent', 'white', 'dark']),
  size: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.arrayOf(PropTypes.string)
  ]),
  semibold: PropTypes.bool
}

export default Divider
