import { useTranslations } from 'contexts/Translation'
import PropTypes from 'prop-types'
import { Tabs, Card } from 'antd'
import { StickyContainer, Sticky } from 'react-sticky'
import {
  Container,
  Row,
  Col,
  Spin,
  Divider,
  Text,
  Title
} from '@qonsoll/react-design'
import { useParams, useHistory, useLocation } from 'react-router-dom'
import { useDocument } from 'hooks'
import { useStateWithStorage } from 'hooks'
import { RoleCustomer } from 'domains/Role/components'

const RoleAdvancedView = (props) => {
  // [COMPONENT_STATE_HOOKS]
  const [activeTab, setActiveTab] = useStateWithStorage(
    null,
    'role-advanced-view-active-tab'
  )

  // [ADDITIONAL_HOOKS]
  const { t } = useTranslations()
  const history = useHistory()
  const params = useParams()
  const location = useLocation()
  const { roleId } = params
  const [role, loading] = useDocument({ ref: `roles/${roleId}` })

  // [COMPUTED_PROPERTIES]
  const { TabPane } = Tabs

  const changeTab = (key) => {
    if (params && roleId) {
      if (key.includes(`roles/${roleId}`)) {
        history.push(key)
      }
      setActiveTab(key)
    }
  }

  return loading ? (
    <Spin />
  ) : (
    <Container>
      <Container mb={4}>
        <Card>
          <Row noGutters mb={2} v="center">
            <Col h="left">
              <Title level={3}>{role?.name}</Title>
            </Col>
          </Row>
        </Card>
      </Container>
      <Row noGutters mb={4}>
        <Col>
          <StickyContainer>
            <Tabs defaultActiveKey={activeTab} onChange={changeTab}>
              <TabPane tab={t('Customer')} key="#customer">
                <RoleCustomer emptyProps={{ withCreate: false }} role={role} />
              </TabPane>
            </Tabs>
          </StickyContainer>
        </Col>
      </Row>
    </Container>
  )
}

RoleAdvancedView.propTypes = {}

export default RoleAdvancedView
