import { useTranslations } from 'contexts/Translation'
import PropTypes from 'prop-types'
import { Tabs, Card } from 'antd'
import { StickyContainer, Sticky } from 'react-sticky'
import {
  Container,
  Row,
  Col,
  Spin,
  Divider,
  Text,
  Title
} from '@qonsoll/react-design'
import { useParams, useHistory, useLocation } from 'react-router-dom'
import { useDocument } from 'hooks'
import { useStateWithStorage } from 'hooks'
import { ApplicationPlatform } from 'domains/Application/components'
import { ApplicationRole } from 'domains/Application/components'

const ApplicationAdvancedView = (props) => {
  // [COMPONENT_STATE_HOOKS]
  const [activeTab, setActiveTab] = useStateWithStorage(
    null,
    'application-advanced-view-active-tab'
  )

  // [ADDITIONAL_HOOKS]
  const { t } = useTranslations()
  const history = useHistory()
  const params = useParams()
  const location = useLocation()
  const { applicationId } = params
  const [application, loading] = useDocument({
    ref: `applications/${applicationId}`
  })

  // [COMPUTED_PROPERTIES]
  const { TabPane } = Tabs

  const changeTab = (key) => {
    if (params && applicationId) {
      if (key.includes(`applications/${applicationId}`)) {
        history.push(key)
      }
      setActiveTab(key)
    }
  }

  return loading ? (
    <Spin />
  ) : (
    <Container>
      <Container mb={4}>
        <Card>
          <Row noGutters mb={2} v="center">
            <Col h="left">
              <Title level={3}>{application?.name}</Title>
            </Col>
          </Row>
          <Row noGutters v="center">
            <Col h="left">
              <Text variant="overline" type="secondary">
                {t('Page ids')}:
              </Text>
            </Col>
            <Col h="right">
              <Text>{application?.pageIds}</Text>
            </Col>
            <Divider my={2} />
          </Row>
          <Row noGutters v="center">
            <Col h="left">
              <Text fontWeight="bold">{t('Platform id')}:</Text>
            </Col>
            <Col cw="auto" h="right">
              <ApplicationPlatform
                emptyProps={{ withIllustration: false, withCreate: false }}
                application={application}
              />
            </Col>
            <Divider my={2} />
          </Row>
        </Card>
      </Container>
      <Row noGutters mb={4}>
        <Col>
          <StickyContainer>
            <Tabs defaultActiveKey={activeTab} onChange={changeTab}>
              <TabPane tab={t('Role')} key="#role">
                <ApplicationRole
                  emptyProps={{ withCreate: false }}
                  application={application}
                />
              </TabPane>
            </Tabs>
          </StickyContainer>
        </Col>
      </Row>
    </Container>
  )
}

ApplicationAdvancedView.propTypes = {}

export default ApplicationAdvancedView
