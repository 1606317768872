import { useTranslations } from 'contexts/Translation'
import PropTypes from 'prop-types'
import { DatabaseSimpleForm } from 'domains/Database/components'
import { Button, Title } from '@qonsoll/react-design'
import { Form, Input } from 'antd'
import { KEYS } from '__constants__'
import {
  useInitializeDatabaseAdvancedForm,
  useActionsDatabaseAdvancedForm
} from './hooks'

const DatabaseAdvancedForm = (props) => {
  const { initialData, group, showTitle } = props

  // [ADDITIONAL_HOOKS]
  const { t } = useTranslations()
  const { form } = useInitializeDatabaseAdvancedForm(initialData)

  // [HANDLERS]
  const handleKeyPress = (e) => {
    if (e.key === KEYS.ENTER) {
      e.preventDefault()
      onFinish()
    }
  }

  const { loading, onFinish, onReset } = useActionsDatabaseAdvancedForm({
    initialData,
    form
  })

  return group ? (
    <>
      {showTitle && (
        <Title level={4} mb={2}>
          {t('Database')}
        </Title>
      )}
      <DatabaseSimpleForm showTitle={false} group={[...group, 'database']} />
    </>
  ) : (
    <Form
      onKeyPress={handleKeyPress}
      onFinish={onFinish}
      layout="vertical"
      autoComplete="off"
      form={form}>
      {showTitle && (
        <Title level={4} mb={2}>
          {t('Database')}
        </Title>
      )}
      <DatabaseSimpleForm group={['database']} />
      <Button mr={2} htmlType="button" onClick={onReset} mb={4}>
        {t('Cancel')}
      </Button>
      <Button type="primary" loading={loading} onClick={() => form.submit()}>
        {t('Save')}
      </Button>
    </Form>
  )
}

DatabaseAdvancedForm.propTypes = {
  initialData: PropTypes.object,
  group: PropTypes.array,
  showTitle: PropTypes.bool
}

export default DatabaseAdvancedForm
