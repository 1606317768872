import { useEffect } from 'react'
import { Form } from 'antd'

const useInitializeFeatureAdvancedForm = (initialData) => {
  // [ADDITIONAL_HOOKS]
  const [form] = Form.useForm()

  // [LIFECYCLE]
  useEffect(() => {
    if (initialData) {
      const { feature } = initialData
      const formValues = {
        feature: {
          estimation: feature['estimation'] ?? null,
          featureLogoURL: feature['featureLogoURL'] ?? null,
          name: feature['name'] ?? null
        }
      }
      form.setFieldsValue(formValues)
    }
  }, [initialData, form])

  return { form }
}

export default useInitializeFeatureAdvancedForm
