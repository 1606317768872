import useGetColorValue from './useGetColorValue'

const useColorViewConfig = () => {
  const [getRgbValue, getHexValue] = useGetColorValue()

  return {
    ACCENT: {
      shade: 'var(--accent-default)',
      name: 'Accent',
      hex: getHexValue('--accent-default'),
      rgb: getRgbValue('--accent-default'),
      textColor: 'primary',
      viewSize: 'xl'
    },
    WHITE: {
      shade: 'var(--white-default)',
      name: 'White',
      hex: getHexValue('--white-default'),
      rgb: getRgbValue('--white-default'),
      textColor: 'primary',
      viewSize: 'sm'
    },
    NEUTRAL: {
      shade: 'var(--neutral-default)',
      name: 'Neutral',
      hex: getHexValue('--neutral-default'),
      rgb: getRgbValue('--neutral-default'),
      textColor: 'white',
      viewSize: 'md'
    }
  }
}

export default useColorViewConfig
