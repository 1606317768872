import PropTypes from 'prop-types'

const Union = (props) => {
  const { iconSize, iconColor, fill } = props

  return (
    <svg
      className="icon-union"
      width={iconSize}
      height={iconSize}
      viewBox="0 0 400 400"
      fill={fill}
      xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0 20C0 8.9543 8.9543 0 20 0H40H360H380C391.046 0 400 8.9543 400 20C400 31.0457 391.046 40 380 40V260C380 282.091 362.091 300 340 300H220V360C231.046 360 240 368.954 240 380C240 391.046 231.046 400 220 400H200H180C168.954 400 160 391.046 160 380C160 368.954 168.954 360 180 360V300H60C37.9086 300 20 282.091 20 260V40C8.9543 40 0 31.0457 0 20ZM60 40V260H200H340V40H60ZM200 80C211.046 80 220 88.9543 220 100V200C220 211.046 211.046 220 200 220C188.954 220 180 211.046 180 200V100C180 88.9543 188.954 80 200 80ZM300 140C300 128.954 291.046 120 280 120C268.954 120 260 128.954 260 140V200C260 211.046 268.954 220 280 220C291.046 220 300 211.046 300 200V140ZM120 120C131.046 120 140 128.954 140 140V200C140 211.046 131.046 220 120 220C108.954 220 100 211.046 100 200V140C100 128.954 108.954 120 120 120Z"
        fill={iconColor}
      />
    </svg>
  )
}

Union.propTypes = {
  iconSize: PropTypes.string,
  fill: PropTypes.string,
  iconColor: PropTypes.string
}

export default Union
