import './TypographyScreenResponsive.vars.css'

import { PositionedContainer } from '../../../../components'
import styled from 'styled-components'

const PositionedContainerStyled = styled(PositionedContainer)`
  top: var(--typo-top);
  left: var(--typo-left);
`

export default PositionedContainerStyled
