import { useEffect } from 'react'
import { Form } from 'antd'

const useInitializeOfferAdvancedForm = (initialData) => {
  // [ADDITIONAL_HOOKS]
  const [form] = Form.useForm()

  // [LIFECYCLE]
  useEffect(() => {
    if (initialData) {
      const { offer, featureIds, mockupIds, technologyIds } = initialData
      const formValues = {
        offer: {
          paymentTerms: offer['paymentTerms'] ?? null,
          price: offer['price'] ?? null,
          dataBaseId: offer['dataBaseId'] ?? null
        },
        featureIds: featureIds ?? null,
        mockupIds: mockupIds ?? null,
        technologyIds: technologyIds ?? null
      }
      form.setFieldsValue(formValues)
    }
  }, [initialData, form])

  return { form }
}

export default useInitializeOfferAdvancedForm
