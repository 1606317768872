import { useTranslations } from 'contexts/Translation'
import PropTypes from 'prop-types'
import { Row, Col, Text } from '@qonsoll/react-design'
import { useState } from 'react'
import { useParams } from 'react-router-dom'
import { CustomerProfileSimpleView } from 'domains/CustomerProfile/components'

const CustomerProfileList = (props) => {
  const { customerProfiles, isListWithCreate, listView, emptyProps, actions } =
    props

  // [COMPONENT_STATE_HOOKS]
  const [selectedItem, setSelectedItem] = useState(null)

  // [ADDITIONAL_HOOKS]
  const { t } = useTranslations()

  // [HANDLERS]
  const onEmptySpaceClick = () => setSelectedItem(null)

  return (
    <Row onClick={onEmptySpaceClick} mx={-3}>
      {customerProfiles?.length > 0 ? (
        customerProfiles?.map((customerProfile, index) => (
          <Col
            mb={4}
            key={customerProfile?._id || index}
            cw={listView ? 12 : [12, 6, 4]}>
            <CustomerProfileSimpleView
              actions={actions}
              index={index}
              isSelected={customerProfile?._id === selectedItem}
              onSelect={setSelectedItem}
              customerProfile={customerProfile}
            />
          </Col>
        ))
      ) : (
        <Col cw={isListWithCreate ? 'auto' : 12}>
          <Text variant="overline" type="secondary">
            {emptyProps?.message ||
              t('No customer profiles have been created yet')}
          </Text>
        </Col>
      )}
    </Row>
  )
}

CustomerProfileList.propTypes = {
  customerProfiles: PropTypes.array,
  isListWithCreate: PropTypes.bool,
  listView: PropTypes.bool,
  emptyProps: PropTypes.object,
  actions: PropTypes.shape({
    onEdit: PropTypes.func,
    onDelete: PropTypes.func,
    onDoubleClick: PropTypes.func,
    onClick: PropTypes.func
  })
}

export default CustomerProfileList
