import {
  ContentScreen,
  PositionedContainer,
  Switcher
} from '../../../../components'

import { SWITCHER_CONFIG_MAP } from '../../../../domains/Switcher/__constants__'
import { SwitcherSimpleView } from '../../../../domains'
import responsiveStyles from './SwitcherScreen.styled'

/**
 * It returns a ContentScreen with elements of Switcher
 * @returns A ContentScreen component with the following props:
 *   key: "Switcher"
 *   chapter: "Switcher"
 *   domainName: "Elem."
 *   pageNumber: "32"
 *   asideTopContent: <SwitcherList />
 *   content: <PositionedContainer left="1150px" top="280px"/>
 */
const SwitcherScreen = () => {
  return (
    <ContentScreen
      chapter="Switcher"
      domainName="Elem."
      key="SwitcherScreen"
      pageNumber="33"
      asideTopContent={SWITCHER_CONFIG_MAP.map(({ name, checked }, i) => (
        <SwitcherSimpleView
          key={`switcher-list-item-${i}`}
          name={name}
          checked={checked}
        />
      ))}
      content={
        <PositionedContainer {...responsiveStyles}>
          <Switcher mega checked />
        </PositionedContainer>
      }
    />
  )
}

export default SwitcherScreen
