import { Col, PageWrapper, Row } from '@qonsoll/react-design'
import { BrandbookConfigProvider } from 'brandbook/contexts/BrandbookConfig'
import { BrandbookModule } from 'brandbook'
import { Spinner } from 'components'
import { useBrandbookInitialValues } from 'domains/Brandbook/hooks'
import { useLocation } from 'react-router-dom'
import { useTranslations } from 'contexts/Translation'
import { urlParamsStringIntoParamsObject } from 'helpers'

const PublicBrandbook = () => {
  // [ADDITIONAL_HOOKS]
  const { t } = useTranslations()
  const location = useLocation()
  const { brandbookId } = urlParamsStringIntoParamsObject(location.search)
  const [initialValues, loading] = useBrandbookInitialValues(brandbookId)

  return (
    <BrandbookConfigProvider initialData={initialValues}>
      <PageWrapper>
        {loading ? (
          <Spinner height="70vh" text={t('Brandbook loading')} />
        ) : (
          <>
            <Row noGutters>
              <Col cw={12}>
                <BrandbookModule initialData={initialValues} />
              </Col>
            </Row>
          </>
        )}
      </PageWrapper>
    </BrandbookConfigProvider>
  )
}

export default PublicBrandbook
