import { useMemo } from 'react'
import { useDocument, useLoading } from 'hooks'
import {} from 'domains/Database/hooks'

const useGetDatabaseInitialValues = (id) => {
  // [DATA_FETCH]
  const [database, databaseLoading] = useDocument({
    ref: id ? `databases/${id}` : null
  })

  // [COMPUTED_PROPERTIES]
  const initialValues = useMemo(
    () => ({
      database
    }),
    [database]
  )
  const loadings = useMemo(
    () => (id ? [!database, databaseLoading] : []),
    [id, database, databaseLoading]
  )

  // [ADDITIONAL_HOOKS]
  const loading = useLoading(loadings)

  return [initialValues, loading]
}

export default useGetDatabaseInitialValues
