import PropTypes from 'prop-types'
import TitleStyled from './Title.styled'

const Title = (props) => {
  const {
    children,
    className,
    color,
    style,
    subtitle,
    type,
    textAlign,
    level = 1,
    fontWeight,
    position,
    textOverflow,
    overflow
  } = props

  const headerLevel = subtitle ? `subtitle` : `h${level}`
  const asHeader = subtitle ? `h5` : headerLevel

  const titleProps = {
    as: asHeader,
    level: headerLevel,
    textAlign,
    type,
    color,
    className,
    style,
    fontWeight,
    position,
    textOverflow,
    overflow
  }
  return (
    <TitleStyled
      {...titleProps}
      dangerouslySetInnerHTML={{ __html: children }}
    />
  )
}

Title.propTypes = {
  children: PropTypes.string,
  className: PropTypes.string,
  color: PropTypes.string,
  style: PropTypes.object,
  subtitle: PropTypes.string,
  type: PropTypes.string,
  textAlign: PropTypes.string,
  level: PropTypes.string,
  fontWeight: PropTypes.string,
  position: PropTypes.string,
  textOverflow: PropTypes.string,
  overflow: PropTypes.string
}

export default Title
