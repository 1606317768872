import { updateDocument, createDocument, getId } from 'services/firestore'

import { Representative } from 'models'
import { message } from 'antd'
import { useHistory } from 'react-router-dom'
import { useState } from 'react'
import { useTranslations } from 'contexts/Translation'

const useActionsRepresentativeSimpleForm = ({ initialData, form } = {}) => {
  // [COMPONENT_STATE_HOOKS]
  const [loading, setLoading] = useState(false)

  // [ADDITIONAL_HOOKS]
  const { t } = useTranslations()
  const history = useHistory()

  const prepareValues = async (values, additionalValues) => {
    const representativeId = initialData?._id || getId('representatives')
    const preparedValues = {
      _id: representativeId,
      ...additionalValues,
      firstName: values?.['firstName'] ?? null,
      lastName: values?.['lastName'] ?? null,
      summary: values?.['summary'] ?? null,
      birthDate: values?.['birthDate']?.toString() ?? null,
      email: values?.['email'] ?? null,
      phone: values?.['phone'] ?? null,
      position: values?.['position'] ?? null
    }
    return preparedValues
  }

  const saveForm = async (values, callback) => {
    try {
      // Prepare data to be saved
      const data = await prepareValues(values)
      // Save data
      if (initialData) {
        await updateDocument(
          'representatives',
          initialData.representative?._id,
          data
        )
        message.success(t('Representative successfully updated'))
      } else {
        await createDocument('representatives', data, data._id)
        message.success(t('Representative successfully created'))
      }
      // Final callback
      callback?.()
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error)
      throw new Error(t('Something went wrong during data save'))
    }
  }

  const validateForm = (values) => {
    try {
      // Prepare data to be validated
      const validationData = {
        firstName: values['firstName'],
        lastName: values['lastName'],
        summary: values['summary'],
        birthDate: values['birthDate']?.toString(),
        email: values['email'],
        phone: values['phone'],
        position: values['position']
      }
      Representative.validationSchema.validateSync(validationData)
    } catch (error) {
      throw new Error(t('Validation error: ') + error.message)
    }
  }

  const onFinish = async () => {
    if (loading) return // Avoid multiple calls

    try {
      setLoading(true)
      // Get form values
      const formValues = form.getFieldsValue()
      // Validate fields
      validateForm(formValues)
      // Final callback
      const callback = () => history.goBack()
      // Save data
      await saveForm(formValues, callback)
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error)
      setLoading(false)
      message.error(error.message)
    }
  }

  const onReset = () => {
    form.resetFields()
  }

  return { onFinish, onReset, loading, saveForm, validateForm, prepareValues }
}

export default useActionsRepresentativeSimpleForm
