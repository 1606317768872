import { model, attr, hasMany } from 'services/model'
import * as yup from 'yup'

const validationSchema = yup.object().shape({
  paymentTerms: yup
    .string()
    .typeError('Field paymentTerms has wrong type')
    .default(null)
    .nullable(),
  featureIds: yup
    .array()
    .typeError('Field featureIds has wrong type')
    .default(null)
    .nullable(),
  mockupIds: yup
    .array()
    .typeError('Field mockupIds has wrong type')
    .default(null)
    .nullable(),
  price: yup
    .number()
    .typeError('Field price has wrong type')
    .default(null)
    .nullable(),
  dataBaseId: yup
    .string()
    .typeError('Field dataBaseId has wrong type')
    .default(null)
    .nullable(),
  technologyIds: yup
    .array()
    .typeError('Field technologyIds has wrong type')
    .default(null)
    .nullable()
})

const Offer = model(
  'offer',
  {
    paymentTerms: attr('string'),
    featureIds: hasMany('feature'),
    mockupIds: hasMany('mockup'),
    price: attr('number'),
    dataBaseId: attr('string'),
    technologyIds: hasMany('technology')
  },
  validationSchema
)

export default Offer
