import { useTranslations } from 'contexts/Translation'
import PropTypes from 'prop-types'
import { Tabs, Card } from 'antd'
import { StickyContainer, Sticky } from 'react-sticky'
import {
  Container,
  Row,
  Col,
  Spin,
  Divider,
  Text,
  Title
} from '@qonsoll/react-design'
import { useParams, useHistory, useLocation } from 'react-router-dom'
import { useDocument } from 'hooks'
import { useStateWithStorage } from 'hooks'

const RepresentativeAdvancedView = (props) => {
  // [COMPONENT_STATE_HOOKS]
  const [activeTab, setActiveTab] = useStateWithStorage(
    null,
    'representative-advanced-view-active-tab'
  )

  // [ADDITIONAL_HOOKS]
  const { t } = useTranslations()
  const history = useHistory()
  const params = useParams()
  const location = useLocation()
  const { representativeId } = params
  const [representative, loading] = useDocument({
    ref: `representatives/${representativeId}`
  })

  // [COMPUTED_PROPERTIES]
  const { TabPane } = Tabs

  return loading ? (
    <Spin />
  ) : (
    <Container>
      <Container mb={4}>
        <Card>
          <Row noGutters mb={2} v="center">
            <Col h="left">
              <Title level={3}>{representative?.firstName}</Title>
            </Col>
          </Row>
          <Row noGutters mb={2} v="center">
            <Col h="left">
              <Title level={3}>{representative?.lastName}</Title>
            </Col>
          </Row>
          <Row noGutters mb={2} v="center">
            <Col h="left">
              <Text fontWeight="bold">{t('Birth date')}:</Text>
            </Col>
            <Col h="right">
              <Text>{representative?.birthDate}</Text>
            </Col>
          </Row>
          <Row noGutters mb={2} v="center">
            <Col h="left">
              <Text fontWeight="bold">{t('Phone')}:</Text>
            </Col>
            <Col h="right">
              <Text>{representative?.phone}</Text>
            </Col>
          </Row>
          <Row noGutters mb={2} v="center">
            <Col h="left">
              <Text>{representative?.summary}</Text>
            </Col>
          </Row>
          <Row noGutters v="center">
            <Col h="left">
              <Text variant="overline" type="secondary">
                {t('Email')}:
              </Text>
            </Col>
            <Col h="right">
              <Text>{representative?.email}</Text>
            </Col>
            <Divider my={2} />
          </Row>
          <Row noGutters v="center">
            <Col h="left">
              <Text variant="overline" type="secondary">
                {t('Position')}:
              </Text>
            </Col>
            <Col h="right">
              <Text>{representative?.position}</Text>
            </Col>
            <Divider my={2} />
          </Row>
        </Card>
      </Container>
    </Container>
  )
}

RepresentativeAdvancedView.propTypes = {}

export default RepresentativeAdvancedView
