import {
  COLOR_CODING_MODELS,
  COLOR_REPRESENTATIONS
} from 'domains/Color/__constants__'

import { BRANDBOOK_ID } from 'brandbook/__constants__'

const useBrandbookPickerTransformedColor = () => {
  const returnRGBAObject = (result, alpha, colorObjectType) => {
    // If color is represented as hsla object like {h:0, s:0, l:0, a:0}
    if (colorObjectType === COLOR_REPRESENTATIONS.BRANDBOOK_HSLA_OBJ) {
      return hslaObjectToRgba(result, alpha)
    } else {
      // If color is represented as rgba object like {r:0, g:0, b:0, a:0}
      return {
        r: `${result?.[0] || 0}`,
        g: `${result?.[1] || 0}`,
        b: `${result?.[2] || 0}`,
        a: result?.[3] ? `${result?.[3]}` : `${alpha || 0}`
      }
    }
  }
  // [HELPER_FUCNTIONS]
  // h, s, l are in the range of [0, 1]
  const hslaObjectToRgba = (color, alpha) => {
    // This is value to make color darker
    // This value has to be subtracted from color.l - stands for color lightness
    const darkenValue = 0.15
    // Make darken color from accent color by changing lightness value
    const colorLightness = color?.l - darkenValue
    // Using formula to convert hsl to rgb
    const k = (n) => (n + color?.h / 30) % 12
    const a = color?.s * Math.min(colorLightness, 1 - colorLightness)
    const hslaToRgba = (n) =>
      colorLightness -
      a * Math.max(-1, Math.min(k(n) - 3, Math.min(9 - k(n), 1)))

    return {
      r: Math.trunc(255 * hslaToRgba(0)),
      g: Math.trunc(255 * hslaToRgba(8)),
      b: Math.trunc(255 * hslaToRgba(4)),
      a: color?.a || alpha
    }
  }
  const returnTransformedColorByType = (transformedColor, type) => {
    if (type === COLOR_REPRESENTATIONS.BRANDBOOK_RGBA_STRING) {
      return `rgba(${transformedColor?.r}, ${transformedColor?.g}, ${transformedColor?.b}, ${transformedColor?.a})`
    } else if (type === COLOR_REPRESENTATIONS.BRANDBOOK_VAR) {
      return `${transformedColor?.r}, ${transformedColor?.g}, ${transformedColor?.b}`
    } else {
      return transformedColor
    }
  }
  /**
   * It takes a color string and returns an object with the color's red, green, blue, and alpha values.
   * @param color - The color you want to convert.
   * @param [alpha=1] - The opacity of the color.
   * @returns An object with the following properties:
   * r: red value
   * g: green value
   * b: blue value
   * a: alpha value
   */
  return ({
    color,
    alpha = 1,
    type = COLOR_REPRESENTATIONS.BRANDBOOK_RGBA_OBJ,
    colorObjectType
  }) => {
    try {
      let result = []

      if (!color) {
        return returnRGBAObject()
      }

      // If color is Hex, then split by 2 symbols and convert to decimal number
      // and push into result-array
      if (color?.match?.(COLOR_CODING_MODELS.HEX)) {
        color.match(/\w\w/g).forEach((item) => {
          result.push(parseInt(item, 16))
        })

        // If color is RGB || RGBA, then replace all spaces, parentheses,
        // split by comma and push into result-array
      } else if (
        color?.match?.(COLOR_CODING_MODELS.RGB) ||
        color?.match?.(COLOR_CODING_MODELS.RGBA)
      ) {
        const rgb = color
          .replace(/^(rgb|rgba)\(/, '')
          .replace(/\)$/, '')
          .replace(/\s/g, '')
          .split(',')
        rgb.forEach((item) => {
          result.push(item)
        })

        // If color is css var - then get its value, replace parentheses and
        // var-prefix, split by comma and push into result-array
      } else if (color?.includes?.('var')) {
        const cssVarValue = getComputedStyle(
          document?.getElementById(BRANDBOOK_ID)
        ).getPropertyValue(
          `${color?.replace(/^(var)\(/, '').replace(/\)$/, '')}`
        )
        cssVarValue.split(',').forEach((item) => {
          result.push(item)
        })
      }
      // Prepared color-data
      let transformedColor
      if (result?.length !== 0) {
        transformedColor = returnRGBAObject(result, alpha)
      } else if (colorObjectType) {
        transformedColor = returnRGBAObject(color, alpha, colorObjectType)
      } else {
        transformedColor = color
      }

      return returnTransformedColorByType(transformedColor, type)
    } catch (error) {
      console.error(error)
    }
  }
}

export default useBrandbookPickerTransformedColor
