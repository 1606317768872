import { FirstLine, SecondLine, ThirdLine } from './MegaText.styled'

import PropTypes from 'prop-types'
import { Title } from '../../../../components'

/**
 * It takes in three strings and a variant, and returns three lines of text
 * @param {variant} string - position of text
 * @returns A component that renders three lines of text.
 */
const MegaText = (props) => {
  const { variant, responsive } = props

  return (
    <>
      <FirstLine variant={variant} responsive={responsive}>
        <Title ff="secondary" variant="mega1" color="accent" ws="nowrap">
          Always nice for our clients to see our brand
        </Title>
      </FirstLine>
      <SecondLine variant={variant} responsive={responsive}>
        <Title ff="secondary" variant="mega1" ws="nowrap">
          vision and see how their brand can look like
        </Title>
      </SecondLine>
      <ThirdLine variant={variant} responsive={responsive}>
        <Title ff="secondary" variant="mega1" fw="regular" ws="nowrap">
          on different mediums.
        </Title>
      </ThirdLine>
    </>
  )
}
MegaText.propTypes = {
  variant: PropTypes.oneOf(['md', 'lg']),
  responsive: PropTypes.bool
}

export default MegaText
