import PropTypes from 'prop-types'

const Scanner = (props) => {
  const { iconSize, iconColor, fill } = props

  return (
    <svg
      width={iconSize}
      height={iconSize}
      viewBox="0 0 72 72"
      fill={fill}
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M9 24V12C9 11.2044 9.31607 10.4413 9.87868 9.87868C10.4413 9.31607 11.2044 9 12 9H24"
        stroke={iconColor}
        strokeWidth="6"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M63 24V12C63 11.2044 62.6839 10.4413 62.1213 9.87868C61.5587 9.31607 60.7956 9 60 9H48"
        stroke={iconColor}
        strokeWidth="6"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9 48V60C9 60.7956 9.31607 61.5587 9.87868 62.1213C10.4413 62.6839 11.2044 63 12 63H24"
        stroke={iconColor}
        strokeWidth="6"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M48 63H60C60.7956 63 61.5587 62.6839 62.1213 62.1213C62.6839 61.5587 63 60.7956 63 60V48"
        stroke={iconColor}
        strokeWidth="6"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M36 51V48M12 36H60H12ZM24 21V24V21ZM36 21V24V21ZM24 51V48V51ZM48 21V24V21ZM48 51V48V51Z"
        stroke={iconColor}
        strokeWidth="6"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

Scanner.propTypes = {
  iconSize: PropTypes.string.isRequired,
  fill: PropTypes.string,
  iconColor: PropTypes.string
}

export default Scanner
