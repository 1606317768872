import styled from 'styled-components'

export const TableStyled = styled.table`
  background-color: ${(props) => props.color || 'var(--table-bg-default)'};
  box-shadow: ${(props) => props.boxShadow || 'var(--table-shadow-default)'};
  display: ${(props) => props.display || 'flex'};
  flex-direction: ${(props) => props.flexDirection || 'column'};
`
export const THeadStyled = styled.thead`
  background-color: ${(props) =>
    props.bgColor || 'var(--table-head-bg-default)'};
  font-family: ${(props) =>
    props.fontFamily || 'var(--table-head-font-family)'};
  font-weight: ${(props) =>
    props.fontWeight || 'var(--table-head-font-weight)'};
  display: ${(props) => props.display || 'flex'};
  flex-direction: ${(props) => props.flexDirection || 'column'};
  position: relative;
`

export const TBodyStyled = styled.tbody``

export const TableHeadStyled = styled.th``

export const TableRowStyled = styled.tr`
  padding: var(--model-table-view-row-p);
  border-bottom: ${(props) => props.borderBottom || 'none'};
  display: ${(props) => props.display || 'flex'};
  justify-content: ${(props) => props.justifyContent || 'unset'};
  align-items: ${(props) => props.alignItems || 'unset'};
  &:last-child {
    border-bottom: none;
  }
`

export const TableColStyled = styled.td``

export const DecorationImg = styled.img`
  height: 100%;
  width: auto;
  position: absolute;
  top: 0;
  left: ${({ left }) => (left ? '0' : 'none')};
  right: ${({ right }) => (right ? '0' : 'none')};
`
