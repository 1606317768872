import { Avatar, Box, Col, Row, Text } from '../../../../components'

import ColStyled from './AvatarSimpleView.styled'
import PropTypes from 'prop-types'
import { useMediaQuery } from 'hooks'

/**
 * This is a Component which  gets avatar-object as a prop
 * and returns AvatarSimpleView Component
 * @param {object} avatar - An object with props for Avatar Component
 * @returns A React component - AvatarSimpleView
 */
const AvatarSimpleView = (props) => {
  const { name } = props

  const isBreakpoint = useMediaQuery('(max-width:576px)')

  return (
    <Row mb={[16, 18, 18, 20]} v="center" noOuterGutters>
      {isBreakpoint ? (
        <ColStyled cw={1}>
          <Box minHeight="3rem">
            <Avatar {...props} mb="auto" />
          </Box>
          <Text
            ff="primary"
            variant="body2"
            color="secondary"
            textAlign="center">
            {name}
          </Text>
        </ColStyled>
      ) : (
        <>
          <Col cw={3}>
            <Avatar {...props} />
          </Col>
          <Col>
            <Text ff="primary" variant="body2" color="secondary">
              {name}
            </Text>
          </Col>
        </>
      )}
    </Row>
  )
}

AvatarSimpleView.propTypes = {
  name: PropTypes.string
}

export default AvatarSimpleView
