import PropTypes from 'prop-types'

const Mobile = (props) => {
  const { iconSize, iconColor, fill } = props

  return (
    <svg
      width={iconSize}
      height={iconSize}
      viewBox="0 0 72 72"
      fill={fill}
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M35.8496 51H36.1496"
        stroke={iconColor}
        strokeWidth="9"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M51 63H21C20.2044 63 19.4413 62.6839 18.8787 62.1213C18.3161 61.5587 18 60.7956 18 60V12C18 11.2044 18.3161 10.4413 18.8787 9.87868C19.4413 9.31607 20.2044 9 21 9H51C51.7956 9 52.5587 9.31607 53.1213 9.87868C53.6839 10.4413 54 11.2044 54 12V60C54 60.7956 53.6839 61.5587 53.1213 62.1213C52.5587 62.6839 51.7956 63 51 63ZM42 9H30L31.5 15H40.5L42 9Z"
        stroke={iconColor}
        strokeWidth="6"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

Mobile.propTypes = {
  iconSize: PropTypes.string.isRequired,
  fill: PropTypes.string,
  iconColor: PropTypes.string
}

export default Mobile
