import './AppIconLogo.vars.css'

import {
  AppIconPosition,
  AppIconWrapper,
  IconLogoImage,
  IconLogoWrapper
} from './AppIconLogo.styled'
import { useEffect, useState } from 'react'

import PropTypes from 'prop-types'
import { Text } from '../../../../components'
import { Upload } from 'antd'
import { uploadFile } from 'services/storage'
import { useBrandbookConfig } from 'brandbook/contexts/BrandbookConfig'

/**
 * This function returns a styled component that contains a box with a flex direction of column, and
 * align items of center. The box contains an icon logo component that contains an image
 * @param {string} variant - The variant of the image to be styled
 * @param {string} logo - path to the image file
 * @param {string} name - name of the Application
 * @param {string} bg  - on of the background color
 * @returns A styled component that is a box with flex direction column and align items center.
 */
const AppIconLogo = (props) => {
  const { variant, logo, name, bg, reducerType } = props

  // [ADDITIONAL_HOOKS]
  const { brandbookConfigDataDispatch } = useBrandbookConfig()
  // [COMPONENT_STATE_HOOKS]
  const [file, setFile] = useState(null)

  // [HELPER_FUNCTIONS]
  const onSelectAppIcon = (url) => {
    if (url) {
      brandbookConfigDataDispatch({
        type: reducerType,
        payload: url
      })
    }
  }

  const handleUpload = async ({ onSuccess, file }) => {
    const [url] = await uploadFile(file, `$icons/${file?.name}`)
    setFile(url)
    onSelectAppIcon(url)
    onSuccess()
  }

  // [LIFECYCLE]
  useEffect(() => logo && setFile(logo), [logo])

  return (
    <Upload cursor="pointer" fileList={null} customRequest={handleUpload}>
      <AppIconPosition variant={variant}>
        <AppIconWrapper>
          <IconLogoWrapper bg={bg} variant={variant}>
            {file && (
              <IconLogoImage
                width="100%"
                height="100%"
                src={file}
                alt="AppIconLogo"
              />
            )}
          </IconLogoWrapper>

          {name && (
            <Text ff="primary" variant="caption2" color="white" fw="medium">
              {name}
            </Text>
          )}
        </AppIconWrapper>
      </AppIconPosition>
    </Upload>
  )
}
AppIconLogo.propTypes = {
  variant: PropTypes.oneOf(['iphone', 'android']).isRequired,
  logo: PropTypes.string,
  name: PropTypes.string,
  bg: PropTypes.string,
  reducerType: PropTypes.string
}

export default AppIconLogo
