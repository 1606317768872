import { ContentScreen, PositionedContainer } from '../../../../components'

import { ColorsList } from '../../../../domains'

const MainColorsScreen = () => {
  return (
    <ContentScreen
      chapter="Main Colors"
      domainName="Color palette."
      key="MainColorsScreen"
      height="100%"
      content={
        <PositionedContainer top="0" right="0" height="100%">
          <ColorsList />
        </PositionedContainer>
      }
      pageNumber="04"
    />
  )
}

export default MainColorsScreen
