import {
  NavigationDotStyled,
  NavigationDotTooltip,
  NavigationDotsStyled
} from './NavigationDots.styled'

import PropTypes from 'prop-types'
import { Text } from '../../../components'

const NavigationDots = (props) => {
  const { screensConfig } = props
  // [HELPER_FUNCTIONS]
  /**
   * It takes an id of section as an argument,
   * and then scrolls the page to the section with that id
   *
   * @param id - The id of the section you want to scroll to.
   */
  const handleClickDot = (id) =>
    document.getElementById(id).scrollIntoView({
      behavior: 'smooth'
    })
  const handleMouseMoveDot = (id) => {
    const dotElement = document.getElementById(`navigation-${id}`)
    dotElement.querySelector('.navigation-dot-tooltip').style.display = 'block'
  }
  const handleMouseLeaveDot = (id) => {
    const dotElement = document.getElementById(`navigation-${id}`)
    dotElement.querySelector('.navigation-dot-tooltip').style.display = 'none'
  }

  return (
    <NavigationDotsStyled id="navigation-dots">
      {screensConfig?.map(
        ({ title, idScreen, isDataForViewingComponentExist }) => {
          if (idScreen?.includes(' ')) idScreen = idScreen.replace(' ', '')
          return (
            idScreen &&
            isDataForViewingComponentExist && (
              <NavigationDotStyled
                key={idScreen}
                className="navigation-dot"
                id={`navigation-${idScreen}`}
                onClick={() => handleClickDot(idScreen)}
                onMouseMove={() => handleMouseMoveDot(idScreen)}
                onMouseLeave={() => handleMouseLeaveDot(idScreen)}>
                <NavigationDotTooltip className="navigation-dot-tooltip">
                  <Text variant="body2" type="default">
                    {title}
                  </Text>
                </NavigationDotTooltip>
              </NavigationDotStyled>
            )
          )
        }
      )}
    </NavigationDotsStyled>
  )
}

NavigationDots.propTypes = {
  screensConfig: PropTypes.arrayOf(PropTypes.object)
}

export default NavigationDots
