import { useEffect } from 'react'
import { Form } from 'antd'
import moment from 'moment'

const useInitializeRepresentativeAdvancedForm = (initialData) => {
  // [ADDITIONAL_HOOKS]
  const [form] = Form.useForm()

  // [LIFECYCLE]
  useEffect(() => {
    if (initialData) {
      const { representative } = initialData
      const formValues = {
        representative: {
          birthDate: representative['birthDate']
            ? moment(representative['birthDate'])
            : null,
          email: representative['email'] ?? null,
          firstName: representative['firstName'] ?? null,
          lastName: representative['lastName'] ?? null,
          phone: representative['phone'] ?? null,
          position: representative['position'] ?? null,
          summary: representative['summary'] ?? null
        }
      }
      form.setFieldsValue(formValues)
    }
  }, [initialData, form])

  return { form }
}

export default useInitializeRepresentativeAdvancedForm
