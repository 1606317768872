const CALCULATOR_EXTERNAL_SERVICE = {
  YES: 'Yes',
  NO: 'No',
  NOT_SURE: 'Not sure'
}

const CALCULATOR_EXTERNAL_SERVICE_OPTIONS = Object.keys(
  CALCULATOR_EXTERNAL_SERVICE
).map((key) => ({ label: CALCULATOR_EXTERNAL_SERVICE[key], value: key }))

export default CALCULATOR_EXTERNAL_SERVICE
export { CALCULATOR_EXTERNAL_SERVICE_OPTIONS, CALCULATOR_EXTERNAL_SERVICE }
