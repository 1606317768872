import { IntroScreen } from '../../../../components'
import { SCREENS_TABLE_OF_CONTENT } from '../../../../__constants__'

const ImageryIntroScreen = () => {
  return (
    <IntroScreen
      key="ImageryIntroScreen"
      sectionNumber={5}
      title="Imagery."
      caption="Image Vector"
      contentList={SCREENS_TABLE_OF_CONTENT.IMAGERY_LIST}
    />
  )
}

export default ImageryIntroScreen
