import { Box, Text } from '../../elements'

import styled from 'styled-components'

const TextWrapperStyled = styled.div`
  border: 4px solid var(--accent);
  border-radius: 50%;
  width: var(--timeline-size);
  height: var(--timeline-size);
  display: flex;
  flex: 1 0 auto;
  align-items: center;
  justify-content: center;
  background: var(--white);
`
const IndicatorWrapper = styled.div`
  display: flex;
  align-items: var(--timeline-indicator-align-items);
  flex-direction: var(--timeline-indicator-flex-direction);
  &:before {
    content: '';
    width: var(--timeline-before-width);
    height: var(--timeline-before-height);
    background: var(--accent);
    visibility: ${({ isFirstItem }) => (!isFirstItem ? 'visible' : 'hidden')};
    display: var(--timeline-before-display);
  }
  &:after {
    content: '';
    width: var(--timeline-after-width);
    height: var(--timeline-after-height);
    background: var(--accent);
    visibility: ${({ isLastItem }) => (!isLastItem ? 'visible' : 'hidden')};
  }
`
const TimeLineWrapper = styled.div`
  display: flex;
  flex-direction: var(--timeline-flex-direction);
  justify-content: center;
`
const TimelineItemWrapper = styled.div.attrs({ className: 'col' })`
  display: flex;
  flex-direction: var(--timeline-item-flex-direction);
`
const BoxStyled = styled(Box)`
  padding: var(--timeline-p);
  margin-left: var(--timeline-ml);
`
const TextStyled = styled(Text)`
  margin-bottom: var(--timeline-mb);
`

export {
  TextWrapperStyled,
  TextStyled,
  IndicatorWrapper,
  TimeLineWrapper,
  TimelineItemWrapper,
  BoxStyled
}
