import { useEffect } from 'react'
import { Form } from 'antd'

const useInitializeDatabaseAdvancedForm = (initialData) => {
  // [ADDITIONAL_HOOKS]
  const [form] = Form.useForm()

  // [LIFECYCLE]
  useEffect(() => {
    if (initialData) {
      const { database } = initialData
      const formValues = {
        database: {
          dataModels: database['dataModels'] ?? null,
          enums: database['enums'] ?? null
        }
      }
      form.setFieldsValue(formValues)
    }
  }, [initialData, form])

  return { form }
}

export default useInitializeDatabaseAdvancedForm
