import { Checkbox } from 'antd'
import PropTypes from 'prop-types'
import { useGetTechnologies } from 'domains/Technology/hooks'
import { useSubfilterActions } from 'hooks'
import { StyledDetails } from '../OfferFilter.styles'
import { useTranslations } from 'contexts/Translation'
import { Text } from '@qonsoll/react-design'

const TechnologyFilter = ({ filterData, setFilterData }) => {
  // [ADDITIONAL_HOOKS]
  const [technologies] = useGetTechnologies()
  const { t } = useTranslations()

  // [COMPUTED_PROPERTIES]
  const summaryStyles = { marginBottom: '8px' }

  const { onToggleDetails, onChange, checkIsEnabled } = useSubfilterActions({
    filterData,
    setFilterData,
    fieldName: 'technologies',
    operand: 'array-contains'
  })

  return (
    <StyledDetails
      open={localStorage.getItem(`technology-details-visibility`) === 'opened'}>
      <summary
        style={summaryStyles}
        onClick={() => onToggleDetails('technology')}>
        {t('Technology')}
      </summary>
      {technologies?.map((technology, index) => (
        <div key={`status-${index}`}>
          <Checkbox
            key={`status-${index}`}
            checked={checkIsEnabled(technology)}
            onChange={() => onChange(technology)}>
            <Text wordBreak="break-all">
              {technology?.name || t('Unnamed filter')}
            </Text>
          </Checkbox>
        </div>
      ))}
    </StyledDetails>
  )
}

TechnologyFilter.propTypes = {
  filterData: PropTypes.object,
  setFilterData: PropTypes.func
}

export default TechnologyFilter
