import { useTranslations } from 'contexts/Translation'
import PropTypes from 'prop-types'
import {
  Col,
  Container,
  Row,
  Card,
  Text,
  Divider,
  Title,
  Img
} from '@qonsoll/react-design'
import { useHistory } from 'react-router-dom'
import { CardDropdown, SelectableCard } from 'components'
import { useSimpleFormActions } from 'hooks'
import { useParams } from 'react-router-dom'
import { updateParentHasOneRelationship } from 'hooks'

const DesignSimpleView = (props) => {
  const { design, onSelect, onDelete, index, isSelected, actions } = props

  // [ADDITIONAL_HOOKS]
  const { t } = useTranslations()
  const history = useHistory()
  const { handleDelete: removeDocument } = useSimpleFormActions({
    document: design,
    collectionName: 'designs'
  })
  const params = useParams()

  // [HANDLERS]
  const handleOpen = () => {
    history.push(`/designs/${design?._id}`)
  }
  const handleEdit = () => {
    history.push(`/designs/${design?._id}/edit`)
  }
  const handleDelete = (item, index) => {
    removeDocument()
    if (params.customerId)
      updateParentHasOneRelationship(
        params.customerId,
        'customers',
        'profileId',
        item?._id,
        true
      )
  }
  const handleSelect = (e) => {
    e.stopPropagation()
    onSelect?.(design?._id)
  }

  return (
    <CardDropdown
      onDelete={handleDelete}
      onEdit={handleEdit}
      onDoubleClick={handleOpen}
      onClick={handleSelect}
      document={design}
      index={index}
      {...actions}>
      <SelectableCard isSelected={isSelected}>
        <Row
          noGutters
          justifyContent="center"
          alignItems="center"
          py="4px"
          mb={2}>
          <Col>
            <Title
              level={4}
              overflow="hidden"
              textOverflow="ellipsis"
              maxWidth="250px"
              whiteSpace="nowrap">
              {design?.name}
            </Title>
          </Col>
        </Row>
        <Row
          noGutters
          justifyContent="center"
          alignItems="center"
          py="4px"
          mb={2}>
          <Col>
            <Text
              overflow="hidden"
              textOverflow="ellipsis"
              maxWidth="250px"
              whiteSpace="nowrap">
              {design?.description}
            </Text>
          </Col>
        </Row>
      </SelectableCard>
    </CardDropdown>
  )
}

DesignSimpleView.propTypes = {
  design: PropTypes.object,
  onSelect: PropTypes.func,
  onDelete: PropTypes.func,
  index: PropTypes.number,
  isSelected: PropTypes.bool,
  actions: PropTypes.shape({
    onEdit: PropTypes.func,
    onDelete: PropTypes.func,
    onDoubleClick: PropTypes.func,
    onClick: PropTypes.func
  })
}

export default DesignSimpleView
