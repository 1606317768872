import PropTypes from 'prop-types'

const Target = (props) => {
  const { iconSize, iconColor, fill } = props

  return (
    <svg
      width={iconSize}
      height={iconSize}
      viewBox="0 0 72 72"
      fill={fill}
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M50.7001 38.9998C50.1574 41.6545 48.9059 44.1127 47.0783 46.1132C45.2507 48.1137 42.9153 49.5818 40.3203 50.3615C37.7253 51.1412 34.9676 51.2035 32.34 50.5416C29.7125 49.8798 27.3133 48.5185 25.3973 46.6026C23.4813 44.6866 22.1201 42.2874 21.4583 39.6598C20.7964 37.0323 20.8587 34.2745 21.6384 31.6795C22.4181 29.0845 23.8862 26.7492 25.8867 24.9216C27.8872 23.094 30.3453 21.8424 33.0001 21.2998"
        stroke={iconColor}
        strokeWidth="6"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M36.99 9H36C30.6599 9 25.4397 10.5835 20.9996 13.5503C16.5595 16.5171 13.0988 20.7339 11.0553 25.6675C9.0117 30.6012 8.47701 36.03 9.51881 41.2674C10.5606 46.5049 13.1321 51.3159 16.9081 55.0919C20.6841 58.8679 25.4951 61.4394 30.7326 62.4812C35.9701 63.523 41.3989 62.9883 46.3325 60.9447C51.2661 58.9012 55.4829 55.4405 58.4497 51.0004C61.4165 46.5603 63 41.3401 63 36C63 35.67 63 35.34 63 35.01M45 27L37.5 34.5L45 27ZM45 18V27H54L63 18H54V9L45 18Z"
        stroke={iconColor}
        strokeWidth="6"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

Target.propTypes = {
  iconSize: PropTypes.string.isRequired,
  fill: PropTypes.string,
  iconColor: PropTypes.string
}

export default Target
