import { Box, Text, Title } from '../../../../components'

import PropTypes from 'prop-types'
import { useTranslations } from 'contexts/Translation'

const PriceView = (props) => {
  const { price } = props

  //[ADDITIONAL_HOOKS]
  const { t } = useTranslations()

  // [COMPUTED_PROPERTIES]
  const textSize = {
    fontSize: '32px',
    lineHeight: '48px'
  }

  return (
    <Box
      display="flex"
      alignItems="center"
      flexDirection="column"
      justifyContent="center"
      textAlign="center"
      wordBreak="break-all">
      <Title level={1} className="mb-3">
        {price?.toLocaleString()}
      </Title>
      <Text {...textSize} type="accent">
        {t('Completed MVP')}
      </Text>
    </Box>
  )
}

PriceView.propTypes = {
  price: PropTypes.string
}

export default PriceView
