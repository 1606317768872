import { IMAGE_DECORATIONS } from '../../../__constants__'

const { INSTA_POST_LEFT, INSTA_POST_RIGHT } = IMAGE_DECORATIONS

const INSTA_POST = [
  {
    img: INSTA_POST_LEFT,
    bottom: 0,
    xs: {
      left: '46%',
      width: '300px'
    },
    sm: {
      left: '50%',
      width: '400px'
    },
    md: {
      left: '40%',
      width: '400px'
    },
    lg: {
      left: '40%',
      width: '429px'
    },
    xl: {
      left: '36%',
      width: '429px'
    }
  },
  {
    img: INSTA_POST_RIGHT,
    top: 0,
    xs: {
      display: 'none'
    },
    sm: {
      display: 'none'
    },
    md: {
      left: '56%',
      width: '400px'
    },
    lg: {
      left: '62%',
      width: '429px'
    },
    xl: {
      left: '62%',
      width: '429px'
    }
  }
]

export default INSTA_POST
