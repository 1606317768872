import { useEffect } from 'react'
import { Form } from 'antd'

const useInitializeCustomerAdvancedForm = (initialData) => {
  // [ADDITIONAL_HOOKS]
  const [form] = Form.useForm()

  // [LIFECYCLE]
  useEffect(() => {
    if (initialData) {
      const {
        customer,
        applicationIds,
        brandbookId,
        customerRepresentativeIds,
        databaseId,
        featureIds,
        mockupIds,
        offerId,
        profileId
      } = initialData
      const formValues = {
        customer: {
          email: customer['email'] ?? null,
          name: customer['name'] ?? null,
          status: customer['status'] ?? null
        },
        applicationIds: applicationIds ?? null,
        brandbookId: brandbookId ?? null,
        customerRepresentativeIds: customerRepresentativeIds ?? null,
        databaseId: databaseId ?? null,
        featureIds: featureIds ?? null,
        mockupIds: mockupIds ?? null,
        offerId: offerId ?? null,
        profileId: profileId ?? null
      }
      form.setFieldsValue(formValues)
    }
  }, [initialData, form])

  return { form }
}

export default useInitializeCustomerAdvancedForm
