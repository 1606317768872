import { Checkbox } from 'antd'
import PropTypes from 'prop-types'
import { useGetCustomerProfiles } from 'domains/CustomerProfile/hooks'
import { useSubfilterActions } from 'hooks'
import { StyledDetails } from '../CustomerFilter.styles'
import { useTranslations } from 'contexts/Translation'
import { Text } from '@qonsoll/react-design'

const CustomerProfileFilter = ({ filterData, setFilterData }) => {
  // [ADDITIONAL_HOOKS]
  const [customerProfiles] = useGetCustomerProfiles()
  const { t } = useTranslations()

  // [COMPUTED_PROPERTIES]
  const summaryStyles = { marginBottom: '8px' }

  const { onToggleDetails, onChange, checkIsEnabled } = useSubfilterActions({
    filterData,
    setFilterData,
    fieldName: 'customerProfile',
    operand: '=='
  })

  return (
    <StyledDetails
      open={
        localStorage.getItem(`customerProfile-details-visibility`) === 'opened'
      }>
      <summary
        style={summaryStyles}
        onClick={() => onToggleDetails('customerProfile')}>
        {t('Customer profile')}
      </summary>
      {customerProfiles?.map((customerProfile, index) => (
        <div key={`status-${index}`}>
          <Checkbox
            key={`status-${index}`}
            checked={checkIsEnabled(customerProfile)}
            onChange={() => onChange(customerProfile)}>
            <Text wordBreak="break-all">
              {customerProfile?.calculatorId || t('Unnamed filter')}
            </Text>
          </Checkbox>
        </div>
      ))}
    </StyledDetails>
  )
}

CustomerProfileFilter.propTypes = {
  filterData: PropTypes.object,
  setFilterData: PropTypes.func
}

export default CustomerProfileFilter
