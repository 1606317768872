import { IntroScreen } from '../../../../components'
import { SCREENS_TABLE_OF_CONTENT } from '../../../../__constants__'

const AppIconIntroScreen = () => {
  return (
    <IntroScreen
      key="AppIconIntroScreen"
      sectionNumber={6}
      title="App icon."
      caption="Application icon"
      contentList={SCREENS_TABLE_OF_CONTENT.APP_ICON_LIST}
    />
  )
}

export default AppIconIntroScreen
