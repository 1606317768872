import './CheckboxList.vars.css'

import styled from 'styled-components'

const CheckboxListWrapper = styled.div`
  display: flex;
  flex-direction: var(--checkbox-wrapper-direction);
  gap: var(--gap-24);
`

const CheckboxListStyled = styled.div`
  display: flex;
  flex-direction: var(--checkbox-list-direction);
  gap: var(--gap-32);
`

export { CheckboxListWrapper, CheckboxListStyled }
