import '../__constants__/LogoScreen.vars.css'

import {
  BRANDBOOK_CONFIG_KEYS,
  BRANDBOOK_CONFIG_REDUCER_CASES
} from 'brandbook/__constants__'
import { LogoCombinedView, TypoIntro } from 'brandbook/domains'

import { ASIDE_STYLE } from '../__constants__'
import { BoundedContainer } from 'brandbook/screens/Logo/LogoContent/components'
import { ContentScreen } from 'brandbook/components'
import { LOGO_BOTTOM_OFFSETS } from 'brandbook/screens/Logo/LogoContent/__constants__'

const LogoWordmarkScreen = () => {
  return (
    <ContentScreen
      key="LogoWordmarkScreen"
      chapter="Wordmark"
      domainName="Logo."
      pageNumber="13"
      asideStyle={ASIDE_STYLE}
      asideTopContent={
        <TypoIntro
          subtitle="/ Logotype"
          description="A wordmark or logotype is a font-based logo that focuses on a business name alone."
          title="Lettermark"
        />
      }
      content={
        <BoundedContainer
          offsetBottom={LOGO_BOTTOM_OFFSETS.variant1}
          top="0"
          right="5%">
          <LogoCombinedView
            hasOffsets
            size="xxl"
            color="dark"
            logoKey={BRANDBOOK_CONFIG_KEYS.WORDMARK}
            reducerCase={BRANDBOOK_CONFIG_REDUCER_CASES.SET_LOGO_WORDMARK}
          />
        </BoundedContainer>
      }
    />
  )
}

export default LogoWordmarkScreen
