import { model, hasMany, belongsTo, attr } from 'services/model'
import * as yup from 'yup'

const validationSchema = yup.object().shape({
  applicationIds: yup
    .array()
    .typeError('Field applicationIds has wrong type')
    .default(null)
    .nullable(),
  brandbookId: yup
    .string()
    .typeError('Field brandbookId has wrong type')
    .default(null)
    .nullable(),
  customerRepresentativeIds: yup
    .array()
    .typeError('Field customerRepresentativeIds has wrong type')
    .default(null)
    .nullable(),
  databaseId: yup
    .string()
    .typeError('Field databaseId has wrong type')
    .default(null)
    .nullable(),
  email: yup
    .string()
    .typeError('Field email has wrong type')
    .required('Field email is required'),
  featureIds: yup
    .array()
    .typeError('Field featureIds has wrong type')
    .default(null)
    .nullable(),
  mockupIds: yup
    .array()
    .typeError('Field mockupIds has wrong type')
    .default(null)
    .nullable(),
  name: yup
    .string()
    .typeError('Field name has wrong type')
    .required('Field name is required'),
  offerId: yup
    .string()
    .typeError('Field offerId has wrong type')
    .default(null)
    .nullable(),
  profileId: yup
    .string()
    .typeError('Field profileId has wrong type')
    .default(null)
    .nullable(),
  status: yup
    .string()
    .typeError('Field status has wrong type')
    .default(null)
    .nullable()
})

const Customer = model(
  'customer',
  {
    applicationIds: hasMany('application'),
    brandbookId: belongsTo('brandbook'),
    customerRepresentativeIds: hasMany('representative'),
    databaseId: belongsTo('database'),
    email: attr('string'),
    featureIds: hasMany('feature'),
    mockupIds: hasMany('mockup'),
    name: attr('string'),
    offerId: belongsTo('offer'),
    profileId: belongsTo('customerProfile'),
    status: attr('string')
  },
  validationSchema
)

export default Customer
