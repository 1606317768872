import { IntroScreen } from '../../../../components'
import { SCREENS_TABLE_OF_CONTENT } from '../../../../__constants__'

const IconographyIntroScreen = () => {
  return (
    <IntroScreen
      key="IconographyIntroScreen"
      sectionNumber={4}
      title="Ico."
      caption="Iconography"
      contentList={SCREENS_TABLE_OF_CONTENT.ICO_LIST}
    />
  )
}

export default IconographyIntroScreen
