import {
  DescriptionView,
  FeatureList,
  MockupView,
  ModelList,
  PaymentTermsList,
  PriceView,
  ProcessTimeline,
  TechnologyList,
  VideoView
} from 'offer/domains'
import {
  useGetCustomerAndCustomerProfileByOfferId,
  useOfferRelatedData
} from 'offer/hooks'

import { useMemo } from 'react'
import { useTranslations } from 'contexts/Translation'

const useScreenConfig = (offerId) => {
  // [ADDITIONAL_HOOKS]
  const { t } = useTranslations()
  const {
    featuresData,
    mockupsData,
    videosData,
    isLoading,
    databaseData,
    technologiesData,
    offerData
  } = useOfferRelatedData(offerId)
  const [customerProfile] = useGetCustomerAndCustomerProfileByOfferId(offerId)

  // [HELPER_FUNCTIONS]
  const determineIfReversedScreen = (index) => index % 2 === 0

  // Check if the data is empty or not
  // Hide or show screen
  const isTechnologies = !!technologiesData?.length
  const isDescription = !!offerData?.description || customerProfile?.description
  const isFeatures = !!featuresData?.length
  const isModels = !!databaseData?.dataModels?.length
  const isPrice = !!offerData?.price
  const isPaymentTerms = !!offerData?.paymentTerms?.length
  const breadcrumbs = { breadcrumbs: [t('Offer'), 'Mockups'] }
  const mockups = useMemo(
    () =>
      mockupsData?.map(({ name, imageUrl, description }, index) => {
        return {
          idScreen: name,
          title: name || `Mockup${index}`,
          isDataForViewingComponentExist: true,
          component: <MockupView src={imageUrl} alt={name} />,
          screenData: {
            imageUrl: imageUrl,
            id: name?.replace(' ', '') || `Mockup${index}`,
            isReverseOrderContent: determineIfReversedScreen(index),
            isHorizontalLayout: true,
            description: description,
            isTitleVisible: true
          }
        }
      }),
    [mockupsData]
  )

  const videos = useMemo(
    () =>
      videosData?.map(({ name, qVideo, description }, index) => {
        return {
          idScreen: name,
          title: name || `Video${index}`,
          isDataForViewingComponentExist: true,
          component: <VideoView qVideo={qVideo} />,
          screenData: {
            id: name?.replace(' ', '') || `Video${index}`,
            isReverseOrderContent: index % 2,
            isHorizontalLayout: true,
            description: description,
            isTitleVisible: true
          }
        }
      }),
    [videosData]
  )

  const screenConfig = useMemo(
    () => [
      {
        idScreen: 'hello',
        title: `${t('Hello, we are senseteq')}!`,
        isDataForViewingComponentExist: true
      },
      {
        idScreen: 'description',
        title: `${t('Description')}.`,
        screenData: {
          isHorizontalLayout: true
        },
        isDataForViewingComponentExist: isDescription,
        component: isDescription && (
          <DescriptionView
            description={offerData?.description || customerProfile?.description}
          />
        )
      },
      ...videos,
      breadcrumbs,
      {
        idScreen: 'technologies',
        title: `${t('Technologies')}.`,
        isDataForViewingComponentExist: isTechnologies,
        component: isTechnologies && <TechnologyList data={technologiesData} />
      },
      {
        idScreen: 'features',
        title: `${t('Features')}.`,
        isDataForViewingComponentExist: isFeatures,
        component: isFeatures && <FeatureList data={featuresData} />
      },
      {
        idScreen: 'models',
        title: `${t('Models')}.`,
        isDataForViewingComponentExist: isModels,
        component: isModels && <ModelList data={databaseData?.dataModels} />
      },
      ...mockups,
      breadcrumbs,
      {
        idScreen: 'price',
        title: `${t('Price')}.`,
        isDataForViewingComponentExist: isPrice,
        component: isPrice && <PriceView price={offerData?.price} />,
        screenData: {
          isHorizontalLayout: true
        }
      },
      {
        idScreen: 'timeline',
        title: `${t('Timeline')}.`,
        isDataForViewingComponentExist: true,
        component: <ProcessTimeline />,
        screenData: {
          isHorizontalLayout: true
        }
      },
      {
        idScreen: 'paymentTerms',
        title: `${t('Payment terms')}.`,
        isDataForViewingComponentExist: isPaymentTerms,
        component: isPaymentTerms && (
          <PaymentTermsList paymentTerms={offerData?.paymentTerms} />
        ),
        screenData: {
          isHorizontalLayout: true
        }
      },
      {
        idScreen: 'thankYou',
        title: `${t('Thank you for watching')}!`,
        isDataForViewingComponentExist: true
      }
    ],
    [
      isTechnologies,
      isFeatures,
      isModels,
      mockups,
      videos,
      isPrice,
      isPaymentTerms,
      t,
      isDescription
    ]
  )

  return [screenConfig, isLoading]
}

export default useScreenConfig
