import { ContentScreen, Img, PositionedContainer } from '../../../../components'

import { AppIconLogo } from '../../../../domains'
import { BRANDBOOK_CONFIG_REDUCER_CASES } from 'brandbook/__constants__'
import { IMAGE_DECORATIONS } from '../../../../__constants__'
import responsiveStyles from './AppIconIosScreen.styled'
import { useBrandbookConfig } from 'brandbook/contexts/BrandbookConfig'
import { useMediaQuery } from 'hooks'

/**
 * It returns a ContentScreen component with a PositionedContainer component inside of it
 * @returns A ContentScreen component with the following props:
 *   - key: "AppIconIosScreen"
 *   - chapter: "iOS"
 *   - domainName: "App icon."
 *   - pageNumber: "26"
 *   - footerColor: "white"
 *   - content:
 */
const AppIconIosScreen = () => {
  const { IOS } = IMAGE_DECORATIONS

  // [ADDITIONAL_HOOKS]
  const { brandbookConfigData } = useBrandbookConfig()

  const isBreakpoint = useMediaQuery('(max-width: 576px)')
  const footerColor = isBreakpoint ? 'dark' : 'white'

  return (
    <ContentScreen
      positionOuter
      key="AppIconIosScreen"
      chapter="iOS"
      domainName="App icon."
      pageNumber="26"
      footerColor={footerColor}
      content={
        <PositionedContainer {...responsiveStyles}>
          <Img {...responsiveStyles} src={IOS} alt="ios image" />

          <AppIconLogo
            reducerType={BRANDBOOK_CONFIG_REDUCER_CASES.SET_APP_IOS}
            logo={brandbookConfigData?.appIcon?.appIconIos}
            variant="iphone"
            bg="accent"
            name="Your app"
          />
        </PositionedContainer>
      }
    />
  )
}

export default AppIconIosScreen
