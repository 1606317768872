const BUTTON_VARIANTS = {
  accent: {
    color: 'var(--btn-default-color)',
    backgroundColor: 'var(--btn-accent-bg)',
    border: 'var(--btn-border-color)',
    '&:hover': {
      background: 'var(--btn-accent-hover-bg)',
      color: 'var(--btn-accent-hover-color)'
    }
  },

  neutral: {
    color: 'var(--btn-default-color)',
    backgroundColor: 'var(--btn-neutral-bg)',
    border: 'var(--btn-border-color)',
    '&:hover': {
      background: 'var(--btn-neutral-hover-bg)',
      color: 'var(--btn-neutral-hover-color)'
    }
  },

  success: {
    color: 'var(--btn-default-color)',
    backgroundColor: 'var(--btn-success-bg)',
    border: 'var(--btn-border-color)',
    '&:hover': {
      background: 'var(--btn-success-hover-bg)',
      color: 'var(--btn-success-hover-color)'
    }
  },

  warning: {
    color: 'var(--btn-default-color)',
    backgroundColor: 'var(--btn-warning-bg)',
    border: 'var(--btn-border-color)',
    '&:hover': {
      background: 'var(--btn-warning-hover-bg)',
      color: 'var(--btn-warning-hover-color)'
    }
  },

  danger: {
    color: 'var(--btn-default-color)',
    backgroundColor: 'var(--btn-danger-bg)',
    border: 'var(--btn-border-color)',
    '&:hover': {
      background: 'var(--btn-danger-hover-bg)',
      color: 'var(--btn-danger-hover-color)'
    }
  },

  gray: {
    color: 'var(--btn-default-color)',
    backgroundColor: 'var(--btn-gray-bg)',
    border: 'var(--btn-border-color)',
    '&:hover': {
      background: 'var(--btn-gray-hover-bg)',
      color: 'var(--btn-gray-hover-color)'
    }
  },

  black: {
    color: 'var(--btn-default-color)',
    backgroundColor: 'var(--btn-black-bg)',
    border: 'var(--btn-border-color)',
    '&:hover': {
      background: 'var(--btn-black-hover-bg)',
      color: 'var(--btn-black-hover-color)'
    }
  },

  white: {
    color: 'var(--btn-white-color)',
    backgroundColor: 'var(--btn-white-bg)',
    border: 'var(--btn-border-color)',
    '&:hover': {
      background: 'var(--btn-white-hover-bg)',
      color: 'var(--btn-white-hover-color)'
    }
  },
  text: {
    color: 'var(--btn-text-color)',
    backgroundColor: 'transparent',
    border: 'var(--btn-border-color)',
    '&:hover': {
      background: 'var(--btn-text-hover-bg)',
      color: 'var(--btn-text-hover-color)'
    }
  },
  link: {
    color: 'var(--btn-link-color)',
    backgroundColor: 'var(--btn-link-bg)',
    border: 'var(--btn-border-color)',
    '&:hover': {
      background: 'transparent',
      color: 'var(--btn-link-hover-color)'
    }
  }
}

export default BUTTON_VARIANTS
