import './Footer.vars.css'

import FooterStyled from './Footer.styled'
import PropTypes from 'prop-types'
import Text from '../../elements/Typography/Text'

/**
 *
 * @param {*} props - This is object with passed props
 * Possible props: pageNumber - string, variant - color of title
 * @returns A React Component - Footer
 */
const Footer = (props) => {
  const { color, pageNumber } = props

  // [COMPUTED PROPERTIES]
  const contentComputed = pageNumber || 'senseteq.io'

  return (
    <FooterStyled pageNumber={pageNumber}>
      <Text ff="senseteq" color={color}>
        {contentComputed}
      </Text>
    </FooterStyled>
  )
}

Footer.propTypes = {
  color: PropTypes.string,
  pageNumber: PropTypes.string
}
export default Footer
