import { ICONS_NAMES, ICONS_SIZES, ICONS_VARIANTS } from './__constants__'

import PropTypes from 'prop-types'
import { createElement } from 'react'

/**
 * If the icon prop is truthy, then return Icon component
 * @param {string} icon - A name of the Icon component
 * @param {string} color - A color of the Icon component
 * @param {string} size - Size of the Icon component
 * @param {bool} filled - A property which detfines
 * whether the component should be filled
 * @returns A Box component with an icon inside.
 */
const Icon = (props) => {
  const { icon, variant, filled, size } = props

  // [COMPUTED PROPERTIES]
  const fill = filled ? ICONS_VARIANTS[variant] : 'transparent'
  const iconColor = ICONS_VARIANTS[variant] || ICONS_VARIANTS.accent
  const iconSize = ICONS_SIZES[size] || ICONS_SIZES.md

  return icon
    ? createElement(ICONS_NAMES[icon], {
        iconSize,
        iconColor,
        fill
      })
    : null
}

Icon.propTypes = {
  icon: PropTypes.string.isRequired,
  size: PropTypes.string.isRequired,
  variant: PropTypes.string,
  filled: PropTypes.bool
}

export default Icon
