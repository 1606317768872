import { useEffect } from 'react'
import { Form } from 'antd'

const useInitializeCalculatorAdvancedForm = (initialData) => {
  // [ADDITIONAL_HOOKS]
  const [form] = Form.useForm()

  // [LIFECYCLE]
  useEffect(() => {
    if (initialData) {
      const { calculator } = initialData
      const formValues = {
        calculator: {
          platforms: calculator['platforms'] ?? null,
          industry: calculator['industry'] ?? null,
          analogue: calculator['analogue'] ?? null,
          administration: calculator['administration'] ?? null,
          appearance: calculator['appearance'] ?? null,
          authentication: calculator['authentication'] ?? null,
          brand: calculator['brand'] ?? null,
          external_service: calculator['external_service'] ?? null,
          localization: calculator['localization'] ?? null,
          revenue: calculator['revenue'] ?? null
        }
      }
      form.setFieldsValue(formValues)
    }
  }, [initialData, form])

  return { form }
}

export default useInitializeCalculatorAdvancedForm
