import styled from 'styled-components'

const TitleItemWrapper = styled.div`
  display: flex;
  align-items: center;
`

const Tag = styled.div`
  width: 72px;
  margin-right: var(--offset-32);
`

const TagSize = styled.div`
  width: 72px;
  display: flex;
  justify-content: flex-end;
`

export { TitleItemWrapper, Tag, TagSize }
