import { ContentScreen } from '../../../../components'
import { MegaText } from '../../../../domains'

const TypographyTypefaceScreen = () => {
  return (
    <ContentScreen
      positionOuter
      pageNumber="07"
      chapter="Typeface"
      key="TypographyTypefaceScreen"
      domainName="Typo."
      content={<MegaText variant="lg" />}
    />
  )
}

export default TypographyTypefaceScreen
