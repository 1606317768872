import './Switcher.vars.css'

import PropTypes from 'prop-types'
import SwitcherStyled from './Switcher.styled'

/**
 *
 * @param {boolean} checked - makes the switcher always active.
 * @param {boolean} mega - makes biggest switcher.
 * @returns switcher.
 */

const Switcher = (props) => {
  const { mega, checked } = props
  return (
    <SwitcherStyled mega={mega} checked={checked}>
      <input type="checkbox" readOnly checked={checked} />
      <span className="slider" />
    </SwitcherStyled>
  )
}

Switcher.propTypes = {
  mega: PropTypes.bool,
  checked: PropTypes.bool
}

export default Switcher
