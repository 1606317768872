import { Box, Switcher, Text } from '../../../../components'

import PropTypes from 'prop-types'
import SwitcherSimpleViewStyled from './SwitcherSimpleView.styled'

/**
 * @param {string} name - name switcher;
 * @param {?boolean} checked - checked or unchecked switcher -
 * - true - always checked, false - always unchecked, null - default
 * @returns switcher
 */
const SwitcherSimpleView = (props) => {
  const { name, checked } = props

  return (
    <SwitcherSimpleViewStyled>
      <Switcher checked={checked} />
      <Box className="text-wrapper">
        <Text ff="primary">{name}</Text>
      </Box>
    </SwitcherSimpleViewStyled>
  )
}

SwitcherSimpleView.propTypes = {
  name: PropTypes.string,
  checked: PropTypes.bool
}
export default SwitcherSimpleView
