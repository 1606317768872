const BRANDBOOK_CONFIG_REDUCER_CASES = {
  SET_BRAND_NAME: 'SET_BRAND_NAME',
  SET_ACCENT_COLOR: 'SET_ACCENT_COLOR',
  SET_ACCENT_DARKEN_COLOR: 'SET_ACCENT_DARKEN_COLOR',
  SET_NEUTRAL_COLOR: 'SET_NEUTRAL_COLOR',
  SET_SHAPE: 'SET_SHAPE',
  SET_INITIAL_DATA: 'SET_INITIAL_DATA',
  SET_PRIMARY_FONT: 'SET_PRIMARY_FONT',
  SET_SECONDARY_FONT: 'SET_SECONDARY_FONT',
  SET_IMG_MOODBOARD: 'SET_IMG_MOODBOARD',
  SET_IMG_DIRECTION: 'SET_IMG_DIRECTION',
  SET_APP_ICON: 'SET_APP_ICON',
  SET_APP_IOS: 'SET_APP_IOS',
  SET_APP_ANDROID: 'SET_APP_ANDROID',
  SET_ICON_STYLE: 'SET_ICON_STYLE',
  SET_LOGO_SYMBOL: 'SET_LOGO_SYMBOL',
  SET_LOGO_WORDMARK: 'SET_LOGO_WORDMARK',
  SET_LOGO_GUIDE: 'SET_LOGO_GUIDE',
  SET_LOGO_PROPER_USE_1: 'SET_LOGO_PROPER_USE_1',
  SET_LOGO_PROPER_USE_2: 'SET_LOGO_PROPER_USE_2',
  SET_LOGO_PROPER_USE_3: 'SET_LOGO_PROPER_USE_3',
  SET_LOGO_MISS_USE_1: 'SET_LOGO_MISS_USE_1',
  SET_LOGO_MISS_USE_2: 'SET_LOGO_MISS_USE_2',
  SET_LOGO_MISS_USE_3: 'SET_LOGO_MISS_USE_3',
  SET_LOGO_MISS_USE_4: 'SET_LOGO_MISS_USE_4',
  SET_LOGO_MISS_USE_5: 'SET_LOGO_MISS_USE_5',
  SET_LOGO_MISS_USE_6: 'SET_LOGO_MISS_USE_6'
}

export default BRANDBOOK_CONFIG_REDUCER_CASES
