import { useEffect, useState } from 'react'
import { getDocument } from 'services/firestore'

const useGetApplicationRole = (application) => {
  const [role, setRole] = useState(null)
  const [loading, setLoading] = useState(!!application?.roleId)

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true)
      const document = await getDocument('roles', application.roleId)
      setRole(document)
      setLoading(false)
    }
    application?.roleId && fetchData()
  }, [application])

  return [role, loading]
}

export default useGetApplicationRole
