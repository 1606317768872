import styled from 'styled-components'

const IntroScreenStyled = styled.div`
  height: 100vh;
  background: var(--intro-screen-bg);
  overflow: hidden;
  position: relative;
`

export default IntroScreenStyled
