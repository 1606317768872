const responsiveStyles = {
  /* positioned container  */
  bottom: 'var(--icon-guide-bottom)',
  right: 'var(--icon-guide-right)',

  /* icon list  */
  mw: 'var(--icons-list-mw)',
  mb: 'var(--icons-list-mb)',
  justify: 'flex-start',
  mr: 'var(--icon-guide-mr)',
  gap: 'var(--icon-guide-gap)',

  // icon view
  iconWidth: 'var(--icon-width)',

  /* icon size */
  xxl: 'var(--icon-xxl)',
  xl: 'var(--icon-xl)'
}

export default responsiveStyles
