import './LogoCombinedView.vars.css'

import styled, { css } from 'styled-components'

import { Box } from 'brandbook/components'
import { Upload } from 'antd'

const { Dragger } = Upload

const COLOR_THEME = (color) => css`
  background-color: var(--logo-${color}-bg);
`

const CONTAINER_SIZE = (size) => css`
  height: var(--logo-height-${size});
  width: var(--logo-width-${size});
`

const LogoCombinedViewStyled = styled(Box)`
  overflow: hidden;
  padding: ${({ hasOffsets }) => hasOffsets && 'var(--offset-24)'};
  border: ${({ color, hasOffsets }) =>
    hasOffsets && color === 'white' && 'var(--logo-border)'};
  ${({ color = 'white' }) => COLOR_THEME(color)};
  ${({ size = 'md' }) => CONTAINER_SIZE(size)};

  display: flex;
  justify-content: center;
  align-items: center;
  flex: 0;
  cursor: ${({ cursor }) => cursor};
  height: ${({ height }) => height};
`

const DraggerStyled = styled(Dragger)`
  border: none !important;
  border-radius: 0px !important;
  .ant-upload {
    display: block !important;
    height: inherit !important;
    cursor: ${({ disabled }) => (disabled ? 'default' : 'pointer')};
  }
  .ant-upload-btn {
    display: block !important;
    height: inherit !important;
    padding: 0px !important;
  }
  .ant-upload-drag-container {
    display: block !important;
    height: inherit !important;
  }
`

export { LogoCombinedViewStyled, DraggerStyled }
