const SCREENS_TABLE_OF_CONTENT = {
  COLOR_LIST: ['Main colors', 'Shades'],
  TYPO_LIST: ['Typeface', 'Primary', 'Secondary', 'Use of type'],
  LOGO_LIST: ['Symbol', 'Wordmark', 'Logo guide', 'Proper use', 'Miss-use'],
  ICO_LIST: ['Guide', 'Examples'],
  IMAGERY_LIST: ['Image direction', 'Moodboard', 'Insta post'],
  APP_ICON_LIST: ['Icon guide', 'IOS', 'Android'],
  ELEM_LIST: ['Button', 'Input', 'Checkbox', 'Radio', 'Switcher', 'Avatar']
}

export default SCREENS_TABLE_OF_CONTENT
