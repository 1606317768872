import { PositionedContainer } from '../../../../../components'
import styled from 'styled-components'

const BoundedContainerStyled = styled(PositionedContainer)`
  height: 100%;

  @media (max-width: 576px) {
    width: 100%;
    top: 15%;
    height: auto;
  }
`

export default BoundedContainerStyled
