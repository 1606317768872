import {
  useCollectionData,
  useCollectionDataOnce
} from 'react-firebase-hooks/firestore'

import { COLLECTIONS } from '__constants__'
import firebase from 'firebase/compat/app'
import { useLoading } from 'hooks'
import { useMemo } from 'react'

const useGetCustomerAndCustomerProfileByOfferId = (offerId) => {
  const [customer, customerLoading, customerError] = useCollectionDataOnce(
    offerId &&
      firebase
        .firestore()
        .collection(COLLECTIONS.CUSTOMERS)
        .where('offerId', '==', offerId)
  )

  const [customerProfile, customerProfileLoading, customerProfileError] =
    useCollectionData(
      customer?.[0]?._id &&
        firebase
          .firestore()
          .collection(COLLECTIONS.CUSTOMER_PROFILES)
          .where('customerId', '==', customer?.[0]?._id)
    )
  const customerProfileDocument =
    !customerProfileLoading && !customerProfileError && customerProfile?.[0]

  const isLoading = useLoading(customerLoading, customerProfileLoading)
  const isError = customerError && customerProfileError

  return useMemo(
    () => [customerProfileDocument, isLoading, isError],
    [customerProfileDocument, isLoading, isError]
  )
}

export default useGetCustomerAndCustomerProfileByOfferId
