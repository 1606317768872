import { useEffect } from 'react'
import { Form } from 'antd'

const useInitializeCustomerProfileAdvancedForm = (initialData) => {
  // [ADDITIONAL_HOOKS]
  const [form] = Form.useForm()

  // [LIFECYCLE]
  useEffect(() => {
    if (initialData) {
      const { customerProfile, calculatorId, colorIds, designIds, roleIds } =
        initialData
      const formValues = {
        customerProfile: {
          description: customerProfile['description'] ?? null,
          isDescriptionChanged: customerProfile['isDescriptionChanged'] ?? null,
          logoFormat: customerProfile['logoFormat'] ?? null,
          logoUrl: customerProfile['logoUrl'] ?? null,
          notes: customerProfile['notes'] ?? null
        },
        calculatorId: calculatorId ?? null,
        colorIds: colorIds ?? null,
        designIds: designIds ?? null,
        roleIds: roleIds ?? null
      }
      form.setFieldsValue(formValues)
    }
  }, [initialData, form])

  return { form }
}

export default useInitializeCustomerProfileAdvancedForm
