const getRgbValue = (value) => {
  const rgba = value?.match(/\d+/g)?.map(Number)
  const red = rgba?.[0].toString()
  const green = rgba?.[1].toString()
  const blue = rgba?.[2].toString()
  const rgbValue = 'R' + red + ' G' + green + ' B' + blue
  return rgbValue
}

export default getRgbValue
