import { useEffect } from 'react'
import { Form } from 'antd'

const useInitializeDesignAdvancedForm = (initialData) => {
  // [ADDITIONAL_HOOKS]
  const [form] = Form.useForm()

  // [LIFECYCLE]
  useEffect(() => {
    if (initialData) {
      const { design } = initialData
      const formValues = {
        design: {
          description: design['description'] ?? null,
          imageUrl: design['imageUrl'] ?? null,
          name: design['name'] ?? null
        }
      }
      form.setFieldsValue(formValues)
    }
  }, [initialData, form])

  return { form }
}

export default useInitializeDesignAdvancedForm
