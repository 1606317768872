import { useTranslations } from 'contexts/Translation'
import PropTypes from 'prop-types'
import { Title, Button } from '@qonsoll/react-design'
import { MAX_LENGTH_FIELD, MAX_LENGTH_DESCRIPTION, KEYS } from '__constants__'
import { Form, DatePicker, Input } from 'antd'
import { PhoneInput } from '@qonsoll/react-design'

const RepresentativeSimpleForm = (props) => {
  const { initialValues, onSubmit, onCancel, showTitle, form, group } = props

  // [ADDITIONAL_HOOKS]
  const { t } = useTranslations()
  const [baseForm] = Form.useForm()

  // [COMPUTED_PROPERTIES]
  const usedForm = form || baseForm

  // [HANDLERS]
  const handleCancel = () => {
    usedForm.resetFields()
    onCancel?.()
  }
  const handleKeyPress = (e) => {
    if (e.key === KEYS.ENTER) {
      e.preventDefault()
      e.stopPropagation()
      usedForm?.submit()
    }
  }

  return group ? (
    <>
      {showTitle && (
        <Title level={4} mb={2}>
          {t('Representative')}
        </Title>
      )}
      <Form.Item
        label={t('First name')}
        name={[...group, 'firstName']}
        rules={[
          {
            required: true,
            message: t('Please enter your representative first name!')
          },
          {
            max: MAX_LENGTH_FIELD,
            message: t('representative first name is too long!')
          }
        ]}>
        <Input placeholder={t('Please enter your representative first name')} />
      </Form.Item>
      <Form.Item
        label={t('Last name')}
        name={[...group, 'lastName']}
        rules={[
          {
            required: true,
            message: t('Please enter your representative last name!')
          },
          {
            max: MAX_LENGTH_FIELD,
            message: t('representative last name is too long!')
          }
        ]}>
        <Input placeholder={t('Please enter your representative last name')} />
      </Form.Item>
      <Form.Item
        label={t('Summary')}
        name={[...group, 'summary']}
        rules={[
          {
            required: false,
            message: t('Please enter your representative summary!')
          },
          {
            max: MAX_LENGTH_DESCRIPTION,
            message: t('representative summary is too long!')
          }
        ]}>
        <Input.TextArea
          placeholder={t('Please enter your representative summary')}
        />
      </Form.Item>
      <Form.Item
        label={t('Birth date')}
        name={[...group, 'birthDate']}
        rules={[
          {
            required: true,
            message: t('Please enter your representative birth date!')
          }
        ]}>
        <DatePicker
          placeholder={t('Please enter your representative birth date')}
          style={{ width: '100%' }}
          onPressEnter={onSubmit}
        />
      </Form.Item>
      <Form.Item
        label={t('Email')}
        name={[...group, 'email']}
        rules={[
          {
            required: true,
            message: t('Please enter your representative email!')
          },
          {
            max: MAX_LENGTH_FIELD,
            message: t('representative email is too long!')
          }
        ]}>
        <Input placeholder={t('Please enter your representative email')} />
      </Form.Item>
      <Form.Item
        label={t('Phone')}
        name={[...group, 'phone']}
        rules={[
          {
            required: true,
            message: t('Please enter your representative phone!')
          }
        ]}>
        <PhoneInput
          specialLabel=""
          placeholder={t('Please enter your representative phone')}
        />
      </Form.Item>
      <Form.Item
        label={t('Position')}
        name={[...group, 'position']}
        rules={[
          {
            required: false,
            message: t('Please enter your representative position!')
          },
          {
            max: MAX_LENGTH_FIELD,
            message: t('representative position is too long!')
          }
        ]}>
        <Input placeholder={t('Please enter your representative position')} />
      </Form.Item>
    </>
  ) : (
    <Form
      form={usedForm}
      initialValues={initialValues}
      onFinish={onSubmit}
      layout="vertical"
      onKeyPress={handleKeyPress}
      autoComplete="off">
      {showTitle && (
        <Title level={4} mb={2}>
          {t('Representative')}
        </Title>
      )}
      <Form.Item
        label={t('First name')}
        name="firstName"
        rules={[
          {
            required: true,
            message: t('Please enter your representative first name!')
          },
          {
            max: MAX_LENGTH_FIELD,
            message: t('representative first name is too long!')
          }
        ]}>
        <Input placeholder={t('Please enter your representative first name')} />
      </Form.Item>
      <Form.Item
        label={t('Last name')}
        name="lastName"
        rules={[
          {
            required: true,
            message: t('Please enter your representative last name!')
          },
          {
            max: MAX_LENGTH_FIELD,
            message: t('representative last name is too long!')
          }
        ]}>
        <Input placeholder={t('Please enter your representative last name')} />
      </Form.Item>
      <Form.Item
        label={t('Summary')}
        name="summary"
        rules={[
          {
            required: false,
            message: t('Please enter your representative summary!')
          },
          {
            max: MAX_LENGTH_DESCRIPTION,
            message: t('representative summary is too long!')
          }
        ]}>
        <Input.TextArea
          placeholder={t('Please enter your representative summary')}
        />
      </Form.Item>
      <Form.Item
        label={t('Birth date')}
        name="birthDate"
        rules={[
          {
            required: true,
            message: t('Please enter your representative birth date!')
          }
        ]}>
        <DatePicker
          placeholder={t('Please enter your representative birth date')}
          style={{ width: '100%' }}
        />
      </Form.Item>
      <Form.Item
        label={t('Email')}
        name="email"
        rules={[
          {
            required: true,
            message: t('Please enter your representative email!')
          },
          {
            max: MAX_LENGTH_FIELD,
            message: t('representative email is too long!')
          }
        ]}>
        <Input placeholder={t('Please enter your representative email')} />
      </Form.Item>
      <Form.Item
        label={t('Phone')}
        name="phone"
        rules={[
          {
            required: true,
            message: t('Please enter your representative phone!')
          }
        ]}>
        <PhoneInput
          specialLabel=""
          placeholder={t('Please enter your representative phone')}
        />
      </Form.Item>
      <Form.Item
        label={t('Position')}
        name="position"
        rules={[
          {
            required: false,
            message: t('Please enter your representative position!')
          },
          {
            max: MAX_LENGTH_FIELD,
            message: t('representative position is too long!')
          }
        ]}>
        <Input placeholder={t('Please enter your representative position')} />
      </Form.Item>
      {!form && (
        <>
          <Button mr={2} htmlType="button" onClick={handleCancel}>
            {t('Cancel')}
          </Button>
          <Button type="primary" onClick={() => usedForm.submit()}>
            {t('Save')}
          </Button>
        </>
      )}
    </Form>
  )
}

RepresentativeSimpleForm.propTypes = {
  initialValues: PropTypes.object,
  onSubmit: PropTypes.func,
  onCancel: PropTypes.func,
  showTitle: PropTypes.bool,
  form: PropTypes.any,
  group: PropTypes.array
}

export default RepresentativeSimpleForm
