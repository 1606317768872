const BRANDBOOK_CONFIG_KEYS = {
  ACCENT: '--accent',
  ACCENT_DARKEN: '--accent-darken',
  NEUTRAL: '--neutral',
  BR: '--br',
  FF_PRIMARY: '--ff-primary',
  FF_SECONDARY: '--ff-secondary',
  SYMBOL: 'symbol',
  WORDMARK: 'wordmark',
  GUIDE: 'guide',
  APP_ICON_GUIDE: 'appIconGuide',
  APP_ICON_IOS: 'appIconIos',
  APP_ICON_ANDROID: 'appIconAndroid',
  APP_ICON: 'appIcon',
  ICON_STYLE: 'iconStyle',
  PROPER_USE: 'properUse',
  MISS_USE: 'missUse',
  PROPER_USE_1: 'properUse1',
  PROPER_USE_2: 'properUse2',
  PROPER_USE_3: 'properUse3',
  MISS_USE_1: 'missUse1',
  MISS_USE_2: 'missUse2',
  MISS_USE_3: 'missUse3',
  MISS_USE_4: 'missUse4',
  MISS_USE_5: 'missUse5',
  MISS_USE_6: 'missUse6',
  IMAGES: 'images',
  MOODBOARD_IMG: 'moodboardImg',
  DIRECTION_IMG: 'directionImg',
  BRAND_NAME: 'brandName'
}

export default BRANDBOOK_CONFIG_KEYS
