import PropTypes from 'prop-types'

const Avatar = (props) => {
  const { iconSize, iconColor } = props

  return (
    <svg
      width={iconSize}
      height={iconSize}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.33398 15.4062C3.33398 17.7307 6.38596 18.3332 10.0007 18.3332C13.5962 18.3332 16.6673 17.7521 16.6673 15.4268C16.6673 13.1023 13.6162 12.4998 10.0007 12.4998C6.40514 12.4998 3.33398 13.0809 3.33398 15.4062ZM10.0007 10.8332C12.5452 10.8332 14.584 8.79334 14.584 6.2494C14.584 3.70547 12.5452 1.6665 10.0007 1.6665C7.45695 1.6665 5.41732 3.70547 5.41732 6.2494C5.41732 8.79334 7.45695 10.8332 10.0007 10.8332Z"
        fill={iconColor}
        fillOpacity="0.64"
      />
    </svg>
  )
}
Avatar.propTypes = {
  iconSize: PropTypes.string.isRequired,
  iconColor: PropTypes.string
}
export default Avatar
