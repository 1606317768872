import { useTranslations } from 'contexts/Translation'
import PropTypes from 'prop-types'
import { Title, Button } from '@qonsoll/react-design'
import { MAX_LENGTH_FIELD, MAX_LENGTH_DESCRIPTION, KEYS } from '__constants__'
import { Form, Select, Input } from 'antd'
import {
  CALCULATOR_PLATFORMS_OPTIONS,
  CALCULATOR_ADMINISTRATION_OPTIONS,
  CALCULATOR_APPEARANCE_OPTIONS,
  CALCULATOR_AUTHENTICATION_OPTIONS,
  CALCULATOR_BRAND_OPTIONS,
  CALCULATOR_EXTERNAL_SERVICE_OPTIONS,
  CALCULATOR_LOCALIZATION_OPTIONS,
  CALCULATOR_REVENUE_OPTIONS
} from 'constants'

const CalculatorSimpleForm = (props) => {
  const { initialValues, onSubmit, onCancel, showTitle, form, group } = props

  // [ADDITIONAL_HOOKS]
  const { t } = useTranslations()
  const [baseForm] = Form.useForm()

  // [COMPUTED_PROPERTIES]
  const usedForm = form || baseForm

  // [HANDLERS]
  const handleCancel = () => {
    usedForm.resetFields()
    onCancel?.()
  }
  const handleKeyPress = (e) => {
    if (e.key === KEYS.ENTER) {
      e.preventDefault()
      e.stopPropagation()
      usedForm?.submit()
    }
  }

  return group ? (
    <>
      {showTitle && (
        <Title level={4} mb={2}>
          {t('Calculator')}
        </Title>
      )}
      <Form.Item
        label={t('Platforms')}
        name={[...group, 'platforms']}
        rules={[
          {
            required: false,
            message: t('Please enter your calculator platforms!')
          }
        ]}>
        <Select
          placeholder={t('Please enter your calculator platforms')}
          options={CALCULATOR_PLATFORMS_OPTIONS.map((o) => ({
            ...o,
            label: t(o.label)
          }))}
          onPressEnter={onSubmit}
        />
      </Form.Item>
      <Form.Item
        label={t('Industry')}
        name={[...group, 'industry']}
        rules={[
          {
            required: false,
            message: t('Please enter your calculator industry!')
          },
          {
            max: MAX_LENGTH_FIELD,
            message: t('calculator industry is too long!')
          }
        ]}>
        <Input placeholder={t('Please enter your calculator industry')} />
      </Form.Item>
      <Form.Item
        label={t('Analogue')}
        name={[...group, 'analogue']}
        rules={[
          {
            required: false,
            message: t('Please enter your calculator analogue!')
          },
          {
            max: MAX_LENGTH_FIELD,
            message: t('calculator analogue is too long!')
          }
        ]}>
        <Input placeholder={t('Please enter your calculator analogue')} />
      </Form.Item>
      <Form.Item
        label={t('Administration')}
        name={[...group, 'administration']}
        rules={[
          {
            required: false,
            message: t('Please enter your calculator administration!')
          }
        ]}>
        <Select
          placeholder={t('Please enter your calculator administration')}
          options={CALCULATOR_ADMINISTRATION_OPTIONS.map((o) => ({
            ...o,
            label: t(o.label)
          }))}
        />
      </Form.Item>
      <Form.Item
        label={t('Appearance')}
        name={[...group, 'appearance']}
        rules={[
          {
            required: false,
            message: t('Please enter your calculator appearance!')
          }
        ]}>
        <Select
          placeholder={t('Please enter your calculator appearance')}
          options={CALCULATOR_APPEARANCE_OPTIONS.map((o) => ({
            ...o,
            label: t(o.label)
          }))}
        />
      </Form.Item>
      <Form.Item
        label={t('Authentication')}
        name={[...group, 'authentication']}
        rules={[
          {
            required: false,
            message: t('Please enter your calculator authentication!')
          }
        ]}>
        <Select
          placeholder={t('Please enter your calculator authentication')}
          options={CALCULATOR_AUTHENTICATION_OPTIONS.map((o) => ({
            ...o,
            label: t(o.label)
          }))}
        />
      </Form.Item>
      <Form.Item
        label={t('Brand')}
        name={[...group, 'brand']}
        rules={[
          {
            required: false,
            message: t('Please enter your calculator brand!')
          }
        ]}>
        <Select
          placeholder={t('Please enter your calculator brand')}
          options={CALCULATOR_BRAND_OPTIONS.map((o) => ({
            ...o,
            label: t(o.label)
          }))}
        />
      </Form.Item>
      <Form.Item
        label={t('External_service')}
        name={[...group, 'external_service']}
        rules={[
          {
            required: false,
            message: t('Please enter your calculator external_service!')
          }
        ]}>
        <Select
          placeholder={t('Please enter your calculator external_service')}
          options={CALCULATOR_EXTERNAL_SERVICE_OPTIONS.map((o) => ({
            ...o,
            label: t(o.label)
          }))}
        />
      </Form.Item>
      <Form.Item
        label={t('Localization')}
        name={[...group, 'localization']}
        rules={[
          {
            required: false,
            message: t('Please enter your calculator localization!')
          }
        ]}>
        <Select
          placeholder={t('Please enter your calculator localization')}
          options={CALCULATOR_LOCALIZATION_OPTIONS.map((o) => ({
            ...o,
            label: t(o.label)
          }))}
        />
      </Form.Item>
      <Form.Item
        label={t('Revenue')}
        name={[...group, 'revenue']}
        rules={[
          {
            required: false,
            message: t('Please enter your calculator revenue!')
          }
        ]}>
        <Select
          placeholder={t('Please enter your calculator revenue')}
          options={CALCULATOR_REVENUE_OPTIONS.map((o) => ({
            ...o,
            label: t(o.label)
          }))}
        />
      </Form.Item>
    </>
  ) : (
    <Form
      form={usedForm}
      initialValues={initialValues}
      onFinish={onSubmit}
      layout="vertical"
      onKeyPress={handleKeyPress}
      autoComplete="off">
      {showTitle && (
        <Title level={4} mb={2}>
          {t('Calculator')}
        </Title>
      )}
      <Form.Item
        label={t('Platforms')}
        name="platforms"
        rules={[
          {
            required: false,
            message: t('Please enter your calculator platforms!')
          }
        ]}>
        <Select
          placeholder={t('Please enter your calculator platforms')}
          options={CALCULATOR_PLATFORMS_OPTIONS.map((o) => ({
            ...o,
            label: t(o.label)
          }))}
        />
      </Form.Item>
      <Form.Item
        label={t('Industry')}
        name="industry"
        rules={[
          {
            required: false,
            message: t('Please enter your calculator industry!')
          },
          {
            max: MAX_LENGTH_FIELD,
            message: t('calculator industry is too long!')
          }
        ]}>
        <Input placeholder={t('Please enter your calculator industry')} />
      </Form.Item>
      <Form.Item
        label={t('Analogue')}
        name="analogue"
        rules={[
          {
            required: false,
            message: t('Please enter your calculator analogue!')
          },
          {
            max: MAX_LENGTH_FIELD,
            message: t('calculator analogue is too long!')
          }
        ]}>
        <Input placeholder={t('Please enter your calculator analogue')} />
      </Form.Item>
      <Form.Item
        label={t('Administration')}
        name="administration"
        rules={[
          {
            required: false,
            message: t('Please enter your calculator administration!')
          }
        ]}>
        <Select
          placeholder={t('Please enter your calculator administration')}
          options={CALCULATOR_ADMINISTRATION_OPTIONS.map((o) => ({
            ...o,
            label: t(o.label)
          }))}
        />
      </Form.Item>
      <Form.Item
        label={t('Appearance')}
        name="appearance"
        rules={[
          {
            required: false,
            message: t('Please enter your calculator appearance!')
          }
        ]}>
        <Select
          placeholder={t('Please enter your calculator appearance')}
          options={CALCULATOR_APPEARANCE_OPTIONS.map((o) => ({
            ...o,
            label: t(o.label)
          }))}
        />
      </Form.Item>
      <Form.Item
        label={t('Authentication')}
        name="authentication"
        rules={[
          {
            required: false,
            message: t('Please enter your calculator authentication!')
          }
        ]}>
        <Select
          placeholder={t('Please enter your calculator authentication')}
          options={CALCULATOR_AUTHENTICATION_OPTIONS.map((o) => ({
            ...o,
            label: t(o.label)
          }))}
        />
      </Form.Item>
      <Form.Item
        label={t('Brand')}
        name="brand"
        rules={[
          {
            required: false,
            message: t('Please enter your calculator brand!')
          }
        ]}>
        <Select
          placeholder={t('Please enter your calculator brand')}
          options={CALCULATOR_BRAND_OPTIONS.map((o) => ({
            ...o,
            label: t(o.label)
          }))}
        />
      </Form.Item>
      <Form.Item
        label={t('External_service')}
        name="external_service"
        rules={[
          {
            required: false,
            message: t('Please enter your calculator external_service!')
          }
        ]}>
        <Select
          placeholder={t('Please enter your calculator external_service')}
          options={CALCULATOR_EXTERNAL_SERVICE_OPTIONS.map((o) => ({
            ...o,
            label: t(o.label)
          }))}
        />
      </Form.Item>
      <Form.Item
        label={t('Localization')}
        name="localization"
        rules={[
          {
            required: false,
            message: t('Please enter your calculator localization!')
          }
        ]}>
        <Select
          placeholder={t('Please enter your calculator localization')}
          options={CALCULATOR_LOCALIZATION_OPTIONS.map((o) => ({
            ...o,
            label: t(o.label)
          }))}
        />
      </Form.Item>
      <Form.Item
        label={t('Revenue')}
        name="revenue"
        rules={[
          {
            required: false,
            message: t('Please enter your calculator revenue!')
          }
        ]}>
        <Select
          placeholder={t('Please enter your calculator revenue')}
          options={CALCULATOR_REVENUE_OPTIONS.map((o) => ({
            ...o,
            label: t(o.label)
          }))}
        />
      </Form.Item>
      {!form && (
        <>
          <Button mr={2} htmlType="button" onClick={handleCancel}>
            {t('Cancel')}
          </Button>
          <Button type="primary" onClick={() => usedForm.submit()}>
            {t('Save')}
          </Button>
        </>
      )}
    </Form>
  )
}

CalculatorSimpleForm.propTypes = {
  initialValues: PropTypes.object,
  onSubmit: PropTypes.func,
  onCancel: PropTypes.func,
  showTitle: PropTypes.bool,
  form: PropTypes.any,
  group: PropTypes.array
}

export default CalculatorSimpleForm
