import { CONTENT_LISTS } from '../../__constants__'
import { ContentItem } from '../../../../domains'
import { ContentListWrapper } from './ContentList.styled'
import { List } from '../../../../components'

/**
 * ContentList - read array of content items, and return a list of all content items.
 */
const ContentList = () => {
  return (
    <ContentListWrapper>
      <List dataSource={CONTENT_LISTS} ListItem={ContentItem} />
    </ContentListWrapper>
  )
}

export default ContentList
