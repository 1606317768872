import { useEffect, useState } from 'react'
import { getDocument } from 'services/firestore'

const useGetCustomerFeatures = (customer) => {
  const [features, setFeatures] = useState([])
  const [loading, setLoading] = useState(!!customer?.featureIds?.length)

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true)
      const promises = customer.featureIds.map((feature) =>
        getDocument('features', feature)
      )
      const data = await Promise.all(promises)
      setFeatures(data)
      setLoading(false)
    }

    customer?.featureIds?.length && fetchData()
  }, [customer])

  return [features, loading]
}

export default useGetCustomerFeatures
