import { useTranslations } from 'contexts/Translation'
import PropTypes from 'prop-types'
import { Tabs, Card } from 'antd'
import { StickyContainer, Sticky } from 'react-sticky'
import {
  Container,
  Row,
  Col,
  Spin,
  Divider,
  Text,
  Img,
  Title
} from '@qonsoll/react-design'
import { useParams, useHistory, useLocation } from 'react-router-dom'
import { useDocument } from 'hooks'
import { useStateWithStorage } from 'hooks'

const DesignAdvancedView = (props) => {
  // [COMPONENT_STATE_HOOKS]
  const [activeTab, setActiveTab] = useStateWithStorage(
    null,
    'design-advanced-view-active-tab'
  )

  // [ADDITIONAL_HOOKS]
  const { t } = useTranslations()
  const history = useHistory()
  const params = useParams()
  const location = useLocation()
  const { designId } = params
  const [design, loading] = useDocument({ ref: `designs/${designId}` })

  // [COMPUTED_PROPERTIES]
  const { TabPane } = Tabs

  return loading ? (
    <Spin />
  ) : (
    <Container>
      <Container mb={4}>
        <Card>
          <Row noGutters mb={2} v="center">
            <Col h="left">
              <Img
                style={{
                  maxHeight: '500px',
                  objectFit: 'cover',
                  width: '100%'
                }}
                src={design?.imageUrl}
              />
            </Col>
          </Row>
          <Row noGutters mb={2} v="center">
            <Col h="left">
              <Title level={3}>{design?.name}</Title>
            </Col>
          </Row>
          <Row noGutters mb={2} v="center">
            <Col h="left">
              <Text>{design?.description}</Text>
            </Col>
          </Row>
        </Card>
      </Container>
    </Container>
  )
}

DesignAdvancedView.propTypes = {}

export default DesignAdvancedView
