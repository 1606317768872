import './IconographyGuideScreen.vars.css'

import { ContentScreen, PositionedContainer } from '../../../../components'
import { IconAdvancedView, IconList } from '../../../../domains'

import { ICONS_GUIDE_LIST } from '../../../../domains/Iconography/__constants__'
import responsiveStyles from './IconographyGuideScreen.styled'

/**
 * It returns a IconographyExamplesScreen component
 * @returns The IconographyExamplesScreen component is being returned.
 */
const IconographyGuideScreen = () => {
  return (
    <ContentScreen
      chapter="Guide"
      domainName="Ico."
      key="IconographyGuideScreen"
      positionOuter
      asideTopContent={
        <IconList
          icons={ICONS_GUIDE_LIST}
          responsiveStyles={responsiveStyles}
        />
      }
      content={
        <PositionedContainer {...responsiveStyles}>
          <IconAdvancedView />
        </PositionedContainer>
      }
      pageNumber="18"
    />
  )
}

export default IconographyGuideScreen
