import { updateDocument, createDocument, getId } from 'services/firestore'

import { updateParentHasManyRelationship } from 'hooks'
import { Representative } from 'models'
import { message } from 'antd'
import { useHistory, useParams } from 'react-router-dom'
import { useState } from 'react'
import { useTranslations } from 'contexts/Translation'

const useActionsRepresentativeAdvancedForm = ({ initialData, form } = {}) => {
  // [COMPONENT_STATE_HOOKS]
  const [loading, setLoading] = useState(false)

  // [ADDITIONAL_HOOKS]
  const { t } = useTranslations()
  const history = useHistory()
  const params = useParams()

  const prepareValues = async (values = {}, additionalValues = {}) => {
    const representativeId =
      initialData?.['representative']?._id || getId('representatives')
    const additionalReferences = { representativeId }
    const preparedValues = {
      _id: representativeId,
      ...additionalValues,
      birthDate: values?.['representative']?.['birthDate']?.toString() ?? null,
      email: values?.['representative']?.['email'] ?? null,
      firstName: values?.['representative']?.['firstName'] ?? null,
      lastName: values?.['representative']?.['lastName'] ?? null,
      phone: values?.['representative']?.['phone'] ?? null,
      position: values?.['representative']?.['position'] ?? null,
      summary: values?.['representative']?.['summary'] ?? null
    }
    if (params.customerId) preparedValues['customerId'] = params.customerId
    return preparedValues
  }

  const saveForm = async (values, callback) => {
    try {
      // Prepare data to be saved
      const data = await prepareValues(values)
      // Updating parent references
      if (params.customerId)
        await updateParentHasManyRelationship(
          params.customerId,
          'customers',
          'customerRepresentativeIds',
          data._id
        )
      // Save data
      if (initialData) {
        await updateDocument(
          'representatives',
          initialData.representative?._id,
          data
        )
        message.success(t('Representative successfully updated'))
      } else {
        await createDocument('representatives', data, data._id)
        message.success(t('Representative successfully created'))
      }
      // Final callback
      callback?.()
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error)
      throw new Error(t('Something went wrong during data save'))
    }
  }

  const validateForm = (values) => {
    try {
      // Prepare data to be validated
      const validationData = {
        birthDate: values?.['representative']?.['birthDate']?.toString(),
        email: values?.['representative']?.['email'],
        firstName: values?.['representative']?.['firstName'],
        lastName: values?.['representative']?.['lastName'],
        phone: values?.['representative']?.['phone'],
        position: values?.['representative']?.['position'],
        summary: values?.['representative']?.['summary']
      }
      Representative.validationSchema.validateSync(validationData)
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error)
      throw new Error(t('Representative validation error: ') + t(error.message))
    }
  }

  const onFinish = async () => {
    if (loading) return // Avoid multiple calls

    try {
      setLoading(true)
      // Get form values
      const formValues = form.getFieldsValue()
      // Validate fields
      validateForm(formValues)
      // Final callback
      const callback = () => history.goBack()
      // Save data
      await saveForm(formValues, callback)
    } catch (error) {
      setLoading(false)
      message.error(error.message)
    }
  }

  const onReset = () => {
    form.resetFields()
    history.goBack()
  }

  return { onFinish, onReset, loading, saveForm, validateForm, prepareValues }
}

export default useActionsRepresentativeAdvancedForm
