import PropTypes from 'prop-types'

const Trash = (props) => {
  const { iconSize, iconColor, fill } = props

  return (
    <svg
      width={iconSize}
      height={iconSize}
      viewBox="0 0 72 72"
      fill={fill}
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M12 21H60H12ZM48 21V12C48 11.2044 47.6839 10.4413 47.1213 9.87868C46.5587 9.31607 45.7956 9 45 9H27C26.2044 9 25.4413 9.31607 24.8787 9.87868C24.3161 10.4413 24 11.2044 24 12V21H48ZM54 60V21H18V60C18 60.7956 18.3161 61.5587 18.8787 62.1213C19.4413 62.6839 20.2044 63 21 63H51C51.7956 63 52.5587 62.6839 53.1213 62.1213C53.6839 61.5587 54 60.7956 54 60Z"
        stroke={iconColor}
        strokeWidth="6"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

Trash.propTypes = {
  iconSize: PropTypes.string.isRequired,
  fill: PropTypes.string,
  iconColor: PropTypes.string
}

export default Trash
