import { Box } from '../../../../components'
import styled from 'styled-components'

const SwitcherSimpleViewStyled = styled(Box)`
  display: flex;
  align-items: center;
  padding-bottom: var(--offset-24);
  & .text-wrapper {
    padding-left: var(--offset-12);
  }
`
export default SwitcherSimpleViewStyled
