import { Col } from '../../../../components'
import styled from 'styled-components'

const ColStyled = styled(Col)`
  display: flex;
  gap: 1rem;
  width: 3.2rem;
  align-items: center;
`

export default ColStyled
