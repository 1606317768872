const USERS = 'users'
const CALCULATORS = 'calculators'
const CUSTOMERS = 'customers'
const CUSTOMER_PROFILES = 'customerProfiles'
const REPRESENTATIVES = 'representatives'
const ROLES = 'roles'
const APPLICATIONS = 'applications'
const BRANDBOOKS = 'brandbooks'
const COLORS = 'colors'
const FEATURES = 'features'
const OFFERS = 'offers'
const PLATFORMS = 'platforms'
const DESIGNS = 'designs'
const MOCKUPS = 'mockups'
const TECHNOLOGIES = 'technologies'
const DATABASES = 'databases'
const VIDEOS = 'videos'

export const COLLECTIONS = {
  USERS,
  CALCULATORS,
  CUSTOMERS,
  CUSTOMER_PROFILES,
  REPRESENTATIVES,
  ROLES,
  APPLICATIONS,
  BRANDBOOKS,
  COLORS,
  FEATURES,
  OFFERS,
  PLATFORMS,
  DESIGNS,
  MOCKUPS,
  VIDEOS,
  TECHNOLOGIES,
  DATABASES
}
