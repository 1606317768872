import { updateDocument, createDocument, getId } from 'services/firestore'
import { uploadImage } from 'helpers'
import { updateParentHasManyRelationship } from 'hooks'
import { Feature } from 'models'
import { message } from 'antd'
import { useHistory, useParams } from 'react-router-dom'
import { useState } from 'react'
import { useTranslations } from 'contexts/Translation'

const useActionsFeatureAdvancedForm = ({ initialData, form } = {}) => {
  // [COMPONENT_STATE_HOOKS]
  const [loading, setLoading] = useState(false)

  // [ADDITIONAL_HOOKS]
  const { t } = useTranslations()
  const history = useHistory()
  const params = useParams()

  const prepareValues = async (values = {}, additionalValues = {}) => {
    const featureId = initialData?.['feature']?._id || getId('features')
    const additionalReferences = { featureId }
    const preparedValues = {
      _id: featureId,
      ...additionalValues,
      estimation: values?.['feature']?.['estimation'] ?? null,
      featureLogoURL:
        (await uploadImage(values?.['feature']?.['featureLogoURL'])) ?? null,
      name: values?.['feature']?.['name'] ?? null
    }
    if (params.customerId) preparedValues['customerId'] = params.customerId
    return preparedValues
  }

  const saveForm = async (values, callback) => {
    try {
      // Prepare data to be saved
      const data = await prepareValues(values)
      // Updating parent references
      if (params.customerId)
        await updateParentHasManyRelationship(
          params.customerId,
          'customers',
          'featureIds',
          data._id
        )
      // Save data
      if (initialData) {
        await updateDocument('features', initialData.feature?._id, data)
        message.success(t('Feature successfully updated'))
      } else {
        await createDocument('features', data, data._id)
        message.success(t('Feature successfully created'))
      }
      // Final callback
      callback?.()
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error)
      throw new Error(t('Something went wrong during data save'))
    }
  }

  const validateForm = (values) => {
    try {
      // Prepare data to be validated
      const validationData = {
        estimation: values?.['feature']?.['estimation'],
        featureLogoURL: values?.['feature']?.['featureLogoURL'],
        name: values?.['feature']?.['name']
      }
      Feature.validationSchema.validateSync(validationData)
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error)
      throw new Error(t('Feature validation error: ') + t(error.message))
    }
  }

  const onFinish = async () => {
    if (loading) return // Avoid multiple calls

    try {
      setLoading(true)
      // Get form values
      const formValues = form.getFieldsValue()
      // Validate fields
      validateForm(formValues)
      // Final callback
      const callback = () => history.goBack()
      // Save data
      await saveForm(formValues, callback)
    } catch (error) {
      setLoading(false)
      message.error(error.message)
    }
  }

  const onReset = () => {
    form.resetFields()
    history.goBack()
  }

  return { onFinish, onReset, loading, saveForm, validateForm, prepareValues }
}

export default useActionsFeatureAdvancedForm
