import './InputList.vars.css'

import styled from 'styled-components'

const InputWrapper = styled.div`
  display: grid;
`
const InputGrid = styled.div`
  display: grid;
  margin-left: var(--input-ml);
  grid-template-columns: repeat(
    var(--input-grid-column),
    var(--input-grid-width)
  );
  column-gap: var(--offset-32);
  grid-row-gap: var(--input-row-gap);
`

export { InputWrapper, InputGrid }
