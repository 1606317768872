import styled from 'styled-components'

const AppIconWrapper = styled.div`
  width: var(--app-icon-width);
  height: var(--app-icon-height);
  background: var(--app-icon-bg);
  display: flex;
  overflow: hidden;
  align-items: center;
  justify-content: center;
  cursor: pointer;
`

export default AppIconWrapper
