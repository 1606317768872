import { Col, Row } from '../../../../../components'

import ExteriorScreenContainerStyled from './ExteriorScreenContainer.styled'
import PropTypes from 'prop-types'

/**
 *  Used for first and last screen --> greeting and thanks.
 *  Container for children components.
 *  @param {node} children - holds in ScreenInfo.
 *  @returns exterior screen container.
 */
const ExteriorScreenContainer = (props) => {
  const { children } = props
  return (
    <ExteriorScreenContainerStyled display="flex">
      <Row my="auto" mx={0}>
        <Col cw={[12, 12, 12, 10]} v="bottom">
          {children}
        </Col>
      </Row>
    </ExteriorScreenContainerStyled>
  )
}

ExteriorScreenContainer.propTypes = {
  children: PropTypes.node.isRequired
}
export default ExteriorScreenContainer
