import './RadioButtonList.vars.css'

import styled from 'styled-components'

const RadioButtonListWrapper = styled.div`
  display: flex;
  flex-direction: var(--radio-wrapper-direction);
  gap: var(--gap-24);
`

const RadioButtonListStyled = styled.div`
  display: flex;
  flex-direction: var(--radio-list-direction);
  gap: var(--gap-32);
`

export { RadioButtonListWrapper, RadioButtonListStyled }
