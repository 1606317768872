import { Box, Text } from '../../../../components'
import { TechnologyImg, TechnologyViewStyled } from './TechnologyView.styled'

import PropTypes from 'prop-types'

/**
 * It returns a styled div with an image and a text box
 * @param {string} src - path to the source file
 * @param {string} name - name of the technology
 * @returns A styled component
 */
const TechnologyView = (props) => {
  const { src, name } = props

  return (
    <TechnologyViewStyled>
      {src && (
        <TechnologyImg
          src={src}
          alt={name}
          className="offer-print-technology-img"
        />
      )}
      {name && (
        <Box className="py-3">
          <Text>{name}</Text>
        </Box>
      )}
    </TechnologyViewStyled>
  )
}

TechnologyView.propTypes = {
  src: PropTypes.string,
  name: PropTypes.string
}

export default TechnologyView
