import { IMG_DIRECTION, INSTA_POST, MOODBOARD } from '../../__constants__'
import { useEffect, useState } from 'react'

import { ImageCombinedView } from 'brandbook/components'
import PropTypes from 'prop-types'
import { useBrandbookConfig } from 'brandbook/contexts/BrandbookConfig'

/**
 * LOGOS_CONFIG - MISS_USE or PROPER_USE.
 * @param {string} variant - variant of images
 * @returns logo list.
 */
const ImageryView = (props) => {
  const { variant } = props

  const { brandbookConfigData } = useBrandbookConfig()

  // [COMPONENT_STATE_HOOKS]
  const [imgDirection, setImgDirection] = useState(IMG_DIRECTION)
  const [moodboard, setMoodboard] = useState(MOODBOARD)

  // set img to component state and display it
  useEffect(() => {
    setImgDirection(brandbookConfigData?.images?.directionImg || IMG_DIRECTION)
    setMoodboard(brandbookConfigData?.images?.moodboardImg || MOODBOARD)
  }, [brandbookConfigData?.images])

  const imgVariants = {
    instaPost: INSTA_POST,
    moodboard: moodboard,
    imgDirection: imgDirection
  }

  return imgVariants[variant]?.map((item, index) => (
    <ImageCombinedView
      imgDirection={imgDirection}
      moodboard={moodboard}
      variant={variant}
      index={index}
      key={`${item?.img}-imagery-view`}
      item={item}
      img={item?.img}
    />
  ))
}

ImageryView.propTypes = {
  variant: PropTypes.oneOf(['imgDirection', 'moodboard', 'instaPost'])
    .isRequired
}
export default ImageryView
