const IMAGE_DECORATIONS = {
  // EXTERIOR SCREEN
  HELLO: '/modules/hello.svg',
  THX: '/modules/thx.svg',

  // SCREEN DECORATION
  LINE: '/modules/offer-illustrations/line.png',

  // FEATURES
  GOOGLE_CLOUD: '/offer-illustrations/google_cloud.svg',
  ELASTIC_SEARCH: '/offer-illustrations/elasticsearch.svg',
  ALGOLIA: '/offer-illustrations/algolia.svg',
  COVEO: '/offer-illustrations/coveo.png',
  NO_DATA: '/offer-illustrations/noData.png',

  // LOGO
  LOGO: '/modules/senseteqLogo.svg',

  // MOCKUP PHONES
  IPHONE_1: '/modules/offer-illustrations/iphone1.png',
  IPHONE_2: '/modules/offer-illustrations/iphone2.png',
  IPHONE_3: '/modules/offer-illustrations/iphone3.png',
  IPHONE_4: '/modules/offer-illustrations/iphone4.png',
  IPHONE_5: '/modules/offer-illustrations/iphone5.png',

  // TABLE HEADER IMAGES
  LEFT_IMG: '/modules/offer-illustrations/LineLeft.png',
  RIGHT_IMG: '/modules/offer-illustrations/LineRight.png',

  // TECHNOLOGIES
  NODE: '/modules/offer-illustrations/node.png',
  REACT: '/modules/offer-illustrations/react.png',
  FIREBASE: '/modules/offer-illustrations/firebase.png'
}

export default IMAGE_DECORATIONS
