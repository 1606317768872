import { BigTypo, Text } from '../../../../components'
import { Tag, TagSize, TitleItemWrapper } from './TitleItem.styled'

import PropTypes from 'prop-types'

/**
 * It returns a div with two children, one of which is a div with a child of a Typography component,
 * and the other is a div with a child of a Text component
 * @param {string} h - tag of heading
 * @param {string} variant - variant of the heading text
 * @param {string} size - size of the heading
 * @returns A TitleItem component that takes in props and returns a TitleItemWrapper component with a
 * Tag component and a TagSize component.
 */
const TitleItem = (props) => {
  const { h, variant, size } = props
  return (
    <TitleItemWrapper>
      <Tag>
        <BigTypo variant={variant}>{h}</BigTypo>
      </Tag>
      <TagSize>
        <Text ff="primary">{size}</Text>
      </TagSize>
    </TitleItemWrapper>
  )
}
TitleItem.propTypes = {
  h: PropTypes.string,
  variant: PropTypes.string,
  size: PropTypes.string
}

export default TitleItem
