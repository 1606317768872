import styled from 'styled-components'

const FooterStyled = styled.footer`
  padding-top: var(--footer-offset);
  padding-bottom: var(--footer-offset);
  max-height: var(--screen-footer-max-height);
  display: flex;
  justify-content: space-between;
`

export default FooterStyled
