import styled, { css } from 'styled-components'

import { BREAKPOINTS } from '../../../../__constants__'
import { Img } from '../../../../components'

const mediaImages = (props) => {
  const breakpoints = Object.keys(BREAKPOINTS)
  return breakpoints.map((breakpoint) => {
    return css`
      @media (min-width: ${BREAKPOINTS?.[breakpoint]}) {
        width: ${props?.[breakpoint]?.width};
        left: ${props?.[breakpoint]?.left};
        top: ${props?.[breakpoint]?.top};
        display: ${props?.[breakpoint]?.display || 'block'};
        z-index: ${props?.[breakpoint]?.zIndex};
      }
    `
  })
}

const ImgStyled = styled(Img)`
  bottom: ${({ bottom }) => bottom};
  top: ${({ top }) => top};
  position: absolute;
  height: auto;
  width: ${({ width }) => width};
  cursor: ${({ isOnlyView }) => (isOnlyView ? 'default' : 'pointer')};
  z-index: ${({ zIndex }) => zIndex || 2};
  ${(props) => props && mediaImages(props)};
`

export default ImgStyled
