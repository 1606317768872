import { useEffect } from 'react'
import { Form } from 'antd'

const useInitializePlatformAdvancedForm = (initialData) => {
  // [ADDITIONAL_HOOKS]
  const [form] = Form.useForm()

  // [LIFECYCLE]
  useEffect(() => {
    if (initialData) {
      const { platform } = initialData
      const formValues = {
        platform: {
          name: platform['name'] ?? null
        }
      }
      form.setFieldsValue(formValues)
    }
  }, [initialData, form])

  return { form }
}

export default useInitializePlatformAdvancedForm
