import './Chapter.vars.css'

import { Col, Row, Text } from '../../../components'

import ChapterStyled from './Chapter.styled'
import PropTypes from 'prop-types'

/**
 *
 * Rhis is a Component that displays chapters of IntroScreen
 * @param {children} children This is text to be displayed
 * inside the ChapterComponent
 * @returns A React component - Chapter
 */
const Chapter = (props) => {
  const { children } = props

  return (
    <Row noGutters h="right">
      <Col cw="auto">
        <ChapterStyled>
          <Text
            ff="primary"
            color="neutral"
            inverse
            variant="subtitle2"
            uppercase>
            {children}
          </Text>
        </ChapterStyled>
      </Col>
    </Row>
  )
}

Chapter.propTypes = {
  children: PropTypes.string
}

export default Chapter
