import styled from 'styled-components'

const InputStyled = styled.input`
  display: block;
  box-sizing: border-box;
  padding: var(--input-py) var(--input-px);
  background: var(--input-bg);
  border-radius: var(--input-br);
  border: unset;
  width: ${({ width }) => width || '100%'};
  transform: ${({ mega }) => mega && 'var(--input-transform-scale)'};

  &::placeholder {
    line-height: var(--input-placeholder-lh);
    font-family: var(--input-placeholder-ff);
    font-size: var(--input-placeholder-fs);
    color: var(--input-placeholder-c-default);
    flex: 1;
  }
  &.hover,
  &:hover {
    box-shadow: var(--input-hover-bs);
  }
  &.active,
  &:focus {
    outline: unset;
    box-shadow: var(--input-active-bs);
  }
  &:disabled {
    box-shadow: unset;
    cursor: not-allowed;
    &::placeholder {
      color: var(--input-placeholder-c-disabled);
    }
  }
`

const InputLabelWrapper = styled.label`
  display: block;
  line-height: var(--input-label-lh);
  font-size: var(--input-label-fs);
  font-family: var(--input-label-ff);
  font-weight: var(--input-label-fw);
  color: var(--input-label-c);
  margin-bottom: var(--offset-4);
`

export { InputStyled, InputLabelWrapper }
