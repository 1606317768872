import './Header.vars.css'

import HeaderStyled from './Header.styled'
import { IMAGE_DECORATIONS } from '../../../__constants__'
import { Img } from '../../../components'
import PropTypes from 'prop-types'
import { useBrandbookConfig } from '../../../contexts/BrandbookConfig'

/**
 * It returns a styled component that displays the title of the page
 * @param {string} breadcrumbsColor - for change the color of the breadcrumbs
 * @param {array} breadcrumbs - array of breadcrumbs to display
 * @returns A Header component that is styled with the HeaderStyled component.
 */
const Header = (props) => {
  const { breadcrumbs, breadcrumbsColor } = props
  const { LOGO } = IMAGE_DECORATIONS

  // [ADDITIONAL_HOOKS]

  const { brandbookConfigData } = useBrandbookConfig()

  // [COMPUTED_PROPERTIES]
  const titleComputed = breadcrumbs ? (
    <Img src={LOGO} alt="senseteqLogo" />
  ) : (
    `${brandbookConfigData?.brandName || 'Brand'} - Guidelines`
  )

  return (
    <HeaderStyled breadcrumbsColor={breadcrumbsColor}>
      {titleComputed}
    </HeaderStyled>
  )
}

Header.propTypes = {
  breadcrumbs: PropTypes.arrayOf(PropTypes.string),
  breadcrumbsColor: PropTypes.string
}
export default Header
