import { model, attr } from 'services/model'
import * as yup from 'yup'

const validationSchema = yup.object().shape({
  birthDate: yup
    .date()
    .typeError('Field birthDate has wrong type')
    .required('Field birthDate is required'),
  email: yup
    .string()
    .typeError('Field email has wrong type')
    .required('Field email is required'),
  firstName: yup
    .string()
    .typeError('Field firstName has wrong type')
    .required('Field firstName is required'),
  lastName: yup
    .string()
    .typeError('Field lastName has wrong type')
    .required('Field lastName is required'),
  phone: yup
    .string()
    .typeError('Field phone has wrong type')
    .required('Field phone is required'),
  position: yup
    .string()
    .typeError('Field position has wrong type')
    .default(null)
    .nullable(),
  summary: yup
    .string()
    .typeError('Field summary has wrong type')
    .default(null)
    .nullable()
})

const Representative = model(
  'representative',
  {
    birthDate: attr('date'),
    email: attr('string'),
    firstName: attr('string'),
    lastName: attr('string'),
    phone: attr('string'),
    position: attr('string'),
    summary: attr('string')
  },
  validationSchema
)

export default Representative
