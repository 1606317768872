import { PositionedContainer } from '../../../../..'
import PropTypes from 'prop-types'
import { setPosition } from '../../../helpers'
import styled from 'styled-components'

const BoundedContainerStyled = styled(PositionedContainer)`
  ${({ left }) => left && setPosition(left, 'left')};
  ${({ bottom }) => bottom && setPosition(bottom, 'bottom')};
  ${({ top }) => top && setPosition(top, 'top')};
`

BoundedContainerStyled.propTypes = {
  left: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
  bottom: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
  top: PropTypes.oneOfType([PropTypes.string, PropTypes.array])
}

export default BoundedContainerStyled
