import { doc, onSnapshot } from 'firebase/firestore'
import { useEffect, useState } from 'react'

import PropTypes from 'prop-types'
import firestore from 'services/firebase/firestore'

const useListenDocument = (props) => {
  const [value, setValue] = useState()
  const [loading, setLoading] = useState(!!props.ref)
  const [error, setError] = useState()

  useEffect(() => {
    let isMounted = true

    const unsubscribe = onSnapshot(
      doc(firestore, props.ref),
      (document) => {
        if (isMounted) setValue(document.data())
        setLoading(false)
      },
      (error) => {
        setError(error)
        setLoading(false)
      }
    )

    return () => {
      isMounted = false
      unsubscribe?.()
    }
  }, [props?.ref])

  return [value, loading, error]
}

useListenDocument.propTypes = {
  ref: PropTypes.string.isRequired
}

export default useListenDocument
