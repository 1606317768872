import './Timeline.vars.css'

import {
  BoxStyled,
  IndicatorWrapper,
  TextStyled,
  TextWrapperStyled,
  TimeLineWrapper,
  TimelineItemWrapper
} from './Timeline.styled'

import PropTypes from 'prop-types'
import { Text } from 'offer/components'
import { useMediaQuery } from 'hooks'
import { useTranslations } from 'contexts/Translation'

const Timeline = (props) => {
  const { data } = props

  // [ADDITIONAL_HOOKS]
  const isBreakpointMd = useMediaQuery('(max-width: 1200px)')
  const isBreakpointSm = useMediaQuery('(max-width: 576px)')
  const { t } = useTranslations()

  // [COMPUTED_PROPERTIES]
  const textAlign = isBreakpointMd ? 'start' : 'center'
  const descriptionVaritant = isBreakpointSm ? 'caption1' : 'body2'
  const stepVaritant = isBreakpointSm ? 'body2' : 'body1'

  return (
    <TimeLineWrapper>
      {data?.map(({ step, description }, index) => (
        <TimelineItemWrapper key={`${index}-timeline-item`}>
          <IndicatorWrapper
            isLastItem={index === data.length - 1}
            isFirstItem={index === 0}>
            <TextWrapperStyled>
              <Text variant="h5" fontWeight="bold">
                {index + 1}
              </Text>
            </TextWrapperStyled>
          </IndicatorWrapper>
          <BoxStyled>
            <TextStyled variant={stepVaritant} textAlign={textAlign}>
              {t(step)}
            </TextStyled>
            <Text
              className="offer-print-timeline-description"
              textAlign={textAlign}
              variant={descriptionVaritant}
              letterSpacing="var(--text-body2-letter-spacing)"
              fontWeight="var(--text-body2-font-weight)">
              {t(description)}
            </Text>
          </BoxStyled>
        </TimelineItemWrapper>
      ))}
    </TimeLineWrapper>
  )
}

Timeline.propTypes = {
  data: PropTypes.array
}

export default Timeline
