import PropTypes from 'prop-types'

const Search = (props) => {
  const { iconSize, iconColor, fill } = props

  return (
    <svg
      width={iconSize}
      height={iconSize}
      viewBox="0 0 72 72"
      fill={fill}
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M63 63L49.98 49.98M57 33C57 37.7468 55.5924 42.3869 52.9553 46.3337C50.3181 50.2805 46.5698 53.3566 42.1844 55.1731C37.799 56.9896 32.9734 57.4649 28.3178 56.5388C23.6623 55.6128 19.3859 53.327 16.0294 49.9706C12.673 46.6141 10.3872 42.3377 9.46116 37.6822C8.53512 33.0266 9.0104 28.201 10.8269 23.8156C12.6434 19.4302 15.7195 15.6819 19.6663 13.0447C23.6131 10.4076 28.2533 9 33 9C39.3652 9 45.4697 11.5286 49.9706 16.0294C54.4714 20.5303 57 26.6348 57 33Z"
        stroke={iconColor}
        strokeWidth="6"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

Search.propTypes = {
  iconSize: PropTypes.string.isRequired,
  fill: PropTypes.string,
  iconColor: PropTypes.string
}

export default Search
