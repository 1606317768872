import { useMemo } from 'react'
import { useDocument, useLoading } from 'hooks'
import { useGetRoleCustomer } from 'domains/Role/hooks'

const useGetRoleInitialValues = (id) => {
  // [DATA_FETCH]
  const [role, roleLoading] = useDocument({ ref: id ? `roles/${id}` : null })
  const [customerId, customerLoading] = useGetRoleCustomer(role)

  // [COMPUTED_PROPERTIES]
  const initialValues = useMemo(
    () => ({
      role,
      customerId
    }),
    [role, customerId]
  )
  const loadings = useMemo(
    () => (id ? [!role, roleLoading, customerLoading] : []),
    [id, role, roleLoading, customerLoading]
  )

  // [ADDITIONAL_HOOKS]
  const loading = useLoading(loadings)

  return [initialValues, loading]
}

export default useGetRoleInitialValues
