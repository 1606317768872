import { useTranslations } from 'contexts/Translation'
import PropTypes from 'prop-types'
import { Row, Col, Text } from '@qonsoll/react-design'
import { useState } from 'react'
import { useParams } from 'react-router-dom'
import { MockupSimpleView } from 'domains/Mockup/components'

const MockupList = (props) => {
  const { mockups, isListWithCreate, listView, emptyProps, actions } = props

  // [COMPONENT_STATE_HOOKS]
  const [selectedItem, setSelectedItem] = useState(null)

  // [ADDITIONAL_HOOKS]
  const { t } = useTranslations()

  // [HANDLERS]
  const onEmptySpaceClick = () => setSelectedItem(null)

  return (
    <Row onClick={onEmptySpaceClick} mx={-3}>
      {mockups?.length > 0 ? (
        mockups?.map((mockup, index) => (
          <Col
            mb={4}
            key={mockup?._id || index}
            cw={listView ? 12 : [12, 6, 4]}>
            <MockupSimpleView
              actions={actions}
              index={index}
              isSelected={mockup?._id === selectedItem}
              onSelect={setSelectedItem}
              mockup={mockup}
            />
          </Col>
        ))
      ) : (
        <Col cw={isListWithCreate ? 'auto' : 12}>
          <Text variant="overline" type="secondary">
            {emptyProps?.message || t('No mockups have been created yet')}
          </Text>
        </Col>
      )}
    </Row>
  )
}

MockupList.propTypes = {
  mockups: PropTypes.array,
  isListWithCreate: PropTypes.bool,
  listView: PropTypes.bool,
  emptyProps: PropTypes.object,
  actions: PropTypes.shape({
    onEdit: PropTypes.func,
    onDelete: PropTypes.func,
    onDoubleClick: PropTypes.func,
    onClick: PropTypes.func
  })
}

export default MockupList
