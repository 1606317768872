import { Box, Logo, Text } from '../../../components'

import HeaderStyled from './Header.styled'
import { IMAGE_DECORATIONS } from 'offer/__constants__'
import PropTypes from 'prop-types'

const Header = (props) => {
  const { breadcrumbs, breadcrumbsProps } = props
  const { LOGO } = IMAGE_DECORATIONS

  // [COMPUTED_PROPERTIES]
  const breadcrumbsComputed =
    Array.isArray(breadcrumbs) && breadcrumbs.join(' / ')

  return (
    <HeaderStyled>
      <Box className="container">
        <Box className="row align-items-center justify-content-between">
          {!breadcrumbsComputed && (
            <Box className="col-auto">
              <Logo src={LOGO} />
            </Box>
          )}

          {breadcrumbsComputed && (
            <Box className="col-auto">
              <Text type="secondary" variant="body2" {...breadcrumbsProps}>
                {breadcrumbsComputed}
              </Text>
            </Box>
          )}
        </Box>
      </Box>
    </HeaderStyled>
  )
}

Header.propTypes = {
  breadcrumbs: PropTypes.array,
  breadcrumbsProps: PropTypes.object
}
export default Header
