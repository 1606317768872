import './Decoration.vars.css'

import styled, { css } from 'styled-components'

import { PositionedContainer } from '../../../../layouts'

const DECORATION_POSITION = (decoration) => css`
  right: var(--decoration-${decoration}-right);
  left: var(--decoration-${decoration}-left);
  top: var(--decoration-${decoration}-top);
  z-index: var(--decoration-${decoration}-z);
  transform: var(--decoration-${decoration}-scale);
`

const DecorationStyled = styled(PositionedContainer)`
  position: absolute;
  ${({ decoration = 'opening' }) => DECORATION_POSITION(decoration)};

  & img {
    visibility: var(--img-visibility);
  }
`

export default DecorationStyled
