import useCollection from 'hooks/useCollection'
import { useMemo } from 'react'

const collection = 'platforms'

const useGetPlatforms = (props) => {
  const query = useMemo(() => ({ ref: collection, ...props }), [props])
  const [value, loading, error, next, loadingMore, loadMoreAvailable] =
    useCollection(query)

  return [value, loading, error, next, loadingMore, loadMoreAvailable]
}

export default useGetPlatforms
