import { css } from 'styled-components'

const TEXT_VARIANTS = {
  body1: css`
    font-size: var(--text-body1-font-size);
    line-height: var(--text-body1-line-height);
    font-weight: var(--text-body1-font-weight);
    letter-spacing: var(--text-body1-letter-spacing);
    font-family: var(--text-body1-font-family);
  `,
  body2: css`
    font-size: var(--text-body2-font-size);
    line-height: var(--text-body2-line-height);
    font-weight: var(--text-body2-font-weight);
    letter-spacing: var(--text-body2-letter-spacing);
    font-family: var(--text-body2-font-family);
  `,
  caption1: css`
    font-size: var(--text-caption1-font-size);
    line-height: var(--text-caption1-line-height);
    font-weight: var(--text-caption1-font-weight);
    letter-spacing: var(--text-caption1-letter-spacing);
    font-family: var(--text-caption1-font-family);
  `,
  caption2: css`
    font-size: var(--text-caption2-font-size);
    line-height: var(--text-caption2-line-height);
    font-weight: var(--text-caption2-font-weight);
    letter-spacing: var(--text-caption2-letter-spacing);
    font-family: var(--text-caption2-font-family);
  `,
  default: {
    fontSize: 'var(--text-body1-font-size)',
    lineHeight: 'var(--text-body1-line-height)',
    fontWeight: 'var(--text-body1-font-weight)',
    letterSpacing: 'var(--text-body1-letter-spacing)',
    fontFamily: 'var(--text-body1-font-family)'
  }
}

export default TEXT_VARIANTS
