const TEXT_PREVIEWS = {
  title1: 'How One Typeface Landed on the Moon',
  title2: 'How One Typeface Landed on the Moon',
  text1: `Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod
        tempor incididunt ut labore et dolore magna aliqua. Vel orci porta non
        pulvinar. Euismod quis viverra nibh cras. Aliquam ut porttitor leo a
        diam sollicitudin tempor. Ornare massa eget egestas purus viverra. Sed
        vulputate mi sit amet. Nibh cras pulvinar mattis nunc sed blandit.
        Ultrices neque ornare aenean euismod elementum nisi quis eleifend quam.
        Diam phasellus vestibulum lorem sed risus ultricies. Consectetur
        adipiscing elit ut aliquam purus. Interdum varius sit amet mattis
        vulputate enim nulla aliquet. Sagittis aliquam malesuada bibendum arcu
        vitae. At risus viverra adipiscing at in tellus integer. Tristique
        senectus et netus et malesuada.`,
  text2: `Donec massa sapien faucibus et molestie ac feugiat sed lectus. Turpis
        massa tincidunt dui ut ornare lectus. Semper feugiat nibh sed pulvinar
        proin gravida hendrerit lectus. Nulla facilisi cras fermentum odio. Sit
        amet tellus cras adipiscing enim eu turpis.`
}

export default TEXT_PREVIEWS
