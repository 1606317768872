import { useEffect, useState } from 'react'

import { BRANDBOOK_CONFIG_REDUCER_CASES } from 'brandbook/__constants__'
import ImgStyled from 'brandbook/domains/Imagery/components/ImageryView/ImageryView.styled'
import PropTypes from 'prop-types'
import { SCREEN_VARIANTS } from './__constants__'
import { Upload } from 'antd'
import { uploadFile } from 'services/storage'
import { useBrandbookConfig } from 'brandbook/contexts/BrandbookConfig'

const ImageCombinedView = (props) => {
  const {
    onChange,
    filePath,
    item,
    img,
    variant,
    moodboard,
    imgDirection,
    index
  } = props
  const { brandbookConfigDataDispatch, isOnlyView } = useBrandbookConfig()
  // [COMPONENT_STATE_HOOKS]
  const [file, setFile] = useState(null)
  const [selectedItemIndex, setSelectedItemIndex] = useState()

  // [HELPERS]
  const selectPhotoToUpload = (url) => {
    if (variant === SCREEN_VARIANTS.MOODBOARD) {
      moodboard[selectedItemIndex].img = url
      brandbookConfigDataDispatch({
        type: BRANDBOOK_CONFIG_REDUCER_CASES.SET_IMG_MOODBOARD,
        payload: moodboard
      })
      brandbookConfigDataDispatch({
        type: BRANDBOOK_CONFIG_REDUCER_CASES.SET_IMG_DIRECTION,
        payload: imgDirection
      })
    } else if (variant === SCREEN_VARIANTS.IMG_DIRECTION) {
      imgDirection[selectedItemIndex].img = url
      brandbookConfigDataDispatch({
        type: BRANDBOOK_CONFIG_REDUCER_CASES.SET_IMG_MOODBOARD,
        payload: moodboard
      })
      brandbookConfigDataDispatch({
        type: BRANDBOOK_CONFIG_REDUCER_CASES.SET_IMG_DIRECTION,
        payload: imgDirection
      })
    }
  }

  const handleUpload = async ({ onSuccess, file }) => {
    const [url] = await uploadFile(file, `${filePath}/${file?.name}`)
    onChange?.(url)
    setFile(url)
    selectPhotoToUpload(url)
    onSuccess()
  }

  // [LIFECYCLE]
  useEffect(() => img && setFile(img), [img])

  return (
    <>
      {isOnlyView ? (
        <ImgStyled
          isOnlyView={isOnlyView}
          key={`${item}-imagery-view`}
          {...item}
          src={file}
          alt={item?.img}
        />
      ) : (
        <Upload
          onClick={() => setSelectedItemIndex(index)}
          cursor="pointer"
          fileList={null}
          customRequest={handleUpload}>
          <ImgStyled
            key={`${item}-imagery-view`}
            {...item}
            src={file}
            alt={item?.img}
          />
        </Upload>
      )}
    </>
  )
}

ImageCombinedView.propTypes = {
  variant: PropTypes.string,
  onChange: PropTypes.func,
  filePath: PropTypes.string,
  index: PropTypes.number,
  imgDirection: PropTypes.array,
  moodboard: PropTypes.array,
  img: PropTypes.string,
  item: PropTypes.object
}

export default ImageCombinedView
