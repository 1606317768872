import { model, belongsTo, hasMany, attr } from 'services/model'
import * as yup from 'yup'

const validationSchema = yup.object().shape({
  calculatorId: yup
    .string()
    .typeError('Field calculatorId has wrong type')
    .default(null)
    .nullable(),
  colorIds: yup
    .array()
    .typeError('Field colorIds has wrong type')
    .default(null)
    .nullable(),
  description: yup
    .string()
    .typeError('Field description has wrong type')
    .default(null)
    .nullable(),
  designIds: yup
    .array()
    .typeError('Field designIds has wrong type')
    .default(null)
    .nullable(),
  isDescriptionChanged: yup
    .boolean()
    .typeError('Field isDescriptionChanged has wrong type')
    .default(null)
    .nullable(),
  logoFormat: yup
    .string()
    .typeError('Field logoFormat has wrong type')
    .default(null)
    .nullable(),
  logoUrl: yup
    .string()
    .typeError('Field logoUrl has wrong type')
    .required('Field logoUrl is required'),
  notes: yup
    .string()
    .typeError('Field notes has wrong type')
    .default(null)
    .nullable(),
  roleIds: yup
    .array()
    .typeError('Field roleIds has wrong type')
    .default(null)
    .nullable()
})

const CustomerProfile = model(
  'customerProfile',
  {
    calculatorId: belongsTo('calculator'),
    colorIds: hasMany('color'),
    description: attr('string'),
    designIds: hasMany('design'),
    isDescriptionChanged: attr('boolean'),
    logoFormat: attr('string'),
    logoUrl: attr('string'),
    notes: attr('string'),
    roleIds: hasMany('role')
  },
  validationSchema
)

export default CustomerProfile
