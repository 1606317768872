import styled from 'styled-components'

const GreetingScreenStyled = styled.section`
  background-color: var(--screen-bg-default);
  min-height: var(--screen-min-height-default);
  max-height: 100vh;
  display: flex;
  position: relative;
  flex-direction: column;
  overflow: hidden;
  padding: 0 var(--offset-32);

  .greeting-container {
    flex: 1;
  }
`

const DecorationImg = styled.img`
  right: 0;
  z-index: 0;
  height: auto;
  position: absolute;
  left: var(--decoration-img-l);
  bottom: var(--decoration-img-b);
  max-width: var(--decoration-img-max-width);
`

export { GreetingScreenStyled, DecorationImg }
