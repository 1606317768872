import styled from 'styled-components'

const HeaderStyled = styled.header`
  padding: var(--header-py) var(--header-px);
  font-size: var(--header-fs);
  line-height: var(--header-lh);
  font-family: var(--header-ff);

  color: ${({ breadcrumbsColor }) =>
    breadcrumbsColor
      ? `var(--header-text-${breadcrumbsColor})`
      : 'var(--header-text-default)'};

  z-index: 1;
`

export default HeaderStyled
