import './TableOfContentScreen.vars.css'

import { ContentList } from '../../../domains'
import { ContentScreen } from '../../../components'
import PositionedContainerStyled from './TableOfContentScreen.styled'

/**
 * It returns a ContentScreen component with a ContentList component positioned at the bottom right of
 * the screen
 * @returns A ContentScreen component with the following props:
 *   - inverse
 *   - layoutOuter
 *   - breadcrumbsColor="white"
 *   - footerColor="white"
 *   - key="AppIconAndroidScreen"
 *   - chapter="Table of contents"
 *   - domainName="Toc."
 */
const TableOfContentScreen = () => {
  return (
    <ContentScreen
      inverse
      layoutOuter
      breadcrumbsColor="white"
      footerColor="white"
      key="TableOfContentScreen"
      chapter="Table of contents"
      domainName="Toc."
      pageNumber="02"
      content={
        <PositionedContainerStyled>
          <ContentList />
        </PositionedContainerStyled>
      }
    />
  )
}

export default TableOfContentScreen
