import { model, attr } from 'services/model'
import * as yup from 'yup'

const validationSchema = yup.object().shape({
  name: yup
    .string()
    .typeError('Field name has wrong type')
    .required('Field name is required'),
  description: yup
    .string()
    .typeError('Field description has wrong type')
    .default(null)
    .nullable(),
  imageUrl: yup
    .string()
    .typeError('Field imageUrl has wrong type')
    .default(null)
    .nullable()
})

const Technology = model(
  'technology',
  {
    name: attr('string'),
    description: attr('string'),
    imageUrl: attr('string')
  },
  validationSchema
)

export default Technology
