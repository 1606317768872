import { useTranslations } from 'contexts/Translation'
import PropTypes from 'prop-types'
import { Tabs, Card } from 'antd'
import { StickyContainer, Sticky } from 'react-sticky'
import {
  Container,
  Row,
  Col,
  Spin,
  Divider,
  Text,
  Title,
  Badge
} from '@qonsoll/react-design'
import { useParams, useHistory, useLocation } from 'react-router-dom'
import { useDocument } from 'hooks'
import { useStateWithStorage } from 'hooks'
import { CustomerApplications } from 'domains/Customer/components'
import { CustomerBrandbook } from 'domains/Customer/components'
import { CustomerRepresentatives } from 'domains/Customer/components'
import { CustomerDatabase } from 'domains/Customer/components'
import { CustomerFeatures } from 'domains/Customer/components'
import { CustomerMockups } from 'domains/Customer/components'
import { CustomerOffer } from 'domains/Customer/components'
import { CustomerCustomerProfile } from 'domains/Customer/components'
import PATHS from 'pages/paths'
import {
  APPLICATION_ROUTES,
  BRANDBOOK_ROUTES,
  REPRESENTATIVE_ROUTES,
  DATABASE_ROUTES,
  FEATURE_ROUTES,
  MOCKUP_ROUTES,
  OFFER_ROUTES,
  CUSTOMER_PROFILE_ROUTES,
  CUSTOMER_ROUTES
} from 'pages/App/routes'
import { Route, Switch, Redirect, generatePath } from 'react-router-dom'

const CustomerAdvancedView = (props) => {
  // [COMPONENT_STATE_HOOKS]
  const [activeTab, setActiveTab] = useStateWithStorage(
    null,
    'customer-advanced-view-active-tab'
  )

  // [ADDITIONAL_HOOKS]
  const { t } = useTranslations()
  const history = useHistory()
  const params = useParams()
  const location = useLocation()
  const { customerId } = params
  const [customer, loading] = useDocument({ ref: `customers/${customerId}` })

  // [COMPUTED_PROPERTIES]
  const { TabPane } = Tabs

  const changeTab = (key) => {
    if (params && customerId) {
      if (key.includes(`customers/${customerId}`)) {
        history.push(key)
      }
      setActiveTab(key)
    }
  }

  return loading ? (
    <Spin />
  ) : (
    <Container>
      <Container mb={4}>
        <Card>
          <Row noGutters mb={2} v="center">
            <Col h="left">
              <Title level={3}>{customer?.name}</Title>
            </Col>
          </Row>
          <Row noGutters mb={2} v="center">
            <Col h="left">
              <Text fontWeight="bold">{t('Status')}:</Text>
            </Col>
            <Col h="right">
              <Badge>{customer?.status}</Badge>
            </Col>
          </Row>
          <Row noGutters v="center">
            <Col h="left">
              <Text variant="overline" type="secondary">
                {t('Email')}:
              </Text>
            </Col>
            <Col h="right">
              <Text>{customer?.email}</Text>
            </Col>
            <Divider my={2} />
          </Row>
        </Card>
      </Container>
      <Row noGutters mb={4}>
        <Col>
          <StickyContainer>
            <Tabs defaultActiveKey={location.pathname} onChange={changeTab}>
              <TabPane
                tab={t('Applications')}
                key={generatePath(
                  PATHS.AUTHENTICATED.CUSTOMER_APPLICATIONS_ALL,
                  { customerId }
                )}
              />
              <TabPane
                tab={t('Brandbook')}
                key={generatePath(PATHS.AUTHENTICATED.CUSTOMER_BRANDBOOK_SHOW, {
                  brandbookId: customer?.brandbookId,
                  customerId
                })}
              />
              <TabPane
                tab={t('Representatives')}
                key={generatePath(
                  PATHS.AUTHENTICATED.CUSTOMER_REPRESENTATIVES_ALL,
                  { customerId }
                )}
              />
              <TabPane
                tab={t('Database')}
                key={generatePath(PATHS.AUTHENTICATED.CUSTOMER_DATABASE_SHOW, {
                  databaseId: customer?.databaseId,
                  customerId
                })}
              />
              <TabPane
                tab={t('Features')}
                key={generatePath(PATHS.AUTHENTICATED.CUSTOMER_FEATURES_ALL, {
                  customerId
                })}
              />
              <TabPane
                tab={t('Mockups')}
                key={generatePath(PATHS.AUTHENTICATED.CUSTOMER_MOCKUPS_ALL, {
                  customerId
                })}
              />
              <TabPane
                tab={t('Offer')}
                key={generatePath(PATHS.AUTHENTICATED.CUSTOMER_OFFER_SHOW, {
                  offerId: customer?.offerId,
                  customerId
                })}
              />
              <TabPane
                tab={t('Customer profile')}
                key={generatePath(
                  PATHS.AUTHENTICATED.CUSTOMER_CUSTOMER_PROFILE_SHOW,
                  { customerProfileId: customer?.profileId, customerId }
                )}
              />
            </Tabs>
            <Switch>
              {CUSTOMER_ROUTES.map((routeProps) => (
                <Route key={routeProps.key} {...routeProps} />
              ))}
              <Redirect
                to={generatePath(
                  PATHS.AUTHENTICATED.CUSTOMER_APPLICATIONS_ALL,
                  { customerId }
                )}
              />
            </Switch>
          </StickyContainer>
        </Col>
      </Row>
    </Container>
  )
}

CustomerAdvancedView.propTypes = {}

export default CustomerAdvancedView
