import { useTranslations } from 'contexts/Translation'
import { HeaderBreadcrumbs, Spinner } from 'components'
import { PageWrapper, Row, Col } from '@qonsoll/react-design'
import { useParams, useHistory } from 'react-router-dom'
import {
  DatabaseList,
  DatabaseSortMenu,
  DatabaseFilter
} from 'domains/Database/components'
import { useGetDatabases } from 'domains/Database/hooks'
import { Tooltip } from 'antd'
import {
  UnorderedListOutlined,
  SortAscendingOutlined,
  AppstoreOutlined,
  PlusOutlined
} from '@ant-design/icons'
import { useState, useMemo } from 'react'
import { useFilter, useStateWithStorage } from 'hooks'
import { Divider, Box } from '@qonsoll/react-design'
import { Spin, Popover, Button } from '@qonsoll/react-design'

const DatabasesAll = (props) => {
  // [ADDITIONAL_HOOKS]
  const { t } = useTranslations()
  const history = useHistory()
  const [listView, setListView] = useStateWithStorage(
    false,
    'database-list-view'
  )
  const {
    filterVisibility,
    setFilterVisibility,
    filterButtonText,
    filterButtonIcon,
    onFilterButtonClick,
    filterData,
    setFilterData
  } = useFilter('database-filter')
  const params = useParams()
  const ref = useMemo(
    () => ({
      ...(filterData || {}),
      where: [
        ...(filterData?.where || []),
        ['customerId', '==', params.customerId]
      ]
    }),
    [filterData, params.customerId]
  )
  const [databases, loading] = useGetDatabases(ref)

  // [COMPONENT_STATE_HOOKS]

  // [COMPUTED_PROPERTIES]
  const headingProps = {
    title: t('Databases'),
    titleSize: 3,
    marginBottom: 3,
    textAlign: 'left'
  }
  const showFilter = useMemo(
    () => Boolean(filterData?.where?.length || databases?.length),
    [filterData?.where, databases]
  )

  // [HANDLERS]
  const handleBackButtonClick = () => history.goBack()

  return (
    <PageWrapper
      action={
        <Box display="flex">
          <Tooltip title={t('Open sort menu')}>
            <Popover
              content={
                <DatabaseSortMenu
                  filteredData={filterData}
                  setFilteredData={setFilterData}
                />
              }
              trigger="click"
              placement="bottomRight">
              <Button
                shape="circle"
                type="ghost"
                mr={2}
                icon={<SortAscendingOutlined />}
              />
            </Popover>
          </Tooltip>
          <Tooltip title={t('Change list view')} placement="topRight">
            <Button
              shape="circle"
              type="ghost"
              onClick={() => setListView(!listView)}
              icon={listView ? <AppstoreOutlined /> : <UnorderedListOutlined />}
            />
          </Tooltip>
          <Divider mx={3} height="24px" alignSelf="center" type="vertical" />
          <Button
            type="primary"
            icon={<PlusOutlined />}
            onClick={() => history.push('./database/create')}>
            {t('Add new database')}
          </Button>
        </Box>
      }
      headingProps={headingProps}>
      <Row noGutters>
        {loading ? (
          <Col>
            <Spin />
          </Col>
        ) : (
          <Col>
            <DatabaseList listView={listView} databases={databases} />
          </Col>
        )}
      </Row>
    </PageWrapper>
  )
}

export default DatabasesAll
