import './styles/index.css'

import { BRANDBOOK_ID } from './__constants__'
import { SCREENS_ORDER } from './screens'
import { useOverwriteTypography } from './hooks'

const BrandbookModule = (props) => {
  // [ADDITIONAL_HOOKS]
  useOverwriteTypography()

  return (
    <div className="brandbook-module" id={BRANDBOOK_ID}>
      {SCREENS_ORDER.map((item) => item())}
    </div>
  )
}

export default BrandbookModule
