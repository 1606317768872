import styled, { css } from 'styled-components'

const variants = (variant) => {
  const textVariant = variant || 'body1'
  return css`
    font-size: var(--text-${textVariant}-fs);
    line-height: var(--text-${textVariant}-lh);
    font-weight: var(--text-fw-regular);
  `
}

const Text = styled.span`
  display: inline-block;
  font-weight: var(--text-fw-regular);
  text-transform: ${({ uppercase }) => (uppercase ? 'uppercase' : 'none')};
  text-align: ${({ textAlign }) => textAlign || 'left'};
  color: ${({ color }) =>
    color ? `var(--text-${color})` : 'var(--neutral-default)'};

  font-weight: ${({ fw }) =>
    fw ? `var(--text-fw-${fw})` : 'var(--text-fw-regular)'};
  font-family: ${({ ff }) => ff && `var(--ff-${ff})`};

  max-width: ${({ mw }) => mw};
  letter-spacing: ${({ letterSpacing }) =>
    letterSpacing && `var(--text-${letterSpacing}-letter-spacing)`};

  ${({ variant }) => variants(variant)}
  ${({ color, inverse }) =>
    inverse && color && `color: var(--${color}-inverse)`};
`

export default Text
