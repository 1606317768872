import PropTypes from 'prop-types'

const Bell = (props) => {
  const { iconSize, iconColor, fill } = props

  return (
    <svg
      width={iconSize}
      height={iconSize}
      viewBox="0 0 72 72"
      fill={fill}
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M58.14 43.14C58.7304 43.7311 59.1986 44.4328 59.5177 45.2049C59.8369 45.9771 60.0007 46.8045 60 47.64V47.64C60 49.3268 59.3299 50.9445 58.1372 52.1372C56.9445 53.3299 55.3268 54 53.64 54H18.36C16.6732 54 15.0555 53.3299 13.8628 52.1372C12.6701 50.9445 12 49.3268 12 47.64V47.64C11.9993 46.8045 12.1632 45.9771 12.4823 45.2049C12.8014 44.4328 13.2696 43.7311 13.86 43.14L18 39V27C18 22.2261 19.8964 17.6477 23.2721 14.2721C26.6477 10.8964 31.2261 9 36 9V9C40.7739 9 45.3523 10.8964 48.7279 14.2721C52.1036 17.6477 54 22.2261 54 27V39L58.14 43.14ZM45 54H27C27 56.3869 27.9482 58.6761 29.636 60.364C31.3239 62.0518 33.6131 63 36 63C38.387 63 40.6761 62.0518 42.364 60.364C44.0518 58.6761 45 56.3869 45 54Z"
        stroke={iconColor}
        strokeWidth="6"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}
Bell.propTypes = {
  iconSize: PropTypes.string.isRequired,
  fill: PropTypes.string,
  iconColor: PropTypes.string
}
export default Bell
