const CALCULATOR_LOCALIZATION = {
  YES: 'Yes',
  NO: 'No',
  NOT_SURE: 'Not sure'
}

const CALCULATOR_LOCALIZATION_OPTIONS = Object.keys(
  CALCULATOR_LOCALIZATION
).map((key) => ({ label: CALCULATOR_LOCALIZATION[key], value: key }))

export default CALCULATOR_LOCALIZATION
export { CALCULATOR_LOCALIZATION_OPTIONS, CALCULATOR_LOCALIZATION }
