import PropTypes from 'prop-types'
import { cloneElement } from 'react'

/**
 * It takes an array of data and a function that returns a component, and returns an array of
 * components
 * @param {dataSource} array - of what show
 * @param {renderItem} function - callback for dataSource
 * @param {ListItem} node - component
 * @returns A list of items
 */
const List = (props) => {
  const { dataSource, renderItem, ListItem } = props

  return ListItem
    ? dataSource?.map((item, id) =>
        cloneElement(<ListItem key={`${id}-list-item`} />, item)
      )
    : dataSource?.map(renderItem)
}
List.propTypes = {
  dataSource: PropTypes.array,
  renderItem: PropTypes.func,
  ListItem: PropTypes.func
}

export default List
