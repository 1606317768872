const PROPER_USE = [
  {
    color: 'white',
    size: 'xl',
    hasOffsets: true
  },
  {
    color: 'dark',
    size: 'xl',
    hasOffsets: true
  },
  {
    color: 'accent',
    size: 'xl',
    hasOffsets: true
  }
]

const MISS_USE = [
  {
    color: 'white',
    size: 'sm',
    hasOffsets: true
  },
  {
    color: 'white',
    size: 'sm',
    hasOffsets: true
  },
  {
    color: 'white',
    size: 'sm',
    hasOffsets: true
  },
  {
    color: 'white',
    size: 'sm',
    hasOffsets: true
  },
  {
    color: 'white',
    size: 'sm',
    hasOffsets: true
  },
  {
    color: 'white',
    size: 'sm',
    hasOffsets: true
  }
]

const LOGOS_CONFIG = {
  MISS_USE,
  PROPER_USE
}

export default LOGOS_CONFIG
