import { Box, Footer, Header, Text, Title } from '../../../components'
import {
  ScreenStyled,
  leftLineDecorationStyles,
  rightLineDecorationStyles
} from './Screen.styled'

import { IMAGE_DECORATIONS } from '../../../__constants__'
import PropTypes from 'prop-types'
import { useMediaQuery } from 'hooks'
import { useScreensNavigation } from '../../../hooks'

/**
 * It's a function that returns a Screen.
 * It shows title and passed content. Also it render Header and Footer
 * if there is onlyContent === true
 */
const Screen = (props) => {
  const {
    onlyContent,
    title,
    description,
    breadcrumbs,
    isHorizontalLayout,
    isReverseOrderContent,
    children,
    isTitleVisible,
    id,
    idScreen
  } = props
  const { LINE } = IMAGE_DECORATIONS

  // [ADDITIONAL_HOOKS]
  // hook is used for checking if curent screen is in view
  const observe = useScreensNavigation(id)
  const isBreakpointSm = useMediaQuery('(max-width: 992px)')

  // [COMPUTED_PROPERTIES]
  // computed tittle to add point in the end of title and make it green
  const titleComputed =
    title &&
    `${title.slice(0, -1)}<span class='text-accent-color'>${
      title.slice(-1)[0]
    }</span>`
  const computedTitle = `${title}<span class='text-accent-color'>.</span>`
  const textWrapperAlignStyle = isBreakpointSm
    ? 'col-sm-12 col-lg-5 mb-4 d-flex align-items-center justify-content-center'
    : 'col-sm-12 col-lg-5 mb-4 d-flex align-items-center'
  const modelsScreenHiddenClass =
    idScreen === 'models' && 'offer-print-hide-models-screen'
  const mockupsFlexDirection = isReverseOrderContent ? 'row-reverse' : 'row'

  return (
    <ScreenStyled ref={observe} id={id} className={modelsScreenHiddenClass}>
      {!onlyContent && <Header breadcrumbs={breadcrumbs} />}
      <Box
        id="left-line-decoration"
        background={`url(${LINE})`}
        {...leftLineDecorationStyles}
      />
      {isHorizontalLayout ? (
        <Box className="container screen-container d-flex align-items-center justify-content-center">
          <Box className="row align-items-center">
            {!isTitleVisible ? (
              <Box className="col-12 d-flex justify-content-center">
                {children}
              </Box>
            ) : (
              <Box className="col">
                <Box
                  className="row"
                  style={{
                    flexDirection: mockupsFlexDirection
                  }}>
                  <Box className={textWrapperAlignStyle}>
                    <Box className="d-flex flex-column">
                      <Title
                        level={4}
                        textAlign={isBreakpointSm && 'center'}
                        className="offer-print-screen-title">
                        {computedTitle}
                      </Title>
                      {description && (
                        <Text
                          textAlign={isBreakpointSm && 'center'}
                          className="offer-print-screen-title">
                          {description}
                        </Text>
                      )}
                    </Box>
                  </Box>
                  <Box className="col-sm-12 col-lg-7 d-flex justify-content-center">
                    {children}
                  </Box>
                </Box>
              </Box>
            )}
          </Box>
        </Box>
      ) : (
        <Box className="container screen-container">
          {titleComputed && (
            <Box className="row mb-5">
              <Box className="col-12">
                <Title level={4}>{titleComputed}</Title>
              </Box>
            </Box>
          )}
          {description && (
            <Box className="row mb-4">
              <Box className="col-12">
                <Text variant="body2">{description}</Text>
              </Box>
            </Box>
          )}
          <Box className="row">
            <Box className="col-12">{children}</Box>
          </Box>
        </Box>
      )}
      <Box
        id="right-line-decoration"
        backgroundImage={`url(${LINE})`}
        {...rightLineDecorationStyles}
      />
      {!onlyContent && <Footer />}
    </ScreenStyled>
  )
}

Screen.propTypes = {
  onlyContent: PropTypes.bool,
  title: PropTypes.string,
  description: PropTypes.string,
  idScreen: PropTypes.string,
  breadcrumbs: PropTypes.array,
  isHorizontalLayout: PropTypes.bool,
  isTitleVisible: PropTypes.bool,
  children: PropTypes.node,
  isReverseOrderContent: PropTypes.bool,
  id: PropTypes.string,
  pageNumber: PropTypes.string
}

export default Screen
