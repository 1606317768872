import { Alphabet, MegaText, TypoIntro } from 'brandbook/domains'

import { ContentScreen } from 'brandbook/components'
import { PositionedContainerStyled } from '../__constants__'
import { useBrandbookConfig } from 'brandbook/contexts/BrandbookConfig'
import { useBrandbookInitialValues } from 'domains/Brandbook/hooks'
import { useParams } from 'react-router-dom'

const TypographyPrimaryScreen = () => {
  // [ADDITIONAL_HOOKS]
  const { brandbookId } = useParams()
  const { brandbookConfigData } = useBrandbookConfig()
  const [brandbook] = useBrandbookInitialValues(brandbookId)

  // [COMPUTED PROPERTIES]
  const primaryFont =
    brandbookConfigData?.ffPrimary || brandbook?.brandbookConfig?.ffPrimary
  const description =
    'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Praesent facilisis nibh ut placerat fermentum. Etiam ut hendrerit elit. Suspendisse ultricies, augue in gravida egestas, enim quam ornare odio, id ultrices sapien purus a erat. Etiam et tincidunt dui. Morbi vel posuere nisi, ut suscipit lectus. Vivamus at condimentum arcu. Mauris at malesuada quam. '

  return (
    <ContentScreen
      positionOuter
      pageNumber="08"
      chapter="Primary"
      domainName="Typo."
      key="TypographyPrimaryScreen"
      asideTopContent={
        <TypoIntro
          title={primaryFont?.name || 'Lorem'}
          subtitle="Regular"
          description={description}
        />
      }
      content={
        <>
          <PositionedContainerStyled>
            <Alphabet ff="primary" />
          </PositionedContainerStyled>
          <MegaText responsive />
        </>
      }
    />
  )
}

export default TypographyPrimaryScreen
