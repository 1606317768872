import { useTranslations } from 'contexts/Translation'
import PropTypes from 'prop-types'
import { Tabs, Card } from 'antd'
import { StickyContainer, Sticky } from 'react-sticky'
import { Container, Row, Col, Spin, Divider, Text } from '@qonsoll/react-design'
import { useParams, useHistory, useLocation } from 'react-router-dom'
import { useDocument } from 'hooks'
import { useStateWithStorage } from 'hooks'
import { OfferFeatures } from 'domains/Offer/components'
import { OfferMockups } from 'domains/Offer/components'
import { OfferTechnologies } from 'domains/Offer/components'

const OfferAdvancedView = (props) => {
  // [COMPONENT_STATE_HOOKS]
  const [activeTab, setActiveTab] = useStateWithStorage(
    null,
    'offer-advanced-view-active-tab'
  )

  // [ADDITIONAL_HOOKS]
  const { t } = useTranslations()
  const history = useHistory()
  const params = useParams()
  const location = useLocation()
  const { offerId } = params
  const [offer, loading] = useDocument({ ref: `offers/${offerId}` })

  // [COMPUTED_PROPERTIES]
  const { TabPane } = Tabs

  const changeTab = (key) => {
    if (params && offerId) {
      if (key.includes(`offers/${offerId}`)) {
        history.push(key)
      }
      setActiveTab(key)
    }
  }

  return loading ? (
    <Spin />
  ) : (
    <Container>
      <Container mb={4}>
        <Card>
          <Row noGutters v="center">
            <Col h="left">
              <Text variant="overline" type="secondary">
                {t('Payment terms')}:
              </Text>
            </Col>
            <Col h="right">
              <Text>{offer?.paymentTerms}</Text>
            </Col>
            <Divider my={2} />
          </Row>
          <Row noGutters v="center">
            <Col h="left">
              <Text variant="overline" type="secondary">
                {t('Price')}:
              </Text>
            </Col>
            <Col h="right">
              <Text>{offer?.price}</Text>
            </Col>
            <Divider my={2} />
          </Row>
          <Row noGutters v="center">
            <Col h="left">
              <Text variant="overline" type="secondary">
                {t('Data base id')}:
              </Text>
            </Col>
            <Col h="right">
              <Text>{offer?.dataBaseId}</Text>
            </Col>
            <Divider my={2} />
          </Row>
        </Card>
      </Container>
      <Row noGutters mb={4}>
        <Col>
          <StickyContainer>
            <Tabs defaultActiveKey={activeTab} onChange={changeTab}>
              <TabPane tab={t('Features')} key="#feature">
                <OfferFeatures
                  emptyProps={{ withCreate: false }}
                  offer={offer}
                />
              </TabPane>
              <TabPane tab={t('Mockups')} key="#mockup">
                <OfferMockups
                  emptyProps={{ withCreate: false }}
                  offer={offer}
                />
              </TabPane>
              <TabPane tab={t('Technologies')} key="#technology">
                <OfferTechnologies
                  emptyProps={{ withCreate: false }}
                  offer={offer}
                />
              </TabPane>
            </Tabs>
          </StickyContainer>
        </Col>
      </Row>
    </Container>
  )
}

OfferAdvancedView.propTypes = {}

export default OfferAdvancedView
