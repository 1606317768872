import './ShadeAdvancedView.vars.css'

import styled from 'styled-components'

const ShadeAdvancedViewStyled = styled.div`
  display: flex;
  flex-direction: column;
  ${({ isReverse }) =>
    isReverse ? 'align-items: flex-end' : 'justify-content: flex-end'};
  width: var(--shade-advanced-view-width);
  height: var(--shade-advanced-view-height);
  padding: var(--shade-advanced-view-py) var(--shade-advanced-view-px);
  background-color: ${({ shade }) => shade};

  @media (max-width: 1024px) {
    align-items: flex-start;
    justify-content: flex-end;
    & span {
      font-size: var(--text-body2-fs);
      line-height: var(--text-body2-lh);
    }
  }

  @media (max-width: 576px) {
    & span {
      font-size: var(--text-caption1-fs);
      line-height: var(--text-caption1-lh);
    }
  }
`

export default ShadeAdvancedViewStyled
