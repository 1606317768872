import { BRANDBOOK_CONFIG_REDUCER_CASES } from 'brandbook/__constants__'

const brandbookConfigReducer = (state, action) => {
  switch (action.type) {
    case BRANDBOOK_CONFIG_REDUCER_CASES.SET_INITIAL_DATA: {
      return {
        ...action?.payload
      }
    }
    case BRANDBOOK_CONFIG_REDUCER_CASES.SET_BRAND_NAME: {
      return {
        ...state,
        brandName: action?.payload
      }
    }
    case BRANDBOOK_CONFIG_REDUCER_CASES.SET_ACCENT_COLOR: {
      return {
        ...state,
        accent: action?.payload?.accent,
        accentDarken: action?.payload?.accentDarken
      }
    }
    case BRANDBOOK_CONFIG_REDUCER_CASES.SET_ACCENT_DARKEN_COLOR: {
      return { ...state, accentDarken: action?.payload }
    }
    case BRANDBOOK_CONFIG_REDUCER_CASES.SET_NEUTRAL_COLOR: {
      return { ...state, neutral: action?.payload }
    }
    case BRANDBOOK_CONFIG_REDUCER_CASES.SET_SHAPE: {
      return { ...state, br: action?.payload }
    }
    case BRANDBOOK_CONFIG_REDUCER_CASES.SET_PRIMARY_FONT: {
      return { ...state, ffPrimary: action?.payload }
    }
    case BRANDBOOK_CONFIG_REDUCER_CASES.SET_SECONDARY_FONT: {
      return { ...state, ffSecondary: action?.payload }
    }
    case BRANDBOOK_CONFIG_REDUCER_CASES.SET_IMG_MOODBOARD: {
      return {
        ...state,
        images: { ...state?.images, moodboardImg: action?.payload }
      }
    }
    case BRANDBOOK_CONFIG_REDUCER_CASES.SET_IMG_DIRECTION: {
      return {
        ...state,
        images: { ...state?.images, directionImg: action?.payload }
      }
    }
    case BRANDBOOK_CONFIG_REDUCER_CASES.SET_APP_ICON: {
      return {
        ...state,
        appIcon: { ...state.appIcon, appIconGide: action?.payload }
      }
    }
    case BRANDBOOK_CONFIG_REDUCER_CASES.SET_APP_IOS: {
      return {
        ...state,
        appIcon: { ...state.appIcon, appIconIos: action?.payload }
      }
    }
    case BRANDBOOK_CONFIG_REDUCER_CASES.SET_APP_ANDROID: {
      return {
        ...state,
        appIcon: { ...state.appIcon, appIconAndroid: action?.payload }
      }
    }
    case BRANDBOOK_CONFIG_REDUCER_CASES.SET_ICON_STYLE: {
      return { ...state, iconStyle: action?.payload }
    }
    case BRANDBOOK_CONFIG_REDUCER_CASES.SET_LOGO_SYMBOL: {
      return { ...state, logos: { ...state?.logos, symbol: action?.payload } }
    }
    case BRANDBOOK_CONFIG_REDUCER_CASES.SET_LOGO_WORDMARK: {
      return { ...state, logos: { ...state?.logos, wordmark: action?.payload } }
    }
    case BRANDBOOK_CONFIG_REDUCER_CASES.SET_LOGO_GUIDE: {
      return { ...state, logos: { ...state?.logos, guide: action?.payload } }
    }
    case BRANDBOOK_CONFIG_REDUCER_CASES.SET_LOGO_PROPER_USE_1: {
      return {
        ...state,
        logos: { ...state?.logos, properUse1: action?.payload }
      }
    }
    case BRANDBOOK_CONFIG_REDUCER_CASES.SET_LOGO_PROPER_USE_2: {
      return {
        ...state,
        logos: { ...state?.logos, properUse2: action?.payload }
      }
    }
    case BRANDBOOK_CONFIG_REDUCER_CASES.SET_LOGO_PROPER_USE_3: {
      return {
        ...state,
        logos: { ...state?.logos, properUse3: action?.payload }
      }
    }
    case BRANDBOOK_CONFIG_REDUCER_CASES.SET_LOGO_MISS_USE_1: {
      return {
        ...state,
        logos: { ...state?.logos, missUse1: action?.payload }
      }
    }
    case BRANDBOOK_CONFIG_REDUCER_CASES.SET_LOGO_MISS_USE_2: {
      return {
        ...state,
        logos: { ...state?.logos, missUse2: action?.payload }
      }
    }
    case BRANDBOOK_CONFIG_REDUCER_CASES.SET_LOGO_MISS_USE_3: {
      return {
        ...state,
        logos: { ...state?.logos, missUse3: action?.payload }
      }
    }
    case BRANDBOOK_CONFIG_REDUCER_CASES.SET_LOGO_MISS_USE_4: {
      return {
        ...state,
        logos: { ...state?.logos, missUse4: action?.payload }
      }
    }
    case BRANDBOOK_CONFIG_REDUCER_CASES.SET_LOGO_MISS_USE_5: {
      return {
        ...state,
        logos: { ...state?.logos, missUse5: action?.payload }
      }
    }
    case BRANDBOOK_CONFIG_REDUCER_CASES.SET_LOGO_MISS_USE_6: {
      return {
        ...state,
        logos: { ...state?.logos, missUse6: action?.payload }
      }
    }
    default: {
      throw new Error(`Unhandled action type: ${action.type}`)
    }
  }
}

export default brandbookConfigReducer
