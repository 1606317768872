import { useTranslations } from 'contexts/Translation'
import PropTypes from 'prop-types'
import { CalculatorSimpleForm } from 'domains/Calculator/components'
import { Button, Title } from '@qonsoll/react-design'
import { Form, Select, Input } from 'antd'
import { KEYS } from '__constants__'
import {
  useInitializeCalculatorAdvancedForm,
  useActionsCalculatorAdvancedForm
} from './hooks'

const CalculatorAdvancedForm = (props) => {
  const { initialData, group, showTitle } = props

  // [ADDITIONAL_HOOKS]
  const { t } = useTranslations()
  const { form } = useInitializeCalculatorAdvancedForm(initialData)

  // [HANDLERS]
  const handleKeyPress = (e) => {
    if (e.key === KEYS.ENTER) {
      e.preventDefault()
      onFinish()
    }
  }

  const { loading, onFinish, onReset } = useActionsCalculatorAdvancedForm({
    initialData,
    form
  })

  return group ? (
    <>
      {showTitle && (
        <Title level={4} mb={2}>
          {t('Calculator')}
        </Title>
      )}
      <CalculatorSimpleForm
        showTitle={false}
        group={[...group, 'calculator']}
      />
    </>
  ) : (
    <Form
      onKeyPress={handleKeyPress}
      onFinish={onFinish}
      layout="vertical"
      autoComplete="off"
      form={form}>
      {showTitle && (
        <Title level={4} mb={2}>
          {t('Calculator')}
        </Title>
      )}
      <CalculatorSimpleForm group={['calculator']} />
      <Button mr={2} htmlType="button" onClick={onReset} mb={4}>
        {t('Cancel')}
      </Button>
      <Button type="primary" loading={loading} onClick={() => form.submit()}>
        {t('Save')}
      </Button>
    </Form>
  )
}

CalculatorAdvancedForm.propTypes = {
  initialData: PropTypes.object,
  group: PropTypes.array,
  showTitle: PropTypes.bool
}

export default CalculatorAdvancedForm
