import { IntroScreen } from '../../../../components'
import { SCREENS_TABLE_OF_CONTENT } from '../../../../__constants__'

const ElementsIntroScreen = () => {
  return (
    <IntroScreen
      key="ElementsIntroScreen"
      sectionNumber={7}
      title="Elem."
      caption="UI Atoms"
      contentList={SCREENS_TABLE_OF_CONTENT.ELEM_LIST}
    />
  )
}

export default ElementsIntroScreen
