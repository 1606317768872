import firebase from 'firebase/compat/app'
import { Redirect, Route, Switch } from 'react-router-dom'
import PATHS from '../paths'
import { useMemo } from 'react'
import PublicOffer from './PublicOffer'
import PublicBrandbook from './PublicBrandbook'

const { PUBLIC_ROUTE_OFFER, PUBLIC_ROUTE_BRANDBOOK } = PATHS.UNAUTHENTICATED

const routes = [
  {
    key: 'PUBLIC_ROUTE_OFFER',
    path: PUBLIC_ROUTE_OFFER,
    component: PublicOffer,
    exact: true
  },
  {
    key: 'PUBLIC_ROUTE_BRANDBOOK',
    path: PUBLIC_ROUTE_BRANDBOOK,
    component: PublicBrandbook,
    exact: true
  }
]

const PublicRoute = () => {
  const isAuthenticated = useMemo(
    () => firebase.auth().currentUser?.emailVerified,
    []
  )

  return isAuthenticated ? null : (
    <Switch>
      {routes.map((routeProps) => (
        <Route key={routeProps.key} {...routeProps} />
      ))}
      <Redirect to={PATHS.SERVICE.NOT_FOUND} />
    </Switch>
  )
}

export default PublicRoute
