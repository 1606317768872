const AVATAR_STATES = {
  title: 'State',
  avatar: [
    {
      name: 'Default',
      size: 'md',
      userInitials: 'AA'
    },
    {
      name: 'Hover',
      size: 'md',
      state: 'hover',
      userInitials: 'AA'
    },
    {
      name: 'Active',
      size: 'md',
      state: 'active',
      userInitials: 'AA'
    },
    {
      name: 'Selected',
      size: 'md',
      state: 'selected',
      userInitials: 'AA'
    }
  ]
}

export default AVATAR_STATES
