import {
  TypographyPrimaryScreen,
  TypographySecondaryScreen,
  TypographyTypefaceScreen,
  TypographyUseOfTypeScreen
} from './TypographyContent'

import { TypographyIntroScreen } from './TypographyIntro'

export const TYPOGRAPHY_SCREENS_ORDER = [
  TypographyIntroScreen,
  TypographyTypefaceScreen,
  TypographyPrimaryScreen,
  TypographySecondaryScreen,
  TypographyUseOfTypeScreen
]
