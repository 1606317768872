import { useEffect, useMemo, useState } from 'react'
import { useLoading } from 'hooks'
import { useDocumentData } from 'react-firebase-hooks/firestore'
import { COLLECTIONS } from '__constants__'
import firebase from 'firebase/compat/app'

const useBrandbookInitialValues = (id) => {
  // [DATA_FETCH]
  const [brandbook, brandbookLoading] = useDocumentData(
    id && firebase.firestore().collection(COLLECTIONS.BRANDBOOKS).doc(id)
  )

  // [COMPONENT_STATE_HOOKS]
  const [brandbookData, setBrandbookData] = useState(brandbook)

  // [USE_EFFECTS]
  useEffect(() => {
    if (brandbook) {
      const {
        _id,
        brandName,
        primaryFont,
        secondaryFont,
        logoUrl,
        colorIds,
        customerId,
        brandbookConfig
      } = brandbook

      const formValues = {
        _id,
        brandName: brandName ?? null,
        primaryFont,
        secondaryFont,
        logoUrl,
        colorIds,
        customerId,
        brandbookConfig
      }
      setBrandbookData(formValues)
    }
  }, [brandbook])

  // [COMPUTED_PROPERTIES]
  const initialValues = useMemo(() => brandbookData, [brandbookData])

  const loadings = useMemo(
    () => (id ? [!brandbook, brandbookLoading] : []),
    [id, brandbook, brandbookLoading]
  )

  // [ADDITIONAL_HOOKS]
  const loading = useLoading(loadings)

  return [initialValues, loading]
}

export default useBrandbookInitialValues
