import './IconSimpleView.vars.css'

import { Box, Icon, Text } from '../../../../components'

import { ICON_STYLE } from '../../../../__constants__'
import IconSimpleViewStyled from './IconSimpleView.styled'
import PropTypes from 'prop-types'
import { useBrandbookConfig } from 'brandbook/contexts/BrandbookConfig'

/**
 * It renders ContentScreenContent components
 * @param {string} name - icon name
 * @returns A React component
 */
const IconSimpleView = (props) => {
  const { name, responsiveStyles } = props

  // [ADDITIONAL_HOOKS]
  const { brandbookConfigData } = useBrandbookConfig()

  // [COMPUTED PROPERTIES]
  const isIconFilled = brandbookConfigData?.iconStyle === ICON_STYLE.FILLED

  return (
    <IconSimpleViewStyled {...responsiveStyles}>
      {name && (
        <>
          <Box mb={3}>
            <Icon
              icon={name}
              size="lg"
              variant="accent"
              filled={isIconFilled}
            />
          </Box>
          <Text ff="primary" color="dark-secondary">
            {name}
          </Text>
        </>
      )}
    </IconSimpleViewStyled>
  )
}

IconSimpleView.propTypes = {
  name: PropTypes.string.isRequired,
  responsiveStyles: PropTypes.object,
  width: PropTypes.string
}

export default IconSimpleView
