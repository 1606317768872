import styled from 'styled-components'

const ContentItemWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: var(--gap-32);
`

const NumberWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: var(--gap-32);
`

export { ContentItemWrapper, NumberWrapper }
