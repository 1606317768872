const IMAGE_DECORATIONS = {
  // HEADER
  LOGO: '/modules/senseteqLogo.svg',

  // EXTERIOR SCREEN
  BG: '/modules/bg.svg',
  THX: '/modules/thx.svg',

  // AVATAR
  AVATAR: '/modules/brandbook-illustrations/avatar.jpeg',

  // IMAGE DIRECTION
  IMG_DIR_1: '/modules/brandbook-illustrations/imgDirection1.png',
  IMG_DIR_2: '/modules/brandbook-illustrations/imgDirection2.png',
  IMG_DIR_3: '/modules/brandbook-illustrations/imgDirection3.png',

  // INSTA POST
  INSTA_POST_LEFT: '/modules/brandbook-illustrations/instaPostLeft.png',
  INSTA_POST_RIGHT: '/modules/brandbook-illustrations/instaPostRight.png',

  // MOODBOARD
  MOODBOARD_1: '/modules/brandbook-illustrations/moodboard1.png',
  MOODBOARD_2: '/modules/brandbook-illustrations/moodboard2.png',
  MOODBOARD_3: '/modules/brandbook-illustrations/moodboard3.png',
  MOODBOARD_4: '/modules/brandbook-illustrations/moodboard4.png',

  // APP ICON
  LOGO_GRID: '/modules/brandbook-illustrations/logoGrid.svg',
  IOS: '/modules/brandbook-illustrations/iphone.svg',
  ANDROID: '/modules/brandbook-illustrations/android.svg'
}

export default IMAGE_DECORATIONS
