import { useTranslations } from 'contexts/Translation'
import PropTypes from 'prop-types'
import { Tabs, Card } from 'antd'
import { StickyContainer, Sticky } from 'react-sticky'
import {
  Container,
  Row,
  Col,
  Spin,
  Divider,
  Text,
  Img
} from '@qonsoll/react-design'
import { useParams, useHistory, useLocation } from 'react-router-dom'
import { useDocument } from 'hooks'
import { useStateWithStorage } from 'hooks'
import { CustomerProfileCalculator } from 'domains/CustomerProfile/components'
import { CustomerProfileColors } from 'domains/CustomerProfile/components'
import { CustomerProfileDesigns } from 'domains/CustomerProfile/components'
import { CustomerProfileRoles } from 'domains/CustomerProfile/components'

const CustomerProfileAdvancedView = (props) => {
  // [COMPONENT_STATE_HOOKS]
  const [activeTab, setActiveTab] = useStateWithStorage(
    null,
    'customer-profile-advanced-view-active-tab'
  )

  // [ADDITIONAL_HOOKS]
  const { t } = useTranslations()
  const history = useHistory()
  const params = useParams()
  const location = useLocation()
  const { customerProfileId } = params
  const [customerProfile, loading] = useDocument({
    ref: `customerProfiles/${customerProfileId}`
  })

  // [COMPUTED_PROPERTIES]
  const { TabPane } = Tabs

  const changeTab = (key) => {
    if (params && customerProfileId) {
      if (key.includes(`customerProfiles/${customerProfileId}`)) {
        history.push(key)
      }
      setActiveTab(key)
    }
  }

  return loading ? (
    <Spin />
  ) : (
    <Container>
      <Container mb={4}>
        <Card>
          <Row noGutters mb={2} v="center">
            <Col h="left">
              <Img
                style={{
                  maxHeight: '500px',
                  objectFit: 'cover',
                  width: '100%'
                }}
                src={customerProfile?.logoFormat}
              />
            </Col>
          </Row>
          <Row noGutters mb={2} v="center">
            <Col h="left">
              <Img
                style={{
                  maxHeight: '500px',
                  objectFit: 'cover',
                  width: '100%'
                }}
                src={customerProfile?.logoUrl}
              />
            </Col>
          </Row>
          <Row noGutters mb={2} v="center">
            <Col h="left">
              <Text>{customerProfile?.description}</Text>
            </Col>
          </Row>
          <Row noGutters mb={2} v="center">
            <Col h="left">
              <Text>{customerProfile?.isDescriptionChanged}</Text>
            </Col>
          </Row>
          <Row noGutters v="center">
            <Col h="left">
              <Text variant="overline" type="secondary">
                {t('Notes')}:
              </Text>
            </Col>
            <Col h="right">
              <Text>{customerProfile?.notes}</Text>
            </Col>
            <Divider my={2} />
          </Row>
          <Row noGutters v="center">
            <Col h="left">
              <Text fontWeight="bold">{t('Color ids')}:</Text>
            </Col>
            <Col cw="auto" h="right">
              <CustomerProfileColors
                emptyProps={{ withIllustration: false, withCreate: false }}
                customerProfile={customerProfile}
              />
            </Col>
            <Divider my={2} />
          </Row>
        </Card>
      </Container>
      <Row noGutters mb={4}>
        <Col>
          <StickyContainer>
            <Tabs defaultActiveKey={activeTab} onChange={changeTab}>
              <TabPane tab={t('Calculator')} key="#calculator">
                <CustomerProfileCalculator
                  emptyProps={{ withCreate: false }}
                  customerProfile={customerProfile}
                />
              </TabPane>
              <TabPane tab={t('Designs')} key="#design">
                <CustomerProfileDesigns
                  emptyProps={{ withCreate: false }}
                  customerProfile={customerProfile}
                />
              </TabPane>
              <TabPane tab={t('Roles')} key="#role">
                <CustomerProfileRoles
                  emptyProps={{ withCreate: false }}
                  customerProfile={customerProfile}
                />
              </TabPane>
            </Tabs>
          </StickyContainer>
        </Col>
      </Row>
    </Container>
  )
}

CustomerProfileAdvancedView.propTypes = {}

export default CustomerProfileAdvancedView
