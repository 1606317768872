import PropTypes from 'prop-types'

const Image = (props) => {
  const { iconSize, iconColor, fill } = props

  return (
    <svg
      width={iconSize}
      height={iconSize}
      viewBox="0 0 72 72"
      fill={fill}
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M30.2402 24.8701H30.5402"
        stroke={iconColor}
        strokeWidth="9"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M63 15V57C63 57.7956 62.6839 58.5587 62.1213 59.1213C61.5587 59.6839 60.7956 60 60 60H12C11.2044 60 10.4413 59.6839 9.87868 59.1213C9.31607 58.5587 9 57.7956 9 57V15C9 14.2044 9.31607 13.4413 9.87868 12.8787C10.4413 12.3161 11.2044 12 12 12H60C60.7956 12 61.5587 12.3161 62.1213 12.8787C62.6839 13.4413 63 14.2044 63 15ZM31.53 44.4L47.13 60H60C60.7956 60 61.5587 59.6839 62.1213 59.1213C62.6839 58.5587 63 57.7956 63 57V47.19L45.87 30L31.53 44.4ZM9 50.13V57C9 57.7956 9.31607 58.5587 9.87868 59.1213C10.4413 59.6839 11.2044 60 12 60H47.13L23.13 36L9 50.13Z"
        stroke={iconColor}
        strokeWidth="6"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

Image.propTypes = {
  iconSize: PropTypes.string.isRequired,
  fill: PropTypes.string,
  iconColor: PropTypes.string
}
export default Image
