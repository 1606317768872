import { ContentScreen, PositionedContainer } from '../../../../components'

import { ImageryView } from '../../../../domains'

const ImageryMoodboardScreen = () => {
  return (
    <ContentScreen
      key="ImageryMoodboardScreen"
      chapter="Moodboard"
      domainName="Imagery."
      positionOuter
      content={
        <PositionedContainer top="0" left="0" width="100%" height="100%">
          <ImageryView variant="moodboard" />
        </PositionedContainer>
      }
      pageNumber="22"
    />
  )
}

export default ImageryMoodboardScreen
