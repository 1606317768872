import { Container, Row, Col, Text } from '@qonsoll/react-design'
import { RoleSimpleView } from '../../../Role/components'
import { useGetApplicationRole } from '../../hooks'
import PropTypes from 'prop-types'
import { Spinner } from 'components'
import { useTranslations } from 'contexts/Translation'

export default function ApplicationRole(props) {
  const { application } = props

  // [ADDITIONAL_HOOKS]
  const [role, loading] = useGetApplicationRole(application)
  const { t } = useTranslations()

  return (
    <Container>
      <Row noGutters>
        <Col>
          {loading ? (
            <Spinner flex={1} />
          ) : role ? (
            <RoleSimpleView {...props} role={role} />
          ) : (
            <Text type="secondary" variant="overline">
              {t('No roles have been added yet')}
            </Text>
          )}
        </Col>
      </Row>
    </Container>
  )
}

ApplicationRole.propTypes = {
  application: PropTypes.object.isRequired
}
