import { Box } from '../../../../components'
import PropTypes from 'prop-types'
import { VideoService } from 'components'
import VideoViewStyled from './VideoView.styled'

const VideoView = (props) => {
  const { qVideo } = props

  return (
    <Box className="d-flex justify-content-between align-items-center">
      <VideoViewStyled>
        <VideoService playerOnly qVideoId={qVideo} />
      </VideoViewStyled>
    </Box>
  )
}

VideoView.propTypes = {
  qVideo: PropTypes.string
}
export default VideoView
