import { ShadeAdvancedView, ShadeSimpleView } from '..'

import PropTypes from 'prop-types'
import ShadesListStyled from './ShadesList.styled'

/**
 * It takes in a list of shades and renders them
 * @param {bool} isReverse  - reverse shadeslist
 * @param {array} shades  - array of shades
 * @param {object} mainShade - main shade
 * @param {string} bg - shadelist bg
 * @returns A list of shades
 */
const ShadesList = (props) => {
  const { isReverse, shades, mainShade, bg } = props

  return (
    <ShadesListStyled isReverse={isReverse} bg={bg}>
      {shades?.map((shade, index) => (
        <ShadeSimpleView
          key={`${index}-chapter-item`}
          isReverse={isReverse}
          {...shade}
        />
      ))}
      <ShadeAdvancedView isReverse={isReverse} {...mainShade} />
    </ShadesListStyled>
  )
}

ShadesList.propTypes = {
  isReverse: PropTypes.bool,
  shades: PropTypes.array,
  mainShade: PropTypes.object,
  bg: PropTypes.oneOf(['dark', 'white'])
}

export default ShadesList
