import { Container, Row, Col, Text } from '@qonsoll/react-design'
import { TechnologyList } from '../../../Technology/components'
import { useGetOfferTechnologies } from '../../hooks'
import PropTypes from 'prop-types'
import { Spinner } from 'components'
import { useTranslations } from 'contexts/Translation'

export default function OfferTechnologies(props) {
  const { offer } = props

  // [ADDITIONAL_HOOKS]
  const [technologies, loading] = useGetOfferTechnologies(offer)
  const { t } = useTranslations()

  return (
    <Container>
      <Row noGutters>
        <Col>
          {loading ? (
            <Spinner flex={1} />
          ) : technologies?.length ? (
            <TechnologyList
              {...props}
              hideAddCard
              technologies={technologies}
            />
          ) : (
            <Text type="secondary" variant="overline">
              {t('No technologies have been added yet')}
            </Text>
          )}
        </Col>
      </Row>
    </Container>
  )
}

OfferTechnologies.propTypes = {
  offer: PropTypes.object.isRequired
}
