import './TypographyUseOfTypeScreen.vars.css'

import { MegaText, TextPreview, TitleList } from '../../../../domains'

import { ContentScreen } from '../../../../components'
import PositionedContainerStyled from './TypographyUseOfTypeScreen.styled'

const TypographyUseOfTypeScreen = () => {
  return (
    <ContentScreen
      positionOuter
      pageNumber="10"
      chapter="Use of type"
      domainName="Typo."
      key="TypographyUseOfTypeScreen"
      asideTopContent={<TitleList />}
      content={
        <>
          <PositionedContainerStyled>
            <TextPreview />
          </PositionedContainerStyled>
          <MegaText responsive />
        </>
      }
    />
  )
}

export default TypographyUseOfTypeScreen
