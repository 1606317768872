import PropTypes from 'prop-types'
import TextStyled from './Text.styled'

const Text = (props) => {
  const { children } = props

  return <TextStyled {...props}>{children}</TextStyled>
}

Text.propTypes = {
  children: PropTypes.string
}

export default Text
