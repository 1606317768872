const CALCULATOR_BRAND = {
  YES: 'Yes',
  NO: 'No',
  NOT_SURE: 'Not sure'
}

const CALCULATOR_BRAND_OPTIONS = Object.keys(CALCULATOR_BRAND).map((key) => ({
  label: CALCULATOR_BRAND[key],
  value: key
}))

export default CALCULATOR_BRAND
export { CALCULATOR_BRAND_OPTIONS, CALCULATOR_BRAND }
