import PropTypes from 'prop-types'

const Notification = (props) => {
  const { iconSize, iconColor, fill } = props

  return (
    <svg
      width={iconSize}
      height={iconSize}
      viewBox="0 0 72 72"
      fill={fill}
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M60 36V54C60 55.5913 59.3679 57.1174 58.2426 58.2426C57.1174 59.3679 55.5913 60 54 60H18C16.4087 60 14.8826 59.3679 13.7574 58.2426C12.6321 57.1174 12 55.5913 12 54V18C12 16.4087 12.6321 14.8826 13.7574 13.7574C14.8826 12.6321 16.4087 12 18 12H36"
        stroke={iconColor}
        strokeWidth="6"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M55.5 24C59.6421 24 63 20.6421 63 16.5C63 12.3579 59.6421 9 55.5 9C51.3579 9 48 12.3579 48 16.5C48 20.6421 51.3579 24 55.5 24Z"
        stroke={iconColor}
        strokeWidth="6"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

Notification.propTypes = {
  iconSize: PropTypes.string.isRequired,
  fill: PropTypes.string,
  iconColor: PropTypes.string
}

export default Notification
