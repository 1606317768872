import { Box, Text } from '../../../../components'
import { FeatureViewImg, FeatureViewStyled } from './FeatureView.styled'

import PropTypes from 'prop-types'

/**
 * It returns a styled div with an image and a text component
 * @param {object} feature - An object with feature properties
 * @returns A styled component
 */
const FeatureView = (props) => {
  const { feature } = props

  return (
    <FeatureViewStyled>
      <FeatureViewImg
        src={feature?.featureLogoURL}
        alt={feature?.name}
        className="offer-print-feature-img"
      />
      <Box className="py-3">
        <Text variant="body2">{feature?.name}</Text>
      </Box>
    </FeatureViewStyled>
  )
}

FeatureView.propTypes = {
  feature: PropTypes.object
}

export default FeatureView
