const CALCULATOR_APPEARANCE = {
  BASIC: 'Basic',
  FANTASTIC: 'Fantastic'
}

const CALCULATOR_APPEARANCE_OPTIONS = Object.keys(CALCULATOR_APPEARANCE).map(
  (key) => ({ label: CALCULATOR_APPEARANCE[key], value: key })
)

export default CALCULATOR_APPEARANCE
export { CALCULATOR_APPEARANCE_OPTIONS, CALCULATOR_APPEARANCE }
