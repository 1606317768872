import { useEffect, useState } from 'react'
import { getDocument } from 'services/firestore'

const useGetCustomerProfileRoles = (customerProfile) => {
  const [roles, setRoles] = useState([])
  const [loading, setLoading] = useState(!!customerProfile?.roleIds?.length)

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true)
      const promises = customerProfile.roleIds.map((role) =>
        getDocument('roles', role)
      )
      const data = await Promise.all(promises)
      setRoles(data)
      setLoading(false)
    }

    customerProfile?.roleIds?.length && fetchData()
  }, [customerProfile])

  return [roles, loading]
}

export default useGetCustomerProfileRoles
