import { Box } from '../../../../components'
import { Img } from '../../../../components'
import PropTypes from 'prop-types'
import { useGetImageSizes } from '../../../../hooks'
import { useRef } from 'react'

const MockupView = (props) => {
  const { src, title } = props

  // [ADDITIONAL_HOOKS]
  const mockupRef = useRef(null)
  const [mockupWidth, mockupHeight] = useGetImageSizes(
    mockupRef,
    'var(--mockup-max-width)',
    'var(--mockup-max-height)'
  )

  return (
    <Box className="d-flex justify-content-between align-items-center">
      <Img
        ref={mockupRef}
        src={src}
        alt={title}
        width={mockupWidth}
        height={mockupHeight}
        objectFit="contain"
      />
    </Box>
  )
}

MockupView.propTypes = {
  src: PropTypes.string,
  title: PropTypes.string
}
export default MockupView
