import { BRANDBOOK_CONFIG_KEYS } from 'brandbook/__constants__'
import { BoundedContainer } from 'brandbook/screens/Logo/LogoContent/components'
import { ContentScreen } from 'brandbook/components'
import { LOGO_BOTTOM_OFFSETS } from 'brandbook/screens/Logo/LogoContent/__constants__'
import { LogoList } from 'brandbook/domains'

const LogoMissUseScreen = () => {
  return (
    <ContentScreen
      key="LogoMissUseScreen"
      domainName="Logo."
      content={
        <BoundedContainer
          offsetBottom={LOGO_BOTTOM_OFFSETS.variant2}
          top="0"
          right="5%">
          <LogoList
            screen={BRANDBOOK_CONFIG_KEYS.MISS_USE}
            reducerCaseKey={BRANDBOOK_CONFIG_KEYS.MISS_USE}
          />
        </BoundedContainer>
      }
      chapter="Miss-use"
      pageNumber="16"
      footerColor="dark"
    />
  )
}

export default LogoMissUseScreen
