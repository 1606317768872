import './ButtonList.vars.css'

import styled from 'styled-components'

const ButtonListWrapper = styled.div`
  display: grid;
`
const ButtonGrid = styled.div`
  display: grid;
  margin-left: var(--btn-ml);
  grid-template-columns: repeat(var(--btn-grid-column), 280px);
  column-gap: var(--offset-32);
`

export { ButtonListWrapper, ButtonGrid }
