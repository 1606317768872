const SHADES_VIEW_CONFIG = {
  ACCENT: [
    {
      label: '5%',
      shade: 'var(--accent-t-lighten6)',
      viewSize: 'md'
    },
    {
      label: '10%',
      shade: 'var(--accent-t-lighten5)'
    },
    {
      label: '20%',
      shade: 'var(--accent-t-lighten4)'
    },
    {
      label: '32%',
      shade: 'var(--accent-t-lighten3)'
    },
    {
      label: '40%',
      shade: 'var(--accent-t-lighten2)'
    },
    {
      label: '72%',
      shade: 'var(--accent-t-lighten1)'
    }
  ],
  NEUTRAL: [
    {
      label: '5%',
      shade: 'var(--neutral-t-lighten6)'
    },
    {
      label: '10%',
      shade: 'var(--neutral-t-lighten5)'
    },
    {
      label: '20%',
      shade: 'var(--neutral-t-lighten4)'
    },
    {
      label: '32%',
      shade: 'var(--neutral-t-lighten3)'
    },
    {
      label: '40%',
      shade: 'var(--neutral-t-lighten2)'
    },
    {
      label: '64%',
      shade: 'var(--neutral-t-lighten1)',
      textColor: 'white'
    }
  ],
  WHITE: [
    {
      label: '5%',
      shade: 'var(--white-t-lighten6)',
      textColor: 'white'
    },
    {
      label: '10%',
      shade: 'var(--white-t-lighten5)',
      textColor: 'white'
    },
    {
      label: '20%',
      shade: 'var(--white-t-lighten4)',
      textColor: 'white'
    },
    {
      label: '32%',
      shade: 'var(--white-t-lighten3)',
      textColor: 'white'
    },
    {
      label: '40%',
      shade: 'var(--white-t-lighten2)',
      textColor: 'white'
    },
    {
      label: '72%',
      shade: 'var(--white-t-lighten1)'
    }
  ]
}

export default SHADES_VIEW_CONFIG
