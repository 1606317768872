import {
  AvatarScreen,
  ButtonScreen,
  CheckboxScreen,
  InputScreen,
  RadioScreen,
  SwitcherScreen
} from './ElementsContent'

import { ElementsIntroScreen } from './ElementsIntro'

export const ELEMENTS_SCREENS_ORDER = [
  ElementsIntroScreen,
  ButtonScreen,
  InputScreen,
  CheckboxScreen,
  RadioScreen,
  SwitcherScreen,
  AvatarScreen
]
