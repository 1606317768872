import styled from 'styled-components'

const FooterStyled = styled.footer`
  padding: var(--footer-py) var(--footer-px);
  display: flex;
  align-items: center;
  justify-content: ${({ pageNumber }) => (pageNumber ? 'flex-end' : 'start')};
  font-size: var(--footer-fs);
  line-height: var(--footer-lh);
  font-family: var(--footer-ff);
  font-weight: var(--footer-fw);
  z-index: var(--footer-z-index);
  color: ${({ color }) =>
    color ? `var(--footer-c-${color})` : 'var(--footer-c-dark)'};
`

export default FooterStyled
