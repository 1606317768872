import { CustomersAll, CustomerShow, CustomerEdit } from './Customer'
import {
  CustomerApplicationsAll,
  CustomerApplicationCreate,
  CustomerApplicationShow,
  CustomerApplicationEdit,
  ApplicationShow,
  ApplicationEdit,
  ApplicationsAll
} from './Application'
import {
  CustomerBrandbooksAll,
  CustomerBrandbookCreate,
  CustomerBrandbookShow,
  CustomerBrandbookEdit,
  BrandbookShow,
  BrandbookEdit,
  BrandbooksAll
} from './Brandbook'
import {
  CustomerRepresentativesAll,
  CustomerRepresentativeCreate,
  CustomerRepresentativeShow,
  CustomerRepresentativeEdit,
  RepresentativeShow,
  RepresentativeEdit,
  RepresentativesAll,
  RepresentativeCreate
} from './Representative'
import {
  CustomerDatabasesAll,
  CustomerDatabaseCreate,
  CustomerDatabaseShow,
  CustomerDatabaseEdit,
  DatabaseShow,
  DatabaseEdit
} from './Database'
import {
  CustomerFeaturesAll,
  CustomerFeatureCreate,
  CustomerFeatureShow,
  CustomerFeatureEdit,
  FeatureShow,
  FeatureEdit,
  FeaturesAll
} from './Feature'
import {
  CustomerMockupsAll,
  CustomerMockupCreate,
  CustomerMockupShow,
  CustomerMockupEdit,
  MockupShow,
  MockupEdit,
  MockupsAll
} from './Mockup'
import {
  CustomerOffersAll,
  CustomerOfferCreate,
  CustomerOfferShow,
  CustomerOfferEdit,
  OfferShow,
  OfferEdit,
  OffersAll
} from './Offer'
import {
  CustomerCustomerProfilesAll,
  CustomerCustomerProfileCreate,
  CustomerCustomerProfileShow,
  CustomerCustomerProfileEdit,
  CustomerProfileShow,
  CustomerProfileEdit,
  CustomerProfilesAll
} from './CustomerProfile'
import { PlatformShow, PlatformEdit } from './Platform'
import { RoleShow, RoleEdit, RolesAll, RoleCreate } from './Role'
import { TechnologyShow, TechnologyEdit } from './Technology'
import { CalculatorShow, CalculatorEdit } from './Calculator'
import { ColorShow, ColorEdit } from './Color'
import { DesignShow, DesignEdit, DesignsAll } from './Design'
import Dashboard from './Dashboard/Dashboard'
import PATHS from '../paths'
import Translations from './Translations'
import { UserShow } from './User'

const {
  DASHBOARD,
  USER_SHOW,
  TRANSLATIONS,
  CUSTOMERS_ALL,
  CUSTOMER_SHOW,
  CUSTOMER_APPLICATIONS_ALL,
  CUSTOMER_BRANDBOOKS_ALL,
  CUSTOMER_REPRESENTATIVES_ALL,
  CUSTOMER_DATABASES_ALL,
  CUSTOMER_FEATURES_ALL,
  CUSTOMER_MOCKUPS_ALL,
  CUSTOMER_OFFERS_ALL,
  CUSTOMER_CUSTOMER_PROFILES_ALL,
  CUSTOMER_APPLICATION_CREATE,
  CUSTOMER_BRANDBOOK_CREATE,
  CUSTOMER_REPRESENTATIVE_CREATE,
  CUSTOMER_DATABASE_CREATE,
  CUSTOMER_FEATURE_CREATE,
  CUSTOMER_MOCKUP_CREATE,
  CUSTOMER_OFFER_CREATE,
  CUSTOMER_CUSTOMER_PROFILE_CREATE,
  CUSTOMER_APPLICATION_SHOW,
  CUSTOMER_BRANDBOOK_SHOW,
  CUSTOMER_REPRESENTATIVE_SHOW,
  CUSTOMER_DATABASE_SHOW,
  CUSTOMER_FEATURE_SHOW,
  CUSTOMER_MOCKUP_SHOW,
  CUSTOMER_OFFER_SHOW,
  CUSTOMER_CUSTOMER_PROFILE_SHOW,
  CUSTOMER_APPLICATION_EDIT,
  CUSTOMER_BRANDBOOK_EDIT,
  CUSTOMER_REPRESENTATIVE_EDIT,
  CUSTOMER_DATABASE_EDIT,
  CUSTOMER_FEATURE_EDIT,
  CUSTOMER_MOCKUP_EDIT,
  CUSTOMER_OFFER_EDIT,
  CUSTOMER_CUSTOMER_PROFILE_EDIT,
  CUSTOMER_EDIT,
  APPLICATION_SHOW,
  APPLICATION_EDIT,
  APPLICATIONS_ALL,
  PLATFORM_SHOW,
  PLATFORM_EDIT,
  ROLE_SHOW,
  ROLE_EDIT,
  ROLES_ALL,
  ROLE_CREATE,
  BRANDBOOK_SHOW,
  BRANDBOOK_EDIT,
  BRANDBOOKS_ALL,
  REPRESENTATIVE_SHOW,
  REPRESENTATIVE_EDIT,
  REPRESENTATIVES_ALL,
  REPRESENTATIVE_CREATE,
  DATABASE_SHOW,
  DATABASE_EDIT,
  FEATURE_SHOW,
  FEATURE_EDIT,
  FEATURES_ALL,
  MOCKUP_SHOW,
  MOCKUP_EDIT,
  MOCKUPS_ALL,
  OFFER_SHOW,
  OFFER_EDIT,
  OFFERS_ALL,
  TECHNOLOGY_SHOW,
  TECHNOLOGY_EDIT,
  CUSTOMER_PROFILE_SHOW,
  CUSTOMER_PROFILE_EDIT,
  CUSTOMER_PROFILES_ALL,
  CALCULATOR_SHOW,
  CALCULATOR_EDIT,
  COLOR_SHOW,
  COLOR_EDIT,
  DESIGN_SHOW,
  DESIGN_EDIT,
  DESIGNS_ALL
} = PATHS.AUTHENTICATED

const MAIN_ROUTES = [
  { key: 'DASHBOARD', path: DASHBOARD, component: Dashboard, exact: true },
  { key: 'USER_SHOW', path: USER_SHOW, component: UserShow, exact: true },
  {
    key: 'TRANSLATIONS',
    path: TRANSLATIONS,
    component: Translations,
    exact: true
  },
  {
    key: 'CUSTOMER_EDIT',
    path: CUSTOMER_EDIT,
    component: CustomerEdit,
    exact: true
  },
  {
    key: 'APPLICATION_EDIT',
    path: APPLICATION_EDIT,
    component: ApplicationEdit,
    exact: true
  },
  {
    key: 'PLATFORM_EDIT',
    path: PLATFORM_EDIT,
    component: PlatformEdit,
    exact: true
  },
  { key: 'ROLE_EDIT', path: ROLE_EDIT, component: RoleEdit, exact: true },
  {
    key: 'BRANDBOOK_EDIT',
    path: BRANDBOOK_EDIT,
    component: BrandbookEdit,
    exact: true
  },
  {
    key: 'REPRESENTATIVE_EDIT',
    path: REPRESENTATIVE_EDIT,
    component: RepresentativeEdit,
    exact: true
  },
  {
    key: 'DATABASE_EDIT',
    path: DATABASE_EDIT,
    component: DatabaseEdit,
    exact: true
  },
  {
    key: 'FEATURE_EDIT',
    path: FEATURE_EDIT,
    component: FeatureEdit,
    exact: true
  },
  { key: 'MOCKUP_EDIT', path: MOCKUP_EDIT, component: MockupEdit, exact: true },
  { key: 'OFFER_EDIT', path: OFFER_EDIT, component: OfferEdit, exact: true },
  {
    key: 'TECHNOLOGY_EDIT',
    path: TECHNOLOGY_EDIT,
    component: TechnologyEdit,
    exact: true
  },
  {
    key: 'CUSTOMER_PROFILE_EDIT',
    path: CUSTOMER_PROFILE_EDIT,
    component: CustomerProfileEdit,
    exact: true
  },
  {
    key: 'CALCULATOR_EDIT',
    path: CALCULATOR_EDIT,
    component: CalculatorEdit,
    exact: true
  },
  { key: 'COLOR_EDIT', path: COLOR_EDIT, component: ColorEdit, exact: true },
  { key: 'DESIGN_EDIT', path: DESIGN_EDIT, component: DesignEdit, exact: true },
  {
    key: 'CUSTOMERS_ALL',
    path: CUSTOMERS_ALL,
    component: CustomersAll,
    exact: true
  },
  {
    key: 'CUSTOMER_SHOW',
    path: CUSTOMER_SHOW,
    component: CustomerShow,
    exact: false
  },
  {
    key: 'APPLICATION_SHOW',
    path: APPLICATION_SHOW,
    component: ApplicationShow,
    exact: true
  },
  {
    key: 'APPLICATIONS_ALL',
    path: APPLICATIONS_ALL,
    component: ApplicationsAll,
    exact: true
  },
  {
    key: 'PLATFORM_SHOW',
    path: PLATFORM_SHOW,
    component: PlatformShow,
    exact: true
  },
  { key: 'ROLE_SHOW', path: ROLE_SHOW, component: RoleShow, exact: true },
  { key: 'ROLES_ALL', path: ROLES_ALL, component: RolesAll, exact: true },
  { key: 'ROLE_CREATE', path: ROLE_CREATE, component: RoleCreate, exact: true },
  {
    key: 'BRANDBOOK_SHOW',
    path: BRANDBOOK_SHOW,
    component: BrandbookShow,
    exact: true
  },
  {
    key: 'BRANDBOOKS_ALL',
    path: BRANDBOOKS_ALL,
    component: BrandbooksAll,
    exact: true
  },
  {
    key: 'REPRESENTATIVE_SHOW',
    path: REPRESENTATIVE_SHOW,
    component: RepresentativeShow,
    exact: true
  },
  {
    key: 'REPRESENTATIVES_ALL',
    path: REPRESENTATIVES_ALL,
    component: RepresentativesAll,
    exact: true
  },
  {
    key: 'REPRESENTATIVE_CREATE',
    path: REPRESENTATIVE_CREATE,
    component: RepresentativeCreate,
    exact: true
  },
  {
    key: 'DATABASE_SHOW',
    path: DATABASE_SHOW,
    component: DatabaseShow,
    exact: true
  },
  {
    key: 'FEATURE_SHOW',
    path: FEATURE_SHOW,
    component: FeatureShow,
    exact: true
  },
  {
    key: 'FEATURES_ALL',
    path: FEATURES_ALL,
    component: FeaturesAll,
    exact: true
  },
  { key: 'MOCKUP_SHOW', path: MOCKUP_SHOW, component: MockupShow, exact: true },
  { key: 'MOCKUPS_ALL', path: MOCKUPS_ALL, component: MockupsAll, exact: true },
  { key: 'OFFER_SHOW', path: OFFER_SHOW, component: OfferShow, exact: true },
  { key: 'OFFERS_ALL', path: OFFERS_ALL, component: OffersAll, exact: true },
  {
    key: 'TECHNOLOGY_SHOW',
    path: TECHNOLOGY_SHOW,
    component: TechnologyShow,
    exact: true
  },
  {
    key: 'CUSTOMER_PROFILE_SHOW',
    path: CUSTOMER_PROFILE_SHOW,
    component: CustomerProfileShow,
    exact: true
  },
  {
    key: 'CUSTOMER_PROFILES_ALL',
    path: CUSTOMER_PROFILES_ALL,
    component: CustomerProfilesAll,
    exact: true
  },
  {
    key: 'CALCULATOR_SHOW',
    path: CALCULATOR_SHOW,
    component: CalculatorShow,
    exact: true
  },
  { key: 'COLOR_SHOW', path: COLOR_SHOW, component: ColorShow, exact: true },
  { key: 'DESIGN_SHOW', path: DESIGN_SHOW, component: DesignShow, exact: true },
  { key: 'DESIGNS_ALL', path: DESIGNS_ALL, component: DesignsAll, exact: true }
]

const CUSTOMER_ROUTES = [
  {
    key: 'CUSTOMER_APPLICATIONS_ALL',
    path: CUSTOMER_APPLICATIONS_ALL,
    component: CustomerApplicationsAll,
    exact: true
  },
  {
    key: 'CUSTOMER_BRANDBOOKS_ALL',
    path: CUSTOMER_BRANDBOOKS_ALL,
    component: CustomerBrandbooksAll,
    exact: true
  },
  {
    key: 'CUSTOMER_REPRESENTATIVES_ALL',
    path: CUSTOMER_REPRESENTATIVES_ALL,
    component: CustomerRepresentativesAll,
    exact: true
  },
  {
    key: 'CUSTOMER_DATABASES_ALL',
    path: CUSTOMER_DATABASES_ALL,
    component: CustomerDatabasesAll,
    exact: true
  },
  {
    key: 'CUSTOMER_FEATURES_ALL',
    path: CUSTOMER_FEATURES_ALL,
    component: CustomerFeaturesAll,
    exact: true
  },
  {
    key: 'CUSTOMER_MOCKUPS_ALL',
    path: CUSTOMER_MOCKUPS_ALL,
    component: CustomerMockupsAll,
    exact: true
  },
  {
    key: 'CUSTOMER_OFFERS_ALL',
    path: CUSTOMER_OFFERS_ALL,
    component: CustomerOffersAll,
    exact: true
  },
  {
    key: 'CUSTOMER_CUSTOMER_PROFILES_ALL',
    path: CUSTOMER_CUSTOMER_PROFILES_ALL,
    component: CustomerCustomerProfilesAll,
    exact: true
  },
  {
    key: 'CUSTOMER_APPLICATION_CREATE',
    path: CUSTOMER_APPLICATION_CREATE,
    component: CustomerApplicationCreate,
    exact: true
  },
  {
    key: 'CUSTOMER_BRANDBOOK_CREATE',
    path: CUSTOMER_BRANDBOOK_CREATE,
    component: CustomerBrandbookCreate,
    exact: true
  },
  {
    key: 'CUSTOMER_REPRESENTATIVE_CREATE',
    path: CUSTOMER_REPRESENTATIVE_CREATE,
    component: CustomerRepresentativeCreate,
    exact: true
  },
  {
    key: 'CUSTOMER_DATABASE_CREATE',
    path: CUSTOMER_DATABASE_CREATE,
    component: CustomerDatabaseCreate,
    exact: true
  },
  {
    key: 'CUSTOMER_FEATURE_CREATE',
    path: CUSTOMER_FEATURE_CREATE,
    component: CustomerFeatureCreate,
    exact: true
  },
  {
    key: 'CUSTOMER_MOCKUP_CREATE',
    path: CUSTOMER_MOCKUP_CREATE,
    component: CustomerMockupCreate,
    exact: true
  },
  {
    key: 'CUSTOMER_OFFER_CREATE',
    path: CUSTOMER_OFFER_CREATE,
    component: CustomerOfferCreate,
    exact: true
  },
  {
    key: 'CUSTOMER_CUSTOMER_PROFILE_CREATE',
    path: CUSTOMER_CUSTOMER_PROFILE_CREATE,
    component: CustomerCustomerProfileCreate,
    exact: true
  },
  {
    key: 'CUSTOMER_APPLICATION_SHOW',
    path: CUSTOMER_APPLICATION_SHOW,
    component: CustomerApplicationShow,
    exact: true
  },
  {
    key: 'CUSTOMER_BRANDBOOK_SHOW',
    path: CUSTOMER_BRANDBOOK_SHOW,
    component: CustomerBrandbookShow,
    exact: true
  },
  {
    key: 'CUSTOMER_REPRESENTATIVE_SHOW',
    path: CUSTOMER_REPRESENTATIVE_SHOW,
    component: CustomerRepresentativeShow,
    exact: true
  },
  {
    key: 'CUSTOMER_DATABASE_SHOW',
    path: CUSTOMER_DATABASE_SHOW,
    component: CustomerDatabaseShow,
    exact: true
  },
  {
    key: 'CUSTOMER_FEATURE_SHOW',
    path: CUSTOMER_FEATURE_SHOW,
    component: CustomerFeatureShow,
    exact: true
  },
  {
    key: 'CUSTOMER_MOCKUP_SHOW',
    path: CUSTOMER_MOCKUP_SHOW,
    component: CustomerMockupShow,
    exact: true
  },
  {
    key: 'CUSTOMER_OFFER_SHOW',
    path: CUSTOMER_OFFER_SHOW,
    component: CustomerOfferShow,
    exact: true
  },
  {
    key: 'CUSTOMER_CUSTOMER_PROFILE_SHOW',
    path: CUSTOMER_CUSTOMER_PROFILE_SHOW,
    component: CustomerCustomerProfileShow,
    exact: true
  },
  {
    key: 'CUSTOMER_APPLICATION_EDIT',
    path: CUSTOMER_APPLICATION_EDIT,
    component: CustomerApplicationEdit,
    exact: true
  },
  {
    key: 'CUSTOMER_BRANDBOOK_EDIT',
    path: CUSTOMER_BRANDBOOK_EDIT,
    component: CustomerBrandbookEdit,
    exact: true
  },
  {
    key: 'CUSTOMER_REPRESENTATIVE_EDIT',
    path: CUSTOMER_REPRESENTATIVE_EDIT,
    component: CustomerRepresentativeEdit,
    exact: true
  },
  {
    key: 'CUSTOMER_DATABASE_EDIT',
    path: CUSTOMER_DATABASE_EDIT,
    component: CustomerDatabaseEdit,
    exact: true
  },
  {
    key: 'CUSTOMER_FEATURE_EDIT',
    path: CUSTOMER_FEATURE_EDIT,
    component: CustomerFeatureEdit,
    exact: true
  },
  {
    key: 'CUSTOMER_MOCKUP_EDIT',
    path: CUSTOMER_MOCKUP_EDIT,
    component: CustomerMockupEdit,
    exact: true
  },
  {
    key: 'CUSTOMER_OFFER_EDIT',
    path: CUSTOMER_OFFER_EDIT,
    component: CustomerOfferEdit,
    exact: true
  },
  {
    key: 'CUSTOMER_CUSTOMER_PROFILE_EDIT',
    path: CUSTOMER_CUSTOMER_PROFILE_EDIT,
    component: CustomerCustomerProfileEdit,
    exact: true
  }
]

export { MAIN_ROUTES, CUSTOMER_ROUTES }
export default MAIN_ROUTES
