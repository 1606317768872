import {
  updateDocument,
  createDocument,
  getId,
  saveHasManyRelationship
} from 'services/firestore'

import { updateParentHasOneRelationship } from 'hooks'
import { Offer } from 'models'
import { message } from 'antd'
import { useHistory, useParams } from 'react-router-dom'
import { useState } from 'react'
import { useTranslations } from 'contexts/Translation'

const useActionsOfferAdvancedForm = ({ initialData, form } = {}) => {
  // [COMPONENT_STATE_HOOKS]
  const [loading, setLoading] = useState(false)

  // [ADDITIONAL_HOOKS]
  const { t } = useTranslations()
  const history = useHistory()
  const params = useParams()

  const prepareValues = async (values = {}, additionalValues = {}) => {
    const offerId = initialData?.['offer']?._id || getId('offers')
    const additionalReferences = { offerId }
    const preparedValues = {
      _id: offerId,
      ...additionalValues,
      paymentTerms: values?.['offer']?.['paymentTerms'] ?? null,
      price: values?.['offer']?.['price'] ?? null,
      dataBaseId: values?.['offer']?.['dataBaseId'] ?? null,
      featureIds: await saveHasManyRelationship(
        'features',
        values['featureIds'],
        additionalReferences
      ),
      mockupIds: await saveHasManyRelationship(
        'mockups',
        values['mockupIds'],
        additionalReferences
      ),
      technologyIds: await saveHasManyRelationship(
        'technologies',
        values['technologyIds'],
        additionalReferences
      )
    }
    if (params.customerId) preparedValues['customerId'] = params.customerId
    return preparedValues
  }

  const saveForm = async (values, callback) => {
    try {
      // Prepare data to be saved
      const data = await prepareValues(values)
      // Updating parent references
      if (params.customerId)
        await updateParentHasOneRelationship(
          params.customerId,
          'customers',
          'offerId',
          data._id
        )
      // Save data
      if (initialData) {
        await updateDocument('offers', initialData.offer?._id, data)
        message.success(t('Offer successfully updated'))
      } else {
        await createDocument('offers', data, data._id)
        message.success(t('Offer successfully created'))
      }
      // Final callback
      callback?.()
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error)
      throw new Error(t('Something went wrong during data save'))
    }
  }

  const validateForm = (values) => {
    try {
      // Prepare data to be validated
      const validationData = {
        paymentTerms: values?.['offer']?.['paymentTerms'],
        price: values?.['offer']?.['price'],
        dataBaseId: values?.['offer']?.['dataBaseId']
      }
      Offer.validationSchema.validateSync(validationData)
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error)
      throw new Error(t('Offer validation error: ') + t(error.message))
    }
  }

  const onFinish = async () => {
    if (loading) return // Avoid multiple calls

    try {
      setLoading(true)
      // Get form values
      const formValues = form.getFieldsValue()
      // Validate fields
      validateForm(formValues)
      // Final callback
      const callback = () => history.goBack()
      // Save data
      await saveForm(formValues, callback)
    } catch (error) {
      setLoading(false)
      message.error(error.message)
    }
  }

  const onReset = () => {
    form.resetFields()
    history.goBack()
  }

  return { onFinish, onReset, loading, saveForm, validateForm, prepareValues }
}

export default useActionsOfferAdvancedForm
