import { model, attr } from 'services/model'
import * as yup from 'yup'

const validationSchema = yup.object().shape({
  logoUrl: yup
    .string()
    .typeError('Field logoUrl has wrong type')
    .required('Field logoUrl is required'),
  primaryFont: yup
    .string()
    .typeError('Field primaryFont has wrong type')
    .default(null)
    .nullable(),
  secondaryFont: yup
    .string()
    .typeError('Field secondaryFont has wrong type')
    .default(null)
    .nullable(),
  shape: yup
    .string()
    .typeError('Field shape has wrong type')
    .default(null)
    .nullable(),
  colorIds: yup
    .string()
    .typeError('Field colorIds has wrong type')
    .default(null)
    .nullable(),
  brandbookConfig: yup
    .string()
    .typeError('Field brandbookConfig has wrong type')
    .default(null)
    .nullable()
})

const Brandbook = model(
  'brandbook',
  {
    logoUrl: attr('string'),
    primaryFont: attr('string'),
    secondaryFont: attr('string'),
    shape: attr('string'),
    colorIds: attr('string'),
    brandbookConfig: attr('string')
  },
  validationSchema
)

export default Brandbook
