import { Container, Row, Col, Text, Box } from '@qonsoll/react-design'
import { useGetCustomerProfileColors } from '../../hooks'
import { Tag } from 'antd'
import PropTypes from 'prop-types'
import { Spinner } from 'components'
import { useTranslations } from 'contexts/Translation'

export default function CustomerProfileColors(props) {
  const { customerProfile } = props

  // [ADDITIONAL_HOOKS]
  const [colors, loading] = useGetCustomerProfileColors(customerProfile)
  const { t } = useTranslations()

  return (
    <Container>
      <Row h="right" noGutters>
        <Col>
          {loading ? (
            <Spinner flex={1} />
          ) : colors?.length ? (
            <Box mr="-8px">
              {colors?.map((color) => (
                <Tag key={color?._id}>
                  <Text>{color?.hex}</Text>
                </Tag>
              ))}
            </Box>
          ) : (
            <Text type="secondary" variant="overline">
              {t('No colors have been added yet')}
            </Text>
          )}
        </Col>
      </Row>
    </Container>
  )
}

CustomerProfileColors.propTypes = {
  customerProfile: PropTypes.object.isRequired
}
