import { useTranslations } from 'contexts/Translation'
import PropTypes from 'prop-types'
import {
  Col,
  Container,
  Row,
  Card,
  Text,
  Divider,
  Title
} from '@qonsoll/react-design'
import { useHistory } from 'react-router-dom'
import { CardDropdown, SelectableCard } from 'components'
import { useSimpleFormActions } from 'hooks'
import { useParams } from 'react-router-dom'
import { updateParentHasOneRelationship } from 'hooks'

const CalculatorSimpleView = (props) => {
  const { calculator, onSelect, onDelete, index, isSelected, actions } = props

  // [ADDITIONAL_HOOKS]
  const { t } = useTranslations()
  const history = useHistory()
  const { handleDelete: removeDocument } = useSimpleFormActions({
    document: calculator,
    collectionName: 'calculators'
  })
  const params = useParams()

  // [HANDLERS]
  const handleOpen = () => {
    history.push(`/calculators/${calculator?._id}`)
  }
  const handleEdit = () => {
    history.push(`/calculators/${calculator?._id}/edit`)
  }
  const handleDelete = (item, index) => {
    removeDocument()
    if (params.customerId)
      updateParentHasOneRelationship(
        params.customerId,
        'customers',
        'profileId',
        item?._id,
        true
      )
  }
  const handleSelect = (e) => {
    e.stopPropagation()
    onSelect?.(calculator?._id)
  }

  return (
    <CardDropdown
      onDelete={handleDelete}
      onEdit={handleEdit}
      onDoubleClick={handleOpen}
      onClick={handleSelect}
      document={calculator}
      index={index}
      {...actions}>
      <SelectableCard isSelected={isSelected}>
        <Row
          noGutters
          justifyContent="center"
          alignItems="center"
          py="4px"
          mb={2}>
          <Col>
            <Title level={4}>{t('Calculator')}</Title>
          </Col>
        </Row>
        <Divider />
        <Row
          noGutters
          justifyContent="center"
          alignItems="center"
          py="4px"
          mb={2}>
          <Col>
            <Text>{t('Platforms')}</Text>
          </Col>
          <Col cw="auto">
            <Text
              overflow="hidden"
              textOverflow="ellipsis"
              maxWidth="250px"
              whiteSpace="nowrap">
              {calculator?.platforms}
            </Text>
          </Col>
        </Row>
        <Divider />
        <Row
          noGutters
          justifyContent="center"
          alignItems="center"
          py="4px"
          mb={2}>
          <Col>
            <Text>{t('Industry')}</Text>
          </Col>
          <Col cw="auto">
            <Text
              overflow="hidden"
              textOverflow="ellipsis"
              maxWidth="250px"
              whiteSpace="nowrap">
              {calculator?.industry}
            </Text>
          </Col>
        </Row>
        <Divider />
        <Row
          noGutters
          justifyContent="center"
          alignItems="center"
          py="4px"
          mb={2}>
          <Col>
            <Text>{t('Analogue')}</Text>
          </Col>
          <Col cw="auto">
            <Text
              overflow="hidden"
              textOverflow="ellipsis"
              maxWidth="250px"
              whiteSpace="nowrap">
              {calculator?.analogue}
            </Text>
          </Col>
        </Row>
        <Divider />
        <Row
          noGutters
          justifyContent="center"
          alignItems="center"
          py="4px"
          mb={2}>
          <Col>
            <Text>{t('Administration')}</Text>
          </Col>
          <Col cw="auto">
            <Text
              overflow="hidden"
              textOverflow="ellipsis"
              maxWidth="250px"
              whiteSpace="nowrap">
              {calculator?.administration}
            </Text>
          </Col>
        </Row>
        <Divider />
        <Row
          noGutters
          justifyContent="center"
          alignItems="center"
          py="4px"
          mb={2}>
          <Col>
            <Text>{t('Appearance')}</Text>
          </Col>
          <Col cw="auto">
            <Text
              overflow="hidden"
              textOverflow="ellipsis"
              maxWidth="250px"
              whiteSpace="nowrap">
              {calculator?.appearance}
            </Text>
          </Col>
        </Row>
        <Divider />
        <Row
          noGutters
          justifyContent="center"
          alignItems="center"
          py="4px"
          mb={2}>
          <Col>
            <Text>{t('Authentication')}</Text>
          </Col>
          <Col cw="auto">
            <Text
              overflow="hidden"
              textOverflow="ellipsis"
              maxWidth="250px"
              whiteSpace="nowrap">
              {calculator?.authentication}
            </Text>
          </Col>
        </Row>
        <Divider />
        <Row
          noGutters
          justifyContent="center"
          alignItems="center"
          py="4px"
          mb={2}>
          <Col>
            <Text>{t('Brand')}</Text>
          </Col>
          <Col cw="auto">
            <Text
              overflow="hidden"
              textOverflow="ellipsis"
              maxWidth="250px"
              whiteSpace="nowrap">
              {calculator?.brand}
            </Text>
          </Col>
        </Row>
        <Divider />
        <Row
          noGutters
          justifyContent="center"
          alignItems="center"
          py="4px"
          mb={2}>
          <Col>
            <Text>{t('External_service')}</Text>
          </Col>
          <Col cw="auto">
            <Text
              overflow="hidden"
              textOverflow="ellipsis"
              maxWidth="250px"
              whiteSpace="nowrap">
              {calculator?.external_service}
            </Text>
          </Col>
        </Row>
        <Divider />
        <Row
          noGutters
          justifyContent="center"
          alignItems="center"
          py="4px"
          mb={2}>
          <Col>
            <Text>{t('Localization')}</Text>
          </Col>
          <Col cw="auto">
            <Text
              overflow="hidden"
              textOverflow="ellipsis"
              maxWidth="250px"
              whiteSpace="nowrap">
              {calculator?.localization}
            </Text>
          </Col>
        </Row>
        <Divider />
        <Row
          noGutters
          justifyContent="center"
          alignItems="center"
          py="4px"
          mb={2}>
          <Col>
            <Text>{t('Revenue')}</Text>
          </Col>
          <Col cw="auto">
            <Text
              overflow="hidden"
              textOverflow="ellipsis"
              maxWidth="250px"
              whiteSpace="nowrap">
              {calculator?.revenue}
            </Text>
          </Col>
        </Row>
      </SelectableCard>
    </CardDropdown>
  )
}

CalculatorSimpleView.propTypes = {
  calculator: PropTypes.object,
  onSelect: PropTypes.func,
  onDelete: PropTypes.func,
  index: PropTypes.number,
  isSelected: PropTypes.bool,
  actions: PropTypes.shape({
    onEdit: PropTypes.func,
    onDelete: PropTypes.func,
    onDoubleClick: PropTypes.func,
    onClick: PropTypes.func
  })
}

export default CalculatorSimpleView
