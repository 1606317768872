import { Box } from '../../../../components'
import PropTypes from 'prop-types'
import { TechnologyView } from '../'

/**
 * It takes in an array of objects and returns a list of TechnologyView components
 * @param {array} data - Array of objects containing information about the technology
 * @returns A box with a row of technology views
 */
const TechnologyList = (props) => {
  const { data } = props

  return (
    <Box className="row">
      {data?.map(({ imageUrl, name }, index) => (
        <Box
          key={`${index}-technology`}
          className="col-sm-6 col-md-6 col-lg-4 justify-content-center align-items-center d-flex mb-sm-3 mb-md-4">
          <TechnologyView src={imageUrl} name={name} />
        </Box>
      ))}
    </Box>
  )
}

TechnologyList.propTypes = {
  data: PropTypes.array
}

export default TechnologyList
