import {
  CHECKBOX_CHECKED_LIST,
  CHECKBOX_UNCHECKED_LIST
} from '../../__constants__'
import { Checkbox, List } from '../../../../components'
import { CheckboxListStyled, CheckboxListWrapper } from './CheckboxList.styled'

/**
 * It returns a wrapper component that contains two lists of checkboxes.
 * The first list contains unchecked checkboxes, and the second list contains checked checkboxes
 * @returns A list of checkboxes
 */
const CheckboxList = () => {
  return (
    <CheckboxListWrapper>
      {/* List of Unchecked Checkboxes */}
      <CheckboxListStyled>
        <List dataSource={CHECKBOX_UNCHECKED_LIST} ListItem={Checkbox} />
      </CheckboxListStyled>
      {/* List of Checked Checkboxes */}
      <CheckboxListStyled>
        <List dataSource={CHECKBOX_CHECKED_LIST} ListItem={Checkbox} />
      </CheckboxListStyled>
    </CheckboxListWrapper>
  )
}

export default CheckboxList
