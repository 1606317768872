import { ExteriorScreen } from '../../../components'

/* Last screen brandbook*/
const ClosingScreen = () => {
  return (
    <ExteriorScreen
      key="ClosingScreen"
      title="Thank you for watching!"
      subtitle="Summer 2022"
      decoration="closing"
      breadcrumbs
    />
  )
}

export default ClosingScreen
