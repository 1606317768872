import styled from 'styled-components'

const IconSimpleViewStyled = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: ${({ iconWidth }) => iconWidth || 'var(--icon-view-width)'};
`

export default IconSimpleViewStyled
