import {
  updateDocument,
  createDocument,
  getId,
  saveHasManyRelationship,
  saveBelongsToRelationship
} from 'services/firestore'
import { useActionsDatabaseAdvancedForm } from 'domains/Database/components/DatabaseAdvancedForm/hooks'
import { useActionsOfferAdvancedForm } from 'domains/Offer/components/OfferAdvancedForm/hooks'
import { useActionsCustomerProfileAdvancedForm } from 'domains/CustomerProfile/components/CustomerProfileAdvancedForm/hooks'

import { updateParentHasOneRelationship } from 'hooks'
import { Customer } from 'models'
import { message } from 'antd'
import { useHistory, useParams } from 'react-router-dom'
import { useState } from 'react'
import { useTranslations } from 'contexts/Translation'

const useActionsCustomerAdvancedForm = ({ initialData, form } = {}) => {
  // [COMPONENT_STATE_HOOKS]
  const [loading, setLoading] = useState(false)

  // [ADDITIONAL_HOOKS]
  const { t } = useTranslations()
  const history = useHistory()
  const params = useParams()
  const {
    prepareValues: prepareDatabaseValues,
    validateForm: validateDatabaseForm
  } = useActionsDatabaseAdvancedForm({ initialData: initialData?.databaseId })
  const { prepareValues: prepareOfferValues, validateForm: validateOfferForm } =
    useActionsOfferAdvancedForm({ initialData: initialData?.offerId })
  const {
    prepareValues: prepareCustomerProfileValues,
    validateForm: validateCustomerProfileForm
  } = useActionsCustomerProfileAdvancedForm({
    initialData: initialData?.profileId
  })

  const prepareValues = async (values = {}, additionalValues = {}) => {
    const customerId = initialData?.['customer']?._id || getId('customers')
    const additionalReferences = { customerId }
    const preparedValues = {
      _id: customerId,
      ...additionalValues,
      email: values?.['customer']?.['email'] ?? null,
      name: values?.['customer']?.['name'] ?? null,
      status: values?.['customer']?.['status'] ?? null,
      applicationIds: await saveHasManyRelationship(
        'applications',
        values['applicationIds'],
        additionalReferences
      ),
      customerRepresentativeIds: await saveHasManyRelationship(
        'representatives',
        values['customerRepresentativeIds'],
        additionalReferences
      ),
      databaseId: await saveBelongsToRelationship(
        'databases',
        await prepareDatabaseValues(values['databaseId']),
        additionalReferences
      ),
      featureIds: await saveHasManyRelationship(
        'features',
        values['featureIds'],
        additionalReferences
      ),
      mockupIds: await saveHasManyRelationship(
        'mockups',
        values['mockupIds'],
        additionalReferences
      ),
      offerId: await saveBelongsToRelationship(
        'offers',
        await prepareOfferValues(values['offerId']),
        additionalReferences
      ),
      profileId: await saveBelongsToRelationship(
        'customerProfiles',
        await prepareCustomerProfileValues(values['profileId']),
        additionalReferences
      )
    }
    if (params.roleId) preparedValues['roleId'] = params.roleId
    return preparedValues
  }

  const saveForm = async (values, callback) => {
    try {
      // Prepare data to be saved
      const data = await prepareValues(values)
      // Updating parent references
      if (params.roleId)
        await updateParentHasOneRelationship(
          params.roleId,
          'roles',
          'customerId',
          data._id
        )
      // Save data
      if (initialData) {
        await updateDocument('customers', initialData.customer?._id, data)
        message.success(t('Customer successfully updated'))
      } else {
        await createDocument('customers', data, data._id)
        message.success(t('Customer successfully created'))
      }
      // Final callback
      callback?.()
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error)
      throw new Error(t('Something went wrong during data save'))
    }
  }

  const validateForm = (values) => {
    try {
      // Prepare data to be validated
      const validationData = {
        email: values?.['customer']?.['email'],
        name: values?.['customer']?.['name'],
        status: values?.['customer']?.['status']
      }
      Customer.validationSchema.validateSync(validationData)
      validateDatabaseForm(values['databaseId'])
      validateOfferForm(values['offerId'])
      validateCustomerProfileForm(values['profileId'])
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error)
      throw new Error(t('Customer validation error: ') + t(error.message))
    }
  }

  const onFinish = async () => {
    if (loading) return // Avoid multiple calls

    try {
      setLoading(true)
      // Get form values
      const formValues = form.getFieldsValue()
      // Validate fields
      validateForm(formValues)
      // Final callback
      const callback = () => history.goBack()
      // Save data
      await saveForm(formValues, callback)
    } catch (error) {
      setLoading(false)
      message.error(error.message)
    }
  }

  const onReset = () => {
    form.resetFields()
    history.goBack()
  }

  return { onFinish, onReset, loading, saveForm, validateForm, prepareValues }
}

export default useActionsCustomerAdvancedForm
