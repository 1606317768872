import styled from 'styled-components'

export const ModelWrapper = styled.div`
  column-count: var(--model-wrapper-count);
  column-gap: var(--model-wrapper-gap);
  margin: var(--model-wrapper-margin);
`

export const ModelContainer = styled.div.attrs(({ className }) => className)`
  margin-bottom: var(--model-container-margin-bottom);
  page-break-inside: auto;
  break-inside: avoid;
  -moz-break-inside: avoid;
  -webkit-break-inside: avoid;
`
