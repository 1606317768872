import { model, attr } from 'services/model'
import * as yup from 'yup'

const validationSchema = yup.object().shape({
  description: yup
    .string()
    .typeError('Field description has wrong type')
    .default(null)
    .nullable(),
  imageUrl: yup
    .string()
    .typeError('Field imageUrl has wrong type')
    .default(null)
    .nullable(),
  name: yup
    .string()
    .typeError('Field name has wrong type')
    .required('Field name is required')
})

const Design = model(
  'design',
  {
    description: attr('string'),
    imageUrl: attr('string'),
    name: attr('string')
  },
  validationSchema
)

export default Design
