import { useTranslations } from 'contexts/Translation'
import PropTypes from 'prop-types'
import { CustomerProfileSimpleForm } from 'domains/CustomerProfile/components'
import { Button, Title } from '@qonsoll/react-design'
import { Form, Input } from 'antd'
import { KEYS } from '__constants__'
import {
  useInitializeCustomerProfileAdvancedForm,
  useActionsCustomerProfileAdvancedForm
} from './hooks'
import { CalculatorAdvancedForm } from 'domains/Calculator/components'
import { ColorListWithCreate } from 'domains/Color/components'
import { DesignListWithCreate } from 'domains/Design/components'
import { RoleListWithCreate } from 'domains/Role/components'
import { ImageUploader } from 'components'

const CustomerProfileAdvancedForm = (props) => {
  const { initialData, group, showTitle } = props

  // [ADDITIONAL_HOOKS]
  const { t } = useTranslations()
  const { form } = useInitializeCustomerProfileAdvancedForm(initialData)

  // [HANDLERS]
  const handleKeyPress = (e) => {
    if (e.key === KEYS.ENTER) {
      e.preventDefault()
      onFinish()
    }
  }

  const { loading, onFinish, onReset } = useActionsCustomerProfileAdvancedForm({
    initialData,
    form
  })

  return group ? (
    <>
      {showTitle && (
        <Title level={4} mb={2}>
          {t('Customer profile')}
        </Title>
      )}
      <CustomerProfileSimpleForm
        showTitle={false}
        group={[...group, 'customerProfile']}
      />
      <CalculatorAdvancedForm group={[...group, 'calculator']} showTitle />
      <Form.Item name={[group, 'colorIds']}>
        <ColorListWithCreate />
      </Form.Item>
      <Form.Item name={[group, 'designIds']}>
        <DesignListWithCreate />
      </Form.Item>
      <Form.Item name={[group, 'roleIds']}>
        <RoleListWithCreate />
      </Form.Item>
    </>
  ) : (
    <Form
      onKeyPress={handleKeyPress}
      onFinish={onFinish}
      layout="vertical"
      autoComplete="off"
      form={form}>
      {showTitle && (
        <Title level={4} mb={2}>
          {t('Customer profile')}
        </Title>
      )}
      <CustomerProfileSimpleForm group={['customerProfile']} />
      <CalculatorAdvancedForm group={['calculatorId']} showTitle />
      <Form.Item name={['colorIds']}>
        <ColorListWithCreate />
      </Form.Item>
      <Form.Item name={['designIds']}>
        <DesignListWithCreate />
      </Form.Item>
      <Form.Item name={['roleIds']}>
        <RoleListWithCreate />
      </Form.Item>
      <Button mr={2} htmlType="button" onClick={onReset} mb={4}>
        {t('Cancel')}
      </Button>
      <Button type="primary" loading={loading} onClick={() => form.submit()}>
        {t('Save')}
      </Button>
    </Form>
  )
}

CustomerProfileAdvancedForm.propTypes = {
  initialData: PropTypes.object,
  group: PropTypes.array,
  showTitle: PropTypes.bool
}

export default CustomerProfileAdvancedForm
