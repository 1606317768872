import { ButtonList, ButtonSimpleView } from '../../../../domains'
import { ContentScreen, PositionedContainer } from '../../../../components'

import responsiveStyles from './ButtonScreen.styled'
/**
 * It returns a ContentScreen component with a bunch of props.
 * @returns Button Screen
 */
const ButtonScreen = () => {
  return (
    <ContentScreen
      key="ButtonScreen"
      chapter="Button"
      domainName="Elem."
      pageNumber="29"
      asideTopContent={
        <PositionedContainer left="10" top="0">
          <ButtonList />
        </PositionedContainer>
      }
      content={
        <PositionedContainer {...responsiveStyles}>
          <ButtonSimpleView type="primary" mega>
            Sign up
          </ButtonSimpleView>
        </PositionedContainer>
      }
    />
  )
}

export default ButtonScreen
