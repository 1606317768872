import { IMAGE_DECORATIONS } from '../../../__constants__'

const { MOODBOARD_1, MOODBOARD_2, MOODBOARD_3, MOODBOARD_4 } = IMAGE_DECORATIONS

const MOODBOARD = [
  {
    img: MOODBOARD_1,
    name: 'MOODBOARD_1',
    xs: {
      display: 'none'
    },
    sm: {
      left: '12%',
      top: '12%',
      width: '470px',
      zIndex: 1
    },
    md: {
      left: '8%',
      top: '14%',
      width: '416px'
    },
    lg: {
      left: '16%',
      top: '14%',
      width: '490px'
    },
    xl: {
      left: '12%',
      top: '14%'
    },
    xxxl: {
      left: '18%',
      top: '10%',
      width: '630px'
    }
  },
  {
    img: MOODBOARD_2,
    name: 'MOODBOARD_2',
    xs: {
      width: '260px',
      left: '27%',
      top: '8%',
      zIndex: 1
    },
    sm: {
      display: 'none'
    },
    md: {
      left: '48%',
      top: '24%',
      zIndex: 3
    },
    lg: {
      left: '44%',
      top: '46%',
      width: '322px'
    },
    xl: {
      left: '40%',
      top: '46%'
    },
    xxxl: {
      left: '42%',
      top: '36%',
      width: '380px'
    }
  },
  {
    img: MOODBOARD_3,
    name: 'MOODBOARD_3',
    xs: {
      width: '266px',
      left: '9%',
      top: '33%'
    },
    sm: {
      display: 'none'
    },
    md: {
      display: 'none'
    },
    lg: {
      left: '60%',
      top: '18%',
      width: '312px'
    },
    xl: {
      left: '58%',
      top: '16%'
    },
    xxxl: {
      left: '58%',
      top: '16%',
      width: '430px',
      zIndex: 2
    }
  },
  {
    img: MOODBOARD_4,
    name: 'MOODBOARD_4',
    xs: {
      display: 'none'
    },
    sm: {
      left: '27%',
      top: '36%'
    },
    md: {
      left: '56%',
      top: '8%',
      width: '400px'
    },
    lg: {
      left: '64%',
      top: 0,
      width: '440px'
    },
    xl: {
      left: '68%',
      top: 0
    },
    xxxl: {
      left: '68%',
      top: 0,
      width: '572px'
    }
  }
]

export default MOODBOARD
