import './AvatarList.vars.css'

import styled from 'styled-components'

const AvatarListWrapper = styled.div`
  display: grid;
  height: max-content;
  align-items: start;
`

const AvatarGrid = styled.div`
  width: 80vw;
  display: grid;
  grid-template-columns: repeat(
    var(--avatar-grid-column),
    var(--avatar-grid-width)
  );
  grid-row-gap: 8px;
`

export { AvatarListWrapper, AvatarGrid }
