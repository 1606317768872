import PropTypes from 'prop-types'

const User = (props) => {
  const { iconSize, iconColor, fill } = props

  return (
    <svg
      width={iconSize}
      height={iconSize}
      viewBox="0 0 72 72"
      fill={fill}
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M36 45C45.9411 45 54 36.9411 54 27C54 17.0589 45.9411 9 36 9C26.0589 9 18 17.0589 18 27C18 36.9411 26.0589 45 36 45Z"
        stroke={iconColor}
        strokeWidth="6"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M24 41.0998C20.4208 42.7975 17.3948 45.473 15.2714 48.8173C13.148 52.1616 12.0139 56.0383 12 59.9998C12 60.7954 12.3161 61.5585 12.8787 62.1211C13.4413 62.6837 14.2044 62.9998 15 62.9998H57C57.7956 62.9998 58.5587 62.6837 59.1213 62.1211C59.6839 61.5585 60 60.7954 60 59.9998C60.0031 56.023 58.877 52.1271 56.7526 48.7654C54.6283 45.4036 51.593 42.7141 48 41.0098"
        stroke={iconColor}
        strokeWidth="6"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

User.propTypes = {
  iconSize: PropTypes.string.isRequired,
  fill: PropTypes.string,
  iconColor: PropTypes.string
}

export default User
