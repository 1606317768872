import styled from 'styled-components'

const ColorsShadesListStyled = styled.div`
  display: flex;
  height: 100%;
  bottom: var(--colors-shades-list-b);
  right: var(--colors-shades-list-r);

  @media (max-width: 576px) {
    flex-direction: column;
    height: inherit;
  }
`

export default ColorsShadesListStyled
