import { useTranslations } from 'contexts/Translation'
import { PageWrapper, Row, Col } from '@qonsoll/react-design'
import { useParams, useHistory } from 'react-router-dom'
import { Tooltip } from 'antd'
import {
  UnorderedListOutlined,
  SortAscendingOutlined,
  AppstoreOutlined,
  PlusOutlined
} from '@ant-design/icons'
import { useStateWithStorage } from 'hooks'
import { Divider, Box } from '@qonsoll/react-design'
import { Spin, Popover, Button } from '@qonsoll/react-design'

const BrandbooksAll = (props) => {
  // [ADDITIONAL_HOOKS]
  const { t } = useTranslations()
  const history = useHistory()
  const [listView, setListView] = useStateWithStorage(
    false,
    'brandbook-list-view'
  )

  // [COMPONENT_STATE_HOOKS]

  // [COMPUTED_PROPERTIES]
  const headingProps = {
    title: t('Brandbooks'),
    titleSize: 3,
    marginBottom: 3,
    textAlign: 'left'
  }

  // [HANDLERS]

  return (
    <PageWrapper
      action={
        <Box display="flex">
          <Tooltip title={t('Open sort menu')}>
            <Popover trigger="click" placement="bottomRight">
              <Button
                shape="circle"
                type="ghost"
                mr={2}
                icon={<SortAscendingOutlined />}
              />
            </Popover>
          </Tooltip>
          <Tooltip title={t('Change list view')} placement="topRight">
            <Button
              shape="circle"
              type="ghost"
              onClick={() => setListView(!listView)}
              icon={listView ? <AppstoreOutlined /> : <UnorderedListOutlined />}
            />
          </Tooltip>
          <Divider mx={3} height="24px" alignSelf="center" type="vertical" />
          <Button
            type="primary"
            icon={<PlusOutlined />}
            onClick={() => history.push('./brandbook/create')}>
            {t('Add new brandbook')}
          </Button>
        </Box>
      }
      headingProps={headingProps}>
      <Row noGutters>
        <>BrandbookList</>
      </Row>
    </PageWrapper>
  )
}

export default BrandbooksAll
