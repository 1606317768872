import { useEffect } from 'react'
import { Form } from 'antd'

const useInitializeColorAdvancedForm = (initialData) => {
  // [ADDITIONAL_HOOKS]
  const [form] = Form.useForm()

  // [LIFECYCLE]
  useEffect(() => {
    if (initialData) {
      const { color } = initialData
      const formValues = {
        color: {
          hex: color['hex'] ?? null
        }
      }
      form.setFieldsValue(formValues)
    }
  }, [initialData, form])

  return { form }
}

export default useInitializeColorAdvancedForm
