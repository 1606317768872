import { Box } from '../../../../components'
import styled from 'styled-components'

const LogoListStyled = styled(Box)`
  max-width: var(--logo-list-max-w);
  display: flex;
  flex-wrap: wrap;
  justify-content: ${({ justifyContent }) => justifyContent};
  height: 100%;
  & .logo-combined-view {
    height: 100%;
  }
`

export default LogoListStyled
