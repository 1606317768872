import './AppIconAndroidScreen.vars.css'

const responsiveStyles = {
  // here use vars that you can overwrite it on different media size
  width: 'var(--android-logo-width)',
  height: 'var(--android-logo-height)',

  right: 'var(--android-logo-right)'
}

export default responsiveStyles
