import styled from 'styled-components'

const FeatureViewStyled = styled.div`
  display: flex;
  text-align: center;
  flex-direction: column;
  align-items: center;
  max-width: 200px;
  margin-bottom: var(--offset-24);
`

const FeatureViewImg = styled.img`
  width: var(--feature-image-size);
  height: var(--feature-image-size);
  object-fit: contain;
`

export { FeatureViewStyled, FeatureViewImg }
