import { model, attr } from 'services/model'
import * as yup from 'yup'

const validationSchema = yup.object().shape({
  platforms: yup
    .string()
    .typeError('Field platforms has wrong type')
    .default(null)
    .nullable(),
  industry: yup
    .string()
    .typeError('Field industry has wrong type')
    .default(null)
    .nullable(),
  analogue: yup
    .string()
    .typeError('Field analogue has wrong type')
    .default(null)
    .nullable(),
  administration: yup
    .string()
    .typeError('Field administration has wrong type')
    .default(null)
    .nullable(),
  appearance: yup
    .string()
    .typeError('Field appearance has wrong type')
    .default(null)
    .nullable(),
  authentication: yup
    .string()
    .typeError('Field authentication has wrong type')
    .default(null)
    .nullable(),
  brand: yup
    .string()
    .typeError('Field brand has wrong type')
    .default(null)
    .nullable(),
  external_service: yup
    .string()
    .typeError('Field external_service has wrong type')
    .default(null)
    .nullable(),
  localization: yup
    .string()
    .typeError('Field localization has wrong type')
    .default(null)
    .nullable(),
  revenue: yup
    .string()
    .typeError('Field revenue has wrong type')
    .default(null)
    .nullable()
})

const Calculator = model(
  'calculator',
  {
    platforms: attr('string'),
    industry: attr('string'),
    analogue: attr('string'),
    administration: attr('string'),
    appearance: attr('string'),
    authentication: attr('string'),
    brand: attr('string'),
    external_service: attr('string'),
    localization: attr('string'),
    revenue: attr('string')
  },
  validationSchema
)

export default Calculator
