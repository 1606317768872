import './ContentScreen.vars.css'

import {
  ContentScreenStyled,
  ContentStyled,
  PageContainerStyled,
  ScreenContainerStyled
} from './ContentScreen.styled'
import { Footer, Header } from '../../../components'

import { Aside } from './components'
import PropTypes from 'prop-types'

/**
 * It returns a div with two children, one of which is a div with a child of a Typography component,
 * and the other is a div with a child of a Text component
 * @param {string} chapter - name of screen
 * @param {node} content - content witch will display in left side
 * @param {string} domainName - domain name title
 * @param {node} asideTopContent - content witch will display in sidebar above divider
 * @param {string} breadcrumbs - heder of screen
 * @param {string} breadcrumbsColor - color of breadcrumps
 * @param {string} footerColor - color of footer
 * @param {string} pageNumber- number of screen
 * @param {boolean} positionOuter- decide who will be relative for content screen or page container
 * @returns Universal component witch can use in all content screen  paste component witch you need
 */
const ContentScreen = (props) => {
  const {
    chapter,
    content,
    domainName,
    asideTopContent,
    footerColor,
    pageNumber,
    positionOuter,
    inverse,
    breadcrumbsColor,
    asideStyle
  } = props

  return (
    <ContentScreenStyled inverse={inverse}>
      <Header breadcrumbsColor={breadcrumbsColor} />
      {/* layoutOuter decide how content will be repositioned 
      if it true relative for content will be screen 
      if it false relative will be screen wrapper   */}
      {positionOuter && content}
      <ScreenContainerStyled bounded>
        <PageContainerStyled>
          {/* in Aside we paste information 
          witch must display buy the left side */}
          <Aside
            asideStyle={asideStyle}
            inverse={inverse}
            chapter={chapter}
            domainName={domainName}
            // Props witch use to paste a component on top divider
            asideTopContent={asideTopContent}
          />
          {/* here a content witch position by page container */}
          {!positionOuter && <ContentStyled>{content}</ContentStyled>}
        </PageContainerStyled>
      </ScreenContainerStyled>

      <Footer pageNumber={pageNumber} color={footerColor} />
    </ContentScreenStyled>
  )
}

ContentScreen.propTypes = {
  chapter: PropTypes.string,
  content: PropTypes.node,
  domainName: PropTypes.string,
  asideTopContent: PropTypes.node,
  footerColor: PropTypes.string,
  pageNumber: PropTypes.string,
  breadcrumbsColor: PropTypes.string,
  positionOuter: PropTypes.bool,
  asideStyle: PropTypes.object,
  inverse: PropTypes.bool
}

export default ContentScreen
