import PropTypes from 'prop-types'

const Home = (props) => {
  const { iconSize, iconColor, fill } = props

  return (
    <svg
      width={iconSize}
      height={iconSize}
      viewBox="0 0 72 72"
      fill={fill}
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M63 36L36 9L9 36"
        stroke={iconColor}
        strokeWidth="6"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M57 30V60.9C56.9466 61.508 56.6547 62.0701 56.1881 62.4636C55.7216 62.8571 55.1183 63.0499 54.51 63H42.9V42.3H29.1V63H17.49C16.8817 63.0499 16.2784 62.8571 15.8119 62.4636C15.3453 62.0701 15.0534 61.508 15 60.9V30"
        stroke={iconColor}
        strokeWidth="6"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

Home.propTypes = {
  iconSize: PropTypes.string.isRequired,
  fill: PropTypes.string,
  iconColor: PropTypes.string
}
export default Home
