import styled, { css } from 'styled-components'

const avatarSizes = (size) => {
  const avatarSize = size || 'md'

  return css`
    height: var(--avatar-size-${avatarSize});
    width: var(--avatar-size-${avatarSize});
  `
}
const avatarTextSizes = (size) => {
  const avatarSize = size || 'md'

  return css`
    line-height: var(--avatar-lh-${avatarSize});
    font-size: var(--avatar-fs-${avatarSize});
  `
}

const avatarStates = (state) => {
  const avatarState = state || 'default'

  return css`
    box-shadow: var(--avatar-bs-${avatarState});
    background-color: var(--avatar-bg-${avatarState});
    outline: var(--avatar-outline-${avatarState});
  `
}

const AvatarStyled = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  border-radius: var(--avatar-br);
  outline-offset: var(--avatar-outline-offset);
  ${({ size }) => avatarSizes(size)};
  ${({ state }) => avatarStates(state)};
  transform: ${({ mega }) => mega && 'var(--avatar-transform-scale)'};

  &:hover {
    background-color: var(--avatar-bg-hover);
    box-shadow: var(--avatar-bs-hover);
    outline: var(--avatar-outline-hover);
  }
  &:active {
    background-color: var(--avatar-bg-active);
    box-shadow: var(--avatar-bs-active);
    outline: var(--avatar-outline-active);
  }
  &:focus {
    background-color: var(--avatar-bg-selected);
    box-shadow: var(--avatar-bs-selected);
    outline: var(--avatar-outline-selected);
  }
`

const AvatarTextStyled = styled.span`
  ${({ size }) => avatarTextSizes(size)}
  color: var(--avatar-text-c);
  text-transform: uppercase;
  font-weight: var(--avatar-fw);
  font-family: var(--avatar-ff);
`

const AvatarImageStyled = styled.img.attrs({
  alt: 'avatar-image'
})`
  ${({ size }) => avatarSizes(size)};
  object-fit: cover;
  border-radius: var(--br-full);
`

export { AvatarImageStyled, AvatarTextStyled, AvatarStyled }
