import { useEffect, useMemo, useState } from 'react'

const useGetImageSizes = (imageRef, width, height) => {
  // [COMPONENT_STATE_HOOKS]
  const [imageAspectRatio, setImageAspectRatio] = useState(null)
  const [imageWidth, setImageWidth] = useState(null)
  const [imageHeight, setImageHeight] = useState(null)

  // [USE_EFFECTS]
  useEffect(() => {
    // Set image height or width based on its aspect-ratio
    if (imageRef) {
      const aspect =
        imageRef?.current?.offsetHeight / imageRef?.current?.offsetWidth
      setImageAspectRatio(aspect)

      if (imageAspectRatio < 1) {
        setImageWidth(width)
      } else if (imageAspectRatio > 1) {
        setImageHeight(height)
      } else {
        setImageWidth(width)
        setImageHeight(height)
      }
    }
  }, [imageRef, imageWidth, imageHeight, imageAspectRatio])

  return useMemo(() => [imageWidth, imageHeight], [imageWidth, imageHeight])
}

export default useGetImageSizes
