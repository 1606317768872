import { useInView } from 'react-cool-inview'

/**
 * It adds an active class to the navigation item when the screen is in view
 * and remove active class from screen in section is no in view
 * @param id - The id of the current section
 * @returns A function that takes an id as an argument.
 */
const useScreensNavigation = (id) => {
  const { observe } = useInView({
    threshold: 0.5,
    onEnter: () =>
      document.getElementById(`navigation-${id}`)?.classList.add('active'),
    onLeave: () =>
      document.getElementById(`navigation-${id}`)?.classList.remove('active')
  })

  return observe
}
export default useScreensNavigation
