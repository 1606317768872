const INTRO_SCREEN_HEADER_POSITION = {
  dividerTop: {
    left: ['19%', '19%', '19%', '19%', '27%'],
    top: '0'
  },
  title: {
    left: ['-6%', '-2%', '-5%', '-5%', '-4%', '-3%'],
    top: ['12%', '19%', '17%', '17%', '19%']
  },
  dividerBottom: {
    top: ['24%', '36%', '37%', '42%', '51%'],
    left: ['19%', '19%', '19%', '19%', '27%']
  },
  caption: {
    top: ['36%', '58%', '60%', '67%', '74%'],
    left: ['22%', '19%', '19%', '19%', '27%']
  }
}

export default INTRO_SCREEN_HEADER_POSITION
