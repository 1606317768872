import styled from 'styled-components'

const NavigationDotsStyled = styled.ul`
  position: fixed;
  right: var(--navigation-dots-offset);
  z-index: 1;
  top: 50%;
  transform: translateY(-50%);
  display: block;
`

const NavigationDotStyled = styled.li`
  width: var(--navigation-dot-size);
  height: var(--navigation-dot-size);
  border-radius: 50%;
  border: 2px solid var(--gray);
  background: transparent;
  list-style: none;
  margin-bottom: 20px;
  cursor: pointer;
  transition: all 0.6s ease;
  position: relative;
  &.active {
    border-color: var(--accent);
    transition: all 0.6s ease;
  }
`

const NavigationDotTooltip = styled.div`
  position: absolute;
  top: -20px;
  right: var(--navigation-tooltip-offset);
  background-color: var(--neutral);
  padding: 8px 20px;
  width: max-content;
  display: none;
  opacity: 0;
  border-radius: 4px;
  animation: showTooltip 0.2s linear forwards;

  @keyframes showTooltip {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
`

export { NavigationDotStyled, NavigationDotsStyled, NavigationDotTooltip }
