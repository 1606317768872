import PropTypes from 'prop-types'

const Help = (props) => {
  const { iconSize, iconColor, fill } = props

  return (
    <svg
      width={iconSize}
      height={iconSize}
      viewBox="0 0 72 72"
      fill={fill}
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M63 36C63 41.3401 61.4165 46.5603 58.4497 51.0004C55.4829 55.4405 51.2661 58.9012 46.3325 60.9447C41.3989 62.9883 35.9701 63.523 30.7326 62.4812C25.4951 61.4394 20.6841 58.8679 16.9081 55.0919C13.1321 51.3159 10.5606 46.5049 9.51881 41.2674C8.47701 36.03 9.0117 30.6012 11.0553 25.6675C13.0988 20.7339 16.5595 16.5171 20.9996 13.5503C25.4397 10.5835 30.6599 9 36 9C43.1609 9 50.0284 11.8446 55.0919 16.9081C60.1554 21.9716 63 28.8392 63 36V36ZM36 24C33.6266 24 31.3066 24.7038 29.3332 26.0224C27.3598 27.3409 25.8217 29.2151 24.9135 31.4078C24.0052 33.6005 23.7676 36.0133 24.2306 38.3411C24.6936 40.6689 25.8365 42.8071 27.5147 44.4853C29.193 46.1635 31.3312 47.3064 33.6589 47.7694C35.9867 48.2324 38.3995 47.9948 40.5922 47.0866C42.7849 46.1783 44.6591 44.6402 45.9776 42.6668C47.2962 40.6935 48 38.3734 48 36C48 32.8174 46.7357 29.7652 44.4853 27.5147C42.2349 25.2643 39.1826 24 36 24V24Z"
        stroke={iconColor}
        strokeWidth="6"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M43.2298 10.7402L39.5098 23.7002"
        stroke={iconColor}
        strokeWidth="6"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M28.7695 10.7402L32.4895 23.7002"
        stroke={iconColor}
        strokeWidth="6"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M61.2598 43.2298L48.2998 39.5098"
        stroke={iconColor}
        strokeWidth="6"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M61.2598 28.77L48.2998 32.49"
        stroke={iconColor}
        strokeWidth="6"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M28.7695 61.2598L32.4895 48.2998"
        stroke={iconColor}
        strokeWidth="6"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M43.2298 61.2598L39.5098 48.2998"
        stroke={iconColor}
        strokeWidth="6"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10.7402 28.77L23.7002 32.49"
        stroke={iconColor}
        strokeWidth="6"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10.7402 43.2298L23.7002 39.5098"
        stroke={iconColor}
        strokeWidth="6"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

Help.propTypes = {
  iconSize: PropTypes.string.isRequired,
  fill: PropTypes.string,
  iconColor: PropTypes.string
}
export default Help
