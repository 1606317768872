const TIMELINE = [
  {
    step: 'Utvelgelse',
    description: 'Timeline selection'
  },
  {
    step: 'Tilbud og spesifikasjon',
    description: 'Timeline specification '
  },
  {
    step: 'Oppstart',
    description: 'Timeline startup '
  },
  {
    step: 'Utvikling',
    description: 'Timeline development'
  },
  {
    step: 'Lansering',
    description: 'Timeline launch'
  }
]

export default TIMELINE
