const AVATAR_SIZES = {
  title: 'Size',
  avatar: [
    {
      name: 'Large',
      size: 'lg',
      userInitials: 'AA'
    },
    {
      name: 'Default',
      size: 'md',
      userInitials: 'AA'
    },
    {
      name: 'Small',
      size: 'sm',
      userInitials: 'AA'
    },
    {
      name: 'Extra small',
      size: 'xs',
      userInitials: 'AA'
    }
  ]
}

export default AVATAR_SIZES
