import { updateDocument, createDocument, getId } from 'services/firestore'

import { updateParentHasOneRelationship } from 'hooks'
import { Platform } from 'models'
import { message } from 'antd'
import { useHistory, useParams } from 'react-router-dom'
import { useState } from 'react'
import { useTranslations } from 'contexts/Translation'

const useActionsPlatformAdvancedForm = ({ initialData, form } = {}) => {
  // [COMPONENT_STATE_HOOKS]
  const [loading, setLoading] = useState(false)

  // [ADDITIONAL_HOOKS]
  const { t } = useTranslations()
  const history = useHistory()
  const params = useParams()

  const prepareValues = async (values = {}, additionalValues = {}) => {
    const platformId = initialData?.['platform']?._id || getId('platforms')
    const additionalReferences = { platformId }
    const preparedValues = {
      _id: platformId,
      ...additionalValues,
      name: values?.['platform']?.['name'] ?? null
    }
    if (params.applicationId)
      preparedValues['applicationId'] = params.applicationId
    return preparedValues
  }

  const saveForm = async (values, callback) => {
    try {
      // Prepare data to be saved
      const data = await prepareValues(values)
      // Updating parent references
      if (params.applicationId)
        await updateParentHasOneRelationship(
          params.applicationId,
          'applications',
          'platformId',
          data._id
        )
      // Save data
      if (initialData) {
        await updateDocument('platforms', initialData.platform?._id, data)
        message.success(t('Platform successfully updated'))
      } else {
        await createDocument('platforms', data, data._id)
        message.success(t('Platform successfully created'))
      }
      // Final callback
      callback?.()
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error)
      throw new Error(t('Something went wrong during data save'))
    }
  }

  const validateForm = (values) => {
    try {
      // Prepare data to be validated
      const validationData = {
        name: values?.['platform']?.['name']
      }
      Platform.validationSchema.validateSync(validationData)
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error)
      throw new Error(t('Platform validation error: ') + t(error.message))
    }
  }

  const onFinish = async () => {
    if (loading) return // Avoid multiple calls

    try {
      setLoading(true)
      // Get form values
      const formValues = form.getFieldsValue()
      // Validate fields
      validateForm(formValues)
      // Final callback
      const callback = () => history.goBack()
      // Save data
      await saveForm(formValues, callback)
    } catch (error) {
      setLoading(false)
      message.error(error.message)
    }
  }

  const onReset = () => {
    form.resetFields()
    history.goBack()
  }

  return { onFinish, onReset, loading, saveForm, validateForm, prepareValues }
}

export default useActionsPlatformAdvancedForm
