import './IconList.vars.css'

import IconListStyled from './IconList.styled'
import { IconSimpleView } from '../../../../domains'
import PropTypes from 'prop-types'
import { useMediaQuery } from 'hooks'

/**
 * It takes in an array of icons, and returns a styled list of IconSimpleView components
 * @param {array} icons - list of icons names
 * @param {string} justify - controls the distribution of space between and around content elements
 */
const IconList = (props) => {
  const { icons, responsiveStyles } = props

  const isBreakpointSm = useMediaQuery('(max-width:576px)')
  const isBreakpointMd = useMediaQuery('(max-width:1024px)')
  const iconAmount = isBreakpointSm ? '6' : isBreakpointMd ? '10' : '16'

  return (
    <IconListStyled {...responsiveStyles}>
      {icons.map(
        (item, index) =>
          index < iconAmount && (
            <IconSimpleView
              name={item}
              key={`${index}-icon-view`}
              responsiveStyles={responsiveStyles}
            />
          )
      )}
    </IconListStyled>
  )
}

IconList.propTypes = {
  icons: PropTypes.array.isRequired,
  responsiveStyles: PropTypes.object,
  justify: PropTypes.string
}

export default IconList
