import { useTranslations } from 'contexts/Translation'
import PropTypes from 'prop-types'
import { Tabs, Card } from 'antd'
import { StickyContainer, Sticky } from 'react-sticky'
import { Container, Row, Col, Spin, Divider, Text } from '@qonsoll/react-design'
import { useParams, useHistory, useLocation } from 'react-router-dom'
import { useDocument } from 'hooks'
import { useStateWithStorage } from 'hooks'

const CalculatorAdvancedView = (props) => {
  // [COMPONENT_STATE_HOOKS]
  const [activeTab, setActiveTab] = useStateWithStorage(
    null,
    'calculator-advanced-view-active-tab'
  )

  // [ADDITIONAL_HOOKS]
  const { t } = useTranslations()
  const history = useHistory()
  const params = useParams()
  const location = useLocation()
  const { calculatorId } = params
  const [calculator, loading] = useDocument({
    ref: `calculators/${calculatorId}`
  })

  // [COMPUTED_PROPERTIES]
  const { TabPane } = Tabs

  return loading ? (
    <Spin />
  ) : (
    <Container>
      <Container mb={4}>
        <Card>
          <Row noGutters v="center">
            <Col h="left">
              <Text variant="overline" type="secondary">
                {t('Platforms')}:
              </Text>
            </Col>
            <Col h="right">
              <Text>{calculator?.platforms}</Text>
            </Col>
            <Divider my={2} />
          </Row>
          <Row noGutters v="center">
            <Col h="left">
              <Text variant="overline" type="secondary">
                {t('Industry')}:
              </Text>
            </Col>
            <Col h="right">
              <Text>{calculator?.industry}</Text>
            </Col>
            <Divider my={2} />
          </Row>
          <Row noGutters v="center">
            <Col h="left">
              <Text variant="overline" type="secondary">
                {t('Analogue')}:
              </Text>
            </Col>
            <Col h="right">
              <Text>{calculator?.analogue}</Text>
            </Col>
            <Divider my={2} />
          </Row>
          <Row noGutters v="center">
            <Col h="left">
              <Text variant="overline" type="secondary">
                {t('Administration')}:
              </Text>
            </Col>
            <Col h="right">
              <Text>{calculator?.administration}</Text>
            </Col>
            <Divider my={2} />
          </Row>
          <Row noGutters v="center">
            <Col h="left">
              <Text variant="overline" type="secondary">
                {t('Appearance')}:
              </Text>
            </Col>
            <Col h="right">
              <Text>{calculator?.appearance}</Text>
            </Col>
            <Divider my={2} />
          </Row>
          <Row noGutters v="center">
            <Col h="left">
              <Text variant="overline" type="secondary">
                {t('Authentication')}:
              </Text>
            </Col>
            <Col h="right">
              <Text>{calculator?.authentication}</Text>
            </Col>
            <Divider my={2} />
          </Row>
          <Row noGutters v="center">
            <Col h="left">
              <Text variant="overline" type="secondary">
                {t('Brand')}:
              </Text>
            </Col>
            <Col h="right">
              <Text>{calculator?.brand}</Text>
            </Col>
            <Divider my={2} />
          </Row>
          <Row noGutters v="center">
            <Col h="left">
              <Text variant="overline" type="secondary">
                {t('External_service')}:
              </Text>
            </Col>
            <Col h="right">
              <Text>{calculator?.external_service}</Text>
            </Col>
            <Divider my={2} />
          </Row>
          <Row noGutters v="center">
            <Col h="left">
              <Text variant="overline" type="secondary">
                {t('Localization')}:
              </Text>
            </Col>
            <Col h="right">
              <Text>{calculator?.localization}</Text>
            </Col>
            <Divider my={2} />
          </Row>
          <Row noGutters v="center">
            <Col h="left">
              <Text variant="overline" type="secondary">
                {t('Revenue')}:
              </Text>
            </Col>
            <Col h="right">
              <Text>{calculator?.revenue}</Text>
            </Col>
            <Divider my={2} />
          </Row>
        </Card>
      </Container>
    </Container>
  )
}

CalculatorAdvancedView.propTypes = {}

export default CalculatorAdvancedView
