import { doc, getDoc } from 'firebase/firestore'
import { useEffect, useMemo, useState } from 'react'

import { firestore } from 'services/firebase'

const useGetDocumentsByIds = ({
  entity,
  collectionName,
  fieldName,
  entityIds
}) => {
  // [COMPONENTS_STATE_HOOKS]
  const [documentsData, setDocumentsData] = useState([])
  const [documentsLoading, setDocumentsLoading] = useState(false)
  const [documentsError, setDocumentsError] = useState(false)

  // [USE_EFFECT]
  useEffect(() => {
    const fetchDocumentsByIds = async ({
      collectionName,
      entity,
      fieldName,
      entityIds,
      isIdsFromObject,
      isOnlyIds
    }) => {
      setDocumentsLoading(true)

      const promises = []
      try {
        if (isIdsFromObject) {
          entity?.[fieldName]?.forEach((docId) => {
            const docRef = doc(firestore, collectionName, docId)
            promises.push(getDoc(docRef))
          })
        } else if (isOnlyIds) {
          entityIds?.forEach((docId) => {
            const docRef = doc(firestore, collectionName, docId)
            promises.push(getDoc(docRef))
          })
        } else {
          return promises
        }
        // After receiving promises - resolve them
        const response = await Promise.allSettled(promises)
        // Extract features data from each promise
        const documentsData = response.map((doc) => doc?.value?.data())
        setDocumentsData(documentsData)
        setDocumentsLoading(false)
      } catch (error) {
        console.error(error)
        setDocumentsError(error)
        setDocumentsLoading(false)
      }
      setDocumentsLoading(false)
    }

    const isIdsFromObject = collectionName && entity && fieldName
    const isOnlyIds = collectionName && entityIds

    if (isIdsFromObject || isOnlyIds) {
      fetchDocumentsByIds({
        entity,
        collectionName,
        fieldName,
        entityIds,
        isIdsFromObject,
        isOnlyIds
      })
    }
  }, [entity, collectionName, fieldName, entityIds])

  return useMemo(
    () => [documentsData, documentsLoading, documentsError],
    [documentsData, documentsLoading, documentsError]
  )
}

export default useGetDocumentsByIds
