import { useTranslations } from 'contexts/Translation'
import PropTypes from 'prop-types'
import { CustomerSimpleForm } from 'domains/Customer/components'
import { Button, Title } from '@qonsoll/react-design'
import { Form } from 'antd'
import { KEYS } from '__constants__'
import {
  useInitializeCustomerAdvancedForm,
  useActionsCustomerAdvancedForm
} from './hooks'
import { ApplicationListWithCreate } from 'domains/Application/components'
import { RepresentativeListWithCreate } from 'domains/Representative/components'
import { DatabaseAdvancedForm } from 'domains/Database/components'
import { FeatureListWithCreate } from 'domains/Feature/components'
import { MockupListWithCreate } from 'domains/Mockup/components'
import { OfferAdvancedForm } from 'domains/Offer/components'
import { CustomerProfileAdvancedForm } from 'domains/CustomerProfile/components'

const CustomerAdvancedForm = (props) => {
  const { initialData, group, showTitle } = props

  // [ADDITIONAL_HOOKS]
  const { t } = useTranslations()
  const { form } = useInitializeCustomerAdvancedForm(initialData)

  // [HANDLERS]
  const handleKeyPress = (e) => {
    if (e.key === KEYS.ENTER) {
      e.preventDefault()
      onFinish()
    }
  }

  const { loading, onFinish, onReset } = useActionsCustomerAdvancedForm({
    initialData,
    form
  })

  return group ? (
    <>
      {showTitle && (
        <Title level={4} mb={2}>
          {t('Customer')}
        </Title>
      )}
      <CustomerSimpleForm showTitle={false} group={[...group, 'customer']} />
      <Form.Item name={[group, 'applicationIds']}>
        <ApplicationListWithCreate />
      </Form.Item>
      <Form.Item name={[group, 'customerRepresentativeIds']}>
        <RepresentativeListWithCreate />
      </Form.Item>
      <DatabaseAdvancedForm group={[...group, 'database']} showTitle />
      <Form.Item name={[group, 'featureIds']}>
        <FeatureListWithCreate />
      </Form.Item>
      <Form.Item name={[group, 'mockupIds']}>
        <MockupListWithCreate />
      </Form.Item>
      <OfferAdvancedForm group={[...group, 'offer']} showTitle />
      <CustomerProfileAdvancedForm
        group={[...group, 'customerProfile']}
        showTitle
      />
    </>
  ) : (
    <Form
      onKeyPress={handleKeyPress}
      onFinish={onFinish}
      layout="vertical"
      autoComplete="off"
      form={form}>
      {showTitle && (
        <Title level={4} mb={2}>
          {t('Customer')}
        </Title>
      )}
      <CustomerSimpleForm group={['customer']} />
      <Form.Item name={['applicationIds']}>
        <ApplicationListWithCreate />
      </Form.Item>
      <Form.Item name={['customerRepresentativeIds']}>
        <RepresentativeListWithCreate />
      </Form.Item>
      <DatabaseAdvancedForm group={['databaseId']} showTitle />
      <Form.Item name={['featureIds']}>
        <FeatureListWithCreate />
      </Form.Item>
      <Form.Item name={['mockupIds']}>
        <MockupListWithCreate />
      </Form.Item>
      <OfferAdvancedForm group={['offerId']} showTitle />
      <CustomerProfileAdvancedForm group={['profileId']} showTitle />
      <Button mr={2} htmlType="button" onClick={onReset} mb={4}>
        {t('Cancel')}
      </Button>
      <Button type="primary" loading={loading} onClick={() => form.submit()}>
        {t('Save')}
      </Button>
    </Form>
  )
}

CustomerAdvancedForm.propTypes = {
  initialData: PropTypes.object,
  group: PropTypes.array,
  showTitle: PropTypes.bool
}

export default CustomerAdvancedForm
