import { useMemo } from 'react'
import { useDocument, useLoading } from 'hooks'
import {} from 'domains/Design/hooks'

const useGetDesignInitialValues = (id) => {
  // [DATA_FETCH]
  const [design, designLoading] = useDocument({
    ref: id ? `designs/${id}` : null
  })

  // [COMPUTED_PROPERTIES]
  const initialValues = useMemo(
    () => ({
      design
    }),
    [design]
  )
  const loadings = useMemo(
    () => (id ? [!design, designLoading] : []),
    [id, design, designLoading]
  )

  // [ADDITIONAL_HOOKS]
  const loading = useLoading(loadings)

  return [initialValues, loading]
}

export default useGetDesignInitialValues
