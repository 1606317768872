import {
  Avatar,
  ContentScreen,
  PositionedContainer
} from '../../../../components'
import { avatarListStyles, avatarStyles } from './AvatarScreen.styled'

import { AvatarList } from '../../../../domains'

/**
 * It returns a ContentScreen component with a bunch of props.
 * @returns Avatar Screen
 */
const AvatarScreen = () => {
  return (
    <ContentScreen
      key="AvatarScreen"
      chapter="Avatar"
      domainName="Elem."
      pageNumber="34"
      asideTopContent={
        <PositionedContainer {...avatarListStyles}>
          <AvatarList />
        </PositionedContainer>
      }
      content={
        <PositionedContainer {...avatarStyles}>
          <Avatar userInitials="OP" mega />
        </PositionedContainer>
      }
    />
  )
}

export default AvatarScreen
