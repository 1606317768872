import { ContentItemWrapper, NumberWrapper } from './ContentItem.styled'

import PropTypes from 'prop-types'
import { Text } from '../../../../components'

/**
 * Return Item of content, on Table of Contents
 * @param {string} number - Number in presentation
 * @param {string} title - Title of presentation
 * @returns A React Component - ContentItem with number and title
 */
const ContentItem = (props) => {
  const { number, title } = props
  return (
    <ContentItemWrapper>
      <NumberWrapper>
        <Text ff="primary" variant="subtitle2" color="accent">
          {number}
        </Text>
      </NumberWrapper>
      <Text ff="primary" variant="subtitle1" color="white">
        {title}
      </Text>
    </ContentItemWrapper>
  )
}

ContentItem.propTypes = {
  number: PropTypes.string,
  title: PropTypes.string
}

export default ContentItem
