import styled from 'styled-components'

const SwitcherStyled = styled.label`
  position: relative;
  display: inline-block;
  transform: ${({ mega }) => mega && 'var(--switcher-transform-scale)'};
  width: var(--switcher-width-default);
  height: var(--switcher-height-default);
  border-radius: var(--switcher-br-default);

  input {
    opacity: 0;
    width: 0;
    height: 0;
  }

  input:checked + .slider {
    background-color: var(--switcher-bg-active);
  }

  input:checked + .slider:before {
    -webkit-transform: var(--switcher-slider-transform-default);
    -ms-transform: var(--switcher-slider-transform-default);
    transform: var(--switcher-slider-transform-default);
  }

  .slider {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: var(--switcher-bg-default);
    -webkit-transition: var(--switcher-transition);
    transition: var(--switcher-transition);
    border-radius: inherit;
    cursor: ${({ checked }) => (checked ? 'default' : 'pointer')};
    &:before {
      position: absolute;
      content: '';
      border-radius: var(--switcher-slider-br);
      background-color: var(--switcher-slider-bg);
      -webkit-transition: var(--switcher-transition);
      transition: var(--switcher-transition);
      width: var(--switcher-slider-width-default);
      height: var(--switcher-slider-height-default);
      left: var(--switcher-slider-left-default);
      bottom: var(--switcher-slider-bottom-default);
    }
  }
`

export default SwitcherStyled
