import { useEffect, useState } from 'react'
import { getDocument } from 'services/firestore'

const useGetOfferTechnologies = (offer) => {
  const [technologies, setTechnologies] = useState([])
  const [loading, setLoading] = useState(!!offer?.technologyIds?.length)

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true)
      const promises = offer.technologyIds.map((technology) =>
        getDocument('technologies', technology)
      )
      const data = await Promise.all(promises)
      setTechnologies(data)
      setLoading(false)
    }

    offer?.technologyIds?.length && fetchData()
  }, [offer])

  return [technologies, loading]
}

export default useGetOfferTechnologies
