import { ContentScreen, Img, PositionedContainer } from '../../../../components'

import { AppIconLogo } from '../../../../domains'
import { BRANDBOOK_CONFIG_REDUCER_CASES } from 'brandbook/__constants__'
import { IMAGE_DECORATIONS } from '../../../../__constants__'
import responsiveStyles from './AppIconAndroidScreen.styled'
import { useBrandbookConfig } from 'brandbook/contexts/BrandbookConfig'
import { useMediaQuery } from 'hooks'

/**
 * It returns a ContentScreen component with a PositionedContainer component that contains an
 * AppIconPhone component
 * @returns A ContentScreen component with the following props:
 *   - key: "AppIconAndroidScreen"
 *   - chapter: "Android"
 *   - domainName: "App icon."
 *   - pageNumber: "27"
 *   - content: A PositionedContainer component with the following props:
 */
const AppIconAndroidScreen = () => {
  const { ANDROID } = IMAGE_DECORATIONS

  // [ADDITIONAL_HOOKS]
  const { brandbookConfigData } = useBrandbookConfig()

  const isBreakpoint = useMediaQuery('(max-width: 576px)')
  const headerColor = isBreakpoint ? 'white' : 'dark'

  return (
    <ContentScreen
      positionOuter
      key="AppIconAndroidScreen"
      chapter="Android"
      domainName="App icon."
      pageNumber="27"
      breadcrumbsColor={headerColor}
      content={
        <PositionedContainer {...responsiveStyles}>
          <Img {...responsiveStyles} src={ANDROID} alt="android image" />
          <AppIconLogo
            reducerType={BRANDBOOK_CONFIG_REDUCER_CASES.SET_APP_ANDROID}
            logo={brandbookConfigData?.appIcon?.appIconAndroid}
            variant="android"
            bg="accent"
          />
        </PositionedContainer>
      }
    />
  )
}

export default AppIconAndroidScreen
