import styled from 'styled-components'

const ImgStyled = styled.img`
  width: ${({ width }) => width};
  height: ${({ height }) => height};
  max-height: ${({ maxHeight }) => maxHeight};
  max-width: ${({ maxWidth }) => maxWidth};
  object-fit: ${({ objectFit }) => objectFit};
`

export default ImgStyled
