import { IntroScreen } from '../../../../components'
import { SCREENS_TABLE_OF_CONTENT } from '../../../../__constants__'

const TypographyIntroScreen = () => {
  return (
    <IntroScreen
      key="TypographyIntroScreen"
      sectionNumber={2}
      title="Typo."
      caption="Typography"
      contentList={SCREENS_TABLE_OF_CONTENT.TYPO_LIST}
    />
  )
}

export default TypographyIntroScreen
