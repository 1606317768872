import { useTranslations } from 'contexts/Translation'
import PropTypes from 'prop-types'
import { MockupSimpleForm } from 'domains/Mockup/components'
import { Button, Title } from '@qonsoll/react-design'
import { Form, Input } from 'antd'
import { KEYS } from '__constants__'
import {
  useInitializeMockupAdvancedForm,
  useActionsMockupAdvancedForm
} from './hooks'
import { OfferSelectWithCreate } from 'domains/Offer/components'
import { ImageUploader } from 'components'

const MockupAdvancedForm = (props) => {
  const { initialData, group, showTitle } = props

  // [ADDITIONAL_HOOKS]
  const { t } = useTranslations()
  const { form } = useInitializeMockupAdvancedForm(initialData)

  // [HANDLERS]
  const handleKeyPress = (e) => {
    if (e.key === KEYS.ENTER) {
      e.preventDefault()
      onFinish()
    }
  }

  const { loading, onFinish, onReset } = useActionsMockupAdvancedForm({
    initialData,
    form
  })

  return group ? (
    <>
      {showTitle && (
        <Title level={4} mb={2}>
          {t('Mockup')}
        </Title>
      )}
      <MockupSimpleForm showTitle={false} group={[...group, 'mockup']} />
      <Form.Item name={[group, 'offerId']}>
        <OfferSelectWithCreate />
      </Form.Item>
    </>
  ) : (
    <Form
      onKeyPress={handleKeyPress}
      onFinish={onFinish}
      layout="vertical"
      autoComplete="off"
      form={form}>
      {showTitle && (
        <Title level={4} mb={2}>
          {t('Mockup')}
        </Title>
      )}
      <MockupSimpleForm group={['mockup']} />
      <Form.Item name={['offerId']}>
        <OfferSelectWithCreate />
      </Form.Item>
      <Button mr={2} htmlType="button" onClick={onReset} mb={4}>
        {t('Cancel')}
      </Button>
      <Button type="primary" loading={loading} onClick={() => form.submit()}>
        {t('Save')}
      </Button>
    </Form>
  )
}

MockupAdvancedForm.propTypes = {
  initialData: PropTypes.object,
  group: PropTypes.array,
  showTitle: PropTypes.bool
}

export default MockupAdvancedForm
