import './ShadesList.vars.css'

import styled from 'styled-components'

const ShadesListStyled = styled.div`
  display: flex;
  height: inherit;
  justify-content: flex-end;
  flex-direction: ${({ isReverse }) =>
    isReverse ? 'column-reverse' : 'column'};
  background: ${({ bg }) =>
    bg ? `var(--shades-list-bg-${bg})` : 'var(--shades-list-bg)'};

  @media (max-width: 576px) {
    flex-direction: row;
    width: max-content;
    margin-left: auto;
    margin-right: 0;
  }
`

export default ShadesListStyled
