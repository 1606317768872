import { collection, doc } from 'firebase/firestore'
import { useDocument, useGetDocumentsByIds } from 'hooks'

import { COLLECTIONS } from '__constants__'
import { firestore } from 'services/firebase'
import { useDocumentData } from 'react-firebase-hooks/firestore'
import { useMemo } from 'react'

/* This hook gathered all offer-related-data into 1 object */
const useOfferRelatedData = (offerId) => {
  // [DATA_FETCH]
  // Receive offer by offerId got from the path
  const [offer, offerLoading, offerError] = useDocumentData(
    offerId && doc(collection(firestore, COLLECTIONS.OFFERS), offerId)
  )

  // [COMPUTED_PROPERTIES]
  const entity = useMemo(
    () => !offerLoading && !offerError && offer,
    [offer, offerLoading, offerError]
  )

  const [mockups, mockupsLoading, mockupsError] = useGetDocumentsByIds({
    entity,
    collectionName: COLLECTIONS.MOCKUPS,
    fieldName: 'mockupIds'
  })
  const [videos, videosLoading, videosError] = useGetDocumentsByIds({
    entity,
    collectionName: COLLECTIONS.VIDEOS,
    fieldName: 'videoIds'
  })

  const [features, featuresLoading, featuresError] = useGetDocumentsByIds({
    entity,
    collectionName: COLLECTIONS.FEATURES,
    fieldName: 'featureIds'
  })
  const [database, databaseLoading, databaseError] = useDocument({
    ref: `${COLLECTIONS.DATABASES}/${entity?.dataBaseId}`
  })
  const [technologies, technologiesLoading, technologiesError] =
    useGetDocumentsByIds({
      entity,
      collectionName: COLLECTIONS.TECHNOLOGIES,
      fieldName: 'technologyIds'
    })

  const featuresData =
    features && !featuresLoading && !featuresError ? features : []
  const mockupsData = mockups && !mockupsLoading && !mockupsError ? mockups : []
  const videosData = videos && !videosLoading && !videosError ? videos : []
  const databaseData =
    database && !databaseLoading && !databaseError ? database : []
  const technologiesData =
    technologies && !technologiesLoading && !technologiesError
      ? technologies
      : []
  const offerData = offer && !offerLoading && !offerError ? offer : {}
  return useMemo(() => {
    const isLoading =
      featuresLoading ||
      mockupsLoading ||
      videosLoading ||
      offerLoading ||
      databaseLoading ||
      technologiesLoading ||
      offerLoading
    return {
      featuresData,
      mockupsData,
      videosData,
      isLoading,
      databaseData,
      technologiesData,
      offerData
    }
  }, [
    featuresData,
    mockupsData,
    videosData,
    databaseData,
    technologiesData,
    offerData,
    featuresLoading,
    mockupsLoading,
    offerLoading,
    databaseLoading,
    technologiesLoading
  ])
}

export default useOfferRelatedData
