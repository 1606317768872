import styled, { css } from 'styled-components'

import { PositionedContainer } from 'brandbook/components'
import breakpointsConfig from 'styles/breakpoints'

const { breakpoints } = breakpointsConfig

const offsetBottom = (offsetBottomMap) => {
  return offsetBottomMap
    .map((item, i) => {
      const isLast = offsetBottomMap.length - 1 === i
      return isLast
        ? css`
            height: calc(100% - ${item});
          `
        : css`
            @media (max-width: ${breakpoints[i]}) {
              height: calc(100% - ${item});
            }
          `
    })
    .reverse()
}

const BoundedContainerStyled = styled(PositionedContainer)`
  display: flex;
  align-items: flex-end;
  ${(props) => offsetBottom(props?.offsetBottom)};
`

export default BoundedContainerStyled
