import { Box, Col, Divider, Menu, MenuItem, Row } from '@qonsoll/react-design'
import { Fragment, useMemo } from 'react'

import { AppstoreOutlined, StarOutlined } from '@ant-design/icons'
import { Icon } from '@qonsoll/icons'
import { LanguageSelect } from 'domains/Translation/components'
import { useHistory, useLocation } from 'react-router-dom'
import { useTranslations } from 'contexts/Translation'

const MainMenu = () => {
  // [ADDITIONAL_HOOKS]
  const { t } = useTranslations()
  const history = useHistory()
  const location = useLocation()

  // [COMPUTED_PROPERTIES]
  const initialSelected = useMemo(() => {
    const currentParentPath = location.pathname.split('/')?.[1]
    return ['/' + currentParentPath]
  }, [location])

  const menuItems = useMemo(
    () => [
      {
        key: '/translations',
        icon: <Icon name="TranslationFilled" />,
        text: t('Translations'),
        onClick: () => history.push('/translations'),
        divided: true
      },
      {
        key: '/dashboard',
        icon: <AppstoreOutlined />,
        text: t('Dashboard'),
        onClick: () => history.push('/dashboard')
      },
      {
        key: '/customers',
        icon: <StarOutlined />,
        text: t('Customers'),
        onClick: () => history.push('/customers')
      },
      {
        key: '/customer-profiles',
        icon: <StarOutlined />,
        text: t('Customer profiles'),
        onClick: () => history.push('/customer-profiles')
      },
      {
        key: '/representatives',
        icon: <StarOutlined />,
        text: t('Representatives'),
        onClick: () => history.push('/representatives')
      },
      {
        key: '/roles',
        icon: <StarOutlined />,
        text: t('Roles'),
        onClick: () => history.push('/roles')
      },
      {
        key: '/applications',
        icon: <StarOutlined />,
        text: t('Applications'),
        onClick: () => history.push('/applications')
      },
      {
        key: '/brandbooks',
        icon: <StarOutlined />,
        text: t('Brandbooks'),
        onClick: () => history.push('/brandbooks')
      },
      {
        key: '/features',
        icon: <StarOutlined />,
        text: t('Features'),
        onClick: () => history.push('/features')
      },
      {
        key: '/offers',
        icon: <StarOutlined />,
        text: t('Offers'),
        onClick: () => history.push('/offers')
      },
      {
        key: '/designs',
        icon: <StarOutlined />,
        text: t('Designs'),
        onClick: () => history.push('/designs')
      },
      {
        key: '/mockups',
        icon: <StarOutlined />,
        text: t('Mockups'),
        onClick: () => history.push('/mockups')
      }
    ],
    [t, history]
  )

  return (
    <Fragment>
      <Row mb={16}>
        <Col>
          <LanguageSelect />
        </Col>
      </Row>
      <Menu mode="inline" defaultSelectedKeys={initialSelected}>
        {menuItems.map((item) => (
          <Fragment key={item.key}>
            <MenuItem key={item.key} onClick={item.onClick} icon={item.icon}>
              {item.text}
            </MenuItem>
            {item.divided && (
              <Box my={3}>
                <Divider />
              </Box>
            )}
          </Fragment>
        ))}
      </Menu>
    </Fragment>
  )
}

export default MainMenu
