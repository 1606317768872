import styled from 'styled-components'

const ExteriorScreenStyled = styled.div`
  height: var(--exterior-screen-height);
  display: flex;
  flex-direction: column;
  position: relative;
  overflow: hidden;
  background: var(--senseteq-screen-bg);
  z-index: 0;
`
export default ExteriorScreenStyled
