import { model, attr } from 'services/model'
import * as yup from 'yup'

const validationSchema = yup.object().shape({
  hex: yup
    .string()
    .typeError('Field hex has wrong type')
    .required('Field hex is required')
})

const Color = model(
  'color',
  {
    hex: attr('string')
  },
  validationSchema
)

export default Color
