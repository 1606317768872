const BREAKPOINTS = {
  xs: '375px',
  sm: '576px',
  md: '768px',
  lg: '1024px',
  xl: '1280px',
  xxl: '1440px',
  xxxl: '1600px',
  xxxxl: '1920px'
}

export default BREAKPOINTS
