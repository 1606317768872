import {
  Checkbox,
  ContentScreen,
  PositionedContainer
} from '../../../../components'

import { CheckboxList } from '../../../../domains'
import responsiveStyles from './CheckboxScreen.styled.js'

/**
 * It returns a ContentScreen with elements of Checkbox
 * @returns A ContentScreen component with the following props:
 *   key: "CheckboxScreen"
 *   chapter: "Checkbox"
 *   domainName: "Elem."
 *   pageNumber: "31"
 *   asideTopContent: <CheckboxList />
 *   content: <PositionedContainer right="-96px" top
 */
const CheckboxScreen = () => {
  return (
    <ContentScreen
      key="CheckboxScreen"
      chapter="Checkbox"
      domainName="Elem."
      pageNumber="31"
      asideTopContent={<CheckboxList />}
      content={
        <PositionedContainer {...responsiveStyles}>
          <Checkbox checked mega>
            Sign up
          </Checkbox>
        </PositionedContainer>
      }
    />
  )
}

export default CheckboxScreen
