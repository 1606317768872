import { ModelContainer, ModelWrapper } from './ModelList.styled'

import ModelView from '../ModelView'
import PropTypes from 'prop-types'

const ModelList = (props) => {
  const { data } = props

  return (
    <ModelWrapper className="offer-model-wrapper">
      {data?.map((model, index) => (
        <ModelContainer key={`${index}-model`}>
          <ModelView model={model} />
        </ModelContainer>
      ))}
    </ModelWrapper>
  )
}

ModelList.propTypes = {
  data: PropTypes.array
}

export default ModelList
