import PropTypes from 'prop-types'

const TV = (props) => {
  const { iconSize, iconColor, fill } = props

  return (
    <svg
      width={iconSize}
      height={iconSize}
      viewBox="0 0 72 72"
      fill={fill}
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M57 61.4998C43.2843 57.5098 28.7157 57.5098 15 61.4998"
        stroke={iconColor}
        strokeWidth="6"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M63 45V15C63 14.2044 62.6839 13.4413 62.1213 12.8787C61.5587 12.3161 60.7956 12 60 12H12C11.2044 12 10.4413 12.3161 9.87868 12.8787C9.31607 13.4413 9 14.2044 9 15V45C9 45.7956 9.31607 46.5587 9.87868 47.1213C10.4413 47.6839 11.2044 48 12 48H60C60.7956 48 61.5587 47.6839 62.1213 47.1213C62.6839 46.5587 63 45.7956 63 45ZM30 48V58.74C33.9944 58.4399 38.0056 58.4399 42 58.74V48H30Z"
        stroke={iconColor}
        strokeWidth="6"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

TV.propTypes = {
  iconSize: PropTypes.string.isRequired,
  fill: PropTypes.string,
  iconColor: PropTypes.string
}

export default TV
