import { List } from '../../../../components'
import PropTypes from 'prop-types'

const PaymentTermsList = (props) => {
  const { paymentTerms } = props

  return <List data={paymentTerms} />
}
PaymentTermsList.propTypes = {
  paymentTerms: PropTypes.string
}

export default PaymentTermsList
