import {
  ImageryDirectionScreen,
  ImageryInstaPostScreen,
  ImageryMoodboardScreen
} from './ImageryContent'

import ImageryIntroScreen from './ImageryIntro/ImageryIntroScreen'

export const IMAGERY_SCREENS_ORDER = [
  ImageryIntroScreen,
  ImageryDirectionScreen,
  ImageryMoodboardScreen,
  ImageryInstaPostScreen
]
