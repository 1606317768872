import styled from 'styled-components'

export const ListStyled = styled.ul`
  padding-left: var(--p-xxl);
`

export const ListItemStyled = styled.li`
  color: var(--list-marker-default);
  font-size: var(--list-marker-fs-default);
  line-height: var(--list-marker-lh-default);
  list-style: ${({ markerStyle }) => markerStyle || 'circle'};
  margin-bottom: var(--m-xl);
`
