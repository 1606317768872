import PropTypes from 'prop-types'

const Comments = (props) => {
  const { iconSize, iconColor, fill } = props

  return (
    <svg
      width={iconSize}
      height={iconSize}
      viewBox="0 0 72 72"
      fill={fill}
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M54.0002 28.5C54.0002 39.27 43.9202 48 31.5002 48C27.8845 48.0227 24.3053 47.2762 21.0002 45.81L19.0502 46.65L9.00018 51L12.4502 39C10.1935 35.9659 8.98286 32.2812 9.00018 28.5C9.00018 17.73 19.0802 9 31.5002 9C42.3902 9 51.4802 15.72 53.5502 24.63C53.8528 25.8977 54.0038 27.1967 54.0002 28.5Z"
        stroke={iconColor}
        strokeWidth="6"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M53.5499 24.6299C56.357 26.239 58.7016 28.545 60.357 31.3251C62.0124 34.1051 62.9227 37.2652 62.9999 40.4999C63.0172 44.2811 61.8066 47.9658 59.5499 50.9999L62.9999 62.9999L50.9999 57.8099C47.6948 59.276 44.1156 60.0226 40.4999 59.9999C36.0167 60.1653 31.5871 58.9814 27.7843 56.6013C23.9815 54.2212 20.9806 50.7545 19.1699 46.6499"
        stroke={iconColor}
        strokeWidth="6"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

Comments.propTypes = {
  iconSize: PropTypes.string.isRequired,
  fill: PropTypes.string,
  iconColor: PropTypes.string
}

export default Comments
