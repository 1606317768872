import './TextPreview.vars.css'

import { Text, Title } from '../../../../components'

import { BREAKPOINTS } from '../../../../__constants__'
import { TEXT_PREVIEWS } from '../../__constants__'
import TextPreviewStyled from './TextPreview.styled'
import { useMediaQuery } from 'hooks'

/**
 * It returns a styled div with two titles and two texts
 * @returns A styled component with a title and text.
 */
const TextPreview = () => {
  // [ADITIONAL HOOK]
  const isFullTextPreview = useMediaQuery(`(min-width: ${BREAKPOINTS.xl})`)

  return (
    <TextPreviewStyled>
      <Title ff="secondary" variant="h4">
        {TEXT_PREVIEWS.title1}
      </Title>
      <Text ff="primary">{TEXT_PREVIEWS.text1}</Text>

      {isFullTextPreview && (
        <>
          <Title ff="secondary" variant="h5">
            {TEXT_PREVIEWS.title2}
          </Title>
          <Text ff="primary">{TEXT_PREVIEWS.text2}</Text>
        </>
      )}
    </TextPreviewStyled>
  )
}

export default TextPreview
