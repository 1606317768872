import styled from 'styled-components'

const IconListStyled = styled.div`
  display: ${({ display }) => display || 'flex'};
  flex-wrap: wrap;
  gap: ${({ gap }) => gap || 'var(--gap-16)'};
  justify-content: ${({ justify }) => justify || 'space-evenly'};
  margin-top: auto;
  max-width: ${({ mw }) => mw || 'var(--icon-list-max-width)'};
  max-height: var(--icon-list-max-height);
  margin-bottom: ${({ mb }) => mb || 0};
  margin-right: ${({ mr }) => mr || 0};
`

export default IconListStyled
