import { useTranslations } from 'contexts/Translation'
import PropTypes from 'prop-types'
import { Tabs, Card } from 'antd'
import { StickyContainer, Sticky } from 'react-sticky'
import { Container, Row, Col, Spin, Divider, Text } from '@qonsoll/react-design'
import { useParams, useHistory, useLocation } from 'react-router-dom'
import { useDocument } from 'hooks'
import { useStateWithStorage } from 'hooks'

const ColorAdvancedView = (props) => {
  // [COMPONENT_STATE_HOOKS]
  const [activeTab, setActiveTab] = useStateWithStorage(
    null,
    'color-advanced-view-active-tab'
  )

  // [ADDITIONAL_HOOKS]
  const { t } = useTranslations()
  const history = useHistory()
  const params = useParams()
  const location = useLocation()
  const { colorId } = params
  const [color, loading] = useDocument({ ref: `colors/${colorId}` })

  // [COMPUTED_PROPERTIES]
  const { TabPane } = Tabs

  return loading ? (
    <Spin />
  ) : (
    <Container>
      <Container mb={4}>
        <Card>
          <Row noGutters v="center">
            <Col h="left">
              <Text variant="overline" type="secondary">
                {t('Hex')}:
              </Text>
            </Col>
            <Col h="right">
              <Text>{color?.hex}</Text>
            </Col>
            <Divider my={2} />
          </Row>
        </Card>
      </Container>
    </Container>
  )
}

ColorAdvancedView.propTypes = {}

export default ColorAdvancedView
