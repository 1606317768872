import { ClosingScreen, OpeningScreen, TableOfContentScreen } from './Default'

import { APP_ICON_SCREENS_ORDER } from './AppIcon'
import { COLOR_SCREENS_ORDER } from './Color'
import { ELEMENTS_SCREENS_ORDER } from './Elements'
import { ICONOGRAPHY_SCREENS_ORDER } from './Iconography'
import { IMAGERY_SCREENS_ORDER } from './Imagery'
import { LOGO_SCREENS_ORDER } from './Logo'
import { TYPOGRAPHY_SCREENS_ORDER } from './Typography'

export const SCREENS_ORDER = [
  OpeningScreen,
  TableOfContentScreen,
  ...COLOR_SCREENS_ORDER,
  ...TYPOGRAPHY_SCREENS_ORDER,
  ...LOGO_SCREENS_ORDER,
  ...ICONOGRAPHY_SCREENS_ORDER,
  ...IMAGERY_SCREENS_ORDER,
  ...APP_ICON_SCREENS_ORDER,
  ...ELEMENTS_SCREENS_ORDER,
  ClosingScreen
]
