import styled, { css } from 'styled-components'

const COLOR_WRAPPER_SIZE = (viewSize) => css`
  width: var(--color-simple-view-width-${viewSize});
  height: var(--color-simple-view-height-${viewSize});
`

const ColorSimpleViewStyled = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  padding: var(--color-simple-view-p);
  border: ${({ name }) =>
    name === 'White' && 'var(--color-simple-view-border)'};
  background-color: ${({ shade }) => shade};
  ${({ viewSize = 'sm' }) => COLOR_WRAPPER_SIZE(viewSize)};
`

export default ColorSimpleViewStyled
