import PropTypes from 'prop-types'

const IconGreed = (props) => {
  const { iconSize } = props

  return (
    <svg
      className="icon-greed"
      width={iconSize}
      height={iconSize}
      viewBox="0 0 592 562"
      fill="transparent"
      xmlns="http://www.w3.org/2000/svg">
      <rect
        x="15"
        y="40"
        width="562"
        height="1"
        fill="#253037"
        fillOpacity="0.2"
      />
      <rect x="536" width="1" height="562" fill="#253037" fillOpacity="0.2" />
      <rect x="55" width="1" height="562" fill="#253037" fillOpacity="0.2" />
      <rect
        x="15"
        y="521"
        width="562"
        height="1"
        fill="#253037"
        fillOpacity="0.2"
      />
      <rect
        x="74"
        y="59"
        width="4"
        height="4"
        rx="2"
        fill="#253037"
        fillOpacity="0.1"
      />
      <rect
        x="114"
        y="59"
        width="4"
        height="4"
        rx="2"
        fill="#253037"
        fillOpacity="0.1"
      />
      <rect
        x="154"
        y="59"
        width="4"
        height="4"
        rx="2"
        fill="#253037"
        fillOpacity="0.1"
      />
      <rect
        x="194"
        y="59"
        width="4"
        height="4"
        rx="2"
        fill="#253037"
        fillOpacity="0.1"
      />
      <rect
        x="234"
        y="59"
        width="4"
        height="4"
        rx="2"
        fill="#253037"
        fillOpacity="0.1"
      />
      <rect
        x="274"
        y="59"
        width="4"
        height="4"
        rx="2"
        fill="#253037"
        fillOpacity="0.1"
      />
      <rect
        x="314"
        y="59"
        width="4"
        height="4"
        rx="2"
        fill="#253037"
        fillOpacity="0.1"
      />
      <rect
        x="354"
        y="59"
        width="4"
        height="4"
        rx="2"
        fill="#253037"
        fillOpacity="0.1"
      />
      <rect
        x="394"
        y="59"
        width="4"
        height="4"
        rx="2"
        fill="#253037"
        fillOpacity="0.1"
      />
      <rect
        x="434"
        y="59"
        width="4"
        height="4"
        rx="2"
        fill="#253037"
        fillOpacity="0.1"
      />
      <rect
        x="474"
        y="59"
        width="4"
        height="4"
        rx="2"
        fill="#253037"
        fillOpacity="0.1"
      />
      <rect
        x="514"
        y="59"
        width="4"
        height="4"
        rx="2"
        fill="#253037"
        fillOpacity="0.1"
      />
      <rect
        x="74"
        y="99"
        width="4"
        height="4"
        rx="2"
        fill="#253037"
        fillOpacity="0.1"
      />
      <rect
        x="114"
        y="99"
        width="4"
        height="4"
        rx="2"
        fill="#253037"
        fillOpacity="0.1"
      />
      <rect
        x="154"
        y="99"
        width="4"
        height="4"
        rx="2"
        fill="#253037"
        fillOpacity="0.1"
      />
      <rect
        x="194"
        y="99"
        width="4"
        height="4"
        rx="2"
        fill="#253037"
        fillOpacity="0.1"
      />
      <rect
        x="234"
        y="99"
        width="4"
        height="4"
        rx="2"
        fill="#253037"
        fillOpacity="0.1"
      />
      <rect
        x="274"
        y="99"
        width="4"
        height="4"
        rx="2"
        fill="#253037"
        fillOpacity="0.1"
      />
      <rect
        x="314"
        y="99"
        width="4"
        height="4"
        rx="2"
        fill="#253037"
        fillOpacity="0.1"
      />
      <rect
        x="354"
        y="99"
        width="4"
        height="4"
        rx="2"
        fill="#253037"
        fillOpacity="0.1"
      />
      <rect
        x="394"
        y="99"
        width="4"
        height="4"
        rx="2"
        fill="#253037"
        fillOpacity="0.1"
      />
      <rect
        x="434"
        y="99"
        width="4"
        height="4"
        rx="2"
        fill="#253037"
        fillOpacity="0.1"
      />
      <rect
        x="474"
        y="99"
        width="4"
        height="4"
        rx="2"
        fill="#253037"
        fillOpacity="0.1"
      />
      <rect
        x="514"
        y="99"
        width="4"
        height="4"
        rx="2"
        fill="#253037"
        fillOpacity="0.1"
      />
      <rect
        x="74"
        y="139"
        width="4"
        height="4"
        rx="2"
        fill="#253037"
        fillOpacity="0.1"
      />
      <rect
        x="114"
        y="139"
        width="4"
        height="4"
        rx="2"
        fill="#253037"
        fillOpacity="0.1"
      />
      <rect
        x="154"
        y="139"
        width="4"
        height="4"
        rx="2"
        fill="#253037"
        fillOpacity="0.1"
      />
      <rect
        x="194"
        y="139"
        width="4"
        height="4"
        rx="2"
        fill="#253037"
        fillOpacity="0.1"
      />
      <rect
        x="234"
        y="139"
        width="4"
        height="4"
        rx="2"
        fill="#253037"
        fillOpacity="0.1"
      />
      <rect
        x="274"
        y="139"
        width="4"
        height="4"
        rx="2"
        fill="#253037"
        fillOpacity="0.1"
      />
      <rect
        x="314"
        y="139"
        width="4"
        height="4"
        rx="2"
        fill="#253037"
        fillOpacity="0.1"
      />
      <rect
        x="354"
        y="139"
        width="4"
        height="4"
        rx="2"
        fill="#253037"
        fillOpacity="0.1"
      />
      <rect
        x="394"
        y="139"
        width="4"
        height="4"
        rx="2"
        fill="#253037"
        fillOpacity="0.1"
      />
      <rect
        x="434"
        y="139"
        width="4"
        height="4"
        rx="2"
        fill="#253037"
        fillOpacity="0.1"
      />
      <rect
        x="474"
        y="139"
        width="4"
        height="4"
        rx="2"
        fill="#253037"
        fillOpacity="0.1"
      />
      <rect
        x="514"
        y="139"
        width="4"
        height="4"
        rx="2"
        fill="#253037"
        fillOpacity="0.1"
      />
      <rect
        x="74"
        y="179"
        width="4"
        height="4"
        rx="2"
        fill="#253037"
        fillOpacity="0.1"
      />
      <rect
        x="114"
        y="179"
        width="4"
        height="4"
        rx="2"
        fill="#253037"
        fillOpacity="0.1"
      />
      <rect
        x="154"
        y="179"
        width="4"
        height="4"
        rx="2"
        fill="#253037"
        fillOpacity="0.1"
      />
      <rect
        x="194"
        y="179"
        width="4"
        height="4"
        rx="2"
        fill="#253037"
        fillOpacity="0.1"
      />
      <rect
        x="234"
        y="179"
        width="4"
        height="4"
        rx="2"
        fill="#253037"
        fillOpacity="0.1"
      />
      <rect
        x="274"
        y="179"
        width="4"
        height="4"
        rx="2"
        fill="#253037"
        fillOpacity="0.1"
      />
      <rect
        x="314"
        y="179"
        width="4"
        height="4"
        rx="2"
        fill="#253037"
        fillOpacity="0.1"
      />
      <rect
        x="354"
        y="179"
        width="4"
        height="4"
        rx="2"
        fill="#253037"
        fillOpacity="0.1"
      />
      <rect
        x="394"
        y="179"
        width="4"
        height="4"
        rx="2"
        fill="#253037"
        fillOpacity="0.1"
      />
      <rect
        x="434"
        y="179"
        width="4"
        height="4"
        rx="2"
        fill="#253037"
        fillOpacity="0.1"
      />
      <rect
        x="474"
        y="179"
        width="4"
        height="4"
        rx="2"
        fill="#253037"
        fillOpacity="0.1"
      />
      <rect
        x="514"
        y="179"
        width="4"
        height="4"
        rx="2"
        fill="#253037"
        fillOpacity="0.1"
      />
      <rect
        x="74"
        y="219"
        width="4"
        height="4"
        rx="2"
        fill="#253037"
        fillOpacity="0.1"
      />
      <rect
        x="114"
        y="219"
        width="4"
        height="4"
        rx="2"
        fill="#253037"
        fillOpacity="0.1"
      />
      <rect
        x="154"
        y="219"
        width="4"
        height="4"
        rx="2"
        fill="#253037"
        fillOpacity="0.1"
      />
      <rect
        x="194"
        y="219"
        width="4"
        height="4"
        rx="2"
        fill="#253037"
        fillOpacity="0.1"
      />
      <rect
        x="234"
        y="219"
        width="4"
        height="4"
        rx="2"
        fill="#253037"
        fillOpacity="0.1"
      />
      <rect
        x="274"
        y="219"
        width="4"
        height="4"
        rx="2"
        fill="#253037"
        fillOpacity="0.1"
      />
      <rect
        x="314"
        y="219"
        width="4"
        height="4"
        rx="2"
        fill="#253037"
        fillOpacity="0.1"
      />
      <rect
        x="354"
        y="219"
        width="4"
        height="4"
        rx="2"
        fill="#253037"
        fillOpacity="0.1"
      />
      <rect
        x="394"
        y="219"
        width="4"
        height="4"
        rx="2"
        fill="#253037"
        fillOpacity="0.1"
      />
      <rect
        x="434"
        y="219"
        width="4"
        height="4"
        rx="2"
        fill="#253037"
        fillOpacity="0.1"
      />
      <rect
        x="474"
        y="219"
        width="4"
        height="4"
        rx="2"
        fill="#253037"
        fillOpacity="0.1"
      />
      <rect
        x="514"
        y="219"
        width="4"
        height="4"
        rx="2"
        fill="#253037"
        fillOpacity="0.1"
      />
      <rect
        x="74"
        y="259"
        width="4"
        height="4"
        rx="2"
        fill="#253037"
        fillOpacity="0.1"
      />
      <rect
        x="114"
        y="259"
        width="4"
        height="4"
        rx="2"
        fill="#253037"
        fillOpacity="0.1"
      />
      <rect
        x="154"
        y="259"
        width="4"
        height="4"
        rx="2"
        fill="#253037"
        fillOpacity="0.1"
      />
      <rect
        x="194"
        y="259"
        width="4"
        height="4"
        rx="2"
        fill="#253037"
        fillOpacity="0.1"
      />
      <rect
        x="234"
        y="259"
        width="4"
        height="4"
        rx="2"
        fill="#253037"
        fillOpacity="0.1"
      />
      <rect
        x="274"
        y="259"
        width="4"
        height="4"
        rx="2"
        fill="#253037"
        fillOpacity="0.1"
      />
      <rect
        x="314"
        y="259"
        width="4"
        height="4"
        rx="2"
        fill="#253037"
        fillOpacity="0.1"
      />
      <rect
        x="354"
        y="259"
        width="4"
        height="4"
        rx="2"
        fill="#253037"
        fillOpacity="0.1"
      />
      <rect
        x="394"
        y="259"
        width="4"
        height="4"
        rx="2"
        fill="#253037"
        fillOpacity="0.1"
      />
      <rect
        x="434"
        y="259"
        width="4"
        height="4"
        rx="2"
        fill="#253037"
        fillOpacity="0.1"
      />
      <rect
        x="474"
        y="259"
        width="4"
        height="4"
        rx="2"
        fill="#253037"
        fillOpacity="0.1"
      />
      <rect
        x="514"
        y="259"
        width="4"
        height="4"
        rx="2"
        fill="#253037"
        fillOpacity="0.1"
      />
      <rect
        x="74"
        y="299"
        width="4"
        height="4"
        rx="2"
        fill="#253037"
        fillOpacity="0.1"
      />
      <rect
        x="114"
        y="299"
        width="4"
        height="4"
        rx="2"
        fill="#253037"
        fillOpacity="0.1"
      />
      <rect
        x="154"
        y="299"
        width="4"
        height="4"
        rx="2"
        fill="#253037"
        fillOpacity="0.1"
      />
      <rect
        x="194"
        y="299"
        width="4"
        height="4"
        rx="2"
        fill="#253037"
        fillOpacity="0.1"
      />
      <rect
        x="234"
        y="299"
        width="4"
        height="4"
        rx="2"
        fill="#253037"
        fillOpacity="0.1"
      />
      <rect
        x="274"
        y="299"
        width="4"
        height="4"
        rx="2"
        fill="#253037"
        fillOpacity="0.1"
      />
      <rect
        x="314"
        y="299"
        width="4"
        height="4"
        rx="2"
        fill="#253037"
        fillOpacity="0.1"
      />
      <rect
        x="354"
        y="299"
        width="4"
        height="4"
        rx="2"
        fill="#253037"
        fillOpacity="0.1"
      />
      <rect
        x="394"
        y="299"
        width="4"
        height="4"
        rx="2"
        fill="#253037"
        fillOpacity="0.1"
      />
      <rect
        x="434"
        y="299"
        width="4"
        height="4"
        rx="2"
        fill="#253037"
        fillOpacity="0.1"
      />
      <rect
        x="474"
        y="299"
        width="4"
        height="4"
        rx="2"
        fill="#253037"
        fillOpacity="0.1"
      />
      <rect
        x="514"
        y="299"
        width="4"
        height="4"
        rx="2"
        fill="#253037"
        fillOpacity="0.1"
      />
      <rect
        x="74"
        y="339"
        width="4"
        height="4"
        rx="2"
        fill="#253037"
        fillOpacity="0.1"
      />
      <rect
        x="114"
        y="339"
        width="4"
        height="4"
        rx="2"
        fill="#253037"
        fillOpacity="0.1"
      />
      <rect
        x="154"
        y="339"
        width="4"
        height="4"
        rx="2"
        fill="#253037"
        fillOpacity="0.1"
      />
      <rect
        x="194"
        y="339"
        width="4"
        height="4"
        rx="2"
        fill="#253037"
        fillOpacity="0.1"
      />
      <rect
        x="234"
        y="339"
        width="4"
        height="4"
        rx="2"
        fill="#253037"
        fillOpacity="0.1"
      />
      <rect
        x="274"
        y="339"
        width="4"
        height="4"
        rx="2"
        fill="#253037"
        fillOpacity="0.1"
      />
      <rect
        x="314"
        y="339"
        width="4"
        height="4"
        rx="2"
        fill="#253037"
        fillOpacity="0.1"
      />
      <rect
        x="354"
        y="339"
        width="4"
        height="4"
        rx="2"
        fill="#253037"
        fillOpacity="0.1"
      />
      <rect
        x="394"
        y="339"
        width="4"
        height="4"
        rx="2"
        fill="#253037"
        fillOpacity="0.1"
      />
      <rect
        x="434"
        y="339"
        width="4"
        height="4"
        rx="2"
        fill="#253037"
        fillOpacity="0.1"
      />
      <rect
        x="474"
        y="339"
        width="4"
        height="4"
        rx="2"
        fill="#253037"
        fillOpacity="0.1"
      />
      <rect
        x="514"
        y="339"
        width="4"
        height="4"
        rx="2"
        fill="#253037"
        fillOpacity="0.1"
      />
      <rect
        x="74"
        y="379"
        width="4"
        height="4"
        rx="2"
        fill="#253037"
        fillOpacity="0.1"
      />
      <rect
        x="114"
        y="379"
        width="4"
        height="4"
        rx="2"
        fill="#253037"
        fillOpacity="0.1"
      />
      <rect
        x="154"
        y="379"
        width="4"
        height="4"
        rx="2"
        fill="#253037"
        fillOpacity="0.1"
      />
      <rect
        x="194"
        y="379"
        width="4"
        height="4"
        rx="2"
        fill="#253037"
        fillOpacity="0.1"
      />
      <rect
        x="234"
        y="379"
        width="4"
        height="4"
        rx="2"
        fill="#253037"
        fillOpacity="0.1"
      />
      <rect
        x="274"
        y="379"
        width="4"
        height="4"
        rx="2"
        fill="#253037"
        fillOpacity="0.1"
      />
      <rect
        x="314"
        y="379"
        width="4"
        height="4"
        rx="2"
        fill="#253037"
        fillOpacity="0.1"
      />
      <rect
        x="354"
        y="379"
        width="4"
        height="4"
        rx="2"
        fill="#253037"
        fillOpacity="0.1"
      />
      <rect
        x="394"
        y="379"
        width="4"
        height="4"
        rx="2"
        fill="#253037"
        fillOpacity="0.1"
      />
      <rect
        x="434"
        y="379"
        width="4"
        height="4"
        rx="2"
        fill="#253037"
        fillOpacity="0.1"
      />
      <rect
        x="474"
        y="379"
        width="4"
        height="4"
        rx="2"
        fill="#253037"
        fillOpacity="0.1"
      />
      <rect
        x="514"
        y="379"
        width="4"
        height="4"
        rx="2"
        fill="#253037"
        fillOpacity="0.1"
      />
      <rect
        x="74"
        y="419"
        width="4"
        height="4"
        rx="2"
        fill="#253037"
        fillOpacity="0.1"
      />
      <rect
        x="114"
        y="419"
        width="4"
        height="4"
        rx="2"
        fill="#253037"
        fillOpacity="0.1"
      />
      <rect
        x="154"
        y="419"
        width="4"
        height="4"
        rx="2"
        fill="#253037"
        fillOpacity="0.1"
      />
      <rect
        x="194"
        y="419"
        width="4"
        height="4"
        rx="2"
        fill="#253037"
        fillOpacity="0.1"
      />
      <rect
        x="234"
        y="419"
        width="4"
        height="4"
        rx="2"
        fill="#253037"
        fillOpacity="0.1"
      />
      <rect
        x="274"
        y="419"
        width="4"
        height="4"
        rx="2"
        fill="#253037"
        fillOpacity="0.1"
      />
      <rect
        x="314"
        y="419"
        width="4"
        height="4"
        rx="2"
        fill="#253037"
        fillOpacity="0.1"
      />
      <rect
        x="354"
        y="419"
        width="4"
        height="4"
        rx="2"
        fill="#253037"
        fillOpacity="0.1"
      />
      <rect
        x="394"
        y="419"
        width="4"
        height="4"
        rx="2"
        fill="#253037"
        fillOpacity="0.1"
      />
      <rect
        x="434"
        y="419"
        width="4"
        height="4"
        rx="2"
        fill="#253037"
        fillOpacity="0.1"
      />
      <rect
        x="474"
        y="419"
        width="4"
        height="4"
        rx="2"
        fill="#253037"
        fillOpacity="0.1"
      />
      <rect
        x="514"
        y="419"
        width="4"
        height="4"
        rx="2"
        fill="#253037"
        fillOpacity="0.1"
      />
      <rect
        x="74"
        y="459"
        width="4"
        height="4"
        rx="2"
        fill="#253037"
        fillOpacity="0.1"
      />
      <rect
        x="114"
        y="459"
        width="4"
        height="4"
        rx="2"
        fill="#253037"
        fillOpacity="0.1"
      />
      <rect
        x="154"
        y="459"
        width="4"
        height="4"
        rx="2"
        fill="#253037"
        fillOpacity="0.1"
      />
      <rect
        x="194"
        y="459"
        width="4"
        height="4"
        rx="2"
        fill="#253037"
        fillOpacity="0.1"
      />
      <rect
        x="234"
        y="459"
        width="4"
        height="4"
        rx="2"
        fill="#253037"
        fillOpacity="0.1"
      />
      <rect
        x="274"
        y="459"
        width="4"
        height="4"
        rx="2"
        fill="#253037"
        fillOpacity="0.1"
      />
      <rect
        x="314"
        y="459"
        width="4"
        height="4"
        rx="2"
        fill="#253037"
        fillOpacity="0.1"
      />
      <rect
        x="354"
        y="459"
        width="4"
        height="4"
        rx="2"
        fill="#253037"
        fillOpacity="0.1"
      />
      <rect
        x="394"
        y="459"
        width="4"
        height="4"
        rx="2"
        fill="#253037"
        fillOpacity="0.1"
      />
      <rect
        x="434"
        y="459"
        width="4"
        height="4"
        rx="2"
        fill="#253037"
        fillOpacity="0.1"
      />
      <rect
        x="474"
        y="459"
        width="4"
        height="4"
        rx="2"
        fill="#253037"
        fillOpacity="0.1"
      />
      <rect
        x="514"
        y="459"
        width="4"
        height="4"
        rx="2"
        fill="#253037"
        fillOpacity="0.1"
      />
      <rect
        x="74"
        y="499"
        width="4"
        height="4"
        rx="2"
        fill="#253037"
        fillOpacity="0.1"
      />
      <rect
        x="114"
        y="499"
        width="4"
        height="4"
        rx="2"
        fill="#253037"
        fillOpacity="0.1"
      />
      <rect
        x="154"
        y="499"
        width="4"
        height="4"
        rx="2"
        fill="#253037"
        fillOpacity="0.1"
      />
      <rect
        x="194"
        y="499"
        width="4"
        height="4"
        rx="2"
        fill="#253037"
        fillOpacity="0.1"
      />
      <rect
        x="234"
        y="499"
        width="4"
        height="4"
        rx="2"
        fill="#253037"
        fillOpacity="0.1"
      />
      <rect
        x="274"
        y="499"
        width="4"
        height="4"
        rx="2"
        fill="#253037"
        fillOpacity="0.1"
      />
      <rect
        x="314"
        y="499"
        width="4"
        height="4"
        rx="2"
        fill="#253037"
        fillOpacity="0.1"
      />
      <rect
        x="354"
        y="499"
        width="4"
        height="4"
        rx="2"
        fill="#253037"
        fillOpacity="0.1"
      />
      <rect
        x="394"
        y="499"
        width="4"
        height="4"
        rx="2"
        fill="#253037"
        fillOpacity="0.1"
      />
      <rect
        x="434"
        y="499"
        width="4"
        height="4"
        rx="2"
        fill="#253037"
        fillOpacity="0.1"
      />
      <rect
        x="474"
        y="499"
        width="4"
        height="4"
        rx="2"
        fill="#253037"
        fillOpacity="0.1"
      />
      <rect
        x="514"
        y="499"
        width="4"
        height="4"
        rx="2"
        fill="#253037"
        fillOpacity="0.1"
      />
      <rect
        x="-0.5"
        y="0.5"
        width="400"
        height="400"
        transform="matrix(-1 0 0 1 495.5 80.5)"
        stroke="#253037"
        strokeOpacity="0.2"
      />
    </svg>
  )
}

IconGreed.propTypes = {
  iconSize: PropTypes.string
}

export default IconGreed
