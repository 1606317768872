import { IMAGE_DECORATIONS } from '../../../__constants__'

const { AVATAR } = IMAGE_DECORATIONS

const AVATAR_VARIANTS = {
  title: 'Variant',
  avatar: [
    {
      name: 'Default',
      size: 'md',
      userInitials: 'AA'
    },
    {
      name: 'Icon',
      size: 'md',
      icon: 'Avatar',
      iconSize: 'md',
      iconColor: 'dark'
    },
    {
      name: 'Image',
      size: 'md',
      imageSrc: AVATAR
    }
  ]
}

export default AVATAR_VARIANTS
