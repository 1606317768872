import { useTranslations } from 'contexts/Translation'
import PropTypes from 'prop-types'
import { ApplicationSimpleForm } from 'domains/Application/components'
import { Button, Title } from '@qonsoll/react-design'
import { Form, Input } from 'antd'
import { KEYS } from '__constants__'
import {
  useInitializeApplicationAdvancedForm,
  useActionsApplicationAdvancedForm
} from './hooks'
import { PlatformSelectWithCreate } from 'domains/Platform/components'
import { RoleSelectWithCreate } from 'domains/Role/components'

const ApplicationAdvancedForm = (props) => {
  const { initialData, group, showTitle } = props

  // [ADDITIONAL_HOOKS]
  const { t } = useTranslations()
  const { form } = useInitializeApplicationAdvancedForm(initialData)

  // [HANDLERS]
  const handleKeyPress = (e) => {
    if (e.key === KEYS.ENTER) {
      e.preventDefault()
      onFinish()
    }
  }

  const { loading, onFinish, onReset } = useActionsApplicationAdvancedForm({
    initialData,
    form
  })

  return group ? (
    <>
      {showTitle && (
        <Title level={4} mb={2}>
          {t('Application')}
        </Title>
      )}
      <ApplicationSimpleForm
        showTitle={false}
        group={[...group, 'application']}
      />
      <Form.Item name={[group, 'platformId']}>
        <PlatformSelectWithCreate />
      </Form.Item>
      <Form.Item name={[group, 'roleId']}>
        <RoleSelectWithCreate />
      </Form.Item>
    </>
  ) : (
    <Form
      onKeyPress={handleKeyPress}
      onFinish={onFinish}
      layout="vertical"
      autoComplete="off"
      form={form}>
      {showTitle && (
        <Title level={4} mb={2}>
          {t('Application')}
        </Title>
      )}
      <ApplicationSimpleForm group={['application']} />
      <Form.Item name={['platformId']}>
        <PlatformSelectWithCreate />
      </Form.Item>
      <Form.Item name={['roleId']}>
        <RoleSelectWithCreate />
      </Form.Item>
      <Button mr={2} htmlType="button" onClick={onReset} mb={4}>
        {t('Cancel')}
      </Button>
      <Button type="primary" loading={loading} onClick={() => form.submit()}>
        {t('Save')}
      </Button>
    </Form>
  )
}

ApplicationAdvancedForm.propTypes = {
  initialData: PropTypes.object,
  group: PropTypes.array,
  showTitle: PropTypes.bool
}

export default ApplicationAdvancedForm
