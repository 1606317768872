import { useMemo } from 'react'
import { useDocument, useLoading } from 'hooks'
import { useGetMockupOffer } from 'domains/Mockup/hooks'

const useGetMockupInitialValues = (id) => {
  // [DATA_FETCH]
  const [mockup, mockupLoading] = useDocument({
    ref: id ? `mockups/${id}` : null
  })
  const [offerId, offerLoading] = useGetMockupOffer(mockup)

  // [COMPUTED_PROPERTIES]
  const initialValues = useMemo(
    () => ({
      mockup,
      offerId
    }),
    [mockup, offerId]
  )
  const loadings = useMemo(
    () => (id ? [!mockup, mockupLoading, offerLoading] : []),
    [id, mockup, mockupLoading, offerLoading]
  )

  // [ADDITIONAL_HOOKS]
  const loading = useLoading(loadings)

  return [initialValues, loading]
}

export default useGetMockupInitialValues
