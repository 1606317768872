const BUTTON_SHAPES = {
  rounded: {
    borderRadius: 'var(--btn-rounded-border-radius)'
  },
  circle: {
    borderRadius: 'var(--btn-circle-border-radius)'
  },
  default: {
    borderRadius: 'var(--btn-default-border-radius)'
  }
}

export default BUTTON_SHAPES
