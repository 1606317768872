const CALCULATOR_AUTHENTICATION = {
  YES: 'Yes',
  NO: 'No',
  NOT_SURE: 'Not sure'
}

const CALCULATOR_AUTHENTICATION_OPTIONS = Object.keys(
  CALCULATOR_AUTHENTICATION
).map((key) => ({ label: CALCULATOR_AUTHENTICATION[key], value: key }))

export default CALCULATOR_AUTHENTICATION
export { CALCULATOR_AUTHENTICATION_OPTIONS, CALCULATOR_AUTHENTICATION }
