import {
  AppIconAndroidScreen,
  AppIconGuideScreen,
  AppIconIosScreen
} from './AppIconContent'

import { AppIconIntroScreen } from './AppIconIntro'

export const APP_ICON_SCREENS_ORDER = [
  AppIconIntroScreen,
  AppIconGuideScreen,
  AppIconIosScreen,
  AppIconAndroidScreen
]
