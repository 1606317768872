import { COLLECTIONS } from '__constants__'
import { COLOR_REPRESENTATIONS } from 'domains/Color/__constants__'
import firebase from 'firebase/compat/app'
import { useBrandbookPickerTransformedColor } from 'domains/Brandbook/hooks'
import { useDocumentDataOnce } from 'react-firebase-hooks/firestore'

const useCustomerAccentColors = (initialData) => {
  const transformColor = useBrandbookPickerTransformedColor()
  const [customerAccentColor] = useDocumentDataOnce(
    initialData?.colorIds?.[0] &&
      firebase
        .firestore()
        .collection(COLLECTIONS.COLORS)
        .doc(initialData?.colorIds?.[0])
  )

  const customerAccentDarkenColor = transformColor({
    color: customerAccentColor?.rgba,
    colorActionType:
      COLOR_REPRESENTATIONS.RGBA_OBJ_FROM_HSLA_OBJ_FROM_RGBA_STRING
  })
  const customerAccentDarkenColorToRGBAString = transformColor({
    color: customerAccentDarkenColor,
    type: COLOR_REPRESENTATIONS.BRANDBOOK_RGBA_STRING
  })

  return [customerAccentColor?.rgba, customerAccentDarkenColorToRGBAString]
}

export default useCustomerAccentColors
