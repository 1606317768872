import styled, { css } from 'styled-components'

const radioButtonVariant = (variant, bold) => {
  const btnVariant = variant || 'default'

  if (bold && btnVariant === 'default') {
    return css`
      border-color: var(--radio-bc-selected);
    `
  }
  return css`
    border-color: var(--radio-bc-${btnVariant});
  `
}

const RadioButtonStyled = styled.label`
  display: flex;
  cursor: ${({ variant }) =>
    variant === 'disabled' ? 'not-allowed' : 'pointer'};
  align-items: center;
  transform: ${({ mega }) => mega && 'var(--radio-transform-scale)'};

  &:not(:last-child) {
    margin-right: var(--radio-mr);
  }

  & > .input {
    box-sizing: border-box;
    outline: none;
    cursor: ${({ variant }) =>
      variant === 'disabled' ? 'not-allowed' : 'pointer'};
    appearance: none;
    -webkit-appearance: none;
    margin: var(--radio-m);
    background: ${({ variant }) =>
      variant === 'disabled'
        ? 'var(--radio-bg-disabled)'
        : 'var(--radio-bg-default)'};
    width: var(--radio-width);
    height: var(--radio-height);
    border-style: solid;
    border-width: ${({ bold }) =>
      bold ? 'var(--radio-bw-bold)' : 'var(--radio-bw)'};
    ${({ variant, bold }) => radioButtonVariant(variant, bold)};
    border-radius: var(--radio-br);
    caret-color: transparent;

    &:focus-visible {
      border-width: var(--radio-bw-bold);
      border-color: ${({ variant }) =>
        variant === 'default' && 'var(--radio-bc-selected )'};
    }
  }

  & > .span {
    display: inline-block;
    font-size: var(--radio-fs);
    font-family: var(--radio-ff);
    line-height: var(--radio-lh);
    color: ${({ variant }) =>
      variant === 'disabled'
        ? 'var(--radio-c-disabled)'
        : 'var(--radio-c-default)'};
  }
`
export default RadioButtonStyled
