import { Container } from '../../../components'
import styled from 'styled-components'

const ContentScreenStyled = styled.div`
  position: relative;
  height: var(--content-screen-height);
  display: flex;
  flex-direction: column;
  background-color: ${({ inverse }) =>
    inverse ? 'var(--intro-screen-bg)' : 'var(--content-screen-bg)'};
  overflow: hidden;
`

const ScreenContainerStyled = styled(Container)`
  flex: 1 0 auto;
  position: relative;
`

const ContentStyled = styled.div`
  height: 100%;
  width: 100%;
`

const PageContainerStyled = styled.div`
  display: flex;
  height: 100%;
`

export {
  ContentScreenStyled,
  ScreenContainerStyled,
  ContentStyled,
  PageContainerStyled
}
