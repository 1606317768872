const PATHS = {
  CONFIG: {
    DEFAULT: '/auth',
    AFTER_LOGIN: '/dashboard',
    AFTER_LOGOUT: '/auth',
    AFTER_SIGNUP: '/dashboard'
  },
  UNAUTHENTICATED: {
    LOGIN: '/auth',
    LOGIN_WITH_EMAIL: '/auth/login-with-email',
    SIGNUP: '/auth/signup',
    SIGNUP_WITH_EMAIL: '/auth/sign-up-with-email',
    FORGOT_PASSWORD: '/auth/forgot-password',
    CONFIRM_EMAIL: '/auth/confirm-email',
    PUBLIC_ROUTE_OFFER: '/public-routes/offer',
    PUBLIC_ROUTE_BRANDBOOK: '/public-routes/brandbook'
  },
  SERVICE: {
    ACCESS_DENIED: '/service/access-denied',
    GDPR: '/service/gdpr',
    NOT_FOUND: '/service/404',
    TERMS_AND_CONDITIONS: '/service/terms-and-conditions'
  },
  AUTHENTICATED: {
    DASHBOARD: '/dashboard',
    USER_SHOW: '/users/:userId',
    TRANSLATIONS: '/translations',
    CUSTOMERS_ALL: '/customers',
    CUSTOMER_SHOW: '/customers/:customerId',
    CUSTOMER_APPLICATIONS_ALL: '/customers/:customerId/applications',
    CUSTOMER_BRANDBOOKS_ALL: '/customers/:customerId/brandbooks',
    CUSTOMER_REPRESENTATIVES_ALL: '/customers/:customerId/representatives',
    CUSTOMER_DATABASES_ALL: '/customers/:customerId/databases',
    CUSTOMER_FEATURES_ALL: '/customers/:customerId/features',
    CUSTOMER_MOCKUPS_ALL: '/customers/:customerId/mockups',
    CUSTOMER_OFFERS_ALL: '/customers/:customerId/offers',
    CUSTOMER_CUSTOMER_PROFILES_ALL: '/customers/:customerId/customer-profiles',
    CUSTOMER_APPLICATION_CREATE: '/customers/:customerId/application/create',
    CUSTOMER_BRANDBOOK_CREATE: '/customers/:customerId/brandbook/create',
    CUSTOMER_REPRESENTATIVE_CREATE:
      '/customers/:customerId/representative/create',
    CUSTOMER_DATABASE_CREATE: '/customers/:customerId/database/create',
    CUSTOMER_FEATURE_CREATE: '/customers/:customerId/feature/create',
    CUSTOMER_MOCKUP_CREATE: '/customers/:customerId/mockup/create',
    CUSTOMER_OFFER_CREATE: '/customers/:customerId/offer/create',
    CUSTOMER_CUSTOMER_PROFILE_CREATE:
      '/customers/:customerId/customer-profile/create',
    CUSTOMER_APPLICATION_SHOW:
      '/customers/:customerId/applications/:applicationId',
    CUSTOMER_BRANDBOOK_SHOW: '/customers/:customerId/brandbooks/:brandbookId',
    CUSTOMER_REPRESENTATIVE_SHOW:
      '/customers/:customerId/representatives/:representativeId',
    CUSTOMER_DATABASE_SHOW: '/customers/:customerId/databases/:databaseId',
    CUSTOMER_FEATURE_SHOW: '/customers/:customerId/features/:featureId',
    CUSTOMER_MOCKUP_SHOW: '/customers/:customerId/mockups/:mockupId',
    CUSTOMER_OFFER_SHOW: '/customers/:customerId/offers/:offerId',
    CUSTOMER_CUSTOMER_PROFILE_SHOW:
      '/customers/:customerId/customer-profiles/:customerProfileId',
    CUSTOMER_APPLICATION_EDIT:
      '/customers/:customerId/applications/:applicationId/edit',
    CUSTOMER_BRANDBOOK_EDIT:
      '/customers/:customerId/brandbooks/:brandbookId/edit',
    CUSTOMER_REPRESENTATIVE_EDIT:
      '/customers/:customerId/representatives/:representativeId/edit',
    CUSTOMER_DATABASE_EDIT: '/customers/:customerId/databases/:databaseId/edit',
    CUSTOMER_FEATURE_EDIT: '/customers/:customerId/features/:featureId/edit',
    CUSTOMER_MOCKUP_EDIT: '/customers/:customerId/mockups/:mockupId/edit',
    CUSTOMER_OFFER_EDIT: '/customers/:customerId/offers/:offerId/edit',
    CUSTOMER_CUSTOMER_PROFILE_EDIT:
      '/customers/:customerId/customer-profiles/:customerProfileId/edit',
    CUSTOMER_EDIT: '/customers/:customerId/edit',
    APPLICATION_SHOW: '/applications/:applicationId',
    APPLICATION_EDIT: '/applications/:applicationId/edit',
    APPLICATIONS_ALL: '/applications',
    PLATFORM_SHOW: '/platforms/:platformId',
    PLATFORM_EDIT: '/platforms/:platformId/edit',
    ROLE_SHOW: '/roles/:roleId',
    ROLE_EDIT: '/roles/:roleId/edit',
    ROLES_ALL: '/roles',
    ROLE_CREATE: '/role/create',
    BRANDBOOK_SHOW: '/brandbooks/:brandbookId',
    BRANDBOOK_EDIT: '/brandbooks/:brandbookId/edit',
    BRANDBOOKS_ALL: '/brandbooks',
    REPRESENTATIVE_SHOW: '/representatives/:representativeId',
    REPRESENTATIVE_EDIT: '/representatives/:representativeId/edit',
    REPRESENTATIVES_ALL: '/representatives',
    REPRESENTATIVE_CREATE: '/representative/create',
    DATABASE_SHOW: '/databases/:databaseId',
    DATABASE_EDIT: '/databases/:databaseId/edit',
    FEATURE_SHOW: '/features/:featureId',
    FEATURE_EDIT: '/features/:featureId/edit',
    FEATURES_ALL: '/features',
    MOCKUP_SHOW: '/mockups/:mockupId',
    MOCKUP_EDIT: '/mockups/:mockupId/edit',
    MOCKUPS_ALL: '/mockups',
    OFFER_SHOW: '/offers/:offerId',
    OFFER_EDIT: '/offers/:offerId/edit',
    OFFERS_ALL: '/offers',
    TECHNOLOGY_SHOW: '/technologies/:technologyId',
    TECHNOLOGY_EDIT: '/technologies/:technologyId/edit',
    CUSTOMER_PROFILE_SHOW: '/customer-profiles/:customerProfileId',
    CUSTOMER_PROFILE_EDIT: '/customer-profiles/:customerProfileId/edit',
    CUSTOMER_PROFILES_ALL: '/customer-profiles',
    CALCULATOR_SHOW: '/calculators/:calculatorId',
    CALCULATOR_EDIT: '/calculators/:calculatorId/edit',
    COLOR_SHOW: '/colors/:colorId',
    COLOR_EDIT: '/colors/:colorId/edit',
    DESIGN_SHOW: '/designs/:designId',
    DESIGN_EDIT: '/designs/:designId/edit',
    DESIGNS_ALL: '/designs'
  }
}

export default PATHS
