import { updateDocument, createDocument, getId } from 'services/firestore'

import { updateParentHasManyRelationship } from 'hooks'
import { Color } from 'models'
import { message } from 'antd'
import { useHistory, useParams } from 'react-router-dom'
import { useState } from 'react'
import { useTranslations } from 'contexts/Translation'

const useActionsColorAdvancedForm = ({ initialData, form } = {}) => {
  // [COMPONENT_STATE_HOOKS]
  const [loading, setLoading] = useState(false)

  // [ADDITIONAL_HOOKS]
  const { t } = useTranslations()
  const history = useHistory()
  const params = useParams()

  const prepareValues = async (values = {}, additionalValues = {}) => {
    const colorId = initialData?.['color']?._id || getId('colors')
    const additionalReferences = { colorId }
    const preparedValues = {
      _id: colorId,
      ...additionalValues,
      hex: values?.['color']?.['hex'] ?? null
    }
    if (params.customerProfileId)
      preparedValues['customerProfileId'] = params.customerProfileId
    return preparedValues
  }

  const saveForm = async (values, callback) => {
    try {
      // Prepare data to be saved
      const data = await prepareValues(values)
      // Updating parent references
      if (params.customerProfileId)
        await updateParentHasManyRelationship(
          params.customerProfileId,
          'customerProfiles',
          'colorIds',
          data._id
        )
      // Save data
      if (initialData) {
        await updateDocument('colors', initialData.color?._id, data)
        message.success(t('Color successfully updated'))
      } else {
        await createDocument('colors', data, data._id)
        message.success(t('Color successfully created'))
      }
      // Final callback
      callback?.()
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error)
      throw new Error(t('Something went wrong during data save'))
    }
  }

  const validateForm = (values) => {
    try {
      // Prepare data to be validated
      const validationData = {
        hex: values?.['color']?.['hex']
      }
      Color.validationSchema.validateSync(validationData)
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error)
      throw new Error(t('Color validation error: ') + t(error.message))
    }
  }

  const onFinish = async () => {
    if (loading) return // Avoid multiple calls

    try {
      setLoading(true)
      // Get form values
      const formValues = form.getFieldsValue()
      // Validate fields
      validateForm(formValues)
      // Final callback
      const callback = () => history.goBack()
      // Save data
      await saveForm(formValues, callback)
    } catch (error) {
      setLoading(false)
      message.error(error.message)
    }
  }

  const onReset = () => {
    form.resetFields()
    history.goBack()
  }

  return { onFinish, onReset, loading, saveForm, validateForm, prepareValues }
}

export default useActionsColorAdvancedForm
