import { useTranslations } from 'contexts/Translation'
import PropTypes from 'prop-types'
import { Title, Button } from '@qonsoll/react-design'
import { MAX_LENGTH_FIELD, MAX_LENGTH_DESCRIPTION, KEYS } from '__constants__'
import { Form, Input, Checkbox } from 'antd'
import { ImageUploader } from 'components'

const CustomerProfileSimpleForm = (props) => {
  const { initialValues, onSubmit, onCancel, showTitle, form, group } = props

  // [ADDITIONAL_HOOKS]
  const { t } = useTranslations()
  const [baseForm] = Form.useForm()

  // [COMPUTED_PROPERTIES]
  const usedForm = form || baseForm

  // [HANDLERS]
  const handleCancel = () => {
    usedForm.resetFields()
    onCancel?.()
  }
  const handleKeyPress = (e) => {
    if (e.key === KEYS.ENTER) {
      e.preventDefault()
      e.stopPropagation()
      usedForm?.submit()
    }
  }

  return group ? (
    <>
      {showTitle && (
        <Title level={4} mb={2}>
          {t('Customer profile')}
        </Title>
      )}
      <Form.Item
        label={t('Logo format')}
        name={[...group, 'logoFormat']}
        rules={[
          {
            required: false,
            message: t('Please enter your customer profile logo format!')
          }
        ]}>
        <ImageUploader
          placeholder={t('Please enter your customer profile logo format')}
        />
      </Form.Item>
      <Form.Item
        label={t('Logo url')}
        name={[...group, 'logoUrl']}
        rules={[
          {
            required: true,
            message: t('Please enter your customer profile logo url!')
          }
        ]}>
        <ImageUploader
          placeholder={t('Please enter your customer profile logo url')}
        />
      </Form.Item>
      <Form.Item
        label={t('Description')}
        name={[...group, 'description']}
        rules={[
          {
            required: false,
            message: t('Please enter your customer profile description!')
          },
          {
            max: MAX_LENGTH_DESCRIPTION,
            message: t('customer profile description is too long!')
          }
        ]}>
        <Input.TextArea
          placeholder={t('Please enter your customer profile description')}
        />
      </Form.Item>
      <Form.Item
        label={t('Description changed')}
        name={[...group, 'isDescriptionChanged']}
        valuePropName="checked"
        initialValue={false} // TODO fix initial value
        rules={[
          {
            required: false,
            message: t(
              'Please enter your customer profile description changed!'
            )
          },
          {
            max: MAX_LENGTH_DESCRIPTION,
            message: t('customer profile description changed is too long!')
          }
        ]}>
        <Input.TextArea
          placeholder={t(
            'Please enter your customer profile description changed'
          )}
        />
      </Form.Item>
      <Form.Item
        label={t('Notes')}
        name={[...group, 'notes']}
        rules={[
          {
            required: false,
            message: t('Please enter your customer profile notes!')
          },
          {
            max: MAX_LENGTH_FIELD,
            message: t('customer profile notes is too long!')
          }
        ]}>
        <Input placeholder={t('Please enter your customer profile notes')} />
      </Form.Item>
    </>
  ) : (
    <Form
      form={usedForm}
      initialValues={initialValues}
      onFinish={onSubmit}
      layout="vertical"
      onKeyPress={handleKeyPress}
      autoComplete="off">
      {showTitle && (
        <Title level={4} mb={2}>
          {t('Customer profile')}
        </Title>
      )}
      <Form.Item
        label={t('Logo format')}
        name="logoFormat"
        rules={[
          {
            required: false,
            message: t('Please enter your customer profile logo format!')
          }
        ]}>
        <ImageUploader
          placeholder={t('Please enter your customer profile logo format')}
        />
      </Form.Item>
      <Form.Item
        label={t('Logo url')}
        name="logoUrl"
        rules={[
          {
            required: true,
            message: t('Please enter your customer profile logo url!')
          }
        ]}>
        <ImageUploader
          placeholder={t('Please enter your customer profile logo url')}
        />
      </Form.Item>
      <Form.Item
        label={t('Description')}
        name="description"
        rules={[
          {
            required: false,
            message: t('Please enter your customer profile description!')
          },
          {
            max: MAX_LENGTH_DESCRIPTION,
            message: t('customer profile description is too long!')
          }
        ]}>
        <Input.TextArea
          placeholder={t('Please enter your customer profile description')}
        />
      </Form.Item>
      <Form.Item
        label={t('Description changed')}
        name="isDescriptionChanged"
        valuePropName="checked"
        initialValue={false} // TODO fix initial value
        rules={[
          {
            required: false,
            message: t(
              'Please enter your customer profile description changed!'
            )
          },
          {
            max: MAX_LENGTH_DESCRIPTION,
            message: t('customer profile description changed is too long!')
          }
        ]}>
        <Input.TextArea
          placeholder={t(
            'Please enter your customer profile description changed'
          )}
        />
      </Form.Item>
      <Form.Item
        label={t('Notes')}
        name="notes"
        rules={[
          {
            required: false,
            message: t('Please enter your customer profile notes!')
          },
          {
            max: MAX_LENGTH_FIELD,
            message: t('customer profile notes is too long!')
          }
        ]}>
        <Input placeholder={t('Please enter your customer profile notes')} />
      </Form.Item>
      {!form && (
        <>
          <Button mr={2} htmlType="button" onClick={handleCancel}>
            {t('Cancel')}
          </Button>
          <Button type="primary" onClick={() => usedForm.submit()}>
            {t('Save')}
          </Button>
        </>
      )}
    </Form>
  )
}

CustomerProfileSimpleForm.propTypes = {
  initialValues: PropTypes.object,
  onSubmit: PropTypes.func,
  onCancel: PropTypes.func,
  showTitle: PropTypes.bool,
  form: PropTypes.any,
  group: PropTypes.array
}

export default CustomerProfileSimpleForm
