import './IconographyExamplesScreen.vars.css'

import { ContentScreen } from '../../../../components'
import { ICONS_LIST } from '../../../../domains/Iconography/__constants__'
import { IconList } from '../../../../domains'
import PositionedContainerStyled from './IconographyExamplesScreen.styled'

/**
 * It returns a IconographyExamplesScreen component
 * @returns The IconographyExamplesScreen component is being returned.
 */
const IconographyExamplesScreen = () => {
  return (
    <ContentScreen
      chapter="Examples"
      domainName="Ico."
      key="IconographyExamplesScreen"
      positionOuter
      content={
        <PositionedContainerStyled>
          <IconList icons={ICONS_LIST} />
        </PositionedContainerStyled>
      }
      pageNumber="19"
    />
  )
}

export default IconographyExamplesScreen
