import { Box, Timeline } from 'offer/components'

import { TIMELINE } from '../../constants'

const ProcessTimeline = () => {
  return (
    <Box className="d-flex flex-wrap" width="100%">
      <Timeline data={TIMELINE} />
    </Box>
  )
}

export default ProcessTimeline
