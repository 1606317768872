import './ColorsShadesList.vars.css'

import ColorsShadesListStyled from './ColorsShadesList.styled'
import { SHADES_VIEW_CONFIG } from '../../__constants__'
import { ShadesList } from '../../../../domains'
import { useColorViewConfig } from '../../hooks'

/**
 * It renders a list of shades for each color
 * @returns A list of shades for each color
 */
const ColorsShadesList = () => {
  const COLORS_CONFIG = useColorViewConfig()

  return (
    <ColorsShadesListStyled>
      <ShadesList
        shades={SHADES_VIEW_CONFIG?.ACCENT}
        mainShade={COLORS_CONFIG?.ACCENT}
      />
      <ShadesList
        shades={SHADES_VIEW_CONFIG?.NEUTRAL}
        mainShade={COLORS_CONFIG?.NEUTRAL}
      />
      <ShadesList
        shades={SHADES_VIEW_CONFIG?.WHITE}
        mainShade={COLORS_CONFIG?.WHITE}
        isReverse
        bg="dark"
      />
    </ColorsShadesListStyled>
  )
}

export default ColorsShadesList
