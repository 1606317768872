const CALCULATOR_ADMINISTRATION = {
  YES: 'Yes',
  NO: 'No',
  NOT_SURE: 'Not sure'
}

const CALCULATOR_ADMINISTRATION_OPTIONS = Object.keys(
  CALCULATOR_ADMINISTRATION
).map((key) => ({ label: CALCULATOR_ADMINISTRATION[key], value: key }))

export default CALCULATOR_ADMINISTRATION
export { CALCULATOR_ADMINISTRATION_OPTIONS, CALCULATOR_ADMINISTRATION }
