import './Alphabet.vars.css'

import AlphabetStyled from './Alphabet.styled'
import { Text } from '../../../../components'
import { useMemo } from 'react'

const Alphabet = (props) => {
  const { ff } = props
  // [ADDITIONAL HOOKS]
  const alphabet = useMemo(
    () =>
      [...Array(26)].map(
        (_, i) =>
          String.fromCharCode(i + 65) + String.fromCharCode(i + 97) + ' '
      ),
    []
  )

  return (
    <AlphabetStyled>
      <Text ff={ff} variant="alphabet" textAlign="justify">
        {alphabet}
      </Text>
    </AlphabetStyled>
  )
}

export default Alphabet
