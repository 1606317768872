import './ShadeSimpleView.vars.css'

import PropTypes from 'prop-types'
import ShadeSimpleViewStyled from './ShadeSimpleView.styled'
import { Text } from '../../../../components'

/**
 * It's a function that returns a styled component that contains a percentage for shade.
 * It is used for show simple shade view
 */
const ShadeSimpleView = (props) => {
  const { label, textColor, ...rest } = props

  return (
    <ShadeSimpleViewStyled color={textColor} {...rest}>
      <Text ff="primary" color={textColor}>
        {label}
      </Text>
    </ShadeSimpleViewStyled>
  )
}

ShadeSimpleView.propTypes = {
  color: PropTypes.string,
  textColor: PropTypes.string,
  label: PropTypes.string,
  isReverse: PropTypes.bool
}

export default ShadeSimpleView
