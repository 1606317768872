import breakpointsConfig from 'styles/breakpoints'
import { css } from 'styled-components'

const { breakpoints } = breakpointsConfig

/**
 * If it is a string - returns the value for all screens.
 * If it is an array, then returns the value from the smallest media size to the largest.
 * When this is the last value of the array, it works for all screens, from the last media size to screens larger than it.
 * @param {array, string} size  - divider height -> xs, sm, md, xl.
 * @returns This function returns css depending on incoming data (array or string).
 */

const height = (size) => {
  const isArray = Array.isArray(size)

  const height = (size) => css`
    height: var(--divider-${size}-height);
  `

  const heightType = {
    withMedia: (size, i) => css`
      @media (max-width: ${breakpoints[i]}) {
        ${height(size)}
      }
    `,
    withoutMedia: (size) => height(size)
  }

  return isArray
    ? size
        ?.map((sizeItem, i) => {
          const isLast = size.length - 1 === i
          return isLast
            ? heightType.withoutMedia(sizeItem)
            : heightType.withMedia(sizeItem, i)
        })
        .reverse()
    : height(size)
}

export default height
