import {
  DecorationImg,
  TBodyStyled,
  THeadStyled,
  TableColStyled,
  TableHeadStyled,
  TableRowStyled,
  TableStyled
} from './ModelView.styled'

import { IMAGE_DECORATIONS } from '../../../../__constants__'
import PropTypes from 'prop-types'
import { Title } from '../../../../components'

const ModelView = (props) => {
  const { model } = props
  const { LEFT_IMG, RIGHT_IMG } = IMAGE_DECORATIONS

  return (
    <TableStyled>
      {/* Head */}
      <THeadStyled>
        <TableRowStyled>
          <TableHeadStyled>
            <DecorationImg src={LEFT_IMG} alt="decoration-table-header" left />
            <Title level="5" type="white">
              {model?.name}
            </Title>
            <DecorationImg
              src={RIGHT_IMG}
              alt="decoration-table-header"
              right
            />
          </TableHeadStyled>
        </TableRowStyled>
      </THeadStyled>
      {/* Body */}
      <TBodyStyled>
        {model?.modelProperties.map((modelProperty, index) => (
          <TableRowStyled
            key={`${index}-domain-property`}
            borderBottom="var(--offer-generator-table-row-border-default)"
            justifyContent="space-between"
            className="row py-2">
            <TableColStyled className="col-8">
              <Title
                level="6"
                fontWeight="medium"
                textOverflow="ellipsis"
                overflow="hidden">
                {modelProperty?.propertyName}
              </Title>
            </TableColStyled>
            <TableColStyled className="col-4">
              <Title
                type="secondary"
                level="6"
                textOverflow="ellipsis"
                overflow="hidden"
                textAlign="right"
                fontWeight="medium">
                {modelProperty?.model}
              </Title>
            </TableColStyled>
          </TableRowStyled>
        ))}
      </TBodyStyled>
    </TableStyled>
  )
}

ModelView.propTypes = {
  model: PropTypes.object
}
export default ModelView
