import { Box } from '../../../../components'
import { FeatureView } from '../'
import PropTypes from 'prop-types'

const FeatureList = (props) => {
  const { data } = props

  return (
    <Box className="row">
      {data?.map((item) => (
        <Box
          key={item?.name}
          className="col-xl-3 col-lg-6 col-md-6 col-sm-6 justify-content-center d-flex">
          <FeatureView feature={item} />
        </Box>
      ))}
    </Box>
  )
}

FeatureList.propTypes = {
  data: PropTypes.array
}

export default FeatureList
