import './ButtonSimpleView.vars.css'

import { Box } from '../../../../components'
import ButtonStyled from './ButtonSimpleView.styled'

/**
 * It takes in a props object and returns a Box component with a Button component inside of it.
 * @param props - This is the props object that is passed to the component.
 * @returns A function that returns a Box component with a Button component inside of it.
 */
const ButtonSimpleView = (props) => {
  return (
    <Box mt={24} width="inherit">
      <ButtonStyled {...props} />
    </Box>
  )
}

export default ButtonSimpleView
