import { useEffect, useState } from 'react'
import { getDocument } from 'services/firestore'

const useGetCustomerProfileCalculator = (customerProfile) => {
  const [calculator, setCalculator] = useState(null)
  const [loading, setLoading] = useState(!!customerProfile?.calculatorId)

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true)
      const document = await getDocument(
        'calculators',
        customerProfile.calculatorId
      )
      setCalculator(document)
      setLoading(false)
    }
    customerProfile?.calculatorId && fetchData()
  }, [customerProfile])

  return [calculator, loading]
}

export default useGetCustomerProfileCalculator
