import { InputGrid, InputWrapper } from './InputList.styled'

import { INPUT_VARIANTS } from '../../__constants__'
import { Input } from '../../../../components'

/**
 * It takes an array of objects, and for each object, it renders a component with the object's
 * properties as props.
 * @returns An array of Input components.
 */
const InputList = () => {
  return (
    <InputGrid>
      {INPUT_VARIANTS.map((item, index) => (
        <InputWrapper key={`${index}-input`}>
          <Input {...item} />
        </InputWrapper>
      ))}
    </InputGrid>
  )
}

export default InputList
