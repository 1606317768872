import { BRANDBOOK_CONFIG_KEYS } from 'brandbook/__constants__'
import { BRANDBOOK_ID } from 'brandbook/__constants__'
import { COLOR_REPRESENTATIONS } from 'domains/Color/__constants__'
import _ from 'lodash'
import { useBrandbookPickerTransformedColor } from 'domains/Brandbook/hooks'
import { useEffect } from 'react'

const useBrandbookConfigValues = (brandbookConfigData) => {
  const transformColor = useBrandbookPickerTransformedColor()

  useEffect(() => {
    const root = document.getElementById(BRANDBOOK_ID)

    if (brandbookConfigData) {
      Object.keys(brandbookConfigData).forEach((key) => {
        if (
          [
            _.camelCase(BRANDBOOK_CONFIG_KEYS.ACCENT),
            _.camelCase(BRANDBOOK_CONFIG_KEYS.ACCENT_DARKEN),
            _.camelCase(BRANDBOOK_CONFIG_KEYS.NEUTRAL)
          ].includes(key)
        ) {
          root?.style?.setProperty(
            BRANDBOOK_CONFIG_KEYS[_.toUpper(_.snakeCase(key))],
            transformColor({
              color: brandbookConfigData?.[key],
              type: COLOR_REPRESENTATIONS.BRANDBOOK_VAR
            })
          )
        } else if (
          [
            _.camelCase(BRANDBOOK_CONFIG_KEYS.FF_PRIMARY),
            _.camelCase(BRANDBOOK_CONFIG_KEYS.FF_SECONDARY)
          ].includes(key)
        ) {
          root?.style?.setProperty(
            BRANDBOOK_CONFIG_KEYS[_.toUpper(_.snakeCase(key))],
            brandbookConfigData?.[key]?.name
          )
        } else {
          root?.style?.setProperty(
            BRANDBOOK_CONFIG_KEYS[key.toUpperCase()],
            brandbookConfigData?.[key]
          )
        }
      })
    }
  }, [brandbookConfigData])
}

export default useBrandbookConfigValues
