import { useMemo } from 'react'
import { useDocument, useLoading } from 'hooks'
import {
  useGetOfferFeatures,
  useGetOfferMockups,
  useGetOfferTechnologies
} from 'domains/Offer/hooks'

const useGetOfferInitialValues = (id) => {
  // [DATA_FETCH]
  const [offer, offerLoading] = useDocument({ ref: id ? `offers/${id}` : null })
  const [featureIds, featureLoading] = useGetOfferFeatures(offer)
  const [mockupIds, mockupLoading] = useGetOfferMockups(offer)
  const [technologyIds, technologyLoading] = useGetOfferTechnologies(offer)

  // [COMPUTED_PROPERTIES]
  const initialValues = useMemo(
    () => ({
      offer,
      featureIds,
      mockupIds,
      technologyIds
    }),
    [offer, featureIds, mockupIds, technologyIds]
  )
  const loadings = useMemo(
    () =>
      id
        ? [
            !offer,
            offerLoading,
            featureLoading,
            mockupLoading,
            technologyLoading
          ]
        : [],
    [id, offer, offerLoading, featureLoading, mockupLoading, technologyLoading]
  )

  // [ADDITIONAL_HOOKS]
  const loading = useLoading(loadings)

  return [initialValues, loading]
}

export default useGetOfferInitialValues
