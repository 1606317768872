import { Box, Remove } from '@qonsoll/react-design'
import { Player, Recorder } from '@qonsoll/qvideo'

import { Icon } from '@qonsoll/icons'
import PropTypes from 'prop-types'
import { useTranslations } from 'contexts/Translation'

const QVIDEO_API_KEY = process.env.REACT_APP_QVIDEO_API_KEY

const VideoService = (props) => {
  const { removable, playerOnly, onUpload, qVideoId } = props

  // [ADDITIONAL HOOKS]
  const { t, language } = useTranslations()

  // [HELPER FUNCTIONS]
  const handleRemove = () => {
    if (!qVideoId) return

    onUpload && onUpload(undefined)
  }

  return (
    <Box height="100%">
      {qVideoId ? (
        <Box height="100%" position="relative">
          <Player
            apiKey={QVIDEO_API_KEY}
            videoToken={qVideoId}
            spinnerSize="sm"
          />
          {removable && (
            <Box position="absolute" top={10} right={10}>
              <Remove
                icon={
                  <Icon
                    name="TrashFilled"
                    size={20}
                    fill="var(--ql-color-white)"
                  />
                }
                type="primary"
                itemName={t('video')}
                onSubmit={handleRemove}
                cancelLabel={t('No, keep')}
                confirmLabel={t('Yes, remove')}
                popconfirmPlacement="bottomRight"
              />
            </Box>
          )}
        </Box>
      ) : (
        !playerOnly && (
          <Box height="100%">
            <Recorder
              onUpload={onUpload}
              isUpload
              isLink
              autoStart
              isMicroConfig
              isScreenRecord
              isCameraConfig
              language={language}
              videoDuration={180}
              countdownDuration={3}
              apiKey={QVIDEO_API_KEY}
            />
          </Box>
        )
      )}
    </Box>
  )
}
VideoService.propTypes = {
  qVideoId: PropTypes.string,
  onUpload: PropTypes.func,
  playerOnly: PropTypes.string,
  removable: PropTypes.bool
}

export default VideoService
