import { IntroScreen } from '../../../../components'
import { SCREENS_TABLE_OF_CONTENT } from '../../../../__constants__'

const ColorIntroScreen = () => {
  return (
    <IntroScreen
      key="ColorIntroScreen"
      sectionNumber={1}
      title="Color palette."
      caption="Color System"
      contentList={SCREENS_TABLE_OF_CONTENT.COLOR_LIST}
    />
  )
}

export default ColorIntroScreen
