import './AvatarScreen.vars.css'

const avatarStyles = {
  top: 'var(--avatar-screen-top)',
  right: 'var(--avatar-screen-right)'
}

const avatarListStyles = {
  top: 'var(--avatar-list-top)',
  left: 'var(--avatar-list-left)'
}

export { avatarStyles, avatarListStyles }
