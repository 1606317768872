import PropTypes from 'prop-types'

const Language = (props) => {
  const { iconSize, iconColor, fill } = props

  return (
    <svg
      width={iconSize}
      height={iconSize}
      viewBox="0 0 72 72"
      fill={fill}
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M63 36H9.00001M36 63C30.6599 63 25.4397 61.4165 20.9996 58.4497C16.5595 55.4829 13.0988 51.2661 11.0553 46.3325C9.0117 41.3989 8.47701 35.9701 9.51881 30.7326C10.5606 25.4951 13.1321 20.6841 16.9081 16.9081C20.6841 13.1321 25.4951 10.5606 30.7326 9.51881C35.9701 8.47701 41.3989 9.0117 46.3325 11.0553C51.2661 13.0988 55.4829 16.5595 58.4497 20.9996C61.4165 25.4397 63 30.6599 63 36C63 43.1609 60.1554 50.0284 55.0919 55.0919C50.0284 60.1554 43.1609 63 36 63V63ZM48 36C48 21 42.63 9.00001 36 9.00001C29.37 9.00001 24 21 24 36C24 51 29.37 63 36 63C42.63 63 48 51 48 36Z"
        stroke={iconColor}
        strokeWidth="6"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

Language.propTypes = {
  iconSize: PropTypes.string.isRequired,
  fill: PropTypes.string,
  iconColor: PropTypes.string
}
export default Language
