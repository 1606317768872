import { Title } from '../../../../../../../components'
import styled from 'styled-components'

const ExteriorInfoTitle = styled(Title)`
  word-break: break-word;
  & span {
    font-family: var(-ff-senseteq) 
    color: var(--senseteq-text-accent);
    font-family: var(--senseteq-ff);
  }
`

export default ExteriorInfoTitle
