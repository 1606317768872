import {
  ContentScreen,
  PositionedContainer,
  RadioButton
} from '../../../../components'

import { RadioButtonList } from '../../../../domains'
import responsiveStyles from './RadioScreen.styled'

/**
 * It returns a ContentScreen with elements of Radio
 * @returns A ContentScreen component with the following props:
 *   key: "RadioScreen"
 *   chapter: "Radio"
 *   domainName: "Elem."
 *   pageNumber: "31"
 *   asideTopContent: <RadioList />
 *   content: <PositionedContainer right="-96px" top
 */
const RadioScreen = () => {
  return (
    <ContentScreen
      key="RadioScreen"
      chapter="Radio"
      domainName="Elem."
      pageNumber="32"
      asideTopContent={<RadioButtonList />}
      content={
        <PositionedContainer {...responsiveStyles}>
          <RadioButton mega variant="focus" bold />
        </PositionedContainer>
      }
    />
  )
}

export default RadioScreen
