const BUTTON_SIZES = {
  lg: {
    fontSize: 'var(--btn-lg-font-size)',
    lineHeight: 'var(--btn-lg-line-height)',
    fontWeight: 'var(--btn-lg-font-weight)',
    padding: 'var(--btn-lg-padding-y) var(--btn-lg-padding-x)'
  },
  md: {
    fontSize: 'var(--btn-md-font-size)',
    lineHeight: 'var(--btn-md-line-height)',
    fontWeight: 'var(--btn-md-font-weight)',
    padding: 'var(--btn-md-padding-y) var(--btn-md-padding-x)'
  }
}

export default BUTTON_SIZES
