import { BoundedContainer, IntroScreenHeaderWrapper } from './components'
import { Divider, Text, Title } from '../../../../../components'

import { Box } from '@qonsoll/react-design'
import { INTRO_SCREEN_HEADER_POSITION } from '../../__constants__'
import PropTypes from 'prop-types'

/**
 * It renders a section header for the IntroScreen
 * @param {number} sectionNumber - Number of the page
 * @param {string} title - Title of IntroScreen
 * @param {string} caption - Caption of IntroScreen - clarifying info
 * @returns A React component - IntroScreenHeader
 */
const IntroScreenHeader = (props) => {
  const { sectionNumber, title, caption } = props

  return (
    <IntroScreenHeaderWrapper>
      <BoundedContainer {...INTRO_SCREEN_HEADER_POSITION.dividerTop}>
        <Divider size="xs" color="white" />
      </BoundedContainer>

      <BoundedContainer {...INTRO_SCREEN_HEADER_POSITION.title}>
        <Box whiteSpace="nowrap">
          <Title ff="secondary" variant="mega2" color="accent" ws="nowrap">
            #{sectionNumber}
          </Title>
          <Title ff="secondary" ws="nowrap" variant="mega2" color="white">
            {title}
          </Title>
        </Box>
      </BoundedContainer>

      <BoundedContainer {...INTRO_SCREEN_HEADER_POSITION.dividerBottom}>
        <Divider size="sm" color="white" />
      </BoundedContainer>

      <BoundedContainer {...INTRO_SCREEN_HEADER_POSITION.caption}>
        <Text
          ff="primary"
          color="neutral"
          inverse
          variant="subtitle2"
          uppercase>
          {caption}
        </Text>
      </BoundedContainer>
    </IntroScreenHeaderWrapper>
  )
}

IntroScreenHeader.propTypes = {
  sectionNumber: PropTypes.number,
  title: PropTypes.string,
  caption: PropTypes.string
}

export default IntroScreenHeader
