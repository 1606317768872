import { BoundedContainer } from './components'
import { ColorsShadesList } from '../../../../domains'
import { ContentScreen } from '../../../../components'
import { useMediaQuery } from 'hooks'

/**
 * It returns a IconographyExamplesScreen component
 * @returns The IconographyExamplesScreen component is being returned.
 */
const ColorShadesScreen = () => {
  // [ADDITIONAL_HOOKS]
  const isBreakpoint = useMediaQuery('(max-width: 576px)')
  const footerColor = isBreakpoint ? 'dark' : 'white'

  return (
    <ContentScreen
      positionOuter
      key="ShadesScreen"
      chapter="Shades"
      domainName="Color palette."
      content={
        <BoundedContainer right="0" bottom="0">
          <ColorsShadesList />
        </BoundedContainer>
      }
      pageNumber="05"
      footerColor={footerColor}
    />
  )
}

export default ColorShadesScreen
