import { useEffect, useState } from 'react'
import { getDocument } from 'services/firestore'

const useGetMockupOffer = (mockup) => {
  const [offer, setOffer] = useState(null)
  const [loading, setLoading] = useState(!!mockup?.offerId)

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true)
      const document = await getDocument('offers', mockup.offerId)
      setOffer(document)
      setLoading(false)
    }
    mockup?.offerId && fetchData()
  }, [mockup])

  return [offer, loading]
}

export default useGetMockupOffer
