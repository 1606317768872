import { List } from '../../../../components'
import { TITLE_LISTS } from '../../__constants__'
import { TitleItem } from '../../../../domains'
import TitleListStyled from './TitleList.styled'

/**
 * It returns a styled component that contains a list of title items
 * @returns A styled component that contains a list of TitleItems
 */
const TitleList = () => {
  return (
    <TitleListStyled>
      <List dataSource={TITLE_LISTS} ListItem={TitleItem} />
    </TitleListStyled>
  )
}

export default TitleList
