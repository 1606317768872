import './TypoIntro.vars.css'

import { Box, Text, Title } from '../../../../components'

import PropTypes from 'prop-types'
import TypoIntroWrapper from './TypoIntro.styled'

/**
 * It returns a div with text
 * @param {string} title - name of typo
 * @param {string} subtitle - subtitle of typo
 * @param {string} description - description of typo
 * @returns Universal component witch can use in all  screen where you need to paste text above divider
 */

const TypoIntro = (props) => {
  const { title, subtitle, description } = props

  return (
    <TypoIntroWrapper>
      <Box mb={2}>
        <Title ff="secondary" variant="heading4">
          {title}
        </Title>
      </Box>
      <Box mb={32}>
        <Text ff="primary" variant="body2" color="secondary">
          {subtitle}
        </Text>
      </Box>
      <Box mb={40}>
        <Text ff="primary">{description}</Text>
      </Box>
    </TypoIntroWrapper>
  )
}

TypoIntro.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
  description: PropTypes.string
}

export default TypoIntro
