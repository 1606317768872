import './GreetingScreen.vars.css'

import { Box, Footer, Header, Text, Title } from 'offer/components'
import { DecorationImg, GreetingScreenStyled } from './GreetingScreen.styled'

import PropTypes from 'prop-types'
import { useScreensNavigation } from '../../../hooks'

/**
 * It's a function that returns a GreetingScreen. It is used for first and last Screen.
 * It shows title, subtitle and decoration (if it was passed).
 * Also it render Header and Footer by default
 */
const GreetingScreen = (props) => {
  const { title, subtitle, decorationSrc, id } = props
  // [ADDITIONAL_HOOKS]
  // hook is used for checking if curent screen is in view
  const observe = useScreensNavigation(id)

  // [COMPUTED PROPERTIES]
  // get the last character and make it accent color
  const titleComputed =
    title &&
    `${title.slice(0, -1)}<span class='text-accent-color'>${
      title.slice(-1)[0]
    }</span>`

  return (
    <GreetingScreenStyled ref={observe} id={id}>
      <Header />
      {decorationSrc && <DecorationImg src={decorationSrc} />}
      <Box className="container greeting-container d-flex flex-column position-relative justify-content-center">
        <Box className="row">
          {subtitle && (
            <Box className="col-12 mb-3">
              <Text
                type="accent"
                variant="caption1"
                textTransform="uppercase"
                className="offer-print-greeting-screen-subtitle">
                {subtitle}
              </Text>
            </Box>
          )}
          <Box className="col-12">
            <Title>{titleComputed}</Title>
          </Box>
        </Box>
      </Box>
      <Footer />
    </GreetingScreenStyled>
  )
}
GreetingScreen.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
  decorationSrc: PropTypes.string,
  id: PropTypes.string
}
export default GreetingScreen
