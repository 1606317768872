import { Box, Title } from '../../../../../components'

import styled from 'styled-components'

const AsideStyled = styled.div`
  margin-top: auto;
  margin-left: var(--aside-ml);
`

const TitleStyled = styled(Title)`
  max-width: var(--title-max-w);
`

const BoxStyled = styled(Box)`
  margin-bottom: ${({ mb }) => mb || '40px'};
`

export { AsideStyled, TitleStyled, BoxStyled }
