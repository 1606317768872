import { BoundedContainer } from '../IntroScreenHeader/components'
import { Chapter } from '../../../../../components'
import PropTypes from 'prop-types'

/**
 * It is a Component which displayes content of IntroScreen
 * @param {array} data - An array of chapters in IntroScreen
 * @returns A React component - IntroScreenContent
 */
const IntroScreenContent = (props) => {
  const { data } = props

  return (
    <BoundedContainer bottom="8%" right="7%">
      {data?.map((item, index) => (
        <Chapter key={`${index}-chapter`}>{item}</Chapter>
      ))}
    </BoundedContainer>
  )
}

IntroScreenContent.propTypes = {
  data: PropTypes.array
}
export default IntroScreenContent
