import DecorationStyled from './Decoration.styled'
import { Img } from '../../../../../components'
import PropTypes from 'prop-types'

/**
 * A component that insert image into the background
 * @param {string} decoration - decoration type.
 * @param {string} imageSrc - src to image.
 */

const Decoration = (props) => {
  const { decoration, imageSrc } = props
  return (
    <DecorationStyled decoration={decoration}>
      <Img width="inherit" src={imageSrc} alt="decoration bg" />
    </DecorationStyled>
  )
}

Decoration.propTypes = {
  decoration: PropTypes.string,
  imageSrc: PropTypes.string
}

export default Decoration
