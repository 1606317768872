import {
  BUTTON_SHAPES,
  BUTTON_SIZES,
  BUTTON_VARIANTS
} from '../../../__constants__'

import styled from 'styled-components'

const ButtonStyled = styled.button`
  cursor: pointer;
  ${(props) => BUTTON_SIZES[props.size] || BUTTON_SIZES.md}
  ${(props) => BUTTON_VARIANTS[props.variant] || BUTTON_VARIANTS.text}
  ${(props) => BUTTON_SHAPES[props.shape] || BUTTON_SHAPES.default}
`

export default ButtonStyled
