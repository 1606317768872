import {
  Box,
  Divider,
  PositionedContainer,
  Text
} from '../../../../../components'

import { ExteriorInfoTitle } from './components'
import PropTypes from 'prop-types'

/**
 *  Used for first and last screen -- greeting and thanks.
 *  @param {string} subtitle - subtitle for information.
 *  @param {string} - main title page.
 *  @returns exterior screen info.
 */

const ExteriorScreenInfo = (props) => {
  const { subtitle, title } = props

  /* [COMPUTER_PROPERTIES] */
  const computedTitle = `${title.slice(0, title.indexOf('.'))}`
  const lastCharacterTitle = title[title.length - 1]

  return (
    <>
      <Box mb={[2, 4]}>
        <ExteriorInfoTitle variant="mega1" fw="bold">
          {computedTitle}
          <span>{lastCharacterTitle}</span>
        </ExteriorInfoTitle>
      </Box>

      <Box ml={['var(--offset-24)', 'var(--offset-40)', 5]} mt={[3, 2]}>
        <Box pl={[3, 4]}>
          <Text ff="senseteq" variant="subtitle2">
            {subtitle}
          </Text>
        </Box>

        <Box position="relative">
          <PositionedContainer bottom="0">
            <Divider color="senseteq" bold size="sm" />
          </PositionedContainer>
        </Box>
      </Box>
    </>
  )
}

ExteriorScreenInfo.propTypes = {
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string.isRequired
}

export default ExteriorScreenInfo
