import { model, attr, hasOne } from 'services/model'
import * as yup from 'yup'

const validationSchema = yup.object().shape({
  name: yup
    .string()
    .typeError('Field name has wrong type')
    .required('Field name is required'),
  customerId: yup
    .string()
    .typeError('Field customerId has wrong type')
    .default(null)
    .nullable()
})

const Role = model(
  'role',
  {
    name: attr('string'),
    customerId: hasOne('customer')
  },
  validationSchema
)

export default Role
