import {
  BRANDBOOK_CONFIG_KEYS,
  BRANDBOOK_CONFIG_REDUCER_CASES
} from 'brandbook/__constants__'
import { useEffect, useReducer } from 'react'

import BrandbookConfigContext from 'brandbook/contexts/BrandbookConfig/BrandbookConfigContext'
import { ICON_STYLE } from '../../../../modules/brandbook-module/__constants__'
import PropTypes from 'prop-types'
import _ from 'lodash'
import { brandbookConfigReducer } from 'brandbook/contexts/BrandbookConfig'
import { useBrandbookConfigValues } from 'domains/Brandbook/hooks'
import { useCustomerAccentColors } from 'brandbook/domains/Color/hooks'

// The value of colors have to be in rgba, because when cutomer will change
// the color in picker, the var will be overwritten
// So if you want to discard these changes and set the default var - you could not
const initialBrandbookConfigData = {
  [_.camelCase(BRANDBOOK_CONFIG_KEYS.ACCENT)]: 'rgba(45, 184, 103, 1)',
  [_.camelCase(BRANDBOOK_CONFIG_KEYS.ACCENT_DARKEN)]: 'rgba(38, 142, 82, 1)',
  [_.camelCase(BRANDBOOK_CONFIG_KEYS.NEUTRAL)]: 'rgba(37, 48, 55, 1)',
  [_.camelCase(BRANDBOOK_CONFIG_KEYS.BR)]: '8px',
  [_.camelCase(BRANDBOOK_CONFIG_KEYS.FF_PRIMARY)]: 'var(--ff-primary)',
  [_.camelCase(BRANDBOOK_CONFIG_KEYS.FF_SECONDARY)]: 'var(--ff-secondary)',
  [_.camelCase(BRANDBOOK_CONFIG_KEYS.ICON_STYLE)]: ICON_STYLE.OUTLINED
}

const BrandbookConfigProvider = (props) => {
  const { children, initialData, isOnlyView = false } = props

  // [COMPONENT_STATE_HOOKS]
  const [brandbookConfigData, brandbookConfigDataDispatch] = useReducer(
    brandbookConfigReducer,
    initialBrandbookConfigData
  )

  // [ADDITIONAL_HOOKS]
  const [customerAccentColor, customerAccentDarkenColor] =
    useCustomerAccentColors(initialData)

  // [USE_EFFECTS]
  useEffect(() => {
    if (initialData?.brandbookConfig) {
      const updatedBrandbookConfigData = {
        ...initialData?.brandbookConfig
      }

      brandbookConfigDataDispatch({
        type: BRANDBOOK_CONFIG_REDUCER_CASES.SET_INITIAL_DATA,
        payload: updatedBrandbookConfigData
      })
    } else if (
      !initialData?.brandbookConfig &&
      customerAccentColor &&
      customerAccentDarkenColor
    ) {
      brandbookConfigDataDispatch({
        type: BRANDBOOK_CONFIG_REDUCER_CASES.SET_ACCENT_COLOR,
        payload: {
          accent: customerAccentColor,
          accentDarken: customerAccentDarkenColor
        }
      })
    }
  }, [
    initialData?.brandbookConfig,
    customerAccentColor,
    customerAccentDarkenColor
  ])

  useBrandbookConfigValues(brandbookConfigData)
  return (
    <BrandbookConfigContext.Provider
      value={{
        brandbookConfigData,
        brandbookConfigDataDispatch,
        initialBrandbookConfigData,
        isOnlyView
      }}>
      {children}
    </BrandbookConfigContext.Provider>
  )
}

BrandbookConfigProvider.propTypes = {
  children: PropTypes.node,
  initialData: PropTypes.object,
  isOnlyView: PropTypes.bool
}
export default BrandbookConfigProvider
