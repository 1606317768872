import { IntroScreenContent, IntroScreenHeader } from './components'

import IntroScreenStyled from './IntroScreen.styled'
import PropTypes from 'prop-types'

/**
 * It's a functional component that takes in props
 * returns a IntroScreen a header and a content.
 * @param {number} sectionNumber - Number of the page
 * @param {string} title - Title of IntroScreen
 * @param {string} caption - Caption of IntroScreen - clarifying info
 * @param {array} contentList - Data to be displayed in content section of Screen
 * @returns A React Component - IntroScreen with title, caption and passed data
 */
const IntroScreen = (props) => {
  const { sectionNumber, title, caption, contentList } = props

  return (
    <IntroScreenStyled>
      {/* TITLE */}
      <IntroScreenHeader
        sectionNumber={sectionNumber}
        title={title}
        caption={caption}
      />
      {/* LIST */}
      <IntroScreenContent data={contentList} />
    </IntroScreenStyled>
  )
}

IntroScreen.propTypes = {
  sectionNumber: PropTypes.number,
  title: PropTypes.string,
  caption: PropTypes.string,
  contentList: PropTypes.array
}
export default IntroScreen
