import './LogoList.vars.css'

import { Box } from 'brandbook/components'
import { LOGOS_CONFIG } from 'brandbook/domains/Logo/__constants__'
import { LogoCombinedView } from 'brandbook/domains/Logo/components'
import LogoListStyled from './LogoList.styled'
import PropTypes from 'prop-types'
import _ from 'lodash'

/**
 * LOGOS_CONFIG - MISS_USE or PROPER_USE.
 * @param {string} screen - name of the screen, will display the required list.
 * @returns logo list.
 */

const LogoList = (props) => {
  const { screen, reducerCaseKey } = props

  const screensType = {
    properUse: LOGOS_CONFIG.PROPER_USE,
    missUse: LOGOS_CONFIG.MISS_USE
  }

  // [COMPUTED_PROPERTIES]
  const justifyContent =
    screensType[screen]?.length > 3 ? 'space-between' : 'flex-end'

  const computeReducerCase = (index) =>
    'SET_LOGO_' + _.snakeCase(`${reducerCaseKey}${index + 1}`).toUpperCase()

  return (
    <LogoListStyled justifyContent={justifyContent}>
      {screensType[screen]?.map((logoConfig, i) => (
        <Box height="33.3%" pb={[2, 3]} display="flex" key={`logoConfig-${i}`}>
          <LogoCombinedView
            height="100%"
            index={i}
            className="logo-combined-view"
            logoKey={`${screen}${i + 1}`}
            {...logoConfig}
            reducerCase={computeReducerCase(i)}
          />
        </Box>
      ))}
    </LogoListStyled>
  )
}

LogoList.propTypes = {
  screen: PropTypes.string,
  reducerCaseKey: PropTypes.string
}
export default LogoList
