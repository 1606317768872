import styled from 'styled-components'

const TechnologyViewStyled = styled.div`
  display: flex;
  text-align: center;
  flex-direction: column;
  align-items: center;
`

const TechnologyImg = styled.img`
  width: var(--technology-image-size);
  height: var(--technology-image-size);
  object-fit: contain;
`

export { TechnologyViewStyled, TechnologyImg }
