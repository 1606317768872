import './MegaText.vars.css'

import styled, { css } from 'styled-components'

const variantsLines = {
  'line1-lg': css`
    left: 22%;
  `,
  'line1-md': css`
    left: ${({ responsive }) => (responsive ? 'var(--line1-left)' : '88%')};
  `,
  'line2-lg': css`
    left: 30%;
  `,
  'line2-md': css`
    left: ${({ responsive }) => (responsive ? 'var(--line2-left)' : '92%')};
  `,
  'line3-lg': css`
    left: 36%;
  `,
  'line3-md': css`
    left: ${({ responsive }) => (responsive ? 'var(--line3-left)' : '96%')};
  `
}

const FirstLine = styled.div`
  position: absolute;
  top: 18%;
  overflow: hidden;
  height: inherit;
  ${({ variant }) =>
    variant ? variantsLines[`line1-${variant}`] : variantsLines['line1-md']}
`

const SecondLine = styled.div`
  position: absolute;
  top: 40%;
  overflow: hidden;
  ${({ variant }) =>
    variant ? variantsLines[`line2-${variant}`] : variantsLines['line2-md']}
`

const ThirdLine = styled.div`
  position: absolute;
  top: 60%;
  overflow: hidden;
  ${({ variant }) =>
    variant ? variantsLines[`line3-${variant}`] : variantsLines['line3-md']}
`

export { ThirdLine, SecondLine, FirstLine }
