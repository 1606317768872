import styled, { css } from 'styled-components'

const buttonType = (type) => {
  const btnType = type || 'primary'
  return css`
    color: var(--btn-${btnType}-text-c);
    background-color: var(--btn-${btnType}-default);
    :hover {
      background-color: var(--btn-${btnType}-hover);
    }
    :focus {
      background-color: var(--btn-${btnType}-active);
    }
  `
}

const buttonState = (type, state) => {
  const btnState = state || 'default'
  return css`
    background-color: var(--btn-${type}-${btnState});
  `
}

const ButtonStyled = styled.button`
  width: 100%;
  cursor: ${({ type }) => (type === 'disabled' ? 'not-allowed' : 'pointer')};
  padding: var(--btn-py) var(--btn-px);
  border: ${({ border }) => border || 'unset'};
  border-radius: var(--btn-br);
  font-size: var(--btn-fs);
  line-height: var(--btn-lh);
  font-weight: var(--btn-fw);
  font-family: var(--btn-ff);
  transform: ${({ scale }) => scale && `scale(${scale})`};
  ${({ type }) => buttonType(type)};
  ${({ type, state }) => buttonState(type, state)};
  transform: ${({ mega }) => mega && 'var(--btn-transform-scale)'};
`

export default ButtonStyled
