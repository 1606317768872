import { useTranslations } from 'contexts/Translation'
import PropTypes from 'prop-types'
import { OfferSimpleForm } from 'domains/Offer/components'
import { Button, Title } from '@qonsoll/react-design'
import { Form, Input } from 'antd'
import { KEYS } from '__constants__'
import {
  useInitializeOfferAdvancedForm,
  useActionsOfferAdvancedForm
} from './hooks'
import { FeatureListWithCreate } from 'domains/Feature/components'
import { MockupListWithCreate } from 'domains/Mockup/components'
import { TechnologyListWithCreate } from 'domains/Technology/components'

const OfferAdvancedForm = (props) => {
  const { initialData, group, showTitle } = props

  // [ADDITIONAL_HOOKS]
  const { t } = useTranslations()
  const { form } = useInitializeOfferAdvancedForm(initialData)

  // [HANDLERS]
  const handleKeyPress = (e) => {
    if (e.key === KEYS.ENTER) {
      e.preventDefault()
      onFinish()
    }
  }

  const { loading, onFinish, onReset } = useActionsOfferAdvancedForm({
    initialData,
    form
  })

  return group ? (
    <>
      {showTitle && (
        <Title level={4} mb={2}>
          {t('Offer')}
        </Title>
      )}
      <OfferSimpleForm showTitle={false} group={[...group, 'offer']} />
      <Form.Item name={[group, 'featureIds']}>
        <FeatureListWithCreate />
      </Form.Item>
      <Form.Item name={[group, 'mockupIds']}>
        <MockupListWithCreate />
      </Form.Item>
      <Form.Item name={[group, 'technologyIds']}>
        <TechnologyListWithCreate />
      </Form.Item>
    </>
  ) : (
    <Form
      onKeyPress={handleKeyPress}
      onFinish={onFinish}
      layout="vertical"
      autoComplete="off"
      form={form}>
      {showTitle && (
        <Title level={4} mb={2}>
          {t('Offer')}
        </Title>
      )}
      <OfferSimpleForm group={['offer']} />
      <Form.Item name={['featureIds']}>
        <FeatureListWithCreate />
      </Form.Item>
      <Form.Item name={['mockupIds']}>
        <MockupListWithCreate />
      </Form.Item>
      <Form.Item name={['technologyIds']}>
        <TechnologyListWithCreate />
      </Form.Item>
      <Button mr={2} htmlType="button" onClick={onReset} mb={4}>
        {t('Cancel')}
      </Button>
      <Button type="primary" loading={loading} onClick={() => form.submit()}>
        {t('Save')}
      </Button>
    </Form>
  )
}

OfferAdvancedForm.propTypes = {
  initialData: PropTypes.object,
  group: PropTypes.array,
  showTitle: PropTypes.bool
}

export default OfferAdvancedForm
