import { useEffect, useState } from 'react'
import { getDocument } from 'services/firestore'

const useGetRoleCustomer = (role) => {
  const [customer, setCustomer] = useState(null)
  const [loading, setLoading] = useState(!!role?.customerId)

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true)
      const document = await getDocument('customers', role.customerId)
      setCustomer(document)
      setLoading(false)
    }
    role?.customerId && fetchData()
  }, [role])

  return [customer, loading]
}

export default useGetRoleCustomer
