import { useMemo } from 'react'
import { useDocument, useLoading } from 'hooks'
import {} from 'domains/Calculator/hooks'

const useGetCalculatorInitialValues = (id) => {
  // [DATA_FETCH]
  const [calculator, calculatorLoading] = useDocument({
    ref: id ? `calculators/${id}` : null
  })

  // [COMPUTED_PROPERTIES]
  const initialValues = useMemo(
    () => ({
      calculator
    }),
    [calculator]
  )
  const loadings = useMemo(
    () => (id ? [!calculator, calculatorLoading] : []),
    [id, calculator, calculatorLoading]
  )

  // [ADDITIONAL_HOOKS]
  const loading = useLoading(loadings)

  return [initialValues, loading]
}

export default useGetCalculatorInitialValues
