import styled from 'styled-components'

const PositionedContainer = styled.div`
  position: ${({ position }) => position || 'absolute'};
  top: ${({ top }) => top};
  bottom: ${({ bottom }) => bottom};
  right: ${({ right }) => right};
  left: ${({ left }) => left};
  overflow: ${({ overflow }) => overflow};
  white-space: ${({ whiteSpace }) => whiteSpace || 'normal'};
  height: ${({ height }) => height};
  width: ${({ width }) => width};
  z-index: ${({ zIndex }) => zIndex};
`

export default PositionedContainer
