import { model, attr, hasOne } from 'services/model'
import * as yup from 'yup'

const validationSchema = yup.object().shape({
  name: yup
    .string()
    .typeError('Field name has wrong type')
    .required('Field name is required'),
  platformId: yup
    .string()
    .typeError('Field platformId has wrong type')
    .default(null)
    .nullable(),
  roleId: yup
    .string()
    .typeError('Field roleId has wrong type')
    .default(null)
    .nullable(),
  pageIds: yup
    .string()
    .typeError('Field pageIds has wrong type')
    .default(null)
    .nullable()
})

const Application = model(
  'application',
  {
    name: attr('string'),
    platformId: hasOne('platform'),
    roleId: hasOne('role'),
    pageIds: attr('string')
  },
  validationSchema
)

export default Application
