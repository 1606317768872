import { useEffect } from 'react'
import { Form } from 'antd'

const useInitializeRoleAdvancedForm = (initialData) => {
  // [ADDITIONAL_HOOKS]
  const [form] = Form.useForm()

  // [LIFECYCLE]
  useEffect(() => {
    if (initialData) {
      const { role, customerId } = initialData
      const formValues = {
        role: {
          name: role['name'] ?? null
        },
        customerId: customerId ?? null
      }
      form.setFieldsValue(formValues)
    }
  }, [initialData, form])

  return { form }
}

export default useInitializeRoleAdvancedForm
