import './Aside.vars.css'

import { AsideStyled, BoxStyled } from './Aside.styled'
import { Box, Divider, Title } from '../../../../../components'

import PropTypes from 'prop-types'

/**
 * This is Aside component with the props passed in.
 * @param {string} chapter - chapter of screen
 * @param {boolean} inverse - A state of ContentScreen if it inverse to dark or no
 * @param {node} asideTopContent - A  component witch can be paste over divider
 * @param {string} domainName - Domain name
 * @returns A styled component - Aside
 */

const Aside = (props) => {
  const { chapter, domainName, asideTopContent, inverse, asideStyle } = props

  // check if we need to inverse color of content screen
  const colorInverse = inverse && 'white'

  return (
    <AsideStyled>
      {/* here we paste content witch must be on top oj divider */}
      <BoxStyled mb={asideStyle?.mb}>{asideTopContent}</BoxStyled>
      <BoxStyled mb={asideStyle?.mb}>
        <Divider color={colorInverse} />
      </BoxStyled>

      <Box mb={2}>
        <Title ff="secondary" color={colorInverse} variant="h5">
          {domainName}
        </Title>
      </Box>
      <Title ff="secondary" color={colorInverse} variant="h2" ws="nowrap">
        {chapter}
      </Title>
    </AsideStyled>
  )
}

Aside.propTypes = {
  asideTopContent: PropTypes.node,
  asideStyle: PropTypes.object,
  chapter: PropTypes.string,
  domainName: PropTypes.string,
  inverse: PropTypes.bool
}

export default Aside
