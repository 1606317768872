import { IMAGE_DECORATIONS } from '../../../__constants__'

const { IMG_DIR_1, IMG_DIR_2, IMG_DIR_3 } = IMAGE_DECORATIONS

const IMG_DIRECTION = [
  {
    img: IMG_DIR_1,
    position: 1,
    // 576
    xs: {
      width: '340px',
      left: '-14%',
      top: '8%'
    },
    // 1024
    sm: {
      width: '380px',
      left: '50%',
      top: '8%'
    },
    // 1440 - 1600
    lg: {
      width: '384px',
      left: '28%',
      top: '14%'
    },
    // 1920
    xxl: {
      width: '488px',
      left: '62%',
      top: '16%'
    }
  },
  {
    img: IMG_DIR_2,
    xs: {
      width: '242px',
      left: '34%',
      top: '16%'
    },
    sm: {
      width: '388px',
      left: '15%',
      top: '18%'
    },
    lg: {
      width: '384px',
      left: '58%',
      top: 0
    },
    xxl: {
      width: '456px',
      left: '34%',
      top: '6%'
    }
  },
  {
    img: IMG_DIR_3,
    xs: {
      width: '410px',
      left: '24%',
      top: '42%'
    },
    sm: {
      width: '466px',
      left: '34%',
      top: '40%'
    },
    xl: {
      width: '576px',
      left: '52%',
      top: '42%'
    },
    xxl: {
      width: '728px',
      left: '42%',
      top: '36%'
    }
  }
]

export default IMG_DIRECTION
