import { IntroScreen } from '../../../../components'
import { SCREENS_TABLE_OF_CONTENT } from '../../../../__constants__'

const LogoIntroScreen = () => {
  return (
    <IntroScreen
      key="LogoIntroScreen"
      sectionNumber={3}
      title="Logo."
      caption="Brand Logotype"
      contentList={SCREENS_TABLE_OF_CONTENT.LOGO_LIST}
    />
  )
}

export default LogoIntroScreen
