import styled, { css } from 'styled-components'

const CheckboxStyled = styled.input.attrs({ type: 'checkbox' })`
  appearance: none;
  -webkit-appearance: none;
  width: var(--checkbox-w);
  height: var(--checkbox-h);
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: var(--checkbox-mr);
  border-radius: var(--checkbox-br);
  border-width: var(--checkbox-bw);
  border-style: var(--checkbox-bs);
  transition: 0.3s;
  border-color: ${({ variant }) =>
    variant ? `var(--checkbox-bc-${variant})` : `var(--checkbox-bc-default)`};
  background-color: ${({ variant }) =>
    variant === 'disabled' && `var(--checkbox-bg-disabled)`};
  cursor: ${({ variant }) =>
    variant === 'disabled' ? 'not-allowed' : 'pointer'};

  :checked::after {
    content: url('/modules/brandbook-illustrations/checked.svg');
  }

  :checked {
    border-width: 0;
    background-color: ${({ variant }) =>
      variant
        ? `var(--checkbox-checked-bg-${variant});`
        : `var(--checkbox-checked-bg-default);`};
  }

  transform: ${({ mega }) => mega && 'var(--checkbox-transform-scale)'};
`

const CheckMark = styled.label`
  display: flex;
  cursor: pointer;
  align-items: center;
  color: var(--checkbox-c);
  font-size: var(--checkbox-fs);
  line-height: var(--checkbox-lh);
  font-weight: var(--checkbox-fw);
  font-family: var(--checkbox-ff);

  ${({ variant }) =>
    variant === 'disabled' &&
    css`
      color: var(--checkbox-c-disabled);
      cursor: not-allowed;
    `}
`

export { CheckboxStyled, CheckMark }
