import {
  LogoGuideScreen,
  LogoMissUseScreen,
  LogoProperUseScreen,
  LogoSymbolScreen,
  LogoWordmarkScreen
} from './LogoContent'

import { LogoIntroScreen } from './LogoIntro'

export const LOGO_SCREENS_ORDER = [
  LogoIntroScreen,
  LogoSymbolScreen,
  LogoWordmarkScreen,
  LogoGuideScreen,
  LogoProperUseScreen,
  LogoMissUseScreen
]
