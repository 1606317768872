import { Box, Text, Title } from '../../../../components'

import PropTypes from 'prop-types'
import ShadeAdvancedViewStyled from './ShadeAdvancedView.styled'

/**
 * It's a function that returns a styled component that contains a color's name, hef and rgb value.
 * It is used for show advanced shade view
 */
const ShadeAdvancedView = (props) => {
  const { shade, name, textColor, hex, rgb, isReverse } = props

  return (
    <ShadeAdvancedViewStyled shade={shade} isReverse={isReverse}>
      <Box mb={2}>
        <Title ff="secondary" variant="h6" color={textColor}>
          {name}
        </Title>
      </Box>
      <Text ff="primary" color={textColor}>
        {hex}
      </Text>
      <Text ff="primary" color={textColor}>
        {rgb}
      </Text>
    </ShadeAdvancedViewStyled>
  )
}
ShadeAdvancedView.propTypes = {
  color: PropTypes.string,
  shade: PropTypes.string,
  name: PropTypes.string,
  textColor: PropTypes.string,
  hex: PropTypes.string,
  rgb: PropTypes.string,
  isReverse: PropTypes.bool
}

export default ShadeAdvancedView
