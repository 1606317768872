import './TextArea.vars.css'

import styled from 'styled-components'

const TextAreaStyled = styled.textarea`
  display: block;
  box-sizing: border-box;
  padding: var(--offset-8) var(--offset-16);
  background: var(--text-area-bg);
  border-radius: var(--text-area-br);
  border: var(--text-area-border);
  width: ${({ width }) => width || '100%'};
  line-height: var(--text-body2-line-height);
  font-family: var(--text-body2-font-family);
  font-size: var(--text-body2-font-size);
  flex: 1;

  &.hover,
  &:hover {
    border: var(--text-area-active-border);
  }
  &.active,
  &:focus {
    outline: unset;
    border: var(--text-area-active-border);
  }
`

const TextAreaLabelWrapper = styled.label`
  display: block;
  line-height: var(--text-body2-line-height);
  font-family: var(--text-body2-font-family);
  font-size: var(--text-body2-font-size);
  font-weight: var(--text-body2-font-weight);
  margin-bottom: var(--offset-4);
`

export { TextAreaStyled, TextAreaLabelWrapper }
