import { Offer } from 'offer'
import { urlParamsStringIntoParamsObject } from 'helpers'
import { useLocation } from 'react-router-dom'

const PublicOffer = () => {
  // [ADDITIONAL HOOKS]
  const location = useLocation()
  // [COMPUTED PROPERTIES]
  const { offerId } = urlParamsStringIntoParamsObject(location.search)

  return <Offer offerId={offerId} />
}

export default PublicOffer
