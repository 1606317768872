const INPUT_VARIANTS = [
  {
    label: 'Default',
    placeholder: 'Placeholder',
    state: 'default'
  },
  {
    label: 'Hover',
    placeholder: 'Placeholder',
    state: 'hover'
  },
  {
    label: 'Active',
    placeholder: 'Placeholder',
    state: 'active'
  },
  {
    label: 'Disabled',
    placeholder: 'Placeholder',
    disabled: true
  }
]

export default INPUT_VARIANTS
