const CONTENT_LISTS = [
  { number: '02', title: 'TOC.' },
  { number: '03', title: 'Color palette.' },
  { number: '06', title: 'Typo.' },
  { number: '11', title: 'Logo.' },
  { number: '17', title: 'Ico.' },
  { number: '20', title: 'Imagery.' },
  { number: '24', title: 'App icon.' },
  { number: '28', title: 'Elem.' }
]

export default CONTENT_LISTS
