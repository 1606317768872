import { ContentScreen } from '../../../../components'
import { ImageryView } from '../../../../domains'

const ImageryInstaPostScreen = () => {
  return (
    <ContentScreen
      key="ImageryInstaPostScreen"
      chapter="Insta post"
      domainName="Imagery."
      positionOuter
      content={<ImageryView variant="instaPost" />}
      pageNumber="23"
    />
  )
}

export default ImageryInstaPostScreen
