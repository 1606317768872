import { useEffect, useState } from 'react'
import { getDocument } from 'services/firestore'

const useGetOfferMockups = (offer) => {
  const [mockups, setMockups] = useState([])
  const [loading, setLoading] = useState(!!offer?.mockupIds?.length)

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true)
      const promises = offer.mockupIds.map((mockup) =>
        getDocument('mockups', mockup)
      )
      const data = await Promise.all(promises)
      setMockups(data)
      setLoading(false)
    }

    offer?.mockupIds?.length && fetchData()
  }, [offer])

  return [mockups, loading]
}

export default useGetOfferMockups
