import './Input.vars.css'

import { InputLabelWrapper, InputStyled } from './Input.styled'

import PropTypes from 'prop-types'

/**
 * This function takes in a label, disabled, and state prop and returns a styled input element with a
 * label and a className of state.
 * @param {string} label - Label for input
 * @param {string} state - State of input : disabled, hovered, active
 * @param {boolean} disabled - Disabled state : true/false
 * @param {boolean} mega - This is a value to make big input
 * @param {string} width - This is a value that sets the width of the input
 * @returns A React component - Input
 */
const Input = (props) => {
  const { label, disabled, state, mega, width, ...rest } = props

  return (
    <>
      {label && <InputLabelWrapper htmlFor={label}>{label}</InputLabelWrapper>}
      <InputStyled
        state={state}
        disabled={disabled}
        id={label}
        className={state}
        mega={mega}
        width={width}
        {...rest}
      />
    </>
  )
}
Input.propTypes = {
  label: PropTypes.string,
  disabled: PropTypes.bool,
  state: PropTypes.string,
  mega: PropTypes.bool,
  width: PropTypes.string
}
export default Input
