import '../__constants__/LogoScreen.vars.css'

import {
  BRANDBOOK_CONFIG_KEYS,
  BRANDBOOK_CONFIG_REDUCER_CASES
} from 'brandbook/__constants__'
import { LogoCombinedView, TypoIntro } from 'brandbook/domains'

import { ASIDE_STYLE } from '../__constants__'
import { BoundedContainer } from 'brandbook/screens/Logo/LogoContent/components'
import { ContentScreen } from 'brandbook/components'
import { LOGO_BOTTOM_OFFSETS } from 'brandbook/screens/Logo/LogoContent/__constants__'

const LogoSymbolScreen = () => {
  return (
    <ContentScreen
      key="LogoSymbolScreen"
      chapter="Symbol"
      domainName="Logo."
      pageNumber="12"
      asideStyle={ASIDE_STYLE}
      asideTopContent={
        <TypoIntro
          subtitle="/Logo symbol"
          description="The image that comes to mind when you think “logo”."
          title="Pictorial mark"
        />
      }
      content={
        <BoundedContainer
          offsetBottom={LOGO_BOTTOM_OFFSETS.variant1}
          top="0"
          right="5%">
          <LogoCombinedView
            hasOffsets
            color="dark"
            logoKey={BRANDBOOK_CONFIG_KEYS.SYMBOL}
            reducerCase={BRANDBOOK_CONFIG_REDUCER_CASES.SET_LOGO_SYMBOL}
          />
        </BoundedContainer>
      }
    />
  )
}

export default LogoSymbolScreen
