import { useMemo } from 'react'
import { useDocument, useLoading } from 'hooks'
import {} from 'domains/Feature/hooks'

const useGetFeatureInitialValues = (id) => {
  // [DATA_FETCH]
  const [feature, featureLoading] = useDocument({
    ref: id ? `features/${id}` : null
  })

  // [COMPUTED_PROPERTIES]
  const initialValues = useMemo(
    () => ({
      feature
    }),
    [feature]
  )
  const loadings = useMemo(
    () => (id ? [!feature, featureLoading] : []),
    [id, feature, featureLoading]
  )

  // [ADDITIONAL_HOOKS]
  const loading = useLoading(loadings)

  return [initialValues, loading]
}

export default useGetFeatureInitialValues
