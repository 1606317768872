import styled from 'styled-components'

const ScreenStyled = styled.section`
  background-color: var(--screen-bg-default);
  min-height: var(--screen-min-height-default);
  margin: var(--offset-32) var(--offset-0);

  @media (max-width: 1024px) {
    margin: var(--offset-16) var(--offset-0);
  }

  position: relative;
  display: flex;
  flex-direction: column;
  padding: 0px var(--p-xxl);
  .screen-container {
    flex: 1;
  }
`
const lineDecorationStyles = {
  position: 'absolute',
  top: '50%',
  transform: 'translateY(-50%)',
  height: '80%',
  width: 'var(--screen-decoration-width)',
  backgroundRepeat: 'repeat-y'
}
const leftLineDecorationStyles = { ...lineDecorationStyles, left: 0 }
const rightLineDecorationStyles = { ...lineDecorationStyles, right: 0 }

export { ScreenStyled, leftLineDecorationStyles, rightLineDecorationStyles }
