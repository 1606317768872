import { BoundedContainerStyled } from './components'
import { Box } from '../../../../components'
import { ColorSimpleView } from '..'
import { useColorViewConfig } from '../../hooks'

/**
 * @returns color wrappers block (main colors screen - content).
 */

const ColorsList = () => {
  const COLORS_CONFIG = useColorViewConfig()

  return (
    <BoundedContainerStyled>
      <ColorSimpleView {...COLORS_CONFIG.ACCENT} />
      <Box display="flex" height="60%">
        <ColorSimpleView {...COLORS_CONFIG.WHITE} />
        <ColorSimpleView {...COLORS_CONFIG.NEUTRAL} />
      </Box>
    </BoundedContainerStyled>
  )
}

export default ColorsList
