import './Avatar.vars.css'

import {
  AvatarImageStyled,
  AvatarStyled,
  AvatarTextStyled
} from './Avatar.styled'

import { Icon } from '../../../components'
import PropTypes from 'prop-types'

/**
 * It returns a styled component that renders a default avatar if the variant prop is not passed in,
 * otherwise it renders the avatar variant that matches the variant prop
 * @param {string} size - Displays height and width of Avatar
 * @param {string} state - This is state of Avatar: default, hover, active, selected
 * @param {string} imageSrc - This is a path to image
 * @param {string} icon - This is an icon name
 * @param {string} iconSize - This is an icon size
 * @param {string} iconColor - This is an icon color
 * @param {string} userInitials - This is user initials
 * @param {boolean} mega - This is a value that makes avatar huge
 * @returns The AvatarStyled component with the size, state, and tabIndex props.
 */
const Avatar = (props) => {
  const {
    size,
    state,
    imageSrc,
    icon,
    iconSize,
    iconColor,
    userInitials,
    mega
  } = props

  // [COMPUTED PROPERTIES]
  const isDefaultAvatar = !icon && !imageSrc

  return (
    <AvatarStyled size={size} state={state} tabIndex="0" mega={mega}>
      {icon && <Icon icon={icon} size={iconSize} variant={iconColor} />}
      {imageSrc && <AvatarImageStyled src={imageSrc} size={size} />}
      {isDefaultAvatar && (
        <AvatarTextStyled size={size}>{userInitials}</AvatarTextStyled>
      )}
    </AvatarStyled>
  )
}

Avatar.propTypes = {
  size: PropTypes.oneOf(['lg', 'md', 'sm', 'xs']),
  state: PropTypes.oneOf(['default', 'hover', 'active', 'selected']),
  icon: PropTypes.string,
  imageSrc: PropTypes.string,
  iconSize: PropTypes.string,
  iconColor: PropTypes.string,
  userInitials: PropTypes.string,
  mega: PropTypes.bool
}

export default Avatar
