import { useEffect, useState } from 'react'
import { getDocument } from 'services/firestore'

const useGetCustomerProfileColors = (customerProfile) => {
  const [colors, setColors] = useState([])
  const [loading, setLoading] = useState(!!customerProfile?.colorIds?.length)

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true)
      const promises = customerProfile.colorIds.map((color) =>
        getDocument('colors', color)
      )
      const data = await Promise.all(promises)
      setColors(data)
      setLoading(false)
    }

    customerProfile?.colorIds?.length && fetchData()
  }, [customerProfile])

  return [colors, loading]
}

export default useGetCustomerProfileColors
