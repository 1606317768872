import './ColorSimpleView.vars.css'

import { Box, Text, Title } from '../../../../components'

import ColorSimpleViewStyled from './ColorSimpleView.styled.js'
import PropTypes from 'prop-types'

/**
 * @param {string} shade - color wrapper.
 * @param {string} name - title in wrapper.
 * @param {string} hex - description hex color in wrapper.
 * @param {string} rgb - description rgba color in wrapper.
 * @param {string} textColor - text color in wrapper.
 * @param {string} viewSize - wrapped content size.
 * @returns color wrapper, with color description.
 */

const ColorSimpleView = (props) => {
  const { shade, name, hex, rgb, textColor, viewSize } = props

  return (
    <ColorSimpleViewStyled name={name} shade={shade} viewSize={viewSize}>
      <Box mb={16}>
        <Title ff="secondary" variant="h6" color={textColor}>
          {name}
        </Title>
      </Box>
      <Text ff="primary" color={textColor}>
        {hex}
      </Text>
      <Text ff="primary" color={textColor}>
        {rgb}
      </Text>
    </ColorSimpleViewStyled>
  )
}

ColorSimpleView.propTypes = {
  viewSize: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  textColor: PropTypes.string.isRequired,
  shade: PropTypes.string.isRequired,
  hex: PropTypes.string.isRequired,
  rgb: PropTypes.string.isRequired
}
export default ColorSimpleView
