const CALCULATOR_REVENUE = {
  SUBSCRIPTION: 'Subscription',
  IN_APP: 'In app',
  ANOTHER: 'Another',
  NOT_SURE: 'Not sure'
}

const CALCULATOR_REVENUE_OPTIONS = Object.keys(CALCULATOR_REVENUE).map(
  (key) => ({ label: CALCULATOR_REVENUE[key], value: key })
)

export default CALCULATOR_REVENUE
export { CALCULATOR_REVENUE_OPTIONS, CALCULATOR_REVENUE }
