import { useEffect, useState } from 'react'
import { getDocument } from 'services/firestore'

const useGetApplicationPlatform = (application) => {
  const [platform, setPlatform] = useState(null)
  const [loading, setLoading] = useState(!!application?.platformId)

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true)
      const document = await getDocument('platforms', application.platformId)
      setPlatform(document)
      setLoading(false)
    }
    application?.platformId && fetchData()
  }, [application])

  return [platform, loading]
}

export default useGetApplicationPlatform
