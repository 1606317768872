import { ExteriorScreen } from '../../../components'

/* First screen brandbook*/
const OpeningScreen = () => {
  return (
    <ExteriorScreen
      key="OpeningScreen"
      title="Brand guidelines."
      subtitle="Summer 2022"
      decoration="opening"
    />
  )
}

export default OpeningScreen
