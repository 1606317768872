import './LogoCombinedView.vars.css'

import {
  DraggerStyled,
  LogoCombinedViewStyled
} from './LogoCombinedView.styled'
import { Img, Title } from 'brandbook/components'
import { useEffect, useMemo, useRef, useState } from 'react'

import { BRANDBOOK_CONFIG_REDUCER_CASES } from 'brandbook/__constants__'
import PropTypes from 'prop-types'
import { getBase64 } from 'helpers'
import { useBrandbookConfig } from 'brandbook/contexts/BrandbookConfig'

/**
 * @param {node} logo - accepts child elements.
 * @param {string} color - color wrapper.
 * @param {string} size - size wrapper.
 * @param {boolean} hasOffsets - bool - has gap or not.
 * @returns wrapper with logo.
 */
const LogoCombinedView = (props) => {
  const { alt, reducerCase, logoKey, hasOffsets, color, size, height } = props

  // [COMPONENT_STATE_HOOKS]
  const [file, setFile] = useState(null)
  const [fileName, setFileName] = useState(null)
  const [logoAspectRatio, setLogoAspectRatio] = useState(null)
  const [logoWidth, setLogoWidth] = useState(null)
  const [logoHeight, setLogoHeight] = useState(null)

  // [ADDITIONAL_HOOKS]
  const logoRef = useRef(null)
  const { brandbookConfigData, brandbookConfigDataDispatch, isOnlyView } =
    useBrandbookConfig()

  // [HELPER_FUNCTIONS]
  const handleUpload = async ({ onSuccess, file }) => {
    getBase64(file, (result) => setFile(result))
    setFileName(file?.name)
    onSuccess()
  }

  // [COMPUTED_PROPERTIES]
  const logoUrl = useMemo(() => {
    return file || brandbookConfigData?.logos?.[logoKey]
  }, [file, brandbookConfigData?.logos?.[logoKey]])

  // check if we need to change color of logo title
  const titleColor = color !== 'white' ? 'neutral' : color

  // [USE_EFFECTS]
  useEffect(() => {
    if (file) {
      brandbookConfigDataDispatch({
        type: BRANDBOOK_CONFIG_REDUCER_CASES[reducerCase],
        payload: { file, fileName }
      })
    }
  }, [file])

  useEffect(() => {
    // Set logo height or width based on its aspect-ratio
    if (logoRef) {
      const aspect =
        logoRef?.current?.offsetHeight / logoRef?.current?.offsetWidth
      setLogoAspectRatio(aspect)
      if (logoAspectRatio < 1) {
        setLogoWidth('66%')
      } else if (logoAspectRatio > 1) {
        setLogoHeight('66%')
      } else {
        setLogoWidth('66%')
        setLogoHeight('66%')
      }
    }
  }, [logoRef, logoAspectRatio, logoWidth, logoHeight])

  return (
    <DraggerStyled
      customRequest={handleUpload}
      fileList={null}
      disabled={isOnlyView}>
      <LogoCombinedViewStyled
        height={height}
        hasOffsets={hasOffsets}
        size={size}
        color={color}>
        {logoUrl && (
          <Img
            ref={logoRef}
            src={logoUrl}
            alt={alt}
            objectFit="contain"
            width={logoWidth}
            height={logoHeight}
          />
        )}
        {!logoUrl && (
          <Title ff="secondary" variant="h5" inverse color={titleColor}>
            Logo
          </Title>
        )}
      </LogoCombinedViewStyled>
    </DraggerStyled>
  )
}

LogoCombinedView.propTypes = {
  alt: PropTypes.string,
  height: PropTypes.string,
  reducerCase: PropTypes.string,
  logoKey: PropTypes.string,
  filePath: PropTypes.string,
  hasOffsets: PropTypes.bool,
  withDirectUpload: PropTypes.bool,
  color: PropTypes.oneOf(['accent', 'white', 'dark']),
  size: PropTypes.oneOf(['sm', 'md', 'xl', 'xxl'])
}

export default LogoCombinedView
