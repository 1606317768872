import { useEffect, useState } from 'react'
import { getDocument } from 'services/firestore'

const useGetCustomerRepresentatives = (customer) => {
  const [representatives, setRepresentatives] = useState([])
  const [loading, setLoading] = useState(
    !!customer?.customerRepresentativeIds?.length
  )

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true)
      const promises = customer.customerRepresentativeIds.map(
        (representative) => getDocument('representatives', representative)
      )
      const data = await Promise.all(promises)
      setRepresentatives(data)
      setLoading(false)
    }

    customer?.customerRepresentativeIds?.length && fetchData()
  }, [customer])

  return [representatives, loading]
}

export default useGetCustomerRepresentatives
