import {
  Avatar,
  Bell,
  Comments,
  Help,
  Home,
  IconGreed,
  Image,
  Language,
  Mail,
  Mobile,
  Notification,
  Scanner,
  Search,
  Settings,
  TV,
  Target,
  Trash,
  Union,
  User
} from '../Icons'

const ICONS_NAMES = {
  User,
  Bell,
  Comments,
  Help,
  Home,
  Image,
  Language,
  Mail,
  Mobile,
  Notification,
  Scanner,
  Search,
  Settings,
  TV,
  Target,
  Trash,
  Union,
  IconGreed,
  Avatar
}

export default ICONS_NAMES
