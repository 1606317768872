import styled from 'styled-components'

const TextPreviewStyled = styled.div`
  display: var(--text-preview-display);
  flex-direction: column;
  gap: var(--gap-32);
  max-width: var(--text-preview-mw);
`

export default TextPreviewStyled
