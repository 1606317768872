import {
  IconographyExamplesScreen,
  IconographyGuideScreen
} from './IconographyContent'

import { IconographyIntroScreen } from './IconographyIntro'

export const ICONOGRAPHY_SCREENS_ORDER = [
  IconographyIntroScreen,
  IconographyGuideScreen,
  IconographyExamplesScreen
]
