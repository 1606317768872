import FooterStyled from './Footer.styled'
import { Text } from '../../../components'

const Footer = () => {
  return (
    <FooterStyled id="pageFooter">
      <Text variant="caption1" type="secondary" className="fw-medium">
        senseteq.io
      </Text>
    </FooterStyled>
  )
}

export default Footer
