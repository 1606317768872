import { useBrandbookConfig } from 'brandbook/contexts/BrandbookConfig'
import { useEffect } from 'react'

const useOverwriteTypography = () => {
  // [ADDITIONAL_HOOKS]
  const { brandbookConfigData } = useBrandbookConfig()

  // [COMPUTED PROPERTIES]
  const primaryFont = brandbookConfigData?.ffPrimary
  const secondaryFont = brandbookConfigData?.ffSecondary

  useEffect(() => {
    const newFontStyleSheetPrimary = document.createElement('style')
    const newFontStyleSheetSecondary = document.createElement('style')

    newFontStyleSheetPrimary.textContent = `
    @font-face {
      font-family: ${primaryFont?.name};
      src: url(${primaryFont?.url}) ;
    }
  `
    newFontStyleSheetSecondary.textContent = `
      @font-face {
        font-family: ${secondaryFont?.name};
        src: url(${secondaryFont?.url}) ;
      }
    `
    document.head.appendChild(newFontStyleSheetPrimary)
    document.head.appendChild(newFontStyleSheetSecondary)
  }, [primaryFont, secondaryFont])
  return [primaryFont, secondaryFont]
}

export default useOverwriteTypography
