import { useEffect } from 'react'
import { Form } from 'antd'

const useInitializeApplicationAdvancedForm = (initialData) => {
  // [ADDITIONAL_HOOKS]
  const [form] = Form.useForm()

  // [LIFECYCLE]
  useEffect(() => {
    if (initialData) {
      const { application, platformId, roleId } = initialData
      const formValues = {
        application: {
          name: application['name'] ?? null,
          pageIds: application['pageIds'] ?? null
        },
        platformId: platformId ?? null,
        roleId: roleId ?? null
      }
      form.setFieldsValue(formValues)
    }
  }, [initialData, form])

  return { form }
}

export default useInitializeApplicationAdvancedForm
