import './Checkbox.vars.css'

import { CheckMark, CheckboxStyled } from './Checkbox.styled'

import PropTypes from 'prop-types'

/**
 * It returns a styled checkbox component
 * @param {string} label - text near checkbox
 * @param {bool} checked - is checked checkbox
 * @param {bool} disabled - is disabled checkbox
 * @param {string} variant - variant of checkbox
 * @param {number} scale - scale of checkbox
 * @returns A Checkbox component that is being styled with the CheckMark component.
 */
const Checkbox = (props) => {
  const { label, checked, disabled, variant, mega } = props

  return (
    <CheckMark variant={variant}>
      <CheckboxStyled
        checked={checked}
        disabled={disabled}
        variant={variant}
        mega={mega}
      />
      {label}
    </CheckMark>
  )
}
Checkbox.protoTypes = {
  label: PropTypes.string,
  checked: PropTypes.bool,
  disabled: PropTypes.bool,
  variant: PropTypes.oneOf(['hover', 'active', 'focus', 'disabled']),
  scale: PropTypes.number
}

export default Checkbox
