import './BigTypo.vars.css'

import styled, { css } from 'styled-components'

import PropTypes from 'prop-types'

// Component only for default h1-h5 on Screen (Use of type)
const BigTypo = styled.span`
  display: inline-block;
  color: ${({ color }) =>
    color ? `var(--text-${color})` : `var(--text-primary)`};

  font-weight: var(--big-typo-fw-semibold);
  font-family: ${({ ff }) => (ff ? `var(--ff-${ff})` : `var(--ff-secondary)`)};
  text-transform: ${({ uppercase }) => (uppercase ? 'uppercase' : 'none')};
  ${({ variant }) => css`
    font-size: ${variant
      ? `var(--big-typo-${variant}-fs)`
      : `var(--big-typo-h1-fs)`};
    line-height: ${variant
      ? `var(--big-typo-${variant}-lh)`
      : `var(--big-typo-h1-lh)`}; ;
  `}
`

BigTypo.propTypes = {
  color: PropTypes.oneOf(['white', 'accent']),
  ff: PropTypes.oneOf(['primary', 'secondary']),
  uppercase: PropTypes.bool,
  variant: PropTypes.oneOf(['h1', 'h2', 'h3', 'h4', 'h5'])
}

export default BigTypo
