import { model, attr } from 'services/model'
import * as yup from 'yup'

const validationSchema = yup.object().shape({
  estimation: yup
    .string()
    .typeError('Field estimation has wrong type')
    .default(null)
    .nullable(),
  featureLogoURL: yup
    .string()
    .typeError('Field featureLogoURL has wrong type')
    .default(null)
    .nullable(),
  name: yup
    .string()
    .typeError('Field name has wrong type')
    .required('Field name is required')
})

const Feature = model(
  'feature',
  {
    estimation: attr('string'),
    featureLogoURL: attr('string'),
    name: attr('string')
  },
  validationSchema
)

export default Feature
