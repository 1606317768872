import { ButtonGrid, ButtonListWrapper } from './ButtonList.styled'

import { BUTTONS_VARIANTS } from '../../__constants__'
import { ButtonSimpleView } from '..'
import { List } from '../../../../components'

/**
 * It takes an array of objects, and for each object, it renders a component with the object's
 * properties as props.
 * @returns A ButtonList Component
 */
const ButtonList = () => {
  return (
    <ButtonGrid>
      <ButtonListWrapper>
        <List
          ListItem={ButtonSimpleView}
          dataSource={BUTTONS_VARIANTS.primary}
        />
      </ButtonListWrapper>
      <ButtonListWrapper>
        <List
          ListItem={ButtonSimpleView}
          dataSource={BUTTONS_VARIANTS.secondary}
        />
      </ButtonListWrapper>
      <ButtonListWrapper>
        <List
          ListItem={ButtonSimpleView}
          dataSource={BUTTONS_VARIANTS.disabled}
        />
      </ButtonListWrapper>
    </ButtonGrid>
  )
}

export default ButtonList
