import { useTranslations } from 'contexts/Translation'
import PropTypes from 'prop-types'
import { Tabs, Card } from 'antd'
import { StickyContainer, Sticky } from 'react-sticky'
import {
  Container,
  Row,
  Col,
  Spin,
  Divider,
  Text,
  Title,
  Img
} from '@qonsoll/react-design'
import { useParams, useHistory, useLocation } from 'react-router-dom'
import { useDocument } from 'hooks'
import { useStateWithStorage } from 'hooks'
import { MockupOffer } from 'domains/Mockup/components'

const MockupAdvancedView = (props) => {
  // [COMPONENT_STATE_HOOKS]
  const [activeTab, setActiveTab] = useStateWithStorage(
    null,
    'mockup-advanced-view-active-tab'
  )

  // [ADDITIONAL_HOOKS]
  const { t } = useTranslations()
  const history = useHistory()
  const params = useParams()
  const location = useLocation()
  const { mockupId } = params
  const [mockup, loading] = useDocument({ ref: `mockups/${mockupId}` })

  // [COMPUTED_PROPERTIES]
  const { TabPane } = Tabs

  const changeTab = (key) => {
    if (params && mockupId) {
      if (key.includes(`mockups/${mockupId}`)) {
        history.push(key)
      }
      setActiveTab(key)
    }
  }

  return loading ? (
    <Spin />
  ) : (
    <Container>
      <Container mb={4}>
        <Card>
          <Row noGutters mb={2} v="center">
            <Col h="left">
              <Img
                style={{
                  maxHeight: '500px',
                  objectFit: 'cover',
                  width: '100%'
                }}
                src={mockup?.imageUrl}
              />
            </Col>
          </Row>
          <Row noGutters mb={2} v="center">
            <Col h="left">
              <Title level={3}>{mockup?.name}</Title>
            </Col>
          </Row>
          <Row noGutters mb={2} v="center">
            <Col h="left">
              <Text>{mockup?.description}</Text>
            </Col>
          </Row>
        </Card>
      </Container>
      <Row noGutters mb={4}>
        <Col>
          <StickyContainer>
            <Tabs defaultActiveKey={activeTab} onChange={changeTab}>
              <TabPane tab={t('Offer')} key="#offer">
                <MockupOffer
                  emptyProps={{ withCreate: false }}
                  mockup={mockup}
                />
              </TabPane>
            </Tabs>
          </StickyContainer>
        </Col>
      </Row>
    </Container>
  )
}

MockupAdvancedView.propTypes = {}

export default MockupAdvancedView
