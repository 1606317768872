import { model, attr } from 'services/model'
import * as yup from 'yup'

const validationSchema = yup.object().shape({
  dataModels: yup
    .string()
    .typeError('Field dataModels has wrong type')
    .default(null)
    .nullable(),
  enums: yup
    .string()
    .typeError('Field enums has wrong type')
    .default(null)
    .nullable()
})

const Database = model(
  'database',
  {
    dataModels: attr('string'),
    enums: attr('string')
  },
  validationSchema
)

export default Database
