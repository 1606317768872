import { ContentScreen, PositionedContainer } from '../../../../components'

import { ImageryView } from '../../../../domains'

const ImageryDirectionScreen = () => {
  return (
    <ContentScreen
      key="ImageryDirectionScreen"
      chapter="Image direction"
      domainName="Imagery."
      positionOuter
      content={
        <PositionedContainer top="0" left="0" width="100%" height="100%">
          <ImageryView variant="imgDirection" />
        </PositionedContainer>
      }
      pageNumber="21"
    />
  )
}

export default ImageryDirectionScreen
