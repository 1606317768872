import {
  updateDocument,
  createDocument,
  getId,
  saveHasManyRelationship,
  saveBelongsToRelationship
} from 'services/firestore'
import { useActionsCalculatorAdvancedForm } from 'domains/Calculator/components/CalculatorAdvancedForm/hooks'
import { uploadImage } from 'helpers'
import { updateParentHasOneRelationship } from 'hooks'
import { CustomerProfile } from 'models'
import { message } from 'antd'
import { useHistory, useParams } from 'react-router-dom'
import { useState } from 'react'
import { useTranslations } from 'contexts/Translation'

const useActionsCustomerProfileAdvancedForm = ({ initialData, form } = {}) => {
  // [COMPONENT_STATE_HOOKS]
  const [loading, setLoading] = useState(false)

  // [ADDITIONAL_HOOKS]
  const { t } = useTranslations()
  const history = useHistory()
  const params = useParams()
  const {
    prepareValues: prepareCalculatorValues,
    validateForm: validateCalculatorForm
  } = useActionsCalculatorAdvancedForm({
    initialData: initialData?.calculatorId
  })

  const prepareValues = async (values = {}, additionalValues = {}) => {
    const customerProfileId =
      initialData?.['customerProfile']?._id || getId('customerProfiles')
    const additionalReferences = { customerProfileId }
    const preparedValues = {
      _id: customerProfileId,
      ...additionalValues,
      description: values?.['customerProfile']?.['description'] ?? null,
      isDescriptionChanged:
        values?.['customerProfile']?.['isDescriptionChanged'] ?? null,
      logoFormat:
        (await uploadImage(values?.['customerProfile']?.['logoFormat'])) ??
        null,
      logoUrl:
        (await uploadImage(values?.['customerProfile']?.['logoUrl'])) ?? null,
      notes: values?.['customerProfile']?.['notes'] ?? null,
      calculatorId: await saveBelongsToRelationship(
        'calculators',
        await prepareCalculatorValues(values['calculatorId']),
        additionalReferences
      ),
      colorIds: await saveHasManyRelationship(
        'colors',
        values['colorIds'],
        additionalReferences
      ),
      designIds: await saveHasManyRelationship(
        'designs',
        values['designIds'],
        additionalReferences
      ),
      roleIds: await saveHasManyRelationship(
        'roles',
        values['roleIds'],
        additionalReferences
      )
    }
    if (params.customerId) preparedValues['customerId'] = params.customerId
    return preparedValues
  }

  const saveForm = async (values, callback) => {
    try {
      // Prepare data to be saved
      const data = await prepareValues(values)
      // Updating parent references
      if (params.customerId)
        await updateParentHasOneRelationship(
          params.customerId,
          'customers',
          'profileId',
          data._id
        )
      // Save data
      if (initialData) {
        await updateDocument(
          'customerProfiles',
          initialData.customerProfile?._id,
          data
        )
        message.success(t('CustomerProfile successfully updated'))
      } else {
        await createDocument('customerProfiles', data, data._id)
        message.success(t('CustomerProfile successfully created'))
      }
      // Final callback
      callback?.()
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error)
      throw new Error(t('Something went wrong during data save'))
    }
  }

  const validateForm = (values) => {
    try {
      // Prepare data to be validated
      const validationData = {
        description: values?.['customerProfile']?.['description'],
        isDescriptionChanged:
          values?.['customerProfile']?.['isDescriptionChanged'],
        logoFormat: values?.['customerProfile']?.['logoFormat'],
        logoUrl: values?.['customerProfile']?.['logoUrl'],
        notes: values?.['customerProfile']?.['notes']
      }
      CustomerProfile.validationSchema.validateSync(validationData)
      validateCalculatorForm(values['calculatorId'])
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error)
      throw new Error(
        t('CustomerProfile validation error: ') + t(error.message)
      )
    }
  }

  const onFinish = async () => {
    if (loading) return // Avoid multiple calls

    try {
      setLoading(true)
      // Get form values
      const formValues = form.getFieldsValue()
      // Validate fields
      validateForm(formValues)
      // Final callback
      const callback = () => history.goBack()
      // Save data
      await saveForm(formValues, callback)
    } catch (error) {
      setLoading(false)
      message.error(error.message)
    }
  }

  const onReset = () => {
    form.resetFields()
    history.goBack()
  }

  return { onFinish, onReset, loading, saveForm, validateForm, prepareValues }
}

export default useActionsCustomerProfileAdvancedForm
