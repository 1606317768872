import { useMemo } from 'react'
import { useDocument, useLoading } from 'hooks'
import {
  useGetCustomerProfileColors,
  useGetCustomerProfileDesigns,
  useGetCustomerProfileRoles
} from 'domains/CustomerProfile/hooks'
import { useGetCalculatorInitialValues } from 'domains/Calculator/hooks'

const useGetCustomerProfileInitialValues = (id) => {
  // [DATA_FETCH]
  const [customerProfile, customerProfileLoading] = useDocument({
    ref: id ? `customerProfiles/${id}` : null
  })
  const [calculatorId, calculatorLoading] = useGetCalculatorInitialValues(
    customerProfile?.['calculatorId']
  )
  const [colorIds, colorLoading] = useGetCustomerProfileColors(customerProfile)
  const [designIds, designLoading] =
    useGetCustomerProfileDesigns(customerProfile)
  const [roleIds, roleLoading] = useGetCustomerProfileRoles(customerProfile)

  // [COMPUTED_PROPERTIES]
  const initialValues = useMemo(
    () => ({
      customerProfile,
      calculatorId,
      colorIds,
      designIds,
      roleIds
    }),
    [customerProfile, calculatorId, colorIds, designIds, roleIds]
  )
  const loadings = useMemo(
    () =>
      id
        ? [
            !customerProfile,
            customerProfileLoading,
            calculatorLoading,
            colorLoading,
            designLoading,
            roleLoading
          ]
        : [],
    [
      id,
      customerProfile,
      customerProfileLoading,
      calculatorLoading,
      colorLoading,
      designLoading,
      roleLoading
    ]
  )

  // [ADDITIONAL_HOOKS]
  const loading = useLoading(loadings)

  return [initialValues, loading]
}

export default useGetCustomerProfileInitialValues
