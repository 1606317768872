import { updateDocument, createDocument, getId } from 'services/firestore'

import { updateParentHasOneRelationship } from 'hooks'
import { Calculator } from 'models'
import { message } from 'antd'
import { useHistory, useParams } from 'react-router-dom'
import { useState } from 'react'
import { useTranslations } from 'contexts/Translation'

const useActionsCalculatorAdvancedForm = ({ initialData, form } = {}) => {
  // [COMPONENT_STATE_HOOKS]
  const [loading, setLoading] = useState(false)

  // [ADDITIONAL_HOOKS]
  const { t } = useTranslations()
  const history = useHistory()
  const params = useParams()

  const prepareValues = async (values = {}, additionalValues = {}) => {
    const calculatorId =
      initialData?.['calculator']?._id || getId('calculators')
    const additionalReferences = { calculatorId }
    const preparedValues = {
      _id: calculatorId,
      ...additionalValues,
      platforms: values?.['calculator']?.['platforms'] ?? null,
      industry: values?.['calculator']?.['industry'] ?? null,
      analogue: values?.['calculator']?.['analogue'] ?? null,
      administration: values?.['calculator']?.['administration'] ?? null,
      appearance: values?.['calculator']?.['appearance'] ?? null,
      authentication: values?.['calculator']?.['authentication'] ?? null,
      brand: values?.['calculator']?.['brand'] ?? null,
      external_service: values?.['calculator']?.['external_service'] ?? null,
      localization: values?.['calculator']?.['localization'] ?? null,
      revenue: values?.['calculator']?.['revenue'] ?? null
    }
    if (params.customerId) preparedValues['customerId'] = params.customerId
    return preparedValues
  }

  const saveForm = async (values, callback) => {
    try {
      // Prepare data to be saved
      const data = await prepareValues(values)
      // Updating parent references
      if (params.customerId)
        await updateParentHasOneRelationship(
          params.customerId,
          'customers',
          'profileId',
          data._id
        )
      // Save data
      if (initialData) {
        await updateDocument('calculators', initialData.calculator?._id, data)
        message.success(t('Calculator successfully updated'))
      } else {
        await createDocument('calculators', data, data._id)
        message.success(t('Calculator successfully created'))
      }
      // Final callback
      callback?.()
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error)
      throw new Error(t('Something went wrong during data save'))
    }
  }

  const validateForm = (values) => {
    try {
      // Prepare data to be validated
      const validationData = {
        platforms: values?.['calculator']?.['platforms'],
        industry: values?.['calculator']?.['industry'],
        analogue: values?.['calculator']?.['analogue'],
        administration: values?.['calculator']?.['administration'],
        appearance: values?.['calculator']?.['appearance'],
        authentication: values?.['calculator']?.['authentication'],
        brand: values?.['calculator']?.['brand'],
        external_service: values?.['calculator']?.['external_service'],
        localization: values?.['calculator']?.['localization'],
        revenue: values?.['calculator']?.['revenue']
      }
      Calculator.validationSchema.validateSync(validationData)
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error)
      throw new Error(t('Calculator validation error: ') + t(error.message))
    }
  }

  const onFinish = async () => {
    if (loading) return // Avoid multiple calls

    try {
      setLoading(true)
      // Get form values
      const formValues = form.getFieldsValue()
      // Validate fields
      validateForm(formValues)
      // Final callback
      const callback = () => history.goBack()
      // Save data
      await saveForm(formValues, callback)
    } catch (error) {
      setLoading(false)
      message.error(error.message)
    }
  }

  const onReset = () => {
    form.resetFields()
    history.goBack()
  }

  return { onFinish, onReset, loading, saveForm, validateForm, prepareValues }
}

export default useActionsCalculatorAdvancedForm
