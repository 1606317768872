import { getHexValue, getRgbValue } from '../helpers'
import { useEffect, useState } from 'react'

const useGetColorValue = () => {
  const [state, setState] = useState()

  useEffect(() => {
    const root = document.getElementById('brandbook-module')
    const rootStyles = window.getComputedStyle(root)
    setState(rootStyles)
  }, [])

  // getting hex value from rgba
  const getHexColor = (color) => {
    const value = state?.getPropertyValue(color)
    return getHexValue(value)
  }

  // getting rgb value
  const getRgbColor = (color) => {
    const value = state?.getPropertyValue(color)
    return getRgbValue(value)
  }
  return [getRgbColor, getHexColor]
}

export default useGetColorValue
