import { useMemo } from 'react'
import { useDocument, useLoading } from 'hooks'
import {
  useGetCustomerApplications,
  useGetCustomerRepresentatives,
  useGetCustomerFeatures,
  useGetCustomerMockups
} from 'domains/Customer/hooks'
import { useBrandbookInitialValues } from 'domains/Brandbook/hooks'
import { useGetDatabaseInitialValues } from 'domains/Database/hooks'
import { useGetOfferInitialValues } from 'domains/Offer/hooks'
import { useGetCustomerProfileInitialValues } from 'domains/CustomerProfile/hooks'

const useGetCustomerInitialValues = (id) => {
  // [DATA_FETCH]
  const [customer, customerLoading] = useDocument({
    ref: id ? `customers/${id}` : null
  })
  const [applicationIds, applicationLoading] =
    useGetCustomerApplications(customer)
  const [brandbookId, brandbookLoading] = useBrandbookInitialValues(
    customer?.['brandbookId']
  )
  const [customerRepresentativeIds, representativeLoading] =
    useGetCustomerRepresentatives(customer)
  const [databaseId, databaseLoading] = useGetDatabaseInitialValues(
    customer?.['databaseId']
  )
  const [featureIds, featureLoading] = useGetCustomerFeatures(customer)
  const [mockupIds, mockupLoading] = useGetCustomerMockups(customer)
  const [offerId, offerLoading] = useGetOfferInitialValues(
    customer?.['offerId']
  )
  const [profileId, customerProfileLoading] =
    useGetCustomerProfileInitialValues(customer?.['profileId'])

  // [COMPUTED_PROPERTIES]
  const initialValues = useMemo(
    () => ({
      customer,
      applicationIds,
      brandbookId,
      customerRepresentativeIds,
      databaseId,
      featureIds,
      mockupIds,
      offerId,
      profileId
    }),
    [
      customer,
      applicationIds,
      brandbookId,
      customerRepresentativeIds,
      databaseId,
      featureIds,
      mockupIds,
      offerId,
      profileId
    ]
  )
  const loadings = useMemo(
    () =>
      id
        ? [
            !customer,
            customerLoading,
            applicationLoading,
            brandbookLoading,
            representativeLoading,
            databaseLoading,
            featureLoading,
            mockupLoading,
            offerLoading,
            customerProfileLoading
          ]
        : [],
    [
      id,
      customer,
      customerLoading,
      applicationLoading,
      brandbookLoading,
      representativeLoading,
      databaseLoading,
      featureLoading,
      mockupLoading,
      offerLoading,
      customerProfileLoading
    ]
  )

  // [ADDITIONAL_HOOKS]
  const loading = useLoading(loadings)

  return [initialValues, loading]
}

export default useGetCustomerInitialValues
