import {
  AVATAR_SIZES,
  AVATAR_STATES,
  AVATAR_VARIANTS
} from '../../__constants__'
import { AvatarGrid, AvatarListWrapper } from './AvatarListStyled.styled'
import { Box, List, Title } from 'brandbook/components'

import AvatarSimpleView from '../AvatarSimpleView'
import { useMediaQuery } from 'hooks'

/**
 * It returns a Component which displays a list of different
 * AvatarSimpleView variants
 * @returns A component that renders a list of avatars.
 */
const AvatarList = () => {
  const isBreakpoint = useMediaQuery('(max-width:576px)')

  return (
    <AvatarGrid>
      <AvatarListWrapper>
        <Box mb={3}>
          <Title ff="secondary" variant="h6">
            {AVATAR_STATES.title}
          </Title>
        </Box>
        {isBreakpoint ? (
          <Box display="flex" style={{ gap: '20px', width: 'auto' }}>
            <List
              ListItem={AvatarSimpleView}
              dataSource={AVATAR_STATES.avatar}
            />
          </Box>
        ) : (
          <List ListItem={AvatarSimpleView} dataSource={AVATAR_STATES.avatar} />
        )}
      </AvatarListWrapper>
      <AvatarListWrapper>
        <Box mb={3}>
          <Title ff="secondary" variant="h6">
            {AVATAR_SIZES.title}
          </Title>
        </Box>
        {isBreakpoint ? (
          <Box display="flex" style={{ gap: '20px', width: 'auto' }}>
            <List
              ListItem={AvatarSimpleView}
              dataSource={AVATAR_SIZES.avatar}
            />
          </Box>
        ) : (
          <List ListItem={AvatarSimpleView} dataSource={AVATAR_SIZES.avatar} />
        )}
      </AvatarListWrapper>
      <AvatarListWrapper>
        <Box mb={3}>
          <Title ff="secondary" variant="h6">
            {AVATAR_VARIANTS.title}
          </Title>
        </Box>
        {isBreakpoint ? (
          <Box display="flex" style={{ gap: '20px', width: 'auto' }}>
            <List
              ListItem={AvatarSimpleView}
              dataSource={AVATAR_VARIANTS.avatar}
            />
          </Box>
        ) : (
          <List
            ListItem={AvatarSimpleView}
            dataSource={AVATAR_VARIANTS.avatar}
          />
        )}
      </AvatarListWrapper>
    </AvatarGrid>
  )
}

export default AvatarList
