import { Box } from '../../../../../components'
import styled from 'styled-components'

const BoundedContainerStyled = styled(Box)`
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  max-height: 40rem;

  @media (max-width: 768px) {
    max-height: 36rem;
  }

  @media (max-width: 576px) {
    max-height: 20rem;
  }
`
export default BoundedContainerStyled
