import PropTypes from 'prop-types'

const Mail = (props) => {
  const { iconSize, iconColor, fill } = props

  return (
    <svg
      width={iconSize}
      height={iconSize}
      viewBox="0 0 72 72"
      fill={fill}
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M21 27L34.14 37.47C34.6696 37.8885 35.325 38.1162 36 38.1162C36.675 38.1162 37.3304 37.8885 37.86 37.47L51 27M60 57H12C11.2044 57 10.4413 56.6839 9.87868 56.1213C9.31607 55.5587 9 54.7956 9 54V18C9 17.2044 9.31607 16.4413 9.87868 15.8787C10.4413 15.3161 11.2044 15 12 15H60C60.7956 15 61.5587 15.3161 62.1213 15.8787C62.6839 16.4413 63 17.2044 63 18V54C63 54.7956 62.6839 55.5587 62.1213 56.1213C61.5587 56.6839 60.7956 57 60 57Z"
        stroke={iconColor}
        strokeWidth="6"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

Mail.propTypes = {
  iconSize: PropTypes.string.isRequired,
  fill: PropTypes.string,
  iconColor: PropTypes.string
}

export default Mail
