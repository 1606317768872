import { useEffect } from 'react'
import { Form } from 'antd'

const useInitializeMockupAdvancedForm = (initialData) => {
  // [ADDITIONAL_HOOKS]
  const [form] = Form.useForm()

  // [LIFECYCLE]
  useEffect(() => {
    if (initialData) {
      const { mockup, offerId } = initialData
      const formValues = {
        mockup: {
          name: mockup['name'] ?? null,
          imageUrl: mockup['imageUrl'] ?? null,
          description: mockup['description'] ?? null
        },
        offerId: offerId ?? null
      }
      form.setFieldsValue(formValues)
    }
  }, [initialData, form])

  return { form }
}

export default useInitializeMockupAdvancedForm
