import { useEffect, useState } from 'react'
import { getDocument } from 'services/firestore'

const useGetCustomerApplications = (customer) => {
  const [applications, setApplications] = useState([])
  const [loading, setLoading] = useState(!!customer?.applicationIds?.length)

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true)
      const promises = customer.applicationIds.map((application) =>
        getDocument('applications', application)
      )
      const data = await Promise.all(promises)
      setApplications(data)
      setLoading(false)
    }

    customer?.applicationIds?.length && fetchData()
  }, [customer])

  return [applications, loading]
}

export default useGetCustomerApplications
