import { Box } from '@qonsoll/react-design'
import styled from 'styled-components'

const IntroScreenHeaderWrapperStyled = styled(Box)`
  position: relative;
  max-width: 1280px;
  max-height: 600px;
  height: 100%;
`
export default IntroScreenHeaderWrapperStyled
