import './IconAdvancedView.vars.css'

import {
  IconGreed,
  Union
} from '../../../../components/elements/Media/Icon/Icons'

import { IconStyled } from './IconAdvancedView.styled'

const IconAdvancedView = () => {
  return (
    <>
      <IconStyled>
        <Union fill="var(--accent-default)" />
      </IconStyled>
      <IconGreed />
    </>
  )
}

IconAdvancedView.propTypes = {}

export default IconAdvancedView
