const BUTTONS_VARIANTS = {
  primary: [
    { type: 'primary', state: 'default', children: 'Primary - Default' },
    { type: 'primary', state: 'hover', children: 'Primary - Hover' },
    { type: 'primary', state: 'active', children: 'Primary - Active' }
  ],
  secondary: [
    { type: 'secondary', state: 'default', children: 'Secondary - Default' },
    { type: 'secondary', state: 'hover', children: 'Secondary - Hover' },
    { type: 'secondary', state: 'active', children: 'Secondary - Active' }
  ],
  disabled: [{ type: 'disabled', children: 'Disabled' }]
}

export default BUTTONS_VARIANTS
