import { Box, Text, Title } from 'offer/components'

import PropTypes from 'prop-types'

/**
 * It returns project description
 * @param description - The prop that contain project description
 * @returns A box with a row of technology views
 */
const DescriptionView = (props) => {
  const { description } = props

  return (
    <Box>
      <Box display="flex" mb={4}>
        <Title level={4}>Description</Title>
        <Title level={4} color="accent">
          .
        </Title>
      </Box>
      <Text variant="body2">{description}</Text>
    </Box>
  )
}

DescriptionView.propTypes = {
  description: PropTypes.string
}

export default DescriptionView
