import { useEffect } from 'react'
import { Form } from 'antd'

const useInitializeTechnologyAdvancedForm = (initialData) => {
  // [ADDITIONAL_HOOKS]
  const [form] = Form.useForm()

  // [LIFECYCLE]
  useEffect(() => {
    if (initialData) {
      const { technology } = initialData
      const formValues = {
        technology: {
          name: technology['name'] ?? null,
          description: technology['description'] ?? null,
          imageUrl: technology['imageUrl'] ?? null
        }
      }
      form.setFieldsValue(formValues)
    }
  }, [initialData, form])

  return { form }
}

export default useInitializeTechnologyAdvancedForm
