const getHexValue = (value) => {
  const hexValue = value
    ?.replace(/[^0-9 ]/g, '')
    ?.split(' ')
    ?.filter(Number)
    ?.map((item) => Number(item)?.toString(16))
    ?.join('')
    ?.slice(0, -2)
    ?.toUpperCase()

  return '#' + hexValue
}

export default getHexValue
