import { Checkbox } from 'antd'
import PropTypes from 'prop-types'
import { useGetCustomers } from 'domains/Customer/hooks'
import { useSubfilterActions } from 'hooks'
import { StyledDetails } from '../RoleFilter.styles'
import { useTranslations } from 'contexts/Translation'
import { Text } from '@qonsoll/react-design'

const CustomerFilter = ({ filterData, setFilterData }) => {
  // [ADDITIONAL_HOOKS]
  const [customers] = useGetCustomers()
  const { t } = useTranslations()

  // [COMPUTED_PROPERTIES]
  const summaryStyles = { marginBottom: '8px' }

  const { onToggleDetails, onChange, checkIsEnabled } = useSubfilterActions({
    filterData,
    setFilterData,
    fieldName: 'customer',
    operand: '=='
  })

  return (
    <StyledDetails
      open={localStorage.getItem(`customer-details-visibility`) === 'opened'}>
      <summary
        style={summaryStyles}
        onClick={() => onToggleDetails('customer')}>
        {t('Customer')}
      </summary>
      {customers?.map((customer, index) => (
        <div key={`status-${index}`}>
          <Checkbox
            key={`status-${index}`}
            checked={checkIsEnabled(customer)}
            onChange={() => onChange(customer)}>
            <Text wordBreak="break-all">
              {customer?.name || t('Unnamed filter')}
            </Text>
          </Checkbox>
        </div>
      ))}
    </StyledDetails>
  )
}

CustomerFilter.propTypes = {
  filterData: PropTypes.object,
  setFilterData: PropTypes.func
}

export default CustomerFilter
