import {
  RadioButtonListStyled,
  RadioButtonListWrapper
} from './RadioButtonList.styled'

import { RADIO_BUTTON } from '../../__constants__'
import { RadioButton } from '../../../../components'

/**
 * It render a list of radioButton
 * @returns A component that renders two lines of radioButton (checked/unchecked)
 */

const RadioButtonList = () => {
  const radioButtonChecked = RADIO_BUTTON.slice(0, -1)

  return (
    <RadioButtonListWrapper>
      <RadioButtonListStyled>
        {RADIO_BUTTON.map((item, index) => (
          <RadioButton variant={item?.variant} key={`radio-${index}`}>
            {item?.name}
          </RadioButton>
        ))}
      </RadioButtonListStyled>
      <RadioButtonListStyled>
        {radioButtonChecked.map((item, index) => (
          <RadioButton variant={item?.variant} bold key={`radio-chk-${index}`}>
            {item?.name}
          </RadioButton>
        ))}
      </RadioButtonListStyled>
    </RadioButtonListWrapper>
  )
}

export default RadioButtonList
