const CALCULATOR_PLATFORMS = {
  WEB: 'Web',
  MOBILE: 'Mobile',
  WEB_MOBILE: 'Web mobile'
}

const CALCULATOR_PLATFORMS_OPTIONS = Object.keys(CALCULATOR_PLATFORMS).map(
  (key) => ({ label: CALCULATOR_PLATFORMS[key], value: key })
)

export default CALCULATOR_PLATFORMS
export { CALCULATOR_PLATFORMS_OPTIONS, CALCULATOR_PLATFORMS }
