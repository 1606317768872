import styled, { css } from 'styled-components'

const variants = (variant) => {
  const textVariant = variant || 'h3'
  return css`
    font-size: var(--title-${textVariant}-fs);
    line-height: var(--title-${textVariant}-lh);
  `
}

const Title = styled.span`
  display: inline-block;
  text-transform: ${({ uppercase }) => (uppercase ? 'uppercase' : 'none')};

  color: ${({ color }) =>
    color ? `var(--text-${color})` : 'var(--neutral-default)'};
  font-weight: ${({ fw }) =>
    fw ? `var(--title-fw-${fw})` : 'var(--title-fw-semibold)'};
  font-family: ${({ ff }) => ff && `var(--ff-${ff})`};
  letter-spacing: ${({ letterSpacing }) =>
    letterSpacing && 'var(--title-letter-spacing)'};
  max-width: ${({ mw }) => mw};

  white-space: ${({ ws = 'normal' }) => ws};
  ${({ variant }) => variants(variant)}
  ${({ color, inverse }) =>
    inverse && color && `color: var(--${color}-inverse)`};
`

export default Title
