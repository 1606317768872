import './RadioButton.vars.css'

import PropTypes from 'prop-types'
import RadioButtonStyled from './RadioButton.styled'

/**
 * This is RadioButton component with the props passed in.
 * @param {string} Variant - A type of RadioButton : hover, active, focus or disabled
 * @param {boolean} bold - A state of RadioButton : is radioButton border bold or no
 * @param {node} children - A node - Text inside the RadioButton
 * @param {bool} mega - A mega-scaling of RadioButton
 * @returns A styled component - RadioButtonStyled
 */

const RadioButton = (props) => {
  const { children, variant, bold, mega } = props
  return (
    <RadioButtonStyled bold={bold} variant={variant} mega={mega}>
      <input readOnly className="input" value="" />
      <span className="span">{children}</span>
    </RadioButtonStyled>
  )
}

RadioButton.propTypes = {
  children: PropTypes.string,
  variant: PropTypes.oneOf(['default', 'hover', 'active', 'disabled', 'focus']),
  bold: PropTypes.bool,
  mega: PropTypes.bool
}

export default RadioButton
