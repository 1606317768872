import breakpointsConfig from 'styles/breakpoints'
import { css } from 'styled-components'

const { breakpoints } = breakpointsConfig

/**
 * If it is a string - returns the value for all screens.
 * If it is an array, then returns the value from the smallest media size to the largest.
 * When this is the last value of the array, it works for all screens, from the last media size to screens larger than it.
 * @param {string} side - The side of the element to which the value.
 * @param {array, string, number} value  - Element position value.
 * @returns This function returns css depending on incoming data (array or string).
 */

const side = (side, value) => {
  value = typeof value === 'number' ? value + '%' : value
  return `${side}: ${value};`
}

const position = {
  withoutMediaSize: (sideValue, value) => css`
    ${side(sideValue, value)}
  `,

  withMediaSize: (sideValue, value, i) => css`
    @media (max-width: ${breakpoints[i]}) {
      ${side(sideValue, value)}
    }
  `
}

const setPosition = (value, sideValue) => {
  const isArray = Array.isArray(value)

  return isArray
    ? value
        .map((item, i) => {
          const isLast = value.length - 1 === i
          return isLast
            ? position.withoutMediaSize(sideValue, item)
            : position.withMediaSize(sideValue, item, i)
        })
        .reverse()
    : css`
        ${side(sideValue, value)};
      `
}

export default setPosition
