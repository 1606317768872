import { model, attr, hasOne } from 'services/model'
import * as yup from 'yup'

const validationSchema = yup.object().shape({
  name: yup
    .string()
    .typeError('Field name has wrong type')
    .required('Field name is required'),
  offerId: yup
    .string()
    .typeError('Field offerId has wrong type')
    .default(null)
    .nullable(),
  imageUrl: yup
    .string()
    .typeError('Field imageUrl has wrong type')
    .default(null)
    .nullable(),
  description: yup
    .string()
    .typeError('Field description has wrong type')
    .default(null)
    .nullable()
})

const Mockup = model(
  'mockup',
  {
    name: attr('string'),
    offerId: hasOne('offer'),
    imageUrl: attr('string'),
    description: attr('string')
  },
  validationSchema
)

export default Mockup
