import styled, { css } from 'styled-components'

import { Title } from '../../../../components'

const AppIconPosition = styled.div`
  position: absolute;
  cursor: pointer;
  ${({ variant }) =>
    variant &&
    css`
      top: var(--app-icon-logo-${variant}-t);
      right: var(--app-icon-logo-${variant}-r);
    `}
  z-index:2;
`

const AppIconWrapper = styled.div`
  display: flex;
  cursor: pointer;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 100%;
`

const IconLogoWrapper = styled.div`
  display: flex;
  overflow: hidden;
  flex-direction: column;
  width: 100%;
  cursor: pointer;
  background-color: ${({ bg }) => (bg ? `var(--${bg}-bg)` : 'var(--dark-bg)')};
  ${({ variant }) =>
    variant &&
    css`
      width: var(--app-icon-logo-${variant}-w);
      height: var(--app-icon-logo-${variant}-h);
      border-radius: var(--app-icon-logo-${variant}-br);
    `}
`

const IconLogoImage = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
`

const TitleStyled = styled(Title)`
  font-size: var(--text-caption2-fs);
  line-height: var(--text-caption2-lh);
`
export {
  AppIconPosition,
  AppIconWrapper,
  IconLogoImage,
  IconLogoWrapper,
  TitleStyled
}
