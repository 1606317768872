import './AppIcon.vars.css'

import { Box, Img, Text } from '../../../../components'
import { useEffect, useState } from 'react'

import AppIconWrapper from './AppIcon.styled'
import { BRANDBOOK_CONFIG_REDUCER_CASES } from 'brandbook/__constants__'
import { IMAGE_DECORATIONS } from '../../../../__constants__'
import PropTypes from 'prop-types'
import { Upload } from 'antd'
import { uploadFile } from 'services/storage'
import { useBrandbookConfig } from 'brandbook/contexts/BrandbookConfig'

/**
 * It renders an app icon, or a placeholder if the app icon is not provided
 * @param props - This is the props object that is passed to the component.
 * @returns A component that displays an app icon and a grid logo.
 */
const AppIcon = (props) => {
  const { appIcon, onChange, filePath } = props
  const { LOGO_GRID } = IMAGE_DECORATIONS

  // [COMPONENT_STATE_HOOKS]
  const [file, setFile] = useState(null)
  const { brandbookConfigDataDispatch } = useBrandbookConfig()

  // [HELPER_FUNCTIONS]
  const onSelectAppIcon = (url) => {
    if (url) {
      brandbookConfigDataDispatch({
        type: BRANDBOOK_CONFIG_REDUCER_CASES.SET_APP_ICON,
        payload: url
      })
    }
  }

  const handleUpload = async ({ onSuccess, file }) => {
    const [url] = await uploadFile(file, `${filePath}/${file?.name}`)
    onChange?.(url)
    setFile(url)
    onSelectAppIcon(url)
    onSuccess()
  }

  // [LIFECYCLE]
  useEffect(() => appIcon && setFile(appIcon), [appIcon])

  return (
    <Upload cursor="pointer" fileList={null} customRequest={handleUpload}>
      <AppIconWrapper>
        <Box position="absolute">
          <Img src={LOGO_GRID} alt="grid logo" />
        </Box>

        {file ? (
          <Img width="100%" src={file} alt="appIcon" />
        ) : (
          <Text ff="primary" color="white" variant="subtitle2">
            Symbol
          </Text>
        )}
      </AppIconWrapper>
    </Upload>
  )
}

AppIcon.propTypes = {
  appIcon: PropTypes.string
}

export default AppIcon
