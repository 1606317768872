import {
  BRANDBOOK_CONFIG_KEYS,
  BRANDBOOK_CONFIG_REDUCER_CASES
} from 'brandbook/__constants__'

import { BoundedContainer } from 'brandbook/screens/Logo/LogoContent/components'
import { ContentScreen } from 'brandbook/components'
import { LOGO_BOTTOM_OFFSETS } from 'brandbook/screens/Logo/LogoContent/__constants__'
import { LogoCombinedView } from 'brandbook/domains'

const LogoGuideScreen = () => {
  return (
    <ContentScreen
      key="LogoGuideScreen"
      content={
        <BoundedContainer
          offsetBottom={LOGO_BOTTOM_OFFSETS.variant1}
          top="0"
          right="5%">
          <LogoCombinedView
            hasOffsets
            size="xxl"
            color="white"
            logoKey={BRANDBOOK_CONFIG_KEYS.GUIDE}
            reducerCase={BRANDBOOK_CONFIG_REDUCER_CASES.SET_LOGO_GUIDE}
          />
        </BoundedContainer>
      }
      chapter="Guide"
      domainName="Logo."
      pageNumber="14"
    />
  )
}

export default LogoGuideScreen
